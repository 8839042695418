<script>

    import { toast } from 'bulma-toast'

    import ShipmentsService from '../$services/shipments.service'

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import OrderUploadModal from '../orders/modal.upload.svelte'
    import LocationCreateModal from '../locations/modal.create.svelte'
    import PackageCreateModal from '../packages/modal.create.svelte'

    import ModalEmail from '../settings/modal.config.email.svelte'
    import ModalLink from '../settings/modal.config.link.svelte'
    import ModalTracking from '../settings/modal.config.tracking.svelte'
    import ModalConfigEmail from '../settings/modal.preview.email.svelte'

    import GraphicsView from '../graphics/component.view.svelte'

    let shipmentsTotal = null
    let loading = false
    
    getShipmentsTotal()

    async function getShipmentsTotal() {

        loading = true
        const resp = await ShipmentsService.getShipmentsTotal()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipmentsTotal = resp.data
    }

</script>

<Menu />

<OrderUploadModal />
<LocationCreateModal />
<PackageCreateModal />

<ModalEmail />
<ModalLink />
<ModalTracking />
<ModalConfigEmail />

<Container>
    <GraphicsView />
</Container>