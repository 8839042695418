<script>

    import QueryString from 'query-string'
    import { replace, querystring } from 'svelte-spa-router'
    import { afterUpdate } from 'svelte'
    import { toast } from 'bulma-toast'
    import { tutorialStore } from '../stores'

    import TutorialsService from '../$services/tutorials.service'
    import DateMethod from '../$methods/date.methods'

    import Button from '../$components/button.svelte'

    afterUpdate(() => {
        
        const videos = document.getElementsByTagName('video')

        if(!videos || !videos.length)
            return

        for(let i=0; i<videos.length; i++)
            videos[i].currentTime = 2
    })

    let loading = false

    getTutorials()

    async function getTutorials() {

        const query = $querystring? QueryString.parse($querystring, {parseBooleans: true}) : {}
    
        loading = true
        const resp = await TutorialsService.getTutorials({find: query.title})
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(!resp.data || !resp.data.tutorials || !resp.data.tutorials.length)
            return replace(`#/tutorials`)
    
        tutorialStore.set(resp.data.tutorials[0])
        setViewed()
    }

    async function setViewed() {

        loading = true
        const resp = await TutorialsService.setViewed($tutorialStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    }

</script>

<style>
    video{
        width: 100%;
    }

    .center {
        width: 100%;
        text-align: center;
    }
</style>

{#if $tutorialStore}

    <Button text="Tutoriales" icon="photo-video" on:click={() => replace(`#/tutorials`)} />

    <div class="title center space-bottom">{ $tutorialStore.title }</div>

    <div class="columns">
        <div class="column is-7">
            <video controls >
                <source src={$tutorialStore.urlVideo} type="video/mp4" >
                <track kind="captions"/>
            </video>
            <div class="items-end">{$tutorialStore.views} vistas • publicado el { DateMethod.dateTimeLarge($tutorialStore.updated) }</div>
        </div>
        <div class="column">
            <div class="content">{@html $tutorialStore.description}</div>
        </div>
    </div>
{/if}