<script>

    import { shipmentsSelectedStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import DownloadComponent from './component.download.svelte'

    $: show = $modalStore === 'shipments_download_modal'

</script>

<Modal {show}>
    <DownloadComponent shipments={$shipmentsSelectedStore} />
</Modal>