<script>

    import { toast } from 'bulma-toast'
    import { merchandisesStore, modalStore } from '../stores'

    import MerchandisesService from '../$services/merchandises.service'

    import Button from '../$components/button.svelte'
    import Table from '../$components/table.svelte'

    let loading = false

    getMerchandises()
    
    async function getMerchandises() {
    
        loading = true
        const resp = await MerchandisesService.getMerchandises()
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        merchandisesStore.set(resp.data)
    }

    async function updateMerchandiseDefault(merchandise) {
    
        loading = true
        const resp = await MerchandisesService.updateMerchandiseDefault(merchandise._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getMerchandises()
    }

</script>

<div class="items-end">
    <button on:click={() => modalStore.set('merchandise_create_modal')} class="button">
        <span class="icon"><i class="fas fa-plus"></i></span>
        <span>Agregar</span>
    </button>
    <br>
</div>

<Table items={$merchandisesStore.length} {loading} >
    <thead>
        <tr>
            <th>
                <div class="icon-text">
                    <span class="icon">
                      <i class="fas fa-tag"></i>
                    </span>
                    <span>Principal</span>
                </div>
            </th>
            <th>
                <div class="icon-text">
                    <span class="icon">
                    <i class="fas fa-clipboard-list"></i>
                    </span>
                    <span>Alias</span>
                </div>
            </th>
            <th>
                <div class="icon-text">
                    <span class="icon">
                    <i class="fas fa-clipboard-list"></i>
                    </span>
                    <span>Producto</span>
                </div>
            </th>
            <th>
                <div class="icon-text">
                    <span class="icon">
                    <i class="fas fa-clipboard-list"></i>
                    </span>
                    <span>Items</span>
                </div>
            </th>
            <th>
                <div class="icon-text">
                    <span class="icon">
                    <i class="fas fa-clipboard-list"></i>
                    </span>
                    <span>Valor</span>
                </div>
            </th>
            <th>
                <div class="icon-text">
                    <span class="icon">
                    <i class="fas fa-clipboard-list"></i>
                    </span>
                    <span>Unidad de medida</span>
                </div>
            </th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        {#each $merchandisesStore as merchandise}
            <tr>
                <td>
                    <div class="field">
                        <input on:click={() => updateMerchandiseDefault(merchandise)} bind:checked={merchandise.default} class="is-checkradio is-primary" id="{merchandise._id}" type="checkbox">
                        <label for="{merchandise._id}"></label>
                    </div>
                </td>
                <td>{ merchandise.alias }</td>
                <td>{ merchandise.product.name }</td>
                <td>{ merchandise.items }</td>
                <td>${ merchandise.value }</td>
                <td>{ merchandise.unit.name }</td>
                <td>
                    <Button on:click={() => modalStore.open('merchandise', 'modal', merchandise)} icon="ellipsis-v" />
                </td>
            </tr>
        {/each}
    </tbody>
</Table>