<script>

    import { modalStore } from '../stores'

    import ProfileUpdateComponent from './component.profile.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'profile_update_modal'

</script>

<Modal title="Actualizar perfil" {show} >
    <ProfileUpdateComponent />
</Modal>