<script>

    import DateMethod from '../$methods/date.methods.js'
    import Value from '../$components/value.svelte'

    export let fedex = null

</script>

<div class="columns">
    <div class="column">
        <Value label="Alias" value={ fedex.alias } />
    </div>
    <div class="column">
        <Value label="Proveedor" value={ fedex.provider } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Cuenta activa" value={ fedex.active ? 'Si' : 'No' } />
    </div>
    <div class="column">
        <Value label="Fecha" value={ DateMethod.dateTimeLarge(fedex.created) } />
    </div>
</div>