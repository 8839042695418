<script>

    import QueryString from 'query-string'

    import { querystring } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'
    import { courseStore } from '../stores'

    import CoursesService from '../$services/courses.service'

    import Menu from '../$layouts/menu.layout.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import CourseDetailComponent from './component.course.detail.svelte'
    import CourseModal from './modal.course.svelte'

    const query = $querystring? QueryString.parse($querystring, {parseBooleans: true}) : {}

    let loading = false

    findCourse()
    
    async function findCourse() {
    
        loading = true
        const resp = await CoursesService.findCourse(query)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        courseStore.set(resp.data)
    }

</script>

<Menu />

<Container>

    <Spinner {loading} />

    {#if $courseStore}
        <CourseDetailComponent />
    {/if}
</Container>

<CourseModal />