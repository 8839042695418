<script>

    import { link } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'

    import PostsService from "../$services/posts.service"

    import Spinner from '../$components/spinner.svelte'

    let loading = false
    let postsBests = []

    getPostsBests()
    
    async function getPostsBests() {
    
        loading = true
        const resp = await PostsService.getPostsBests()
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        postsBests = resp.data
    }

</script>

<Spinner {loading} />

{#if !loading}
    <div class="title">Top</div>
    <hr>
    <div class="table-container">
        <table class="table is-fullwidth is-striped">
            <tbody>
                {#each postsBests as post, index}
                    <tr>
                        <td>
                            <div>{ index+1 }</div>
                        </td>
                        <td>
                            <div class="items-space">
                                <a href="/post/{ post._id }" use:link >{ post.title }</a>
                                <div class="items-end">
                                    <span class="space-right">{ post.likesTotal }</span>
                                    <span class="icon"><i class="fas fa-heart"></i></span>
                                </div>
                            </div>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}