<script>

    import { toast } from 'bulma-toast'

    import LocationsService from '../$services/locations.service'

    import InputEmail from '../$components/input.email.svelte'
    import InputText from '../$components/input.text.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Select from '../$components/select.svelte'

    import ZipcodeColonyComponent from './component.zipcode.colony.svelte'

    export let location = {}

    let locations = []
    let loading = false

    getLocations()

    async function getLocations() {

        loading = true
        const resp = await LocationsService.getLocations({})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        locations = resp.data.locations
        location = locations.find(item => item.default) || {}
    }

    function setLocation(event) {
        if(event.detail)
            location = event.detail
    }

</script>

<Spinner {loading} />
 
{#if !loading}

    <Select value={ location._id || '' } on:data={ setLocation } label="Selecciona una dirección" icon="list" 
        options={locations.map(item => {
            return {value: item._id, text: item.alias, data: item}
        })}
    />

    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.contact } label="Contacto" icon="user" placeholder="Nombre del contacto" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.corporate } label="Empresa" icon="building" placeholder="Nombre de la empresa" required={false}/>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.phone } label="Teléfono" icon="phone" placeholder="Número de teléfono" />
        </div>
        <div class="column">
            <InputEmail bind:value={ location.email } label="Correo electrónico" icon="envelope" placeholder="Correo electrónico" />
        </div>
    </div>
    <ZipcodeColonyComponent
        bind:zipcode={location.zipcode}
        bind:state={location.state}
        bind:city={location.city}
        bind:municipality={location.municipality}
        bind:colony={location.colony}
    />
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.street } label="Calle" icon="map-marker-alt" placeholder="Calle" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.number } label="Número exterior" icon="hashtag" placeholder="Número exterior" />
        </div>
        <div class="column">
            <InputText bind:value={ location.apartment } label="Número interior" icon="building" placeholder="Número interior" required={false}/>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.references } label="Referencias" icon="asterisk" minLength="10" placeholder="Referencias" />
        </div>
    </div>       
{/if}