<script>

    import { modalStore, redpacksStore, estafetasStore, paquetexpresssStore, ampmsStore, dhlsStore, fedexsStore, N99MinutossStore, treggosStore, upssStore  } from '../stores'

    import AccountsCreateModal from './modal.accounts.svelte'

    import RedpackAccountComponent from '../redpack/component.redpack.accounts.svelte'
    import RedpackAccountModal from '../redpack/modal.redpack.account.svelte'
    import RedpackFoliosModal from '../folios/modal.redpack.folios.svelte'
    import RedpackAccountUpdateModal from '../redpack/modal.update.svelte'

    import EstafetaAccountComponent from '../estafeta/component.estafeta.accounts.svelte'
    import EstafetaAccountModal from '../estafeta/modal.estafeta.account.svelte'
    import EstafetaAccountUpdateModal from '../estafeta/modal.update.svelte'

    import PaquetexpressAccountComponent from '../paquetexpress/component.paquetexpress.accounts.svelte'
    import PaquetexpressAccountModal from '../paquetexpress/modal.paquetexpress.account.svelte'
    import PaquetexpressAccountUpdateModal from '../paquetexpress/modal.update.svelte'

    import AmpmAccountComponent from '../ampm/component.ampm.accounts.svelte'
    import AmpmAccountModal from '../ampm/modal.ampm.account.svelte'
    import AmpmAccountUpdateModal from '../ampm/modal.update.svelte'

    import DhlAccountComponent from '../dhl/component.dhl.accounts.svelte'
    import DhlAccountModal from '../dhl/modal.dhl.account.svelte'
    import DhlAccountUpdateModal from '../dhl/modal.update.svelte'

    import FedexAccountComponent from '../fedex/component.fedex.accounts.svelte'
    import FedexAccountModal from '../fedex/modal.fedex.account.svelte'
    import FedexAccountUpdateModal from '../fedex/modal.update.svelte'

    import N99MinutosAccountComponent from '../99minutos/component.99minutos.accounts.svelte'
    import N99MinutosAccountModal from '../99minutos/modal.99minutos.account.svelte'
    import N99MinutosAccountUpdateModal from '../99minutos/modal.update.svelte'

    import TreggoAccountComponent from '../treggo/component.treggo.accounts.svelte'
    import TreggoAccountModal from '../treggo/modal.treggo.account.svelte'
    import TreggoAccountUpdateModal from '../treggo/modal.update.svelte'

    import UpsAccountComponent from '../ups/component.ups.accounts.svelte'
    import UpsAccountModal from '../ups/modal.ups.account.svelte'
    import UpsAccountUpdateModal from '../ups/modal.update.svelte'

    import Message from '../$components/message.svelte'

</script>

<AccountsCreateModal />

<RedpackAccountModal />
<RedpackFoliosModal />
<RedpackAccountUpdateModal />

<EstafetaAccountModal />
<EstafetaAccountUpdateModal />

<PaquetexpressAccountModal />
<PaquetexpressAccountUpdateModal />

<AmpmAccountModal />
<AmpmAccountUpdateModal />

<DhlAccountModal />
<DhlAccountUpdateModal />

<FedexAccountModal />
<FedexAccountUpdateModal />

<N99MinutosAccountModal />
<N99MinutosAccountUpdateModal />

<TreggoAccountModal />
<TreggoAccountUpdateModal />

<UpsAccountModal />
<UpsAccountUpdateModal />

<div class="items-end">
    <button on:click={() => modalStore.set('account_create_modal')} class="button">
        <span class="icon"><i class="fas fa-plus"></i></span>
        <span>Agregar cuenta con paquetería</span>
    </button>
    <br>
</div>

<Message show={ !$redpacksStore.length && !$estafetasStore.length && !$paquetexpresssStore.length && !$ampmsStore.length && !$dhlsStore.length && !$fedexsStore.length && !$N99MinutossStore && !$treggosStore && !$upssStore } />

<RedpackAccountComponent />
<EstafetaAccountComponent />
<PaquetexpressAccountComponent />
<AmpmAccountComponent />
<DhlAccountComponent />
<FedexAccountComponent />
<N99MinutosAccountComponent />
<TreggoAccountComponent />
<UpsAccountComponent />