<script>

    import { toast } from 'bulma-toast'
    import { importantNotificationsStore, importantNotificationsGlobalStore } from '../stores'
    
    import NotificationsService from '../$services/notifications.service'

    let loading = false

    getImportantNotifications()

    async function getImportantNotifications() {

        loading = true
        const resp = await NotificationsService.getImportantNotifications(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        importantNotificationsStore.set(resp.data.user)
        importantNotificationsGlobalStore.set(resp.data.global)

    }

    async function updateViewNotification(notification) {
        
        const resp = await NotificationsService.updateViewNotification(notification._id)

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        importantNotificationsStore.remove(notification._id)
}

</script>

<style>
    .notification-item {
        max-width: 50%;
    }
</style>
{#if !loading}
    {#if $importantNotificationsGlobalStore.length}
        <div class="notification-items">
            {#each $importantNotificationsGlobalStore as notificationGlobal}
                {#if !notificationGlobal.view}
                    <div class="notification-item">
                        <div class="notification">
                            <button on:click={() => updateViewNotification(notificationGlobal)} class="delete"></button>
                            <div class="title is-5"><span class="icon"><i class="fas fa-bell"></i></span> { notificationGlobal.subject }</div>
                            <div class="subtitle is-6">{@html notificationGlobal.message }</div>
                            <div class="title is-6">{@html notificationGlobal.html }</div>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>
    {/if}
    {#if $importantNotificationsStore.length}
        <div class="notification-items">
            {#each $importantNotificationsStore as notificationUser}
                {#if !notificationUser.view} 
                    <div class="notification-item">
                        <div class="notification">
                            <button on:click={() => updateViewNotification(notificationUser)} class="delete"></button>
                            <div class="title is-5"><span class="icon"><i class="fas fa-bell"></i></span> {notificationUser.subject }</div>
                            <div class="subtitle is-6">{@html notificationUser.message }</div>
                            <div class="title is-6">{@html notificationUser.html }</div>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>
    {/if}
{/if}