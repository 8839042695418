<script>

    import { afterUpdate } from 'svelte'
    import { courseStore } from '../stores'

    import CourseComponent from './component.course.svelte'

    let showCourse = false

    afterUpdate(() => {

        const videos = document.getElementsByTagName('video')

        if(!videos || !videos.length)
            return

        for(let i=0; i<videos.length; i++)
            videos[i].currentTime = 3
    })

</script>

<style>
    .contenido {
        padding: 10px;
        border: solid 1px #ccc;
        border-radius: 15px;
        background: whitesmoke;
        margin: 1%;
    }

    p {
        text-align: justify;
    }
</style>

<div class="title center space-bottom">
    Marketing digital avanzado: ROI en redes sociales
</div>
<div class="subtitle center space-bottom">
    Por Katherine Méndez, Media Buyer Manager en PayJoy. Curso de Crehana.
</div>

{#if showCourse}
    <CourseComponent />
{:else}
    <div class="center">
        <video  controls width="80%">
            <source src={$courseStore.urlVideo} type="video/mp4">
            <track kind="captions"/>
            No soportado por el navegador
        </video>
    </div>
    <br/>
    <br/>
    <div class="columns">
        <div class="column">
            <div class="title is-4 center space-bottom">
                Aprende a calcular y maximizar el ROI en tus campañas de redes sociales con este curso de Marketing digital avanzado.
            </div>
            <div class="title is-3 center space-bottom">
                ¿Qué aprenderás en este curso?
            </div>
            <div>
                <div class="columns">
                    <div class="column">
                        <p><span class="icon"><i class="fas fa-check"></i></span>
                            Aprenderás los fundamentos del ROI y cómo medir el Retorno de la inversión en redes sociales paso a paso ¿Cómo saber si estás en un ROI positivo?
                        </p>
                        <br/>
                        <p><span class="icon"><i class="fas fa-check"></i></span>
                            Entenderás cómo calcular el ROI en Facebook e Instagram y aprenderás buenas prácticas para mejorar el ROI en redes sociales.
                        </p>
                        <br/>
                    </div>
                    <div class="column">
                        <p><span class="icon"><i class="fas fa-check"></i></span>
                            Revisarás cuáles son los factores de negocio que influyen en la medición del ROI en redes sociales e identificarás las métricas más importantes de tu negocio.
                        </p>
                        <br/>
                        <p><span class="icon"><i class="fas fa-check"></i></span>
                            Descubrirás las herramientas de recolección de datos más útiles para medir, extraer y analizar tu información.
                        </p>
                        <br/>
                    </div>
                </div>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                Descripción
            </div>
            <div>
                <p>
                    En este curso de Marketing Digital avanzado, aprenderás a calcular una de las métricas más complejas de medir en redes sociales: el ROI o Return of Investment. Con ello, además de saber el verdadero impacto de tu estrategia de social media, lograrás medir el valor de una venta por este canal y dejarás de pagar por publicidad sin saber el retorno de la inversión.
                </p>
                <br/>
                <p>
                    De la mano de Katherine Mendez y su amplia experiencia manejando presupuestos mensuales en redes sociales, aprenderás a extraer, analizar e interpretar tus datos para tomar decisiones que ofrezcan mayor rentabilidad a tu negocio.
                </p>
                <br/>
                <p>
                    A lo largo de este curso de Marketing digital avanzado y ROI para redes sociales, revisarás cuáles son los factores del negocio que influyen en la medición del ROI. Además, identificarás las métricas más importantes para tu negocio y verás qué herramientas de recolección de datos son las mejores para ti.
                </p>
                <br/>
                <p>
                    Al finalizar este curso, sabrás cómo calcular el ROI de tus redes sociales paso a paso y entenderás si estás dentro de un ROI positivo o negativo.
                </p>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                Contenidos
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 1: Antes de empezar
                </div>
                <div>
                    1 clase  2m 51s
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 2: Adentrándonos en las profundidades
                </div>
                <div>
                    4 clases  14m 23s
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 3: Herramientas para recolectar tus datos importantes
                </div>
                <div>
                    7 clases  28m 59s
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 4: Fundamentos de ROI
                </div>
                <div>
                    5 clases  15m 57s
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 5: ROI en Facebook e Instagram
                </div>
                <div>
                    3 clases  10m 39s
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 6: Concluciones
                </div>
                <div>
                    1 clase  1m 48s
                </div>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                ¿Qué necesito para llevar el curso de cálculo del ROI en redes sociales?
            </div>
            <div>
                <p><span class="icon"><i class="fas fa-arrow-right"></i></span>
                    Cuenta de Google Analytics.
                </p>
                <p><span class="icon"><i class="fas fa-arrow-right"></i></span>
                    Computadora o laptop con conexión a internet.
                </p>
                <p><span class="icon"><i class="fas fa-arrow-right"></i></span>
                    Excel.
                </p>
                <p><span class="icon"><i class="fas fa-arrow-right"></i></span>
                    Un proyecto enfocado en el desarrollo de una estrategia en redes sociales en el que lleves trabajando de 3 a 6 meses.
                </p>
                <p><span class="icon"><i class="fas fa-arrow-right"></i></span>
                    Cuenta comercial de Facebook Ads.
                </p>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                ¿Qué habilidades voy a aprender en este curso de Marketing Digital y ROI en redes sociales?
            </div>
            <div>
                <p><b><span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                    Análisis e Interpretación de datos</b></p>
                <p>
                    Serás capaz de analizar métricas de rendimiento de tus campañas y calcular el ROI de tus esfuerzos en redes sociales.
                </p>
                <br/>
                <p><b><span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                    Pensamiento estratégico</b></p>
                <p>
                    Lograrás tomar decisiones que beneficien a tu estrategia de redes sociales y rentabilicen la inversión de publicidad en Facebook e Instagram.
                </p>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                Información del curso
            </div>
            <div>
                <p><b>Duración: 1h 14m</b></p>
                <p>
                    Nivel Avanzado
                </p>
                <p>
                    21 clases de acceso ilimitado
                </p>
                <p>
                    Aprendizaje especializado
                </p>
                <p>
                    2 Archivos adjuntos
                </p>
            </div>
            <br/>
            <button on:click={() => (showCourse = true)} class="button is-danger is-large is-fullwidth">Ir al curso</button>
        </div>
    </div>
    <br>
    <br>
{/if}