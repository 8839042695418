import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createCheckout,
    createCheckoutLink,
    createCheckoutAccount
}

function createCheckout(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/stripe/checkout/credit`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createCheckoutLink(data, query) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/stripe/checkout/link`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createCheckoutAccount(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/stripe/checkout/account`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}