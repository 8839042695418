<script>

    import { toast } from 'bulma-toast'

    import ShipmentsService from '../$services/shipments.service'
    import Spinner from '../$components/spinner.svelte'
    import Date from '../$components/date.svelte'

    export let storeId = null

    let query = {}
    let loading = false
    let shipments = []
    let globalIndex = 0
    let element = null

    async function getShipmentsDownload() {

        query.storeId = storeId

        loading = true
        const resp = await ShipmentsService.getShipmentsDownload(query)

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipments = resp.data
        
        await downloadGuide()

        loading = false
    }

    async function downloadGuide(index = 0) {

        globalIndex = index

        if(index >= shipments.length)
            return

        const shipment = shipments[index]

        const blob = await ShipmentsService.getShipmentDocument(shipment._id)

        if(!blob)
            return toast({message: 'Error al descargar el reporte', type: 'is-danger'})

        const alias = shipment.order.tag? `.${shipment.order.tag}` : ''

        element.download = `${shipment.order.number}.${shipment.order.storeType}.${shipment.provider}.${shipment.guide}.${shipment.order.delivery.contact}${alias? alias: ''}.pdf`
        element.href = window.URL.createObjectURL(blob)
        element.click()
        
        return await downloadGuide(index+1)
    }

</script>

<style>
    form {
        padding: 200px 32px;
    }
</style>

{#if loading || globalIndex > 0}
    <div class="items-center col">
        <Spinner {loading} />
        <br>
        {#if globalIndex === shipments.length}
            <div class="title">Proceso finalizado <span class="icon"><i class="fas fa-laugh-wink"></i></span></div>
        {:else}
            <div class="title is-4">Descargando guías</div>
        {/if}
        <div class="subtitle is-5">{globalIndex} de {shipments.length}</div>
    </div>
{/if}

<a bind:this={element} href="/" style="display: none;">.</a>

{#if !loading && globalIndex === 0}

    <form on:submit|preventDefault={getShipmentsDownload}>

        <div class="columns">
            <div class="column">
                <Date label="Fecha de inicio" bind:value={query.dateStart} showTime={false} />
            </div>
            <div class="column">
                <Date label="Fecha final" bind:value={query.dateEnd} showTime={false} />
            </div>
        </div>

        <div class="items-end">
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Descargar</span>
            </button>
        </div>
    </form>
{/if}