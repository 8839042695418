<script>

    import { toast } from 'bulma-toast'
    import { packagesStore, packageStore, modalStore } from '../stores'

    import PackagesService from '../$services/packages.service'
    import Spinner from '../$components/spinner.svelte'
    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'

    const data = new Object({
        alias: $packageStore.alias,
        width: $packageStore.width,
        height: $packageStore.height,
        length: $packageStore.length,
        weight: $packageStore.weight,
        value: $packageStore.value,
        content: $packageStore.content,
    })

    let loading = false

    async function updatePackage() {

        loading = true
        const resp = await PackagesService.updatePackage($packageStore._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        packagesStore.replace(resp.data)
        packageStore.set(resp.data)

        toast({message: 'Paquete actualizado', type: 'is-success'})
        modalStore.set('package_modal')
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updatePackage}>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.alias } label="Alias" icon="address-book" placeholder="Identificador del paquete" required/>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputNumber bind:value={ data.width } label="Ancho" icon="ruler-horizontal" placeholder="Ancho, medida en cm" required/>
            </div>
            <div class="column">
                <InputNumber bind:value={ data.height } label="Alto" icon="ruler-vertical" placeholder="" required/>
            </div>
            <div class="column">
                <InputNumber bind:value={ data.length } label="Largo" icon="ruler" placeholder="Largo, medida en cm" required/>
            </div>
            <div class="column">
                <InputNumber bind:value={ data.weight } label="Peso" icon="weight" placeholder="Peso en kg" required/>
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <InputNumber bind:value={ data.value } label="envío" icon="dollar-sign" placeholder="Valor declarado" required/>
            </div>
            <div class="column">
                <InputText bind:value={ data.content } label="Contenido" icon="" placeholder="Contenido, o descripción del paquete" required/>
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <Button on:click={() => modalStore.set('package_modal')} text="Cancelar" fullwidth={true} icon="ban"/>
                </div>
                <div class="column">
                    <Button type="submit" text="Editar" fullwidth={true} icon="edit" color="primary" />
                </div>
            </div>
        </div>
    </form>
{/if}