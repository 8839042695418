<script>

    import { shipmentStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import TrackingComponent from './component.tracking.svelte'
    
    $: show = $modalStore === 'tracking_modal'
    
</script>

{#if $shipmentStore}
<Modal title="Tracking {$shipmentStore.guide}" {show} >
        <TrackingComponent shipmentId={$shipmentStore._id} />
</Modal>
{/if}