<script>

    import DateMethod from '../$methods/date.methods.js'
    import Value from '../$components/value.svelte'

    export let paquetexpress = null

</script>

<div class="columns">
    <div class="column">
        <Value label="Alias" value={ paquetexpress.alias } />
    </div>
    <div class="column">
        <Value label="Proveedor" value={ paquetexpress.provider } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Cuenta activa" value={ paquetexpress.active ? 'Si' : 'No' } />
    </div>
    <div class="column">
        <Value label="Fecha" value={ DateMethod.dateTimeLarge(paquetexpress.created) } />
    </div>
</div>