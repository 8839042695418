<script>

    import { modalStore } from '../stores'

    import TreggoAccountUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'treggo_update_modal'

</script>

<Modal title="Actualizar cuenta" {show}>
    <TreggoAccountUpdateComponent />
</Modal>