<script>

    import { modalStore } from '../stores'

    import UpdateClientComponent from './component.update.client.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'facturama_update_client_modal'

</script>

<Modal title="Actualizar datos" {show} >
    <UpdateClientComponent />
</Modal>