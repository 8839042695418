import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getConfig,
    getConfigTracking,
    getConfigLink,
    updateConfig,
    updateConfigShopifyDefault,
    updateConfigWoocommerceDefault,
    updateConfigVtexDefault
}

function getConfig() {
    return new Promise((resolve, reject) => {

        if(!Storage.getItem('access'))
            return {success: true}

        Superagent
            .get(`${HOST_API}/configs`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getConfigTracking(userId) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/configs/tracking`)
            .query({userId})
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getConfigLink(userId) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/configs/link`)
            .query({userId})
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateConfig(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/configs`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateConfigShopifyDefault() {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/configs/shopify/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateConfigWoocommerceDefault() {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/configs/woocommerce/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateConfigVtexDefault() {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/configs/vtex/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}