<script>

    import { toast } from 'bulma-toast'
    import { configStore, modalStore, userStore } from '../stores'

    import ConfigsService from '../$services/configs.service'
    import BucketService from '../$services/bucket.service'

    import Switch from '../$components/switch.svelte'
    import Spinner from '../$components/spinner.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'
    import File from '../$components/file.svelte'

    let data = {
        sendPickup: $configStore && $configStore.email? $configStore.email.sendPickup : false,
        sendDelivery: $configStore && $configStore.email? $configStore.email.sendDelivery : false,
        message: $configStore && $configStore.email? $configStore.email.message : null,
        facebook: $configStore && $configStore.email? $configStore.email.facebook : null,
        pinterest: $configStore && $configStore.email? $configStore.email.pinterest : null,
        instagram: $configStore && $configStore.email? $configStore.email.instagram : null,
        pinterest: $configStore && $configStore.email? $configStore.email.pinterest : null,
        webpage: $configStore && $configStore.email? $configStore.email.webpage : null,
    }

    let heading = null
    let brand = null
    let loading = false

    async function updateConfig() {

        if(heading) {
            const success = await uploadFile(heading, 'heading')
            if(!success) return
        }

        if(brand) {
            const success = await uploadFile(brand, 'brand')
            if(!success) return
        }

        loading = true
        const resp = await ConfigsService.updateConfig({email: data})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)
        toast({message: 'Configuración actualizada', type: 'is-success'})
    }

    async function uploadFile(file, fileName) {

        const query = {
            fileType: file.type,
            fileDir: $userStore._id,
            fileName
        }

        loading = true
        const resp = await BucketService.getSigned(query)

        if(!resp.success) {
            toast({message: resp.error.message, type: 'is-danger'})
            return false
        }

        const {signed, url} = resp.data

        await BucketService.uploadFile(file, signed)

        toast({message: `Archivo ${file.name} cargado`, type: 'is-info'})

        data[fileName] = url
        return true
    }

</script>

<Spinner {loading} />

{#if !loading}
    <div class="columns">
        <div class="column">
            <div class="title">Correo personalizado</div>
            <form on:submit|preventDefault={updateConfig}>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ data.facebook } label="URL Facebook" iconBase="fab" icon="facebook" placeholder="URL de la página" required={false}/>
                        <InputText bind:value={ data.instagram } label="URL Instagram" iconBase="fab" icon="instagram" placeholder="URL de la página" required={false} />
                        <InputText bind:value={ data.pinterest } label="URL Pinterest" iconBase="fab" icon="pinterest" placeholder="URL de la página" required={false} />
                        <InputText bind:value={ data.webpage } label="URL Webpage" icon="globe" placeholder="URL de la página" required={false} />
                        <InputText bind:value={ data.message } label="Mensaje" icon="comment" placeholder="Mensaje al cliente" required={false} />
                    </div>
                    <div class="column">
                        <div class="field">
                            <label for="" class="label">Encabezado</label>
                            <div class="control">
                                <File bind:file={heading} required={false} />
                                <small>Recomendamos una imagen no mayor a 600px de largo</small>
                            </div>
                        </div>
                        <div class="field">
                            <label for="" class="label">Logo</label>
                            <div class="control">
                                <File bind:file={brand} required={false} />
                                <small>Recomendamos una imagen no mayor a 300px de largo y ancho</small>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label for="" class="label">Enviar correo de confirmación al origen</label>
                                    <div class="control">
                                        <Switch bind:checked={data.sendPickup} />
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label for="" class="label">Enviar correo de confirmación al destino</label>
                                    <div class="control">
                                        <Switch bind:checked={data.sendDelivery} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="items-end">
                    <div class="columns">
                        <div class="column">
                            <Button on:click={ () => modalStore.set('config_email_modal') } text="Ver correo" icon="eye" fullwidth={ true }/>
                        </div>
                        <div class="column">
                            <Button type="submit" text="Guadar" icon="save" color="primary" fullwidth={ true }/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
{/if}