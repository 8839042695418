<script>

	import 'flatpickr/dist/flatpickr.css'

    import Moment from 'moment'
    import Flatpickr from 'svelte-flatpickr'

    export let label = ''
    export let value = Moment().startOf('day').toDate()

    export let minDate = null
    export let maxDate = null
    export let showTime = true

    const options = {
        minDate,
        maxDate,
        altInput: true,
        allowInput: true,
        disableMobile: true,
        enableTime: showTime,
        dateFormat: "Y-m-d H:i"
        // formatDate: (date, format, locale) => {
        //     return Moment(value).locale('es').format(showTime? 'dddd DD MMMM YYYY h:mm A' : 'dddd DD MMMM YYYY')
        // },
        // parseDate: (datestr, format) => {
        //     return Moment(value).locale('es').toDate()
        // }
    }

</script>

<div class="field">
    {#if label}
        <label for="" class="label">{ label }</label>
    {/if}
    <div class="control has-icons-left">
        <span class="icon"><i class="fas fa-calendar"></i></span>
        <Flatpickr {options} bind:value />
    </div>
</div>