<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import PackageCreateComponent from './component.create.svelte'

    $: show = $modalStore === 'package_create_modal'

</script>

<Modal title="Agregar paquete" {show} >
    <PackageCreateComponent />
</Modal>