import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getStores,
    updateStore,
    deleteStore
}

function getStores(query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/stores`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateStore(storeId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/stores/${storeId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteStore(storeId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/stores/${storeId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}