<script>

    import { toast } from 'bulma-toast'

    import { createEventDispatcher } from 'svelte'

    import { shopifysStore, modalStore } from '../stores'
    import ShopifysService from '../$services/shopify.service'

    import Spinner from '../$components/spinner.svelte'

    const dispatch = createEventDispatcher()

    let data = {}
    let loading = false

    async function createShopifyApp() {

        loading = true
        const resp = await ShopifysService.createShopifyApp(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shopifysStore.append(resp.data)
        toast({message: 'Solicitud envíada', type: 'is-success'})
        modalStore.close()
    }

</script>

<style>

    .items-center {
        max-height: 180px;
        margin: 8px;
    }

    .items-center img {
        height: 120px !important;
        width: 100%;
    }
    
</style>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createShopifyApp}>
        <div class="items-center">
            <img src="images/shopify.svg" alt="Shopify"> 
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Dominio</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-store"></i></span>
                        <input bind:value={data.domain} type="text" class="input" pattern="(.+)(.myshopify.com)" placeholder="tienda.myshopify.com" required>
                    </div>
                    <small>dominio "myshopify.com"</small>
                </div>
            </div>
        </div>
        <div class="items-end">
            <button on:click={() => dispatch('close')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Solicitar integración</span>
            </button>
        </div>
    </form>
{/if}