<script>

    export let file = null
    export let required = true
    export let accept = null
    export let label = null

    $: files? file = files[0] : null
    let files;

</script>

<div class="field">
    {#if label}
        <label for="" class="label">{ label }</label>
    {/if}
    <div class="control">
        <div class="file has-name is-fullwidth">
            <label class="file-label">
                <input bind:files class="file-input" type="file" name="resume" {accept} {required}>
                <span class="file-cta">
                    <span class="file-icon"><i class="fas fa-upload"></i></span>
                    <span class="file-label">Selecciona</span>
                </span>
                <span class="file-name">{ file? file.name : 'No se ha selecciona un archivo' }</span>
            </label>
        </div>
    </div>
</div>