<script>

    import {toast} from 'bulma-toast'

    import TrackingsService from '../$services/trackings.service'
    import DateMethod from '../$methods/date.methods.js'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    export let shipmentId = null

    let loading = false
    let trackings = []

    getTracking()

    async function getTracking() {

        loading = true
        const resp = await TrackingsService.getTracking(shipmentId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        trackings = resp.data
    }

</script>

<Message show={!loading && !trackings.length} />
<Spinner {loading} />

{#if !loading && trackings.length}
    <div class="timeline">
        <header class="timeline-header">
            <span class="tag is-medium">Inicio</span>
        </header>
        {#each trackings as tracking}
            <div class="timeline-item">
                <div class="timeline-marker"></div>
                <div class="timeline-content">
                    <p class="heading">{ tracking.name }</p>
                    <div>
                        <div>{ tracking.details }</div>
                        <small>{ DateMethod.dateTimeLarge(tracking.created) }</small>
                    </div>
                </div>
            </div>
        {/each}
        <div class="timeline-header">
            <span class="tag is-medium">Final</span>
        </div>
    </div>
{/if}