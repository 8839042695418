<script>

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import Tabs from '../$components/tabs.svelte'

    import Merchandises from '../merchandises/component.merchandises.svelte'
    import MerchandiseModal from '../merchandises/modal.merchandise.svelte'
    import MerchandiseCreateModal from '../merchandises/modal.create.svelte'
    import MerchandiseUpdateModal from '../merchandises/modal.update.svelte'

    import StocksGroup from '../stocks/component.stocks.group.svelte'

    let selected = 'merchandises'

</script>

<MerchandiseModal />
<MerchandiseCreateModal />
<MerchandiseUpdateModal />

<Menu />
<Container>

    <Tabs bind:selected options={[
        {key: 'merchandises', name: 'Mercancias', icon: 'store'},
        {key: 'stocks', name: 'Stock', icon: 'store'},
    ]} />

    {#if selected === 'merchandises'}
        <Merchandises />
    {:else if selected === 'stocks'}
        <StocksGroup />
    {/if}

</Container>