<script>

    import { fedexStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import FedexAccountComponent from './component.fedex.account.svelte'

    $: show = $modalStore === 'fedex_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <FedexAccountComponent fedex={$fedexStore} />
</Modal>