<script>

    import { toast } from 'bulma-toast'
    import { storeStore, shipmentsStore, shipmentsSelectedStore, modalStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'
    import DateMethod from '../$methods/date.methods.js'

    import Pagination from '../$components/pagination.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    $: $storeStore && getShipmentsStore(true)

    let query = {
        page: 0,
        find: null,
        trackingStatus: ''
    }

    let metadata = {}
    let loading = true
    let checkedGlobal = false
    let checkedOrders = false
    let checkedCancels = false

    async function getShipmentsStore(clean = false) {

        shipmentsStore.set([])

        if(clean === true)
            query = {page: 0, find: null, trackingStatus: ''}

        if(query.find)
            query.find = query.find.trim()

        loading = true
        const resp = await ShipmentsService.getShipmentsStore($storeStore._id, query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        const shipments = resp.data.shipments
        const shipment = shipments.length? shipments[0] : null

        if(shipment && shipment.storeId != $storeStore._id)
            return

        checkedGlobal = false
        checkedOrders = false
        checkedCancels = false
        metadata = resp.data.metadata

        shipmentsStore.set(resp.data.shipments)
    }

    function findShipments(event) {
        if(event.keyCode === 13) {
            query.page = 0
            getShipmentsStore()
        }
    }

    function verifyCheckeds() {
        setTimeout(() => {
            checkedGlobal = $shipmentsStore.find(item => item.checked === true)
        }, 100)
    }

    function onCheckedGlobal() {

        checkedGlobal = !checkedGlobal

        shipmentsStore.set($shipmentsStore.map(shipment => {
            shipment.checked = checkedGlobal
            return shipment
        }))
    }

    function downloadGuides() {
        const shipments = $shipmentsStore.filter(shipment => shipment.checked && shipment.status === 'progress')
        shipmentsSelectedStore.set(shipments)
        modalStore.set('shipments_download_modal')
    }

    function createOrders() {
        const shipments = $shipmentsStore.filter(shipment => shipment.checked && shipment.type === 'national')
        shipmentsSelectedStore.set(shipments)
        modalStore.set('shipments_create_orders_modal')
    }

    function cancelShipments() {
        const shipments = $shipmentsStore.filter(shipment => shipment.checked && shipment.status != 'canceled')
        shipmentsSelectedStore.set(shipments)
        modalStore.set('shipments_cancel_shipments_modal')
    }

    function mapPaymentType(paymentType) {
        switch(paymentType) {
            case 'wallet': return 'Saldo'
            case 'prepaid': return 'Prepago'
            case 'account': return 'Cuenta'
        }
    }

</script>

<Spinner {loading} />

{#if !loading }
    <div class="columns">
        {#if checkedGlobal}
            <div class="column is-narrow">
                <button on:click={downloadGuides} class="button is-primary" data-tooltip="Descargar guías">
                    <span class="icon"><i class="fas fa-file-download"></i></span>
                </button>
            </div>
            <div class="column is-narrow">
                <button on:click={createOrders} class="button is-primary" data-tooltip="Duplicar ordenes">
                    <span class="icon"><i class="fas fa-copy"></i></span>
                </button>
            </div>
            <div class="column is-narrow">
                <button on:click={cancelShipments} class="button is-primary" data-tooltip="Cancelar envios">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                </button>
            </div>
        {/if}
        <div class="column">
            <div class="field">
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-search"></i></span>
                    <input bind:value={query.find} on:keydown={findShipments} type="text" class="input" placeholder="Buscar" minlength="6" >
                </div>
            </div>
        </div>
        <div class="column is-narrow">
            <button on:click={() => modalStore.set('shipments_download_report_modal')} class="button" data-tooltip="Descargar reporte">
                <span class="icon"><i class="fas fa-file-excel"></i></span>
            </button>
        </div>
        <div class="column is-narrow">
            <button on:click={() => modalStore.set('shipments_download_massive_modal')} class="button" data-tooltip="Descargar guías masiva">
                <span class="icon"><i class="fas fa-cloud-download-alt"></i></span>
            </button>
        </div>
    </div>

    <div class="items-space space-bottom">
        <small>
            { $shipmentsStore.length }
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <div class="items-end">
            <div class="column is-narrow">
                <div class="field">
                    <div class="control">
                        <div class="select is-small">
                            <select bind:value={query.trackingStatus} on:change={getShipmentsStore}>
                                <option value="">Estatus de envío</option>
                                <option value="Guía generada">Guía generada</option>
                                <option value="En camino">En camino</option>
                                <option value="Revisión">Revisión</option>
                                <option value="Entregado">Entregado</option>
                                <option value="Cancelado">Cancelado</option>
                                <option value="Finalizado">Finalizado</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination bind:metadata bind:page={ query.page } on:change={ getShipmentsStore } />
        </div>
    </div>
{/if}

<Message show={!loading && !$shipmentsStore.length} />

{#if !loading && $shipmentsStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>
                        <div class="items-space">
                            <div class="field">
                                <input on:click={onCheckedGlobal} bind:checked={checkedGlobal} class="is-checkradio is-primary" id="mainCheck" type="checkbox">
                                <label for="mainCheck"></label>
                            </div>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-hashtag"></i>
                            </span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-truck"></i>
                            </span>
                            <span>Servicio</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-map-marker-alt"></i>
                            </span>
                            <span>Destino</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-check"></i>
                            </span>
                            <span>Estatus</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha de envío</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $shipmentsStore as shipment, index}
                    <tr>
                        <td>
                            <div class="field">
                                <input on:click={verifyCheckeds} bind:checked={shipment.checked} class="is-checkradio is-primary" id="{shipment._id}" type="checkbox">
                                <label for="{shipment._id}"></label>
                            </div>
                        </td>
                        <td>
                            <div>{ shipment.order.number }</div>
                            {#if shipment.status === 'canceled'}
                                <div><span class="tag is-danger">Cancelado</span></div>
                            {/if}
                        </td>
                        <td>
                            <div>${ parseFloat(shipment.pricing.finalPrice).toFixed(2) }</div>
                            <div>
                                <span class="tag is-info">{ mapPaymentType(shipment.pricing.paymentType) }</span>
                            </div>
                        </td>
                        <td>
                            <div><b>{ shipment.provider }</b>, { shipment.service }</div>
                            <div class="items-start">
                                <button class="button space-right">
                                    <span on:click={() => modalStore.open('tracking', 'modal', shipment)} class="icon"><i class="fas fa-map-marker-alt"></i></span>
                                </button>
                                <div>{ shipment.guide }</div>
                            </div>
                        </td>
                        <td>
                            <div>{ shipment.order.delivery.contact }</div>
                            <div>{ shipment.order.delivery.zipcode } { shipment.order.delivery.city }, { shipment.order.delivery.state }</div>
                        </td>
                        <td>
                            <div>{ shipment.trackingStatus || 'Guía generada' }</div>
                            {#if shipment.pickup && DateMethod.getOffsetDays(shipment.pickup.date) >= 0 }
                                <div class="tag is-success">Agendado { DateMethod.dateShort(shipment.pickup.date) }</div>
                            {/if}
                        </td>
                        <td>{ DateMethod.dateTimeShort(shipment.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('shipment', 'modal', shipment)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}