<script>

    import { toast } from 'bulma-toast'

    import PackagesService from '../$services/packages.service'

    import Select from '../$components/select.svelte'
    import Spinner from '../$components/spinner.svelte'
    import InputText from '../$components/input.text.svelte'
    import InputNumber from '../$components/input.number.svelte'
    import Switch from '../$components/switch.svelte'

    export let ipackage = {}
    export let showSelectValue = false

    let packages = []
    let loading = false

    getPackages()

    async function getPackages() {

        loading = true
        const resp = await PackagesService.getPackages()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        packages = resp.data
        ipackage = resp.data.find(item => item.default) || {}
    }

    function setPackage(event) {
        if(event.detail)
            ipackage = event.detail
    }

</script>

<Spinner {loading} />

<Select value={ ipackage._id || '' } on:data={ setPackage } label="Selecciona un paquete" icon="list"
    options={packages.map(item => {
        return {value: item._id, text: item.alias, data: item}
    })}
/>

<div class="columns">
    <div class="column">
        <InputNumber bind:value={ ipackage.width } label="Ancho" icon="ruler-horizontal" placeholder="Ancho, medida en cm"/>
    </div>
    <div class="column">
        <InputNumber bind:value={ ipackage.height } label="Alto" icon="ruler-vertical" placeholder="Alto, medida en cm"/>
    </div>
    <div class="column">
        <InputNumber bind:value={ ipackage.length } label="Largo" icon="ruler" placeholder="Largo, medida en cm"/>
    </div>
    <div class="column">
        <InputNumber bind:value={ ipackage.weight } label="Peso" icon="weight" placeholder="Peso fisico en kg"/>
    </div>
</div>
<div class="columns">
    <div class="column">
        <InputText bind:value={ ipackage.content } label="Contenido del paquete" icon="asterisk" placeholder="Contenido"/>
    </div>
    <div class="column">
        <InputNumber bind:value={ ipackage.value } label="Asegurar paquete" icon="dollar-sign" placeholder="Valor del contenido"/>
    </div>
</div>

{#if showSelectValue}
    <div class="columns">
        <div class="column">
            <Switch bind:checked={ ipackage.selectValue } label="El cliente ingresará el monto de seguro" />
        </div>
    </div>
{/if}