<script>

    import { modalStore } from '../stores'
    
    import Modal from '../$components/modal.svelte'
    import UpdateComponent from './component.update.svelte'

    $: show = $modalStore === 'store_update_modal'

</script>

<Modal title="Actualizar tienda" {show} >
    <UpdateComponent />
</Modal>