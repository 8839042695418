<script>

    import { toast } from 'bulma-toast'
    import { ordersStore, orderStore, modalStore } from '../stores'

    import OrdersService from '../$services/orders.service'
    import ZipcodeColonyComponent from '../locations/component.zipcode.colony.svelte'
    import Spinner from '../$components/spinner.svelte'

    const order = new Object({
        _id: $orderStore._id,
        delivery: {
            contact: $orderStore.delivery.contact,
            corporate: $orderStore.delivery.corporate,
            phone: $orderStore.delivery.phone,
            email: $orderStore.delivery.email,
            zipcode: $orderStore.delivery.zipcode,
            state: $orderStore.delivery.state,
            city: $orderStore.delivery.city,
            municipality: $orderStore.delivery.municipality,
            colony: $orderStore.delivery.colony,
            street: $orderStore.delivery.street,
            number: $orderStore.delivery.number,
            apartment: $orderStore.delivery.apartment,
            references: $orderStore.delivery.references
	    }
    })
    
    let loading = false

    async function updateOrder() {

        loading = true
        const resp = await OrdersService.updateOrder(order._id, order)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ordersStore.replace(resp.data)
        orderStore.set(resp.data)

        toast({message: 'Dirección de destino actualizada', type: 'is-success'})
        modalStore.set('order_modal')
    }

</script>

<Spinner {loading} />

{#if !loading} 
    <form on:submit|preventDefault={updateOrder}>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Contacto</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={order.delivery.contact} type="text" class="input" placeholder="Nombre del contacto">
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Empresa</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-building"></i></span>
                        <input bind:value={order.delivery.corporate} type="text" class="input" placeholder="Nombre de la empresa">
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Teléfono</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-phone"></i></span>
                        <input bind:value={order.delivery.phone} type="text" class="input" placeholder="Número de teléfono">
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Correo electronico</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-envelope"></i></span>
                        <input bind:value={order.delivery.email} type="text" class="input" placeholder="Correo del contacto">
                    </div>
                </div>
            </div>
        </div>

        <ZipcodeColonyComponent 
            bind:zipcode={order.delivery.zipcode}
            bind:state={order.delivery.state}
            bind:city={order.delivery.city}
            bind:municipality={order.delivery.municipality}
            bind:colony={order.delivery.colony}
        />
        
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Calle</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-road"></i></span>
                        <input bind:value={order.delivery.street} type="text" class="input" placeholder="Calle">
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Número exterior</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-hashtag"></i></span>
                        <input bind:value={order.delivery.number} type="text" class="input" placeholder="Número exterior de la casa/apartamento">
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Número interior</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-building"></i></span>
                        <input bind:value={order.delivery.apartment} type="text" class="input" placeholder="Número interior del apartamento">
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Referencias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-asterisk"></i></span>
                        <input bind:value={order.delivery.references} type="text" class="input" placeholder="Referencias de la casa/apartamento">
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
                <button on:click={() => modalStore.set('order_modal')} type="button" class="button space-right">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                    <span>Cancelar</span>
                </button>

                <button type="submit" class="button is-primary">
                    <span class="icon"><i class="fas fa-edit"></i></span>
                    <span>Editar</span>
                </button>
            </div>
    </form>
{/if}