<script>

    import { toast } from 'bulma-toast'
    import { packagesStore, modalStore } from '../stores'

    import PackagesService from '../$services/packages.service'
    import Spinner from '../$components/spinner.svelte'
    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'
    import Form from '../$components/form.svelte'

    let data = {}
    let loading = false

    async function createPackage() {

        loading = true
        const resp = await PackagesService.createPackage(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        toast({message: 'Paquete creado', type: 'is-success'})

        packagesStore.append(resp.data)
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <Form on:submit={ createPackage } >
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.alias } label="Alias" icon="address-book" placeholder="Identificador del paquete" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputNumber bind:value={ data.width } label="Ancho" icon="ruler-horizontal" placeholder="Ancho, medida en cm" />
            </div>
            <div class="column">
                <InputNumber bind:value={ data.height } label="Alto" icon="ruler-vertical" placeholder="Alto, medida en cm" />
            </div>
            <div class="column">
                <InputNumber bind:value={ data.length } label="Largo" icon="ruler" placeholder="Largo, medida en cm" />
            </div>
            <div class="column">
                <InputNumber bind:value={ data.weight } label="Peso" icon="weight" placeholder="Peso en kg" />
            </div>
        </div>
        <div class="columns">
            <div class="column is-3">
                <InputNumber bind:value={ data.value } label="Asegurar paquete" icon="dollar-sign" placeholder="Valor declarado" />
            </div>
            <div class="column">
                <InputText bind:value={ data.content } label="Contenido" icon="asterisk" placeholder="Contenido, o descripción del paquete" />
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <Button on:click={() => modalStore.close() } text="Cancelar" icon="ban"/>
                </div>
                <div class="column">
                    <Button type="submit" text="Guardar" color="primary" icon="save"/>
                </div>
            </div>
        </div>
    </Form>
{/if}