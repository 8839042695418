<script>

    import { toast } from 'bulma-toast'
    import { invitationsStore } from '../stores'

    import UserInvitationService from '../$services/users.service'

    import Button from '../$components/button.svelte'
    import InputText from '../$components/input.text.svelte'
    import Form from '../$components/form.svelte'

    let data = {}
    let invitationCode = null
    let loading = false
    let showInvitation = false

    async function getInvitationCode() {

        loading = true
        const resp = await UserInvitationService.getInvitationCode(invitationCode)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        showInvitation = true
        data.invitationCode = invitationCode
    }

    async function createUserInvitation() {

        loading = true
        const resp = await UserInvitationService.createUserInvitation(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        invitationsStore.append(resp.data)

        toast({message: 'Usuario registrado, ingresa a tu panel', type: 'is-success'})
        window.location = 'https://panel.myshipper.com.mx'

    }

</script>

<style>

    .hero {
        background: linear-gradient(90deg, #19382b, #19382b, #306850);
        /* background: linear-gradient(70deg, #4E3596, #306850); */
    }

    .label {
        color: whitesmoke;
    }
    
</style>

<section class="hero is-fullheight">
    <div class="hero-body">
        <div class="container">
            {#if !showInvitation}
                <Form on:submit={getInvitationCode} {loading} >
                    <div class="columns">
                        <div class="column">
                            <InputText bind:value={invitationCode} label="Ingresa tu código de invitación" icon="barcode" placeholder="000000000000" whitesmoke={true} />
                        </div>
                    </div>
                    <Button type="submit" text="Continuar" icon="chevron-right" color="primary" fullwidth={true} />
                </Form>
            {:else}
                <Form on:submit={createUserInvitation} {loading} >
                    <div class="columns">
                        <div class="column">
                            <InputText bind:value={data.firstName} label="Nombre" icon="user" placeholder="p. ej. Ana" whitesmoke={true} />
                        </div>
                        <div class="column">
                            <InputText bind:value={data.lastName} label="Apellidos" icon="user" placeholder="p. ej. Sanchez Roldán" whitesmoke={true} />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Teléfono</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-phone"></i></span>
                                    <input bind:value={data.phone} type="text" minlength="10" class="input" placeholder="p. ej. 2228945123" required>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Correo electronico</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-at"></i></span>
                                    <input bind:value={data.email} type="email" class="input" placeholder="p. ej. ejemplo@gmail.com" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Contraseña</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-key"></i></span>
                                    <input bind:value={data.password} type="password" class="input" placeholder="••••••••••••••••" required>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Referido</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-key"></i></span>
                                    <input bind:value={data.referido} type="text" class="input" placeholder="Código de referido">
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" text="Confirmar" icon="paper-plane" color="primary" fullwidth={true} />
                </Form>
            {/if}
        </div>
    </div>
</section>
