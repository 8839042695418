<script>

    import { toast } from 'bulma-toast'
    import { configStore, modalStore } from '../stores'
    
    import ConfigsService from '../$services/configs.service'
    import Field from './component.config.vtex.field.svelte'

    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'

    export let vtexConfig;

    let loading = false

    const data = {
        changeStatus: vtexConfig.changeStatus,
        contact: vtexConfig.contact,
        corporate: vtexConfig.corporate,
        phone: vtexConfig.phone,
        email: vtexConfig.email,
        zipcode: vtexConfig.zipcode,
        state: vtexConfig.state,
        city: vtexConfig.city,
        municipality: vtexConfig.municipality,
        colony: vtexConfig.colony,
        street: vtexConfig.street,
        number: vtexConfig.number,
        apartment: vtexConfig.apartment,
        references: vtexConfig.references,
    }

    async function updateConfigVtex() {
        
        loading = true
        const resp = await ConfigsService.updateConfig({vtex: data})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)

        toast({message: 'Configuración de vtex actualizada', type: 'is-success'})
        modalStore.close()
    }

    async function updateConfigVtexDefault() {

        loading = true
        const resp = await ConfigsService.updateConfigVtexDefault()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)

        toast({message: 'Configuración de vtex actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateConfigVtex}>

        <div class="columns">
            <div class="column">
                <div class="subtitle is-5">Estatus de orden</div>
                <div class="field">
                    <div class="control">
                        <Switch bind:checked={data.changeStatus} label="Cambiar el estatus de la orden en la tienda" />
                    </div>
                </div>
            </div>
        </div>
        
        <Field label="Contacto" icon="user" bind:field={data.contact} />
        <Field label="Empresa" icon="building" bind:field={data.corporate} />
        <Field label="Teléfono" icon="phone" bind:field={data.phone} />
        <Field label="Correo" icon="envelope" bind:field={data.email} />
        <Field label="Código postal" icon="map-marker-alt" bind:field={data.zipcode} />
        <Field label="Estado" icon="map-marker-alt" bind:field={data.state} />
        <Field label="Ciudad" icon="map-marker-alt" bind:field={data.city} />
        <Field label="Municipio" icon="map-marker-alt" bind:field={data.municipality} />
        <Field label="Colonia" icon="map-marker-alt" bind:field={data.colony} />
        <Field label="Calle" icon="map-marker-alt" bind:field={data.street} />
        <Field label="Número exterior" icon="map-marker-alt" bind:field={data.number} />
        <Field label="Apartamento" icon="map-marker-alt" bind:field={data.apartment} />
        <Field label="Referencias" icon="asterisk" bind:field={data.references} />

        <div class="columns">
            <div class="column">
                <button on:click={updateConfigVtexDefault} type="button" class="button is-primary is-fullwidth">
                    <span class="icon"><i class="fas fa-history"></i></span>
                    <span>Restaurar</span>
                </button>
            </div>
            <div class="column">
                <button on:click={modalStore.close} type="button" class="button is-fullwidth">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                    <span>Cancelar</span>
                </button>
            </div>
            <div class="column">        
                <button type="submit" class="button is-primary is-fullwidth">
                    <span class="icon"><i class="fas fa-save"></i></span>
                    <span>Guardar</span>
                </button>
            </div>
        </div>
    </form>
{/if}
