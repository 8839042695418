import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getShipmentsTotal,
    getShipmentsStatus,
    getShipmentsProfitMargin,
    getShipmentsUtility,
    getShipmentsInvoice,
    getShipmentsServices,
    getShipmentsDelivery,
    getStocksTotal,
}

function getShipmentsTotal(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/total`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsStatus(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/status`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsProfitMargin(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/profitmargin`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsUtility(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/utility`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsInvoice(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/invoice`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsServices(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/services`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsDelivery(query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset() / 60

        Superagent
            .get(`${HOST_API}/graphics/shipments/delivery`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone })
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getStocksTotal(query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/graphics/stocks/total`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}