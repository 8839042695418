import Superagent from 'superagent'
import Storage from './storage.service'

const { HOST_API } = _CONSTANTS_

export default {
    getCharges,
    downloadCharges
}

function getCharges(query = {}) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/charges`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
               resolve(res.body)
           })
    })
}

function downloadCharges(query) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/charges/download`)
            .set('access', Storage.getItem('access'))
            .send(query)
            .responseType('blob')
            .end((err, res) => {
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}