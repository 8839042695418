<script>

    import { modalStore } from '../stores'

    import UpdateInvoicesComponent from './component.update.invoices.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'user_update_invoices_modal'

</script>

<Modal title="Actualizar información de facturación" {show}>
    <UpdateInvoicesComponent />
</Modal>