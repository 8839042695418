<script>

    import { toast } from 'bulma-toast'
    import { userStore, modalStore } from '../stores'

    import StorageService from '../$services/storage.service'
    import UsersService from '../$services/users.service'

    import Select from '../$components/select.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'
    import Button from '../$components/button.svelte'

    let loading = false
    let data = {
        invoiceAuto: $userStore.invoiceAuto,
        invoiceCFDI: $userStore.invoiceCFDI,
        invoicePayment: $userStore.invoicePayment,
    }

    async function updateUser() {

        loading = true
        const resp = await UsersService.updateUser(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        StorageService.setItem('user', resp.data)
        userStore.set(resp.data)

        toast({message: 'Información actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateUser}>
        <div class="columns">
            <div class="column">
                <Switch bind:checked={ data.invoiceAuto } label="Facturación automática" />
            </div>
        </div>
        {#if data.invoiceAuto}
            <div class="columns">
                <div class="column">
                    <Select
                        bind:value={ data.invoiceCFDI }
                        label="Uso del CFDI"
                        icon="tag"
                        options={[
                            {value: 'P01', text: 'Por definir'},
                            {value: 'G03', text: 'Gastos en general'},
                        ]}
                    />
                </div>
                <div class="column">
                    <Select
                        bind:value={ data.invoicePayment }
                        label="Forma de pago"
                        icon="tag"
                        options={[
                            {value: '99', text: 'Por definir'},
                            {value: '04', text: 'Tarjeta de crédito'},
                            {value: '28', text: 'Tarjeta de débito'},
                            {value: '03', text: 'Transferencia electrónica de fondos'},
                        ]}
                    />
                </div>
            </div>
        {/if}
        <div class="items-end">
            <div class="buttons">
                <Button on:click={() => modalStore.set(null)} type="button" text="Cancelar" icon="ban" />
                <Button on:click={ updateUser } type="submit" text="Guardar" icon="save" color="primary" />
            </div>
        </div>
    </form>
{/if}