<script>

    import { link } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'
    import { postStore, commentsStore, userStore, modalStore} from '../stores'

    import PostsService from '../$services/posts.service'
    import CommentsService from '../$services/comments.service'
    import DateMethods from '../$methods/date.methods'

    import CommentsComponent from '../comments/component.comments.svelte'
    import CommentCreateComponent from '../comments/component.create.svelte'

    import LikeComponent from './component.likes.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Button from '../$components/button.svelte'

    export let postId = null

    let loading = false

    getPost()

    async function getPost() {

        loading = true
        const resp = await PostsService.getPost(postId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        postStore.set(resp.data)

        getAllCommentsPost()
    }

    async function getAllCommentsPost() {

        loading = true
        const resp = await CommentsService.getAllCommentsPost(postId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        commentsStore.set(resp.data)
    }

</script>

<style>
    .image{
        position:relative;
        overflow:hidden;
        padding-bottom:100%;
    }
    .image img{
        position:absolute;
    }
</style>

<Spinner {loading} />

{#if $postStore && !loading}

    <div class="space-bottom">
        <a href="/posts/user/{ $postStore.user._id }" use:link>
            <small>
                <span class="icon"><i class="fas fa-user"></i></span>
                <span>{ $postStore.user.alias || $postStore.user.name } · { DateMethods.timeAgo($postStore.created) } </span>
            </small>
        </a>
    </div>

    <div class="columns">
        <div class="column is-narrow">
            <figure class="image is-128x128">
                {#if $postStore.user.avatar}
                    <img src="{ $postStore.user.avatar }" alt="Avatar">
                {:else}
                    <img src="https://bulma.io/images/placeholders/128x128.png" alt="default">
                {/if}
            </figure>
        </div>
        <div class="column">
            <div class="title is-3">{ $postStore.title }</div>
            <div class="subtitle is-6">
                <div class="tag is-primary">{ $postStore.tag }</div>
            </div>
        </div>
    </div>

    <p class="content">
        {@html $postStore.content}
    </p>

    <div class="items-space">
        <div>{ $postStore.comments } { $postStore.comments === 1? 'Comentario' : 'Comentarios' }</div>
        <div class="items-end space-top">
            {#if $postStore.userId === $userStore._id}
                <Button on:click={() => modalStore.open('post_update_modal')} text="Editar" icon="edit" size="small" />
            {/if}
            <LikeComponent post={$postStore} />
        </div>
    </div>

    <hr>
    <CommentCreateComponent postId={$postStore._id} />
    <hr>
    <CommentsComponent comments={$commentsStore} />
    <br>
{/if}