<script>

    import { toast } from 'bulma-toast'
    import { userStore, notificationsStore, notificationsGlobalStore } from '../stores'
    
    import NotificationsService from '../$services/notifications.service'
    import DateMethod from '../$methods/date.methods.js'
    import Spinner from '../$components/spinner.svelte'
    import Delete from '../$components/delete.svelte'
    
    const { BRAND } = _CONSTANTS_
    
    let loading = false

    getNotifications()

    async function getNotifications() {

        if(!$userStore)
            return

        loading = true
        const resp = await NotificationsService.getNotifications(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        notificationsStore.set(resp.data.user)
        notificationsGlobalStore.set(resp.data.global)
    }

    async function deleteNotification(notification) {

        loading = true
        const resp = await NotificationsService.deleteNotification(notification._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        notificationsStore.remove(notification._id)
        toast({message: 'Notificación borrada', type: 'is-success'})
    }

</script>

<style>

    .title, .subtitle {
        color: rgba(0, 0, 0, 0.7) !important;
    }

    @media (max-width: 500px) {
        .items-space {
            display: grid;
        }
    }

</style>

<Spinner {loading} />

{#if !loading}
    {#if $notificationsGlobalStore.length}
            
        <div class="title is-5">MyShipper</div>

        {#each $notificationsGlobalStore as notificationGlobal}
            <div class="title is-5"><span class="icon"><i class="fas fa-exclamation-triangle"></i></span> { notificationGlobal.subject }</div>
            <div class="subtitle is-6">{@html notificationGlobal.message }</div>
            <div class="title is-6">{@html notificationGlobal.html || '' }</div>
            <div class="items-end">
                <small>{ DateMethod.dateTimeLarge(notificationGlobal.created)} </small>
            </div>
        {/each}
    {/if}
    <br>
    {#if $notificationsStore.length}
        
        <div class="title is-5">Notificaciones</div>

        {#each $notificationsStore as notificationUser}
            <div class="title is-5"> <span class="icon"><i class="fas fa-bell"></i></span> { notificationUser.subject }</div>
            <div class="subtitle is-6">{@html notificationUser.message }</div>
            <div class="title is-6">{@html notificationUser.html || '' }</div>
            <div class="items-space">
                <Delete on:confirm={() => deleteNotification(notificationUser)} fullwidth={false} {loading} />
                <small>{ DateMethod.dateTimeLarge(notificationUser.created)} </small>
            </div>
        {/each}
    {/if}
{/if}