<script>
    
    import QueryString from 'query-string'
    import { toast } from 'bulma-toast'
    import { replace, location, querystring } from 'svelte-spa-router'
    import { orderStore, storeStore, configStore } from '../stores'

    import LocationsService from '../$services/locations.service'
    import PackagesService from '../$services/packages.service'

    import LocationSelectComponent from '../locations/component.select.svelte'
    import PackageSelectComponent from '../packages/component.select.svelte'
    import QuotationsComponent from '../quotations/component.quotations.svelte'
    import ShipmentCreateComponent from './component.shipment.create.svelte'
    import PositionsComponent from '../locations/component.positions.svelte'

    import VolumetricPopup from '../$popups/popup.volumetric.weight.svelte'
    import ValuePopup from '../$popups/popup.declared.value.svelte'
    import FrequencyPopup from '../$popups/popup.delivery.frequency.svelte'
    import IdentifierPopup from '../$popups/popup.personal.identifier.svelte'

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    export let params = {}

    if(!$orderStore) {
        replace('#/orders')
        $orderStore = {}
    }

    const query = $querystring? QueryString.parse($querystring, {parseBooleans: true}) : {}

    let step = 'pickup'
    let store = $storeStore
    let order = $orderStore
    let pickup = $orderStore.pickup || {}
    let delivery = $orderStore.delivery || {}
    let ipackage = $orderStore.package
    let quotation = null

    let localServices = query.localServices

    if(localServices) {
        setDefaultLocation().then(() => {
            step = 'positions'
        })
    }

    if($configStore && $configStore.email) {
        pickup.sendEmail = $configStore.email.sendPickup
        delivery.sendEmail = $configStore.email.sendDelivery
    } else {
        pickup.sendEmail = false
        delivery.sendEmail = false
    }

    function onPositionsSelected() {
        step = 'pickup'
    }

    function onPickupSelected() {
        step = 'delivery'
    }

    function onDeliverySelected() {
        step = 'package'
    }

    function onPackageSelected() {
        step = 'quotations'
    }

    function onQuotationSelected() {
        step = 'guide'
    }

    function onCancel() {
        step = 'pickup'
        replace('#/orders')
    }

    function setStep(event) {
        step = event.detail || event
    }

    if(params.default)
        setDefaultData()

    async function setDefaultData() {
        
        await setDefaultLocation()
        await setDefaultPackage()

        step = 'quotations'
    }

    async function setDefaultLocation() {

        if(pickup.zipcode)
            return

        let resp = await LocationsService.getLocationDefault()

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(!resp.data)
            return

        pickup = resp.data || {}
    }

    async function setDefaultPackage() {

        let resp = await PackagesService.getPackageDefault()

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(!resp.data)
            return

        ipackage = resp.data || {}
    }

</script>

<style>
    .column.is-11 .steps{
        margin-left: 7%;
        margin-right: 16%;
        margin-bottom: 0%
    }
</style>

<Menu />

<VolumetricPopup />
<ValuePopup />
<FrequencyPopup />
<IdentifierPopup />

<Container>
    <div class="columns">
        <div class="column is-1">
            <div class="items-center">
                {#if step === 'positions'}
                    <span on:click={onCancel} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'pickup'}
                    <span on:click={() => localServices? step = 'positions' : onCancel()} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'delivery'}
                    <span on:click={() => setStep('pickup')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'package'}
                    <span on:click={() => setStep('delivery')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'quotations'}
                    <span on:click={() => setStep('package')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'guide'}
                    <span on:click={() => setStep('quotations')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
            </div>
        </div>
        <div class="column">
            <ul class="steps has-content-centered">
                {#if localServices}
                    <li class="steps-segment" class:is-active={step === 'positions'}>
                        <span class="steps-marker"></span>
                        <div class="steps-content">
                            <div class="subtitle">Ubicaciones</div>
                        </div>
                    </li>
                {/if}
                <li class="steps-segment" class:is-active={step === 'pickup'}>
                    <span class="steps-marker">1</span>
                    <div class="steps-content">
                        <div class="subtitle">Origen</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'delivery'}>
                    <span class="steps-marker">2</span>
                    <div class="steps-content">
                        <div class="subtitle">Destino</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'package'}>
                    <span class="steps-marker">3</span>
                    <div class="steps-content">
                        <div class="subtitle">Empaque</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'quotations'}>
                    <span class="steps-marker">4</span>
                    <div class="steps-content">
                        <div class="subtitle">Precios</div>
                    </div>
                </li>                   
                <li class="steps-segment" class:is-active={step === 'guide'}>
                    <span class="steps-marker">5</span>
                    <div class="steps-content">
                        <div class="subtitle">Guía</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    {#if $orderStore}
        <div class="columns">
            <div class="column is-6 is-offset-3">
                {#if step === 'positions'}
                    <PositionsComponent bind:pickup={pickup} bind:delivery={delivery} on:selected={onPositionsSelected} />
                {/if}
            
                {#if step === 'pickup'}
                    <LocationSelectComponent {step} disabled={localServices} showSelected={!localServices} bind:location={pickup} on:selected={onPickupSelected} />
                {/if}
                    
                {#if step === 'delivery'}
                    <LocationSelectComponent {step} disabled={localServices} showSelected={false} bind:location={delivery} on:selected={onDeliverySelected} />
                {/if}        
                        
                {#if step === 'package'}
                    <PackageSelectComponent bind:ipackage={ipackage} on:selected={onPackageSelected} />
                {/if}
            
                {#if step === 'quotations'}
                    <QuotationsComponent {pickup} {delivery} {ipackage} {order} {localServices} bind:quotation={quotation}  on:selected={onQuotationSelected} />
                {/if}
            
                {#if step === 'guide'}
                    <ShipmentCreateComponent {store} {order} {pickup} {delivery} {ipackage} {quotation} on:step={setStep} />
                {/if}
            </div>
        </div>
    {/if}
</Container>
<br>
<br>
<br>
<br>
<br>