<script>

    import { toast } from 'bulma-toast'
    import { pickupsStore, shipmentStore, shipmentsStore, modalStore } from '../stores'

    import PickupsService from '../$services/pickups.service'

    import Spinner from '../$components/spinner.svelte'
    import Date from '../$components/date.svelte'

    let loading = false
    const data = {
        provider: $shipmentStore.provider,
        guide: $shipmentStore.guide,
        package: {
            width: $shipmentStore.order.package.width,
            height: $shipmentStore.order.package.height,
            length: $shipmentStore.order.package.length,
            weight: $shipmentStore.order.package.weight,
            value: $shipmentStore.order.package.value,
            content: $shipmentStore.order.package.content,
        },
        location: {
            contact: $shipmentStore.order.pickup.contact,
            corporate: $shipmentStore.order.pickup.corporate,
            phone: $shipmentStore.order.pickup.phone,
            email: $shipmentStore.order.pickup.email,
            zipcode: $shipmentStore.order.pickup.zipcode,
            state: $shipmentStore.order.pickup.state,
            city: $shipmentStore.order.pickup.city,
            municipality: $shipmentStore.order.pickup.municipality,
            colony: $shipmentStore.order.pickup.colony,
            street: $shipmentStore.order.pickup.street,
            number: $shipmentStore.order.pickup.number,
            apartment: $shipmentStore.order.pickup.apartment,
            references: $shipmentStore.order.pickup.references
        }
    }

    async function createPickup() {

        loading = true
        const resp = await PickupsService.createPickup(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        pickupsStore.append(resp.data)
        modalStore.close()

        $shipmentStore.pickup = resp.data
        shipmentsStore.replace($shipmentStore)

        toast({message: 'Recolección creada', type: 'is-success'})
    }

</script>

<style>
    article .message-body {
        border-color: #C26359;
    }
</style>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createPickup}>
        <div class="columns">
            <div class="column">
                <label for="" class="label">Cantidad de paquetes</label>
                <div class="field">
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-address-book"></i></span>
                        <input bind:value={data.packageTotal} type="number" min="1" class="input" placeholder="Cantidad de paquetes a recolectar" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Date bind:value={data.date} label="Fecha de recolección" showTime={false} />
            </div>
        </div>
        <article class="message">
            <div class="message-body">
                "Las recolecciones con Fedex son en un periodo de 2pm a 7pm. En caso de no contar con esa ventana de tiempo para el día que necesitas tu recolección, favor de solicitarla para el día siguiente. Se puede agendar recolección hasta un máximo de dos días siguientes a la fecha actual".
            </div>
        </article>
        <div class="items-end">
            <button on:click={() => modalStore.set('shipment_modal')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}