<script>

    import { replace } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'
    import { courseStore } from '../stores'

    import CoursesService from '../$services/courses.service'

    import MarketingViral from './component.course.detail.marketing.viral.svelte'
    import MarketingDigital from './component.course.detail.marketing.digital.svelte'

    import Button from '../$components/button.svelte'

    let loading = false

    setViewed()

    async function setViewed() {

        loading = true
        const resp = await CoursesService.setViewed($courseStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    }

</script>

<Button text="Cursos" icon="photo-video" on:click={() => replace(`#/courses`)} />
<br/>

{#if $courseStore.course === "Marketing Viral"}
    <MarketingViral />
{:else if $courseStore.course === "Marketing Digital"}
    <MarketingDigital />
{/if}