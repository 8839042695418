<script>

    import { modalStore } from '../stores'
    import UpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'comment_update_modal'

</script>

<Modal title="Editar comentario" {show} >
    <UpdateComponent/>
</Modal>