<script>

    import { modalStore } from '../stores'

    import DhlAccountUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'dhl_update_modal'

</script>

<Modal title="Actualizar cuenta" {show}>
    <DhlAccountUpdateComponent />
</Modal>