<script>

    import { createEventDispatcher } from 'svelte'
    const dispatch = createEventDispatcher()

    export let label = null
    export let text = ''
    export let icon = null
    export let value = ''

    export let iconType = 'fa'
    export let required = true
    export let blank = false

    export let whitesmoke = false

    export let options = [] // {value, text}

    function onChange() {
        
        const option = options.find(item => item.value === value)
        
        dispatch('select', value)
        dispatch('change', value)

        if(option && option.data)
            dispatch('data', option.data)
        else
            dispatch('data', {})
    }

</script>

<style>
    .class-whitesmoke {
        color: whitesmoke;
    }
</style>

<div class="field">
    {#if label}
        <label for="" class="label" class:class-whitesmoke={ whitesmoke}>{ label }</label>
    {/if}
    <div class="control has-icons-left">
        {#if icon}
            <span class="icon"><i class="fas { iconType }-{ icon }"></i></span>
        {/if}
        <div class="select">
            <select bind:value={value} on:change={onChange} { required } >
                {#if !blank}
                    <option value="">{ text || 'Selecciona una opción'}</option>
                {/if}
                {#each options as option}
                    <option value="{ option.value }">{ option.text }</option>
                {/each}
            </select>
        </div>
    </div>
</div>