<script>

    import { modalStore } from '../stores'

    import ConfigTrackingComponent from './component.config.tracking.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'tracking_modal'

</script>

<Modal title="" {show}>
    <ConfigTrackingComponent />
</Modal>