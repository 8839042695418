import { userStore, accessStore } from './stores'
import StorageService from './$services/storage.service'
import Superagent from 'superagent'

const { HOST_API } = _CONSTANTS_

export {
    needLogin,
    isLogin,
}

async function needLogin() {
    try {

        const access = StorageService.getItem('access')
        const user = StorageService.getItem('user')
    
        if(!user)
            return true
    
        const resp = await getUserData(user, access)
    
        if(!resp || !resp.success)
            return true
    
        userStore.set(user)
        accessStore.set(access)
    
        return false

    } catch(e) {
        return true
    }
}

async function isLogin() {
    try {

        const access = StorageService.getItem('access')
        const user = StorageService.getItem('user')

        if(!user)
            return false

        const resp = await getUserData(user, access)

        if(!resp || !resp.success)
            return false

        userStore.set(user)
        accessStore.set(access)

        return true

    } catch(e) {
        return false
    }
}

async function getUserData(user, access) {
    try {

        const resp = await Superagent
            .get(`${HOST_API}/users/${user._id}`)
            .timeout({deadline: 20000, response: 20000})
            .set('access', access)

        return resp.body
        
    } catch(error) {
        return null
    }
}