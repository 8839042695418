<script>

    import { configStore, modalStore } from '../stores'

    import ConfigVtexComponent from './component.config.vtex.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'vtex_config_modal'

</script>

<Modal title="Configuración vtex" {show}>
    {#if $configStore}
        <ConfigVtexComponent vtexConfig={$configStore.vtex} />
    {/if}
</Modal>