<script>

    import { toast } from 'bulma-toast'
    import { estafetasStore, estafetaStore, modalStore } from '../stores'

    import Spinner from '../$components/spinner.svelte'
    import EstafetaService from '../$services/estafeta.service'

    const data = new Object({
        _id: $estafetaStore._id,
        alias: $estafetaStore.alias,
        accountType: $estafetaStore.accountType,
        quotation: {
            id: $estafetaStore.quotation.id,
            user: $estafetaStore.quotation.user,
            password: $estafetaStore.quotation.password
        },
        order: {
            login: $estafetaStore.order.login,
            suscriber: $estafetaStore.order.suscriber,
            customer: $estafetaStore.order.customer,
            office: $estafetaStore.order.office,
            password: $estafetaStore.order.password,
        },
        orderInsurance: {
            account: $estafetaStore.orderInsurance.account,
            user: $estafetaStore.orderInsurance.user,
            password: $estafetaStore.orderInsurance.password
        },
        tracking: {
            login: $estafetaStore.tracking.login,
            suscriber: $estafetaStore.tracking.suscriber,
            password: $estafetaStore.tracking.password,
        }
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await EstafetaService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        estafetasStore.replace(resp.data)
        estafetaStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/Estafeta.svg" alt="Estafeta" style="width: 60%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.alias} type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Tipo de cuenta</label>
                    <div class="control">
                        <div class="select">
                            <select bind:value={data.accountType} required>
                                <option value="">Tipo de cuenta</option>
                                <option value="prepaid">Prepago</option>
                                <option value="account">Cuenta consumo</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="subtitle is-5"><b>Cotización</b></div>
        <div class="is-divider"></div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Id</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.quotation.id} type="text"  class="input" placeholder="Id" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">User</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.quotation.user} type="text"  class="input" placeholder="User" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.quotation.password} type="password"  class="input" placeholder="Password" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="subtitle is-5"><b>Órden</b></div>
        <div class="is-divider"></div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Customer</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.order.customer} type="text"  class="input" placeholder="Customer" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Suscriber</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.order.suscriber} type="text"  class="input" placeholder="Suscriber" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Office</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-building"></i></span>
                        <input bind:value={data.order.office} type="text"  class="input" placeholder="Office" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Login</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.order.login} type="text"  class="input" placeholder="Login" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.order.password} type="password"  class="input" placeholder="Password" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="subtitle is-5"><b>Órden con seguro</b></div>
        <div class="is-divider"></div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Cuenta</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.orderInsurance.account}  type="text"  class="input" placeholder="Cuenta">
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">User</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.orderInsurance.user} type="text"  class="input" placeholder="User">
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.orderInsurance.password} type="password"  class="input" placeholder="Password">
                    </div>
                </div>
            </div>
        </div>

        <div class="subtitle is-5"><b>Tracking</b></div>
        <div class="is-divider"></div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Suscriber</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.tracking.suscriber} type="text"  class="input" placeholder="Suscriber" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Login</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.tracking.login} type="text"  class="input" placeholder="Login" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.tracking.password} type="password"  class="input" placeholder="Password" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.set('estafeta_account_modal')} type="button" class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary is-fullwidth">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}