<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'identifier_popup'
    
</script>


<Popup url="https://www.notion.so/myshipper/Identificador-personal-b324614ac5054b72a21a05d5352dc118" {show}>
    <div class="title">¿Identificador personal?</div>
    <p>El identificador personal de un envío es <b>una palabra clave asignada</b> a ese envío para poder encontrarlo más rápido en el historial.</p>
    <p>Por ejemplo, si tienes 5 almacenes, puedes asignar un identificador personal a cada uno (“Almacén A”, “Almacén B”, etc) y al momento de tener la necesidad de encontrar rápido todos los envíos del almacén A en el historial, puedes evitar los filtros más complejos (dirección, fecha u otro) y buscarlos por su palarbra clave “Almacén A”.</p>
</Popup>