<script>

    import Spinner from './spinner.svelte'
    import Message from './message.svelte'

    export let items = 0
    export let total = null
    export let loading = false
    export let height = 'auto'

</script>

{#if items}
    <small>
        { items } 
        {#if total}
            / {total}
        {/if}
        Resultados
    </small>
{/if}

<Spinner { loading } />
<Message show={!loading && !items} />

{#if !loading && items}
    <div class="table-container" style='overflow: auto; max-height: { height };'>
        <table class="table is-fullwidth is-hoverable">
            <slot></slot>
        </table>
    </div>
{/if}