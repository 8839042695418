<script>

    import { toast } from 'bulma-toast'

    import GraphicsService from '../$services/graphics.service'
    import Graphic from '../$components/graphic.svelte'
    import Title from '../$components/title.svelte'

    export let query = null
    export let count = 1

    $: count && getShipmentsStatus()

    let loading = false
    let labels = []
    let datasets = []

    async function getShipmentsStatus() {

        const send = {...query}
              send.dateStart = query.dateStart.toISOString()
              send.dateEnd = query.dateEnd.toISOString()
    
        loading = true
        const resp = await GraphicsService.getShipmentsStatus(send)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        labels = resp.data.labels
        datasets = resp.data.datasets
    }

</script>

<div>
    <Title text="Envíos por estatus" />
    <Graphic bind:labels={labels} bind:datasets={datasets} chartType="pie" colorRandom fill {loading} />
</div>