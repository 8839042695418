<script>

    import { toast } from 'bulma-toast'
    import { redpacksStore, redpackStore, modalStore } from '../stores'

    import Spinner from '../$components/spinner.svelte'
    import RedpackService from '../$services/redpack.service'

    const data = new Object({
        _id: $redpackStore._id,
        id: $redpackStore.id,
        alias: $redpackStore.alias,
        account: $redpackStore.account,
        pin: $redpackStore.pin
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await RedpackService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        redpacksStore.replace(resp.data)
        redpackStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/Redpack.svg" alt="Redpack">
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.alias} type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Cuenta</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.account} type="text"  class="input" placeholder="Cuenta" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Id</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.id} type="text"  class="input" placeholder="Id" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Pin</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.pin} type="text"  class="input" placeholder="Pin" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.set('redpack_account_modal')} type="button" class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary is-fullwidth">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}