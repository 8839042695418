<script>

    import { paquetexpressStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import PaquetexpressAccountComponent from './component.paquetexpress.account.svelte'

    $: show = $modalStore === 'paquetexpress_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <PaquetexpressAccountComponent paquetexpress={$paquetexpressStore} />
</Modal>