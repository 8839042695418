import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createShipmentNational,
    createShipmentInternational,
    createShipmentAutomatic,
    getShipmentsStore,
    getShipments,
    getShipmentsTotal,
    getShipmentZPL,
    getShipmentDocument,
    getShipmentCode,
    getShipmentsDownload,
    getShipmentsReport,
    sendShipmentEmail,
    updateShipmentGuide,
    cancelShipment
}

function createShipmentNational(data) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .post(`${HOST_API}/shipments/national`)
            .set('access', Storage.getItem('access'))
            .query({timezone})
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createShipmentInternational(data) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .post(`${HOST_API}/shipments/international`)
            .set('access', Storage.getItem('access'))
            .query({timezone})
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createShipmentAutomatic(data) {
    return new Promise((resolve, reject) => {


        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .post(`${HOST_API}/shipments/national/automatic`)
            .set('access', Storage.getItem('access'))
            .query({timezone})
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsStore(storeId, query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments/store/${storeId}`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipments() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsTotal() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments/total`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentZPL(shipmentId) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments/${shipmentId}/zpl`)
            .set('access', Storage.getItem('access'))
            .responseType('blob')
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}

function getShipmentDocument(shipmentId) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments/${shipmentId}/document`)
            .set('access', Storage.getItem('access'))
            .responseType('blob')
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}

function getShipmentCode(shipmentCode) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments/code/${shipmentCode}`)
            .set('content-type', 'application/json')
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsDownload(query) {
    return new Promise((resolve, reject) => {

        const data = { ...query }

        data.dateStart = query.dateStart.toISOString()
        data.dateEnd = query.dateEnd.toISOString()
        data.timezone = new Date().getTimezoneOffset()/60

        Superagent
            .get(`${HOST_API}/shipments/download`)
            .set('access', Storage.getItem('access'))
            .query(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShipmentsReport(query) {
    return new Promise((resolve, reject) => {

        const data = { ...query }

        data.dateStart = query.dateStart.toISOString()
        data.dateEnd = query.dateEnd.toISOString()
        data.timezone = new Date().getTimezoneOffset()/60

        Superagent
            .get(`${HOST_API}/shipments/report`)
            .set('access', Storage.getItem('access'))
            .query(data)
            .responseType('blob')
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}

function sendShipmentEmail(shipmentId, query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shipments/${ shipmentId }/email`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}

function updateShipmentGuide(shipmentId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/shipments/${shipmentId}/guide`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function cancelShipment(shipmentId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/shipments/${shipmentId}/cancel`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}