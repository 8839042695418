<script>

    import { Loader } from '@googlemaps/js-api-loader'
    import { toast } from 'bulma-toast'

    const { GOOGLE_MAPS_KEY } = _CONSTANTS_

    let map
    let mapDiv
    let geocoder
    let LatLngBounds

    export let pickup = {
        position: {
            lat: 19.435109,
            lng: -99.133806
        },
        address: '06000',
        zipcode: '',
        state: '',
        city: '',
        colony: '',
        street: '',
        number: '',
        marker: null,
        input: null,
        autocomplete: null
    }

    export let delivery = {
        position: {
            lat: 19.435109,
            lng: -99.133806
        },
        address: '08800',
        zipcode: '',
        state: '',
        city: '',
        colony: '',
        street: '',
        number: '',
        marker: null,
        input: null,
        autocomplete: null
    }

    const point = {
            lat: 19.435109,
            lng: -99.133806
        }

    const loader = new Loader({
        apiKey: GOOGLE_MAPS_KEY,
        version: "weekly",
        libraries: ["places"]
    })

    loader.load().then(() => {

        map = new google.maps.Map(mapDiv, {zoom: 14, center: point})

        geocoder = new google.maps.Geocoder()
        LatLngBounds = google.maps.LatLngBounds

        pickup.marker = new google.maps.Marker({map, position: point, label: "A", draggable: true})
        delivery.marker = new google.maps.Marker({map, position: point, label: "B", draggable: true})

        pickup.autocomplete = new google.maps.places.Autocomplete(pickup.input, {
            componentRestrictions: {
                country: ["MX"]
            },
            fields: ["address_components", "geometry"],
            types: ["address"],
        })

        delivery.autocomplete = new google.maps.places.Autocomplete(delivery.input, {
            componentRestrictions: {
                country: ["MX"]
            },
            fields: ["address_components", "geometry"],
            types: ["address"],
        })

        google.maps.event.addListener(pickup.marker, 'dragend', event => {

            pickup.position.lat = event.latLng.lat()
            pickup.position.lng = event.latLng.lng()

            pickup.marker.setPosition(pickup.position)
            findAddress(pickup)
        })

        google.maps.event.addListener(delivery.marker, 'dragend', event => {

            delivery.position.lat = event.latLng.lat()
            delivery.position.lng = event.latLng.lng()

            delivery.marker.setPosition(delivery.position)
            findAddress(delivery)
        })

        google.maps.event.addListener(pickup.autocomplete, 'place_changed', () => {

            if(!pickup.autocomplete.getPlace().geometry)
                return toast({message: 'No se encontraron resultados', type: 'is-success'})

            const information = pickup.autocomplete.getPlace()

            pickup.marker.setPosition(information.geometry.location)

            pickup.address = pickup.input.value
            pickup = mapAddress(pickup, information.address_components)

            centerMap()
        })

        google.maps.event.addListener(delivery.autocomplete, 'place_changed', () => {

            if(!delivery.autocomplete.getPlace().geometry)
                return toast({message: 'No se encontraron resultados', type: 'is-success'})

            const information = delivery.autocomplete.getPlace()

            delivery.marker.setPosition(information.geometry.location)

            delivery.address = delivery.input.value
            delivery = mapAddress(delivery, information.address_components)

            centerMap()
        })

        setLocations()
    })

    async function setLocations() {

        if(pickup.address)
            await setAddress(pickup)

        if(delivery.address)
            await setAddress(delivery)

        centerMap()
    }

    async function findAddress(location) {

        const promise = new Promise((resolve, reject) => {
            geocoder.geocode({location: location.position}, (results, status) => {

                if(status != 'OK')
                    return toast({message: 'No se encontraron resultados', type: 'is-success'})

                const information = results[0]

                location.input.value = information.formatted_address
                location.address = information.formatted_address

                location = mapAddress(location, information.address_components)

                resolve(location)
            })
        })

        await promise
        return centerMap()
    }

    async function setAddress(location) {

        location.input.value = location.address

        const promise = new Promise((resolve, reject) => {
            geocoder.geocode({address: location.input.value}, (results, status) => {

                if(status != 'OK')
                    return toast({message: 'No se encontraron resultados', type: 'is-success'})

                const information = results[0]

                location.position = {
                    lat: information.geometry.location.lat(),
                    lng: information.geometry.location.lng(),
                }

                location.marker.setPosition(location.position)

                location.input.value = information.formatted_address
                location.address = information.formatted_address

                location = mapAddress(location, information.address_components)

                resolve(location)
            })
        })

        await promise
        return centerMap()
    }

    function mapAddress(location, address_components) {

        location.zipcode = ''
        location.state = ''
        location.city = ''
        location.municipality = ''
        location.colony = ''
        location.street = ''
        location.number = ''
        location.apartment = ''
        location.references = ''

        let isZipcode = false

        address_components.forEach(item => {

            if(item.types.indexOf("postal_code") >= 0) {
                location.zipcode = item.long_name
                isZipcode = true
            }

            if(item.types.indexOf("administrative_area_level_1") >= 0)
                location.state = item.long_name

            if(item.types.indexOf("locality") >= 0)
                location.city = item.long_name

            if(item.types.indexOf("sublocality") >= 0)
                location.colony = item.long_name

            if(item.types.indexOf("route") >= 0)
                location.street = item.long_name

            if(item.types.indexOf("street_number") >= 0)
                location.number = item.long_name
        })

        if(!isZipcode)
            toast({message: 'La dirección no cuenta con un código postal', type: 'is-warning'})

        return location
    }

    function centerMap() {

        const bounds = new LatLngBounds()

        const markets = [pickup.marker, delivery.marker]

        markets.forEach(market => {
            bounds.extend(market.getPosition())
        })

        map.fitBounds(bounds)
    }

</script>

<style>
    #map {
      height: 400px;
    }
</style>

<div class="container">
    <div class="columns">
        <div class="column">
            <div class="field">
                <div class="label">Dirección de origen (A)</div>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                    <input bind:this={ pickup.input } type="text" class="input" placeholder="Origen">
                </div>
            </div>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <div class="field">
                <div class="label">Dirección de destino (B)</div>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                    <input bind:this={ delivery.input } type="text" class="input" placeholder="Destino">
                </div>
            </div>
        </div>
    </div>
    
    <div id="map" bind:this={ mapDiv }></div>
</div>