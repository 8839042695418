<script>

    import { toast } from 'bulma-toast'
    import { ordersStore, orderStore, modalStore } from '../stores'

    import OrdersService from '../$services/orders.service'
    import Spinner from '../$components/spinner.svelte'

    const order = new Object({
        _id: $orderStore._id,
        package: {
            width: $orderStore.package.width,
            height: $orderStore.package.height,
            length: $orderStore.package.length,
            weight: $orderStore.package.weight,
            value: $orderStore.package.value,
            content: $orderStore.package.content
	    }
    })
    
    let loading = false

    async function updateOrder() {

        loading = true
        const resp = await OrdersService.updateOrder(order._id, order)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ordersStore.replace(resp.data)
        orderStore.set(resp.data)

        toast({message: 'Paque actualizado', type: 'is-success'})
        modalStore.set('order_modal')
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateOrder}>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Ancho</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-ruler-horizontal"></i></span>
                        <input bind:value={order.package.width} type="number" class="input" placeholder="Ancho, medida en cm" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alto</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-ruler-vertical"></i></span>
                        <input bind:value={order.package.height} type="number" class="input" placeholder="Alto, medida en cm" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Largo</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-ruler"></i></span>
                        <input bind:value={order.package.length} type="number" class="input" placeholder="Largo, medida en cm" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Peso</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-weight"></i></span>
                        <input bind:value={order.package.weight} type="number" class="input" placeholder="Peso en kg" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Asegurar paquete</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-dollar-sign"></i></span>
                        <input bind:value={order.package.value} type="number" class="input" placeholder="Valor declarado" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Descripción del paquete</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-comment"></i></span>
                        <input bind:value={order.package.content} type="text" class="input" placeholder="Descripción o contenido del paquete" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="items-end">
            <button on:click={() => modalStore.set('order_modal')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-edit"></i></span>
                <span>Editar</span>
            </button>
        </div>
    </form>
{/if}