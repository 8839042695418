<script>

    import { toast } from 'bulma-toast'
    import { locationsStore, locationStore, modalStore } from '../stores'

    import LocationsService from '../$services/locations.service'

    import ZipcodeColonyComponent from './component.zipcode.colony.svelte'
    import InputEmail from '../$components/input.email.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'
    import Spinner from '../$components/spinner.svelte'

    const location = new Object({
        _id: $locationStore._id,
        user: $locationStore.user,
        userId: $locationStore.userId,
        alias: $locationStore.alias,
        contact: $locationStore.contact,
        corporate: $locationStore.corporate,
        phone: $locationStore.phone,
        email: $locationStore.email,
        zipcode: $locationStore.zipcode,
        state: $locationStore.state,
        city: $locationStore.city,
        municipality: $locationStore.municipality,
        colony: $locationStore.colony,
        street: $locationStore.street,
        number: $locationStore.number,
        apartment: $locationStore.apartment,
        references: $locationStore.references,
        latitude: $locationStore.latitude,
        longitude: $locationStore.longitude,
    })
    
    let loading = false

    async function updateLocation() {

        loading = true
        const resp = await LocationsService.updateLocation(location._id, location)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        locationsStore.replace(resp.data)
        locationStore.set(resp.data)

        toast({message: 'Dirección actualizada', type: 'is-success'})
        modalStore.set('location_modal')
    }

</script>

<Spinner {loading} />

{#if !loading && location}
    <form on:submit|preventDefault={updateLocation}>

        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.alias } label="Alias" icon="address-book" placeholder="Identificador de la dirección" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.contact } label="Nombre" icon="user" placeholder="Nombre del contacto" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.corporate } label="Empresa" icon="building" placeholder="Nombre de la empresa" required={false}/>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.phone } label="Teléfono" icon="phone" placeholder="Número de teléfono" />
            </div>
            <div class="column">
                <InputEmail bind:value={ location.email } label="Correo electrónico" icon="envelope" placeholder="Correo electrónico" />
            </div>
        </div>

        <ZipcodeColonyComponent 
            bind:zipcode={location.zipcode}
            bind:state={location.state}
            bind:city={location.city}
            bind:municipality={location.municipality}
            bind:colony={location.colony}
        />
        
        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.street } label="Calle" icon="map-marker-alt" placeholder="Calle" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.number } label="Número exterior" icon="hashtag" placeholder="Número exterior" />
            </div>
            <div class="column">
                <InputText bind:value={ location.apartment } label="Número interior" icon="building" placeholder="Número interior" required={false}/>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ location.references } label="Referencias" icon="asterisk" placeholder="Referencias de la casa/apartamento" required={false}/>
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <Button on:click={ () => modalStore.set('location_modal') } text="Cancelar" icon="ban" fullwidth={ true }/>
                </div>
                <div class="column">
                    <Button type="submit" text="Editar" icon="edit" color="primary" fullwidth={ true }/>
                </div>
            </div>
        </div>
    </form>
{/if}