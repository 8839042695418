<script>

    import { toast } from 'bulma-toast'
    import { userStore, modalStore } from '../stores'

    import UsersService from '../$services/users.service'
    import BucketService from '../$services/bucket.service'
    import StorageService from '../$services/storage.service'
    
    import Form from '../$components/form.svelte'
    import File from '../$components/file.svelte'
    import Quill from '../$components/quill.svelte'
    import Button from '../$components/button.svelte'
    import InputText from '../$components/input.text.svelte'

    let loading = false
    let avatar = null
    let data = {
        alias: $userStore.alias,
        description: $userStore.description,
        avatar: $userStore.avatar,
    }
    
    async function updateUser() {

        if(avatar)
            data.avatar = await uploadFiles(avatar, `avatar.png`)
    
        loading = true
        const resp = await UsersService.updateUser(data)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        StorageService.setItem('user', resp.data)
        userStore.set(resp.data)
        modalStore.close()
    }
    
    async function uploadFiles(file, fileName) {

        const data = {
            fileType: file.type,
            fileDir: $userStore._id,
            fileName
        }
    
        loading = true
        const resp = await BucketService.getSigned(data)
        loading = false
    
        if(!resp.success) {
            toast({message: resp.error.message, type: 'is-danger'})
            return null
        }
    
        const {signed, url} = resp.data

        await BucketService.uploadFile(file, signed)

        return url
    }

</script>

<Form on:submit={updateUser} {loading} >

    <File bind:file={avatar} label="Avatar" required={false} />

    <InputText bind:value={data.alias} label="Alias" icon="user-circle" placeholder="Alias" />

    <Quill bind:value={data.description} />

    <div class="items-end space-top">
        <Button type="submit" text="Guardar" icon="save" color="primary" />
    </div>

</Form>