<script>

    import { toast } from 'bulma-toast'
    import { N99MinutossStore, modalStore } from '../stores'

    import N99MinutosService from '../$services/99minutos.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await N99MinutosService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        N99MinutossStore.set(resp.data)
    }

    async function updateCredentialActive(N99Minutos) {
    
        loading = true
        const resp = await N99MinutosService.updateCredentialActive(N99Minutos._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<style>
    img {
        width: 50%;
    }
</style>

<Spinner {loading} />

{#if !loading && $N99MinutossStore.length}
    <div class="subtitle is-5"><b>Cuentas con 99 minutos</b></div>
    <div class="credential-items">
        {#each $N99MinutossStore as N99Minutos}
        <div class="credential-item">
            <img class="image" src="images/99Minutos.svg" alt="99Minutos">
            <div class="title is-5">{ N99Minutos.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(N99Minutos.created) }</div>
            <div class="items-space-around">
                <div class="field" style="margin: 0;">
                    <input on:click={() => updateCredentialActive(N99Minutos)} bind:checked={N99Minutos.active} class="is-checkradio is-primary" id="{N99Minutos._id}" type="checkbox">
                    <label for="{N99Minutos._id}"></label>
                </div>
                <button on:click={() => modalStore.open('N99Minutos', 'account_modal', N99Minutos)} class="button">
                    <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                </button>
            </div>
        </div>
        {/each}
    </div>
{/if}