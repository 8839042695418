<script>

    import { userStore, socketStore } from '../stores'

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import ChatUser from './component.chat.user.svelte'
    import ChatUsers from './component.chat.users.svelte'

</script>

<style>
    .chat-title {
        border: solid 2px whitesmoke;
        border-radius: 16px 0 0 0;
    }

    .chat-title-right {
        border: solid 2px whitesmoke;
        border-radius: 0 16px 0 0;
    }

    .chat-users {
        border: solid 2px whitesmoke;
        border-radius: 0 0 0 16px;
        height: 75vh;
    }

    .chat-users-right {
        border: solid 2px whitesmoke;
        border-radius: 0 0 16px 0;
        height: 75vh;
    }
</style>

<Menu />

<Container>

    <div class="columns">
        <div class="column is-4 chat-title">
            <div class="items-space">
                <div class="items-start" style="padding: 0 32px;">
                    <span class="icon is-large"><i class="fas fa-2x fa-users"></i></span>
                    <div class="title space-left">Usuarios</div>
                </div>
                <span class="icon is-large"><i class="fas fa-lg fa-chevron-down"></i></span>
            </div>
        </div>
        <div class="column chat-title-right">
            <div class="items-center">
                <span class="icon is-large"><i class="fas fa-2x fa-comments"></i></span>
                <div class="title space-left"></div>
            </div>
        </div>
    </div>

    {#if !$userStore.isChat }
        <div class="notification">
            <div class="title center">No puedes envíar mensajes hasta estar visible en el chat</div>
            <div class="subtitle center">Puedes cambiar esto en <a href="#/settings">Ajustes</a></div>
        </div>
    {/if}

    {#if $userStore.isChat }
        {#if $socketStore}
            <div class="columns">
                <div class="column is-4 chat-users">
                    <ChatUsers />
                </div>
                <div class="column chat-users-right">
                    <ChatUser />
                </div>
            </div>
        {/if}
    {/if}

</Container>