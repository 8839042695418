<script>

    import { modalStore } from '../stores'

    import PaquetexpressAccountUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'paquetexpress_update_modal'

</script>

<Modal title="Actualizar cuenta" {show}>
    <PaquetexpressAccountUpdateComponent />
</Modal>