import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getPackages,
    getPackageDefault,
    createPackage,
    updatePackage,
    updatePackageDefault,
    deletePackage
}

function getPackages() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/packages`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getPackageDefault() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/packages/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createPackage(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/packages`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updatePackage(packageId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/packages/${packageId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updatePackageDefault(packageId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/packages/${packageId}/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deletePackage(packageId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/packages/${packageId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}