<script>

    import { toast } from 'bulma-toast'
    import { ticketsStore, modalStore, userStore } from '../stores'
    
    import TicketsService from '../$services/tickets.service'
    import DateMethod from '../$methods/date.methods.js'

    import Pagination from '../$components/pagination.svelte'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    let query = {}
    let metadata = {}
    let loading = false

    getTickets()

    async function getTickets() {

        query.userId = $userStore._id

        loading = true
        const resp = await TicketsService.getTickets(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ticketsStore.set(resp.data.userTickets)
        metadata = resp.data.metadata
    }

</script>

{#if !loading}
    <div class="items-space space-bottom">
        <small>
            { $ticketsStore.length } 
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <Pagination bind:metadata bind:page={ query.page } on:change={ getTickets } />
    </div>
{/if}

<Spinner {loading} />
<Message show={!loading && !$ticketsStore.length} />

{#if !loading && $ticketsStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-hashtag"></i>
                            </span>
                            <span>Folio</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-hashtag"></i>
                            </span>
                            <span>Guía</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-check"></i>
                            </span>
                            <span>Estatus</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha de registro</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $ticketsStore as ticket, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ ticket.code }</td>
                        <td>{ ticket.shipment.guide }</td>
                        <td>{ ticket.status }</td>
                        <td>{ DateMethod.dateTimeLarge(ticket.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('ticket', 'modal', ticket)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}