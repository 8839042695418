<script>

    import { fade } from 'svelte/transition'
    import { replace, location } from 'svelte-spa-router'

    import Quickview from '../$components/quickview.svelte'

    let show = false

    function showView(view) {
        replace(`#/${view}`);
    }

    function onClose() {
        show = false
    }

</script>


<style>

    .subtitle {
        color: #4E3596;
    }

    .menu {
        color:whitesmoke;
    }

    .menu:hover {
        color: #306850;
    }

    .menu .active  * {
        color: #306850;
    }

    .item {
        padding: 12px;
    }

    .item.active {
        color: #4E3596;
        background-color: whitesmoke;
    }

</style>
<span on:click={() => show = true} class="menu icon is-medium pointer">
    <i class="fas fa-bars" ></i>
</span>

<Quickview title="Menú" bind:show >
    <div on:click={() => showView('home')} class="item" class:active={$location ==='/home'}>
        <span class="icon is-medium">
            <i class="fas fa-home"></i>
        </span>
        <small>INICIO</small>
    </div>
    <div on:click={() => showView('quotations')} class="item" class:active={$location ==='/quotations'}>
        <span class="icon is-medium">
            <i class="fas fa-calculator"></i>
        </span>
        <small>COTIZADOR</small>
    </div>
    <div on:click={() => showView('shipments')} class="item" class:active={$location ==='/shipments'}>
        <span class="icon is-medium">
            <i class="fas fa-shipping-fast"></i>
        </span>
        <small class="center">ENVÍOS</small>
    </div>
    <div on:click={() => showView('orders')} class="item" class:active={$location ==='/orders'}>
        <span class="icon is-medium">
            <i class="fas fa-clipboard-list"></i>
        </span>
        <small>ÓRDENES</small>
    </div>
    <div on:click={() => showView('fulfillment')} class="item" class:active={$location ==='/fulfillment'}>
        <span class="icon is-medium">
            <i class="fas fa-shopping-bag"></i>
        </span>
        <small>FULFILLMENT</small>
    </div>
    <div on:click={() => showView('posts')} class="item" class:active={$location ==='/posts'}>
        <span class="icon is-medium">
            <i class="fas fa-bullhorn"></i>
        </span>
        <small>FORO</small>
    </div>
    <div on:click={() => showView('courses')} class="item" class:active={$location ==='/courses'}>
        <span class="icon is-medium">
            <i class="fas fa-graduation-cap"></i>
        </span>
        <small>CURSOS</small>
    </div>
    <div on:click={() => showView('tutorials')} class="item" class:active={$location ==='/tutorials'}>
        <span class="icon is-medium">
            <i class="fas fa-film"></i>
        </span>
        <small>TUTORIALES</small>
    </div>
    <div on:click={() => showView('tracings')} class="item pointer" class:active={$location === '/tracings'}>
        <span class="icon is-medium">
            <i class="fas fa-info-circle"></i>
        </span>
        <small>SEGUIMIENTO</small>
    </div>
    <div on:click={() => showView('settings')} class="item pointer" class:active={$location === '/settings'}>
        <span class="icon is-medium">
            <i class="fas fa-user-cog"></i>
        </span>
        <small>AJUSTES</small>
    </div>
    <br>
    <div class="items-center">
        <div on:click={onClose} class="subtitle pointer" in:fade>CERRAR</div>
    </div>
</Quickview>