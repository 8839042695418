import Superagent from 'superagent'
import Storage from './storage.service'

const { HOST_API } = _CONSTANTS_

export default {
    sendClientInformation
}

function sendClientInformation(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/emails/client/information`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}