<script>

    import { ampmStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import AmpmAccountComponent from './component.ampm.account.svelte'

    $: show = $modalStore === 'ampm_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <AmpmAccountComponent ampm={$ampmStore} />
</Modal>