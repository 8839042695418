import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createPickup,
    getPickups,
    deletePickup
}

function createPickup(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/pickups`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getPickups(query) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/pickups`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
               resolve(res.body)
           })
    })
}

function deletePickup(pickupId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/pickups/${pickupId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}