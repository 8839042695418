import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createPost,
    getPosts,
    getPostsBests,
    getPostsUser,
    getPost,
    setLike,
    updatePost,
    deletePost,
}

function createPost(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/posts`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getPosts(query) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/posts`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function getPostsBests() {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/posts/bests`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function getPostsUser(userId, query) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/posts/user/${ userId }`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function getPost(postId) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/posts/${ postId }`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function setLike(postId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/posts/${postId}/likes`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updatePost(postId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/posts/${ postId }`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deletePost(postId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/posts/${postId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}