<script>

    import { modalStore } from '../stores'

    import MustacheService from '../$services/mustache.service'

    import Modal from '../$components/modal.svelte'
    import Spinner from '../$components/spinner.svelte'

    $: show = $modalStore === 'config_email_modal' && getViewEmailShipment()

    let loading = false
    let template;

    async function getViewEmailShipment() {
    
        loading = true
        const resp = await MustacheService.getViewEmailShipment()
        loading = false
    
        template = resp
    }

</script>

<Modal title="Correo de confirmación" {show}>
    <Spinner {loading} />
    {#if !loading}
        {@html template}
    {/if}
</Modal>