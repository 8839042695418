<script>

    import { toast } from 'bulma-toast'
    import { userStore, walletStore, notifyStore } from '../stores'

    import WalletsService from '../$services/wallets.service'

    $: $notifyStore === 'wallet_updated' && getWallet() && notifyStore.set(null)

    let loading = false

    getWallet()

    async function getWallet() {

        if(!$userStore)
            return

        loading = true
        const resp = await WalletsService.getWallet(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        walletStore.set(resp.data || {})
    }

</script>

{#if !loading}
   ${$walletStore.amount || 0}
{/if}