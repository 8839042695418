<script>

    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'

    import { ampmsStore, modalStore } from '../stores'
    import AmpmService from '../$services/ampm.service'

    import Icon from '../$components/icon.svelte'
    import Spinner from '../$components/spinner.svelte'
    import InputText from '../$components/input.text.svelte'

    const dispatch = createEventDispatcher()

    let data = {
        provider: 'AMPM'
    }

    let loading = false

    async function createCredential() {

        loading = true
        const resp = await AmpmService.createCredential(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ampmsStore.append(resp.data)
        toast({message: 'Credencial creada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createCredential}>
        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/AMPM.svg" alt="AMPM" style="width: 60%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.alias } icon="user" label="Alias" placeholder="Alias" />
            </div>
            <div class="column">
                <InputText bind:value={ data.client } icon="id-card" label="Usuario" placeholder="Usuario" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.password } icon="key" label="Contraseña" placeholder="Contraseña" />
            </div>
        </div>

        <div class="items-end">
            <button on:click={() => dispatch('close')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}