<script>

    import { toast } from 'bulma-toast'
    import { paquetexpresssStore, modalStore } from '../stores'

    import PaquetexpressService from '../$services/paquetexpress.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await PaquetexpressService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        paquetexpresssStore.set(resp.data)
    }

    async function updateCredentialActive(paquetexpress) {
    
        loading = true
        const resp = await PaquetexpressService.updateCredentialActive(paquetexpress._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<Spinner {loading} />

{#if !loading && $paquetexpresssStore.length}
    <div class="subtitle is-5"><b>Cuentas con Paquetexpress</b></div>
    <div class="credential-items">
        {#each $paquetexpresssStore as paquetexpress}
        <div class="credential-item">
            <img class="image" src="images/Paquetexpress.svg" alt="Paquetexpress">
            <div class="title is-5">{ paquetexpress.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(paquetexpress.created) }</div>
            <div class="items-space-around">
                <div class="field" style="margin: 0;">
                    <input on:click={() => updateCredentialActive(paquetexpress)} bind:checked={paquetexpress.active} class="is-checkradio is-primary" id="{paquetexpress._id}" type="checkbox">
                    <label for="{paquetexpress._id}"></label>
                </div>
                <button on:click={() => modalStore.open('paquetexpress', 'account_modal', paquetexpress)} class="button">
                    <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                </button>
            </div>
        </div>
        {/each}
    </div>
{/if}