<script>

    import { storeStore, modalStore } from '../stores'

    import DownloadReportComponent from './component.download.report.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'shipments_download_report_modal'

</script>

<Modal title="Descargar reporte" {show}>
    <DownloadReportComponent storeId={$storeStore._id} storeName={$storeStore.name} />
</Modal>