<script>

    import Switch from './switch.svelte'

    export let value = false
    export let shipmentType = 'national'

    function changeType() {
        shipmentType = value? 'international' : 'national'
    }

</script>

<div class="control">
    <label for="" class="label">Tipo de códigos postales</label>
    <div class="field">
        <div class="items-start space-bottom">
            <span class="icon-text space-right">
                <span class="icon" class:has-text-info={value === false}>
                    <i class="fas fa-truck"></i>
                </span>
                <span>Nacional</span>
            </span>
            <Switch bind:checked={value} on:change={changeType} on:change />
            <span class="icon-text space-left">
                <span class="icon" class:has-text-info={value === true}>
                    <i class="fas fa-plane"></i>
                </span>
                <span>Internacional</span>
            </span>
        </div>
    </div>
</div>