<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import MerchandiseUpdate from './component.update.svelte'

    $: show = $modalStore === 'merchandise_update_modal'

</script>

<Modal title="Actualizar mercancia" {show} >
    <MerchandiseUpdate />
</Modal>