<script>

    import { toast } from 'bulma-toast'
    import { facturamaInvoicesStore, facturamaClientStore, notifyStore } from '../stores'

    import FacturamaService from '../$services/facturama.service'
    
    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Select from '../$components/select.svelte'
    import Button from '../$components/button.svelte'
    import Spinner from '../$components/spinner.svelte'
    
    let loading = false
    let data = {
        rfc: $facturamaClientStore.rfc,
        name: $facturamaClientStore.name,
        zipcode: $facturamaClientStore.zipcode,
        fiscalRegime: $facturamaClientStore.fiscalRegime,
    }

    async function createInvoice() {

        loading = true
        const resp = await FacturamaService.createInvoice(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        facturamaInvoicesStore.append(resp.data)

        toast({message: 'Factura creada', type: 'is-success'})
        notifyStore.set('facturama_invoices_updated')
        
        data.amount = null
        data.cfdiUse = ''
        data.paymentType = ''
    }

</script>

<div class="title">Facturar</div>
<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createInvoice}>
        <div class="columns">
            <div class="column">
                <InputText bind:value={data.rfc} label="RFC" icon="fingerprint" placeholder="RFC" />
            </div>
            <div class="column">
                <InputText bind:value={data.name} label="Razón social" icon="fingerprint" placeholder="Razón social" />
            </div>
            <div class="column">
                <InputText bind:value={data.zipcode} label="Código postal" icon="fingerprint" placeholder="Código postal" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Select bind:value={ data.fiscalRegime } 
                    label="Régimen fiscal"
                    icon="list-alt"
                    options={[
                        {value: '601', text: 'General de Ley Personas Morales'},
                        {value: '603', text: 'Personas Morales con Fines no Lucrativos'},
                        {value: '605', text: 'Sueldos y Salarios e Ingresos Asimilados a Salarios'},
                        {value: '606', text: 'Arrendamiento'},
                        {value: '607', text: 'Régimen de Enajenación o Adquisición de Bienes'},
                        {value: '608', text: 'Demás ingresos'},
                        {value: '609', text: 'Consolidación'},
                        {value: '610', text: 'Residentes en el Extranjero sin Establecimiento Permanente en México'},
                        {value: '611', text: 'Ingresos por Dividendos (socios y accionistas)'},
                        {value: '612', text: 'Personas Físicas con Actividades Empresariales y Profesionales'},
                        {value: '614', text: 'Ingresos por intereses'},
                        {value: '615', text: 'Régimen de los ingresos por obtención de premios'},
                        {value: '616', text: 'Sin obligaciones fiscales'},
                        {value: '620', text: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'},
                        {value: '621', text: 'Incorporación Fiscal'},
                        {value: '622', text: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
                        {value: '623', text: 'Opcional para Grupos de Sociedades'},
                        {value: '624', text: 'Coordinados'},
                        {value: '625', text: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
                        {value: '626', text: 'Régimen Simplificado de Confianza'},
                        {value: '628', text: 'Hidrocarburos'},
                        {value: '629', text: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'},
                        {value: '630', text: 'Enajenación de acciones en bolsa de valores'},
                    ]}
                />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputNumber bind:value={ data.amount } label="Monto a facturar" icon="dollar-sign" placeholder="Monto a facturar" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Select bind:value={ data.cfdiUse } 
                    label="Uso del CFDI"
                    icon="list-alt"
                    options={[
                        {value: 'G03', text: 'Gastos en general'},
                    ]}
                />
            </div>
            <div class="column">
                <Select bind:value={ data.paymentType } 
                    label="Método de pago"
                    icon="list-alt"
                    options={[
                        {value: '04', text: 'Tarjeta de crédito'},
                        {value: '28', text: 'Tarjeta de débito'},
                        {value: '01', text: 'Efectivo'},
                        {value: '02', text: 'Cheque nominativo'},
                        {value: '03', text: 'Transferencia electrónica de fondos'}
                    ]}
                />
            </div>
        </div>
        <div class="items-end">
            <Button text="Generar" type="submit" icon="save" color="primary" />
        </div>
    </form>
{/if}