<script>

    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'
    import { ticketsStore, shipmentStore, modalStore } from '../stores'

    import TicketsService from '../$services/tickets.service'
    import BucketService from '../$services/bucket.service'

    import Spinner from '../$components/spinner.svelte'
    import File from '../$components/file.svelte'

    let data = {information: {}}
    let loading = false

    async function createTicket() {

        loading = true

        const information = await verifyProps()

        if(!information) {
            loading = false
            return toast({message: 'Error al cargar la información', type: 'is-danger'})
        }

        const request = {
            shipmentId: $shipmentStore._id,
            subject: data.subject,
            message: data.message,
            information
        }

        const resp = await TicketsService.createTicket(request)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ticketsStore.append(resp.data)
        
        toast({message: 'Ticket creado', type: 'is-success'})
        replace('#/tickets')
    }

    async function verifyProps() {

        const props = Object.keys(data.information)
        const promises = props.map(prop => verifyProp(prop))
        const request = {}

        const resp = await Promise.all(promises)

        let isError = false

        resp.forEach(item => {

            if(!item)
                isError = true

            request[item.prop] = item.value
        })

        return !isError? request : null
    }

    async function verifyProp(prop) {

        const file = data.information[prop]

        if(typeof file != 'object' && !file.type)
            return {prop, value: data.information[prop]}

        if(!file || !file.type)
            return {prop, value: data.information[prop]}

        const query = {
            fileType: file.type,
            fileDir: `ticket_${$shipmentStore.code}`,
        }

        toast({message: `Cargando archivo ${file.name}`, type: 'is-info'})

        const resp = await BucketService.getSigned(query)

        if(!resp.success) {
            toast({message: resp.error.message, type: 'is-danger'})
            return null
        }

        const {signed, url} = resp.data

        await BucketService.uploadFile(file, signed)

        toast({message: `Archivo ${file.name} cargado`, type: 'is-info'})

        return {
            prop,
            value: url
        }
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createTicket}>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Tema</label>
                    <div class="select">
                        <select bind:value={data.subject}>
                            <option value="">Selecciona un tema</option>
                            <option value="Daño o extravío">Daño o extravío</option>
                            <option value="Entrega fallida">Entrega fallida</option>
                            <option value="Domicilio no encontrado">Domicilio no encontrado</option>
                            <option value="El paquete no se mueve de algún hub">El paquete no se mueve de algún hub</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        {#if data.subject === 'Daño o extravío'}
            <div class="field">
                <label for="" class="label">Descripción del bien afectado</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-comment"></i></span>
                    <input bind:value={data.information.description} type="text" class="input" placeholder="Descripción del bien afectado" required>
                </div>
            </div>
            <div class="field">
                <label for="" class="label">Valor del bien afectado</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-dollar-sign"></i></span>
                    <input bind:value={data.information.value} type="number" class="input" placeholder="Valor del bien afectado" required>
                </div>
            </div>
            <div class="field">
                <label for="" class="label">Número de cuenta CLABE</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-credit-card"></i></span>
                    <input bind:value={data.information.clabe} type="text" class="input" placeholder="Número de cuenta CLABE" required>
                </div>
            </div>
            <div class="field">
                <label for="" class="label">Factura original del bien afectado</label>
                <File bind:file={data.information.invoice} />
                <small>Sube tu documento en PDF</small>
            </div>
            <div class="field">
                <label for="" class="label">Video de empaquetado</label>
                <File bind:file={data.information.video} />
            </div>
            <div class="field">
                <label for="" class="label">Foto</label>
                <File bind:file={data.information.photo1} />
            </div>
            <div class="field">
                <label for="" class="label">Foto</label>
                <File bind:file={data.information.photo2} required={false} />
            </div>
            <div class="field">
                <label for="" class="label">Foto</label>
                <File bind:file={data.information.photo3} required={false} />
            </div>
            <div class="field">
                <label for="" class="label">Comentarios</label>
                <div class="control has-icons-left">
                    <textarea bind:value={data.message} class="textarea" placeholder="Cometarios o notas sobre el problema"></textarea>
                </div>
            </div>
            <div class="notification is-warning">Entrega a dirección incorrecta o pérdida en algún proceso del transporte. <strong>No incluye el robo a la unidad de transporte si no se contaba con el seguro de pago.</strong></div>
        {/if}

        {#if data.subject === 'Entrega fallida'}
            <div class="field">
                <label for="" class="label">Dirección del mostrador</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                    <input bind:value={data.information.references} type="text" class="input" placeholder="Dirección del mostrador" required>
                </div>
            </div>
            <div class="field">
                <label for="" class="label">Comentarios</label>
                <div class="control has-icons-left">
                    <textarea bind:value={data.message} class="textarea" placeholder="Cometarios o notas sobre el problema"></textarea>
                </div>
            </div>
            <div class="notification is-warning">La persona debe llevar consigo el número de guía y una identificación oficial. En caso de que vaya una persona distinta a la expresada en la guía, deberá llevar una carta poder y una copia de identificación de la persona que debía recoger originalmente.</div>
        {/if}

        {#if data.subject === 'Domicilio no encontrado'}
            <div class="field">
                <label for="" class="label">Referencias extras</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-asterisk"></i></span>
                    <input bind:value={data.information.references} type="text" class="input" placeholder="Referencias extras" required>
                </div>
            </div>
            <div class="field">
                <label for="" class="label">Número de contacto</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-phone"></i></span>
                    <input bind:value={data.information.phone} type="text" class="input" placeholder="Número de contacto" required>
                </div>
            </div>
            <div class="field">
                <label for="" class="label">Comentarios</label>
                <div class="control has-icons-left">
                    <textarea bind:value={data.message} class="textarea" placeholder="Cometarios o notas sobre el problema"></textarea>
                </div>
            </div>
            <div class="notification is-warning">Número de contacto de alguna persona que pueda recibir el paquete si el destinatario original no se encuentra en el domicilio en las horas de reparto y desea de cualquier forma que se entregue en domicilio.</div>
        {/if}

        {#if data.subject === 'El paquete no se mueve de algún hub'}
            <div class="field">
                <label for="" class="label">Comentarios</label>
                <div class="control has-icons-left">
                    <textarea bind:value={data.message} class="textarea" placeholder="Cometarios o notas sobre el problema"></textarea>
                </div>
            </div>
            <div class="notification is-warning">El tiempo de entrega puede variar debido a la demanda de Estafeta y/o los días de entrega en ciertas zonas</div>
        {/if}

        {#if data.subject === 'Otro'}
            <div class="field">
                <label for="" class="label">Comentarios</label>
                <div class="control has-icons-left">
                    <textarea bind:value={data.message} class="textarea" placeholder="Cometarios o notas sobre el problema"></textarea>
                </div>
            </div>
        {/if}

        <div class="items-end">
            <button on:click={() => modalStore.set('shipment_modal')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}