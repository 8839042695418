<script>

    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'

    import { dhlsStore, modalStore } from '../stores'
    import DhlService from '../$services/dhl.service'

    import Spinner from '../$components/spinner.svelte'

    const dispatch = createEventDispatcher()

    let data = {
        provider: 'DHL'
    }

    let loading = false

    async function createCredential() {

        loading = true
        const resp = await DhlService.createCredential(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        dhlsStore.append(resp.data)
        toast({message: 'Credencial creada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createCredential}>
        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/DHL.svg" alt="DHL">
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.alias} type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Número de cuenta</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.account}  type="text"  class="input" placeholder="Cuenta" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Usuario</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.client} type="text"  class="input" placeholder="Usuario" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Contraseña</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.password} type="password"  class="input" placeholder="Contraseña" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => dispatch('close')} type="button" class="button">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary">
                        <span class="icon"><i class="fas fa-save"></i></span>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}