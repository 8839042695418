import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createComment,
    getAllCommentsPost,
    getCommentsPost,
    getCommentsComment,
    setLike,
    updateComment,
    deleteComment,
}

function createComment(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/comments`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getAllCommentsPost(postId) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/comments/post/${postId}/all`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function getCommentsPost(postId) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/comments/post/${postId}`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function getCommentsComment(commentId) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/comments/comment/${commentId}`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}

function setLike(commentId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/comments/${commentId}/likes`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateComment(commentId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/comments/${commentId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteComment(commentId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/comments/${commentId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}