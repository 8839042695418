<script>

    import { onMount } from 'svelte'
    import { toast } from 'bulma-toast'
    import { merchandiseStore } from '../stores'

    import GraphicsService from '../$services/graphics.service'

    import Graphic from '../$components/graphic.svelte'
    import Button from '../$components/button.svelte'
    import Date from '../$components/date.svelte'

    let results = []
    let labels = []
    let datasets = []
    let loading = false
    let query = {
        merchandiseId: $merchandiseStore._id
    }

    onMount(getStocksTotal)

    async function getStocksTotal() {

        results = []
        labels = []
        datasets = []

        const options = {...query}

        if(options.created)
            options.created = options.created.toISOString()

        loading = true
        const resp = await GraphicsService.getStocksTotal(options)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        results = resp.data.results
        labels = resp.data.labels
        datasets = resp.data.datasets
    }

</script>

<div class="columns">
    <div class="column">
        <Date bind:value={query.created} showTime={false} />
    </div>
    <div class="column is-narrow">
        <Button on:click={ getStocksTotal } icon="search" text="Buscar" />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Graphic bind:labels={labels} bind:datasets={datasets} chartType="pie" colorRandom={true} {loading} />
    </div>
</div>