import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getUser,
    getUserConfig,
    getUsersChat,
    getInvitationCode,
    getUsersAgreement,
    getReferidos,
    createUserInvitation,
    userLogin,
    updateUser,
    recoveryUser,
    recoveryHash
}

function getUser(userId) {
    return new Promise((resolve, reject) => {

        Superagent
            .get(`${HOST_API}/users/${ userId }`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getUserConfig(userId) {
    return new Promise((resolve, reject) => {

        Superagent
            .get(`${HOST_API}/users/config`)
            .query({requestAccess: userId})
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getInvitationCode(invitationCode) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/invitations/${invitationCode}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function createUserInvitation(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/users/invitation`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function getUsersChat() {
    return new Promise((resolve, reject) => {

        Superagent
            .get(`${HOST_API}/users/chat`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function userLogin(data) {
    return new Promise((resolve, reject) => {

        Superagent
            .post(`${HOST_API}/login`)
            .send(data)
            .end((err, res) => {
 
                if(!err) {
                    Storage.setItem('user', res.body.data.user)
                    Storage.setItem('access', res.body.data.access)
                }

                resolve(res.body)
            })
    })
}

function updateUser(data) {
    return new Promise((resolve, reject) => {

        Superagent
            .put(`${HOST_API}/users/`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getUsersAgreement() {
    return new Promise((resolve, reject) => {

        if(!Storage.getItem('access'))
            return {success: true}

        Superagent
            .get(`${HOST_API}/users/agreement`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getReferidos(userId) {
    return new Promise((resolve, reject) => {

        Superagent
            .get(`${HOST_API}/users/${ userId }/referidos`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function recoveryUser(data) {
    return new Promise((resolve, reject) => {

        Superagent
            .post(`${HOST_API}/users/recovery`)
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function recoveryHash(hash, data) {
    return new Promise((resolve, reject) => {

        Superagent
            .post(`${HOST_API}/users/recovery/${hash}`)
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}