import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getMerchandises,
    getMerchandiseDefault,
    createMerchandise,
    updateMerchandise,
    updateMerchandiseDefault,
    deleteMerchandise,
}

function getMerchandises() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/merchandises`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getMerchandiseDefault() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/merchandises/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createMerchandise(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/merchandises`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateMerchandise(merchandiseId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/merchandises/${merchandiseId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateMerchandiseDefault(merchandiseId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/merchandises/${merchandiseId}/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteMerchandise(merchandiseId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/merchandises/${merchandiseId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}