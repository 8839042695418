import { writable } from 'svelte/store'

export const accountsStore = items([])
export const ampmsStore = items([])
export const chargesStore = items([])
export const commentsStore = items([])
export const coursesStore = items([])
export const creditsStore = items([])
export const dhlsStore = items([])
export const estafetasStore = items([])
export const facturamaInvoicesStore = items([])
export const fedexsStore = items([])
export const foliosStore = items([])
export const importantNotificationsGlobalStore = items([])
export const importantNotificationsStore = items([])
export const incidentsStore = items([])
export const invitationsStore = items([])
export const locationsStore = items([])
export const merchandisesStore = items([])
export const N99MinutossStore = items([])
export const notificationsGlobalStore = items([])
export const notificationsStore = items([])
export const ordersAutomaticStore = items([])
export const ordersLinksStore = items([])
export const ordersStore = items([])
export const packagesStore = items([])
export const paquetexpresssStore = items([])
export const pickupsStore = items([])
export const postsStore = items([])
export const prepaidsStore = items([])
export const productsStore = items([])
export const quillsStore = items([])
export const redpacksStore = items([])
export const sengridsStore = items([])
export const shipmentsSelectedStore = items([])
export const shipmentsStore = items([])
export const shopifysStore = items([])
export const socketEventsStore = items([])
export const stocksStore = items([])
export const storesStore = items([])
export const ticketsStore = items([])
export const tracingsStore = items([])
export const trackingsStore = items([])
export const transactionsStore = items([])
export const treggosStore = items([])
export const tutorialsStore = items([])
export const upssStore = items([])
export const usersChatStore = items([])
export const vtexsStore = items([])
export const woocommercesStore = items([])
export const odoosStore = items([])

export const accessStore = writable(null)
export const accountStore = writable(null)
export const ampmStore = writable(null)
export const chargeStore = writable(null)
export const commentStore = writable(null)
export const courseStore = writable(null)
export const creditStore = writable(null)
export const dhlStore = writable(null)
export const estafetaStore = writable(null)
export const facturamaClientStore = writable(null)
export const facturamaInvoiceStore = writable(null)
export const fedexStore = writable(null)
export const incidentStore = writable(null)
export const locationStore = writable(null)
export const merchandiseStore = writable(null)
export const N99MinutosStore = writable(null)
export const notifyStore = writable(null)
export const orderLinkStore = writable(null)
export const orderStore = writable(null)
export const packageStore = writable(null)
export const paquetexpressStore = writable(null)
export const pickupStore = writable(null)
export const postStore = writable(null)
export const prepaidStore = writable(null)
export const productStore = writable(null)
export const redpackStore = writable(null)
export const shipmentStore = writable(null)
export const socketStore = writable(null)
export const stockStore = writable(null)
export const storeStore = writable(null)
export const ticketStore = writable(null)
export const tracingStore = writable(null)
export const trackingStore = writable(null)
export const transactionStore = writable(null)
export const treggoStore = writable(null)
export const tutorialStore = writable(null)
export const upsStore = writable(null)
export const userChatStore = writable(null)
export const userStore = writable(null)
export const vtexStore = writable(null)
export const walletStore = writable(null)
export const odooStore = writable(null)

export const configStore = writable({})
export const modalStore = $modalStore()
export const popupStore = $popupStore()

function $modalStore() {

    const store = writable(null)

    store.close = () => store.set(null)

    store.open = (type, action = null, data = null) => {

        if(!action && !data)
            return store.set(type)

        type === 'account' && accountStore.set(data)
        type === 'ampm' && ampmStore.set(data)
        type === 'automatic' && ordersAutomaticStore.set(data)
        type === 'charge' && chargeStore.set(data)
        type === 'comment' && commentStore.set(data)
        type === 'course' && courseStore.set(data)
        type === 'credit' && creditStore.set(data)
        type === 'dhl' && dhlStore.set(data)
        type === 'estafeta' && estafetaStore.set(data)
        type === 'facturama' && facturamaInvoiceStore.set(data)
        type === 'fedex' && fedexStore.set(data)
        type === 'incident' && incidentStore.set(data)
        type === 'location' && locationStore.set(data)
        type === 'merchandise' && merchandiseStore.set(data)
        type === 'N99Minutos' && N99MinutosStore.set(data)
        type === 'order' && orderStore.set(data)
        type === 'package' && packageStore.set(data)
        type === 'paquetexpress' && paquetexpressStore.set(data)
        type === 'pickup' && pickupStore.set(data)
        type === 'post' && postStore.set(data)
        type === 'prepaid' && prepaidStore.set(data)
        type === 'product' && productStore.set(data)
        type === 'redpack' && redpackStore.set(data)
        type === 'shipment' && shipmentStore.set(data)
        type === 'stock' && incidentStore.set(data)
        type === 'store' && storeStore.set(data)
        type === 'ticket' && ticketStore.set(data)
        type === 'tracing' && tracingStore.set(data)
        type === 'tracking' && shipmentStore.set(data)
        type === 'transaction' && transactionStore.set(data)
        type === 'treggo' && treggoStore.set(data)
        type === 'tutorial' && tutorialStore.set(data)
        type === 'ups' && upsStore.set(data)

        store.set(`${type}_${action}`)
    }

    return store
}

function $popupStore() {

    const store = writable(null)

    store.close = () => store.set(null)

    store.open = (type, action = null, data = null) => {

        if(!action && !data)
            return store.set(type)

        store.set(`${type}_${action}`)
    }

    return store
}

function items(state = null) {
    const {subscribe, update, set} = writable(state)
    return {
        subscribe,
        update,
        set,
        append: item => append(item, update),
        replace: item => replace(item, update),
        remove: itemId => remove(itemId, update)
    }
}

function append(item, update) {
    return update(items => {
        items.unshift(item)
        return items
    })
}

function replace(data, update) {
    return update(items => {
        return items.map(item => {
            return item._id === data._id? data : item
        })
    })
}

function remove(itemId, update) {
    return update(items => {
        return items.filter(item => item._id != itemId)
    })
}