<script>

    import DateMethod from '../$methods/date.methods.js'

    export let ampm = null

</script>

<div class="table-container">
    <table class="table is-fullwidth">
        <tbody>
            <tr>
                <th>Alias</th>
                <td>{ ampm.alias }</td>
            </tr>
            <tr>
                <th>Proveedor</th>
                <td>{ ampm.provider }</td>
            </tr>
            <tr>
                <th>Cuenta activa</th>
                <td>{ ampm.active ? 'Si' : 'No' }</td>
            </tr>
            <tr>
                <th>Fecha</th>
                <td>{ DateMethod.dateTimeLarge(ampm.created) }</td>
            </tr>
        </tbody>
    </table>
</div>