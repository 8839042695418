<script>

    import { replace } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'

    import { ordersStore, modalStore } from '../stores'

    import OrdersService from '../$services/orders.service'
    import DateMethod from '../$methods/date.methods'
    import NumeralMethods from '../$methods/numeral.methods'

    import Delete from '../$components/delete.svelte'
    import Value from '../$components/value.svelte'

    import LocationDetails from '../locations/component.location.details.svelte'
    import PackageDetails from '../packages/component.package.details.svelte'

    const { BRAND } = _CONSTANTS_

    export let order = null

    let loading = false
    let localService = false

    async function deleteOrder() {

        loading = true
        const resp = await OrdersService.deleteOrder(order._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ordersStore.remove(order._id)
        modalStore.close()
        toast({message: 'Órden borrada', type: 'is-success'})
    }

    async function updateOrderStoreShipment() {
    
        loading = true
        const resp = await OrdersService.updateOrderStoreShipment(order._id)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        toast({message: 'Actualizado', type: 'is-success'})
    }

    async function openOrder(def='') {
        modalStore.close()
        replace(`#/order/${def}?localServices=${localService}`)
    }

</script>


<div class="columns">
    <div class="column">
        <div class="title">Orden #{ order.number } 
            <div class="tag is-primary">{ order.storeType }</div> 
            {#if order.storePayment}
                <div class="tag is-info">{ order.storePayment }</div>
            {/if}
        </div>
        <div class="subtitle">{ DateMethod.dateTimeLarge(order.created) }</div>
    </div>
</div>

{#if order.shipmentId}
    <div class="columns">
        <div class="column">
            <div class="title is-5" style="color: #4E3596;">El envío ya fue generado</div>
            <div class="subtitle is-6">Guía { order.shipment.guide }</div>
            <button on:click={updateOrderStoreShipment} class="button is-primary" class:is-loading={loading}>
                <span class="icon"><i class="fas fa-sync-alt"></i></span>
                <span>Intentar asignar los datos de envío nuevamente</span>
            </button>
        </div>
    </div>
{/if}

<div class="columns">

    {#if order.pickup}
        <div class="column">
            <div class="title">Origen</div>

            <LocationDetails location={ order.pickup } />

            {#if order.storeType === BRAND}
                <div class="items-end">
                    <button on:click={() => modalStore.set('order_update_pickup_modal') } class="button space-top">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            {/if}
        </div>
    {/if}

    <div class="column">
        <div class="title">Destino</div>

        <LocationDetails location={ order.delivery } />

        {#if order.storeType === BRAND}
            <div class="items-end">
                <button on:click={() => modalStore.set('order_update_delivery_modal') } class="button space-top">
                    <span class="icon"><i class="fas fa-edit"></i></span>
                    <span>Editar</span>
                </button>
            </div>
        {/if}
    </div>
</div>

<br>

<div class="columns">
    {#if order.package}
        <div class="column">
            <div class="title">Paquete</div>

            <PackageDetails ipackage={ order.package } />

            {#if order.storeType === BRAND}
                <div class="items-end">
                    <button on:click={() => modalStore.set('order_update_package_modal') } class="button space-top">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            {/if}
        </div>
    {/if}
</div>

{#if order.quotation && order.quotation.provider}
    <div class="columns">
        <div class="column">
            <div class="title">Servicio</div>
            <div class="columns">
                <div class="column">
                    <Value label="Proveedor" value={ order.quotation.provider.replace(' Partner', '') } />
                </div>
                <div class="column">
                    <Value label="Servicio" value={ order.quotation.service } />
                </div>
                <div class="column">
                    <Value label="Precio final" value={ NumeralMethods.cash(order.quotation.finalPrice) } />
                </div>
            </div>
            {#if order.quotation.payment === 'pending'}
                <div class="notification is-primary">Pago de envío pendiente por parte del cliente</div>
            {/if}
            {#if order.quotation.payment === 'success'}
                <div class="notification is-info">El pago del envío fue realizado</div>
            {/if}
        </div>
    </div>
{/if}

{#if order.service}
    <div class="columns">
        <div class="column">
            <div class="title">Servicio</div>
            {#if order.service.code}
                <div>{ order.service.code }</div>
            {/if}
            {#if order.service.price}
                <div>Precio ${ order.service.price }</div>
            {/if}
        </div>
    </div>
{/if}

{#if order.iccid && order.iccid.length}
    <div class="columns">
        <div class="column">
            <div class="title">ICCID <span class="tag is-primary">{ order.tag }</span> </div>
            <div class="table-container">
                <table class="table is-fullwidth is-hoverable">
                    <tbody>
                        {#each order.iccid as iccid}
                            <tr>
                                <th>{ iccid }</th>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
{/if}

{#if order.products.length}
    <div class="columns">
        <div class="column">
            <div class="title">Productos</div>
            <div class="table-container">
                <table class="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th></th>
                            <th>SKU</th>
                            <th>Nombre</th>
                            <th>Precio</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                    {#each order.products as product, index}
                        <tr>
                            <td>{ index+1 }</td>
                            <td>{ product.sku }</td>
                            <td>{ product.name }</td>
                            <td>${ product.price }</td>
                            <td>{ product.quantity }</td>
                        </tr>
                    {/each}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
{/if}

{#if !order.shipment}
    <div class="columns">

        {#if order.storeType === BRAND}
            <div class="column">
                <Delete on:confirm={deleteOrder} {loading} />
            </div>
        {/if}
    
        <div class="column">
            <button on:click={() => openOrder()} class="button is-primary is-fullwidth">
                <span class="icon"><i class="fas fa-shipping-fast"></i></span>
                <span>Revisar y envíar</span>
            </button>
        </div>
    </div>
{/if}