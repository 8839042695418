<script>

    import { createEventDispatcher } from 'svelte'
    import { toast } from 'bulma-toast'

    import SatProductsService from '../$services/catalogs.service'

    import InputText from '../$components/input.text.svelte'

    const dispatch = createEventDispatcher()

    export let selected = {}

    let loading = false
    let hide = false
    let units = []

    async function getSatUnits() {

        hide = false
        selected._id = null
        selected.code = null

        loading = true
        const resp = await SatProductsService.getSatUnits({find: selected.name})
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        units = resp.data            
    }

    function onSelect(unit) {
        hide = true
        selected = unit
        dispatch('selected', unit)
    }  

    function hideBox(value) {
        setTimeout(() => {
            hide = value
        }, 200)
    }

</script>

<style>
    .boxContainer {
        position: relative;
    }

    .boxSelect {
        max-width: 500px;
        max-height: 300px;
        overflow: auto;
        border-radius: 16px;
        background: hsl(0, 0%, 98%);
        border: solid 2px whitesmoke;
        box-shadow: 0px 10px 11px -10px  hsl(0, 0%, 90%);
        position: absolute;
        z-index: 5;
    }

    .boxSelect.hide {
        display: none;
    }

    .item {
        padding: 8px 16px;
    }

    .item:hover {
        color: white;
        cursor: pointer;
        background: #C33B8D;
        border-radius: 100px;
    }
</style>

<InputText 
    bind:value={ selected.name } 
    on:keyup={ getSatUnits } 
    on:focus={() => hideBox(false)} 
    on:blur={() => hideBox(true)} 
    label="Unidad de medida" 
    icon="weight" 
    placeholder="Unidad de medida" 
/>

<div class="boxContainer">
    <div class="boxSelect" class:hide={!units.length || selected._id || hide}>
        {#each units as unit}
            <div on:click={() => onSelect(unit)} class="item">{ unit.name }</div>
        {/each}
    </div>
</div>

<div class="items-end">
    <div>{ selected.code || 'No se eligió una opción' } { selected.details || '' }</div>
</div>