<script>

    import Papaparse from 'papaparse'
    import Moment from 'moment'
    import { toast } from 'bulma-toast'

    import GraphicsService from '../$services/graphics.service'
    import Graphic from '../$components/graphic.svelte'
    import Table from '../$components/table.svelte'
    import Title from '../$components/title.svelte'
    import Button from '../$components/button.svelte'
    
    let query = {
        adminUser: true,
        timezone: 6,
        dateStart: Moment().subtract(10, 'days').startOf('day').toDate(),
        dateEnd: Moment().endOf('day').toDate(),
    }
    
    let loading = false
    let labels = []
    let datasets = []
    let results = []
    let shipmentsTotal = 0
    let element = null

    getShipmentsDelivery()
    
    async function getShipmentsDelivery() {

        if(!query) return

        const send = {...query}
              send.dateStart = query.dateStart.toISOString()
              send.dateEnd = query.dateEnd.toISOString()
    
        loading = true
        const resp = await GraphicsService.getShipmentsDelivery(send)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        labels = resp.data.labels
        datasets = resp.data.datasets
        results = resp.data.results

        shipmentsTotal = parseFloat(datasets.reduce((total, value) => total+value, 0) / datasets.length).toFixed(2)
    }

    function createFile() {

        const info = results.map(result => {
            return {
                'Proveedor.Servicio': `${ result._id.provider }.${ result._id.service }`,
                'Dias': result.total,
            }
        })

        const blob = new Blob([Papaparse.unparse(info, { delimiter: ',' })], {type : 'text/csv'})

        let dateStart = Moment(query.dateStart).format('DD-MM-YYYY')
        let dateEnd = Moment(query.dateEnd).format('DD-MM-YYYY')

        element.download = `shipments.delivery.${dateStart}.a.${dateEnd}.csv`
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

</script>

<a bind:this={element} href="/" style="display: none;">.</a>

<div class="box">
    
    <div class="items-space">
        <Title text="Promedio de entregas" />
        <Title text="{ shipmentsTotal } Días" />
    </div>

    <div class="columns">
        <div class="column">
            <Graphic bind:labels={labels} bind:datasets={datasets} chartType="bar" colorRandom={true} {loading} />
        </div>
        <div class="column is-3">
            <Table items={ results.length } {loading} height="450px" >
                <thead>
                    <tr>
                        <th>Proveedor_Servicio</th>
                        <th>Días</th>
                    </tr>
                </thead>
                <tbody>
                    {#each results as result}
                        <tr>
                            <td>{ `${result._id.provider}.${result._id.service}` }</td>
                            <td>{ result.total }</td>
                        </tr>
                    {/each}
                </tbody>
            </Table>
        </div>
    </div>
</div>

<div class="items-end">
    <Button on:click={createFile} text="Descargar" icon="file-csv" size="small" />
</div>