<script>

    import { toast } from 'bulma-toast'
    import { configStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'
    import TrackingComponent from '../trackings/component.tracking.svelte'

    let loading = false
    let shipment = null

    getShipmentCode()

    async function getShipmentCode() {

        loading = true
        const resp = await ShipmentsService.getShipmentCode('8BF8B55B4C4D6E23')
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipment = resp.data
    }

</script>

{#if $configStore.tracking.brand}
    <div class="center">
        <img src="{$configStore.tracking.brand}?random={Math.random()}" alt="Brand">
    </div>
{:else}
    <div class="hero is-primary">
        <div class="hero-body">
            <div class="container">
                <div class="title">MyShipper</div>
                <div class="subtitle">Rastreo</div>
            </div>
        </div>
    </div>
{/if}
<br>
{#if shipment}
    <div class="columns">
        <div class="column">
            <div class="columns">
                <div class="column">
                    <div class="items-end">
                        {#if $configStore.tracking.facebook}
                            <a href="{$configStore.tracking.facebook}" target="_blank" style="color: #333;">
                                <span class="icon is-large"><i class="fab fa-2x fa-facebook-square"></i></span>
                            </a>
                        {/if}
                        {#if $configStore.tracking.instagram}
                            <a href="{$configStore.tracking.instagram}" target="_blank" style="color: #333;">
                                <span class="icon is-large"><i class="fab fa-2x fa-instagram"></i></span>
                            </a>
                        {/if}
                        {#if $configStore.tracking.pinterest}
                            <a href="{$configStore.tracking.pinterest}" target="_blank" style="color: #333;">
                                <span class="icon is-large"><i class="fab fa-2x fa-pinterest"></i></span>
                            </a>
                        {/if}
                        {#if $configStore.tracking.webpage}
                            <a href="{$configStore.tracking.webpage}" target="_blank" style="color: #333;">
                                <span class="icon is-large"><i class="fas fa-2x fa-globe"></i></span>
                            </a>
                        {/if}
                    </div>
                </div>
            </div>

            <div class="title is-4">Origen</div>
            <div class="subtitle is-5">
                {shipment.order.pickup.zipcode}, {shipment.order.pickup.state}, {shipment.order.pickup.city}
            </div>
            <hr>
            <div class="title is-4">Destino</div>
            <div class="subtitle is-5">
                {shipment.order.delivery.zipcode}, {shipment.order.delivery.state}, {shipment.order.delivery.city}
            </div>
            <hr>
            <div class="title is-4">Guía</div>
            <div class="subtitle is-5">{shipment.guide}</div>
            <hr>
            <div class="title is-4">{shipment.provider}</div>
            <div class="subtitle is-5">{shipment.service}</div>
            <hr>
            <TrackingComponent shipmentId={shipment._id}/>
            <hr>
            <div>{$configStore.tracking.message || ''}</div>

            {#if $configStore.tracking.footer}
                <br>
                <div class="center">
                    <img src="{$configStore.tracking.footer}?random={Math.random()}" alt="Footer">
                </div>
            {/if}

        </div>
        <div class="column">
            {#if $configStore.tracking.banner}
                <div class="center">
                    <a href="{$configStore.tracking.bannerURL}" target="_blank">
                        <img src="{$configStore.tracking.banner}?random={Math.random()}" alt="Banner">
                    </a>
                </div>
            {/if}
        </div>
    </div>
{/if}