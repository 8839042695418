<script>

    import { createEventDispatcher } from 'svelte'
    import Date from './date.svelte'

    export let dateStart
    export let dateEnd
    export let showTime = true 

    const dispatch = createEventDispatcher()

    function onChange() {
        dispatch('change')
    }

</script>

<div class="columns">
    <div class="column">
        <Date on:change={onChange} bind:value={dateStart} label="Fecha inicial" {showTime} />
    </div>
    <div class="column">
        <Date on:change={onChange} bind:value={dateEnd} label="Fecha final" {showTime} />
    </div>
</div>