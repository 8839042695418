<script>

    import { shipmentStore, modalStore } from '../stores'

    import ShipmentUpdateGuideComponent from './component.update.guide.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'shipment_update_guide'

</script>

<Modal title="Actualizar guía" {show}>
    <ShipmentUpdateGuideComponent 
        shipmentId={$shipmentStore._id} 
        orderId={$shipmentStore.order._id} 
        pickup={$shipmentStore.order.pickup} 
        delivery={$shipmentStore.order.delivery} 
        packageContent={$shipmentStore.order.package.content}
    />
</Modal>