<script>

    import { createEventDispatcher } from 'svelte'
    import { toast } from 'bulma-toast'

    import LocationsService from '../$services/locations.service'

    import InputText from '../$components/input.text.svelte'

    const dispatch = createEventDispatcher()

    export let selected = {}
    export let label = ''
    
    export let autocomplete = true
    export let details = true
    export let clear = true

    let loading = false
    let boxHide = false
    let inputText = ''
    let locations = []
    let items = []

    getLocations()

    async function getLocations() {

        if(!autocomplete)
            return

        loading = true
        const resp = await LocationsService.getLocations({})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        locations = resp.data.locations
    }

    function onSelect(location) {
        inputText = `${location.zipcode}`
        selected = location
        dispatch('select', location)
    }

    function onKeyup(event) {

        selected.zipcode = inputText

        if(!autocomplete)
            return

        if(clear)
            selected = {zipcode: inputText}

        items = locations.filter(location => {

            if(location.alias.toLowerCase().includes(inputText.toLowerCase()))
                return true

            if(location.zipcode.includes(inputText))
                return true

            return false
        })
    }

    function hideBox(value) {
        setTimeout(() => {
            boxHide = value
        }, 200)
    }

</script>

<style>

    .boxContainer {
        position: absolute;
        z-index: 5;
    }

    .boxSelect {
        width: 100%;
        height: auto;
        min-width: 500px;
        border-radius: 16px;
        background: hsl(0, 0%, 98%);
        border: solid 2px whitesmoke;
        box-shadow: 0px 10px 11px -10px  hsl(0, 0%, 90%);
    }

    .boxSelect.hide {
        display: none;
    }

    .item {
        padding: 8px 16px;
    }

    .item.active {
        background: #C33B8D;
    }

    .item:hover {
        color: white;
        cursor: pointer;
        background: #C33B8D;
        border-radius: 100px;
    }

</style>

<div>
    <InputText on:keyup={onKeyup} bind:value={ inputText } on:focus={() => hideBox(false)} on:blur={() => hideBox(true)} label={ label } icon="map-marker-alt" minlength="5" placeholder="Código postal" />
    
    {#if autocomplete}
        <div class="boxContainer">
            <div class="boxSelect" class:hide={!items.length || selected._id || boxHide}>
                {#each items as location}
                    <div on:click={() => onSelect(location)} class="item" class:active={selected._id === location._id}><b>{location.alias}</b>, {location.zipcode}, {location.state}.</div>
                {/each}
            </div>
        </div>
    {/if}

    {#if details}
        <div class="items-end">
            {#if selected._id}
                <small><b>{selected.alias}</b>, {selected.zipcode}, {selected.state}.</small>
            {:else}
                <span>No se eligió una dirección</span>
            {/if}
        </div>
    {/if}
</div>