<script>

    import { Loader } from '@googlemaps/js-api-loader'
    import { toast } from 'bulma-toast'

    const { GOOGLE_MAPS_KEY } = _CONSTANTS_

    let container
    let map
    let geocoder
    let autocomplete
    let marker
    let address

    export let location
    export let addressComponents
    export let position = {
        lat: 19.435109,
        lng: -99.133806
    }

    const loader = new Loader({
        apiKey: GOOGLE_MAPS_KEY,
        version: "weekly",
        libraries: ["places"]
    })

    loader.load().then(() => {

        map = new google.maps.Map(container, {center: position, zoom: 15})
        marker = new google.maps.Marker({map, position, draggable: true})
        geocoder = new google.maps.Geocoder()

        autocomplete = new google.maps.places.Autocomplete(address, {
            componentRestrictions: {
                country: ["MX"]
            },
            fields: ["address_components", "geometry"],
            types: ["address"],
        })

        map.addListener("center_changed", () => {
            window.setTimeout(() => {
                map.panTo(marker.getPosition())
            }, 3600)
        })

        google.maps.event.addListener(marker, 'dragend', event => {

            position.lat = event.latLng.lat()
            position.lng = event.latLng.lng()

            marker.setPosition(position)
            findAddress()
        })

        google.maps.event.addListener(autocomplete, 'place_changed', () => {

            if(!autocomplete.getPlace().geometry)
                return toast({message: 'No se encontraron resultados', type: 'is-success'})

            marker.setPosition(autocomplete.getPlace().geometry.location)

            map.setZoom(15)
            map.setCenter(marker.getPosition())

            location = address.value
            addressComponents = autocomplete.getPlace().address_components
        })

        if(location)
            setAddress()
    })

    async function findAddress() {

        geocoder.geocode({location: position}, (results, status) => {

            if(status != 'OK')
                return toast({message: 'No se encontraron resultados', type: 'is-success'})

            address.value = results[0].formatted_address
            location = address.value
            addressComponents = results[0].address_components
        })
    }

    async function setAddress() {

        address.value = location

        geocoder.geocode({address: address.value}, (results, status) => {

            if(status != 'OK')
                return toast({message: 'No se encontraron resultados', type: 'is-success'})

            position = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
            }

            marker.setPosition(position)

            map.setZoom(15)
            map.setCenter(marker.getPosition())

            address.value = results[0].formatted_address
            location = address.value
            addressComponents = results[0].address_components
        })
    }

</script>

<style>
    #map {
      height: 350px;
    }
</style>

<div class="columns">
    <div class="column">
        <div class="field">
            <div class="control has-icons-left">
                <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                <input bind:this={ address } type="text" class="input" placeholder="Dirección">
            </div>
        </div>
    </div>
</div>

<div id="map" bind:this={ container }></div>