<script>

    import { createEventDispatcher } from 'svelte'
    import MapLocations from '../$components/map.locations.svelte'
    import Button from '../$components/button.svelte'

    const dispatch = createEventDispatcher()

    export let pickup
    export let delivery

    pickup.address = mapAddress(pickup)
    delivery.address = mapAddress(delivery)

    function mapAddress(location) {
        
        if(location.street)
            return `${location.street} ${location.number} ${location.colony} ${location.zipcode}`

        return location.zipcode
    }

    function selected() {

        delete pickup.autocomplete
        delete pickup.marker

        delete delivery.autocomplete
        delete delivery.marker

        dispatch('selected')
    }

</script>

<div class="columns">
    <div class="column">
        <MapLocations bind:pickup={pickup} bind:delivery={delivery} />
        <br>
        <div class="items-end">
            <Button on:click={ selected} text="Guardar y continuar" icon="save" color="primary"/>
        </div>
    </div>
</div>