import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getStocks,
    getStocksGroup
}

function getStocks(query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/stocks`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getStocksGroup() {
    return new Promise((resolve, reject) => {

        Superagent
           .get(`${HOST_API}/stocks/group`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
               resolve(res.body)
           })
    })
}