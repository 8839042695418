<script>

    import { onMount } from 'svelte'
    import { toast } from 'bulma-toast'
    import { stocksStore, merchandiseStore } from '../stores'

    import StocksService from '../$services/stocks.service'
    import DateMethods from '../$methods/date.methods'

    import Pagination from '../$components/pagination.svelte'
    import Table from '../$components/table.svelte'

    let loading = false
    let query = {
        merchandiseId: $merchandiseStore._id
    }
    let metadata = {}

    onMount(getStocks)
    
    async function getStocks() {
    
        loading = true
        const resp = await StocksService.getStocks(query)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        stocksStore.set(resp.data.stocks)
        metadata = resp.data.metadata
    }

</script>

<div class="items-end">
    <Pagination bind:page={ query.page } { metadata } />
</div>

<Table items={ $stocksStore.length } { loading } >
    <thead>
        <tr>
            <th>#</th>
            <th>Tipo</th>
            <th>Total</th>
            <th>Fecha</th>
        </tr>
    </thead>
    <tbody>
        {#each $stocksStore as stock, index}
            <tr>
                <td>{ index+1 }</td>
                <td>{ stock.type }</td>
                <td>{ stock.input || stock.output }</td>
                <td>{ DateMethods.dateTimeLarge(stock.created) }</td>
            </tr>
        {/each}
    </tbody>
</Table>