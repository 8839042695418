<script>

    import { replace } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'
    import { fade } from 'svelte/transition'
    import { storeStore, ordersStore, ordersAutomaticStore, modalStore, notifyStore } from '../stores'

    import OrdersService from '../$services/orders.service'
    import DateMethod from '../$methods/date.methods.js'

    import Pagination from '../$components/pagination.svelte'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    $: $storeStore && getOrdersStore(true)
    $: $notifyStore === 'orders_updated' && getOrdersStore() && notifyStore.set(null)

    let query = {
        page: 0,
        find: null,
        created: '-1',
        paymentStatus: 'paid',
        shipmentStatus: 'unfulfilled'
    }

    let metadata = {}
    let loading = true
    let checkedGlobal = false

    async function getOrdersStore(clean = false) {

        ordersStore.set([])

        if(clean === true)
            query.page = 0

        loading = true
        const resp = await OrdersService.getOrdersStore($storeStore._id, query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        const orders = resp.data.orders
        const order = orders.length? orders[0] : null

        if(order && order.storeId != $storeStore._id)
            return

        checkedGlobal = false
        metadata = resp.data.metadata

        ordersStore.set(resp.data.orders)
    }

    function verifyCheckeds() {
        setTimeout(() => {
            let isOrdersChecked = false
            $ordersStore.forEach(order => {
                if(order.checked)
                    isOrdersChecked = true
            })
            checkedGlobal = isOrdersChecked
        }, 100)
    }

    function onCheckedGlobal() {
        checkedGlobal = !checkedGlobal
        ordersStore.set($ordersStore.map(order => {
            order.checked = checkedGlobal
            return order
        }))
    }

    function onOpenAutomatic() {
        $ordersAutomaticStore = $ordersStore.filter(order => order.checked)
        replace('#/automatic')
    }

    function findOrders(event) {
        if(event.keyCode === 13)
            getOrdersStore()
    }

</script>

<style>
    .isShipment {
        color: #4E3596;
    }
</style>

<Spinner {loading} />
{#if !loading}
    <div class="columns">
        {#if checkedGlobal}
            <div class="column is-narrow" in:fade>
                <button on:click={onOpenAutomatic} class="button is-primary" data-tooltip="Guías automáticas">
                    <span class="icon"><i class="fas fa-tachometer-alt"></i></span>
                </button>
            </div>
        {/if}
        <div class="column">
            <div class="field">
                <div class="control has-icons-left">
                    <span class="icon is-left"><i class="fas fa-search color-primary"></i></span>
                    <input on:keyup={findOrders} bind:value={query.find} type="text" class="input" placeholder="Buscar">
                </div>
            </div>
        </div>
        <div class="column is-narrow">
            <button on:click={() => modalStore.set('order_link_create_modal')} class="button" data-tooltip="Generar Link">
                <span class="icon is-left"><i class="fas fa-link"></i></span>
            </button>
        </div>
        <div class="column is-narrow">
            <button on:click={() => modalStore.set('order_upload_modal')} class="button" data-tooltip="Cargar órdenes">
                <span class="icon is-left"><i class="fas fa-file-upload"></i></span>
            </button>
        </div>
    </div>
    <div class="items-space space-bottom">
        <small>
            { $ordersStore.length }
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <div class="items-end">
            {#if $storeStore.type === 'shopify' }
                <div class="column is-narrow">
                    <div class="control">
                        <div class="select is-small">
                            <select bind:value={query.paymentStatus} on:change={() => getOrdersStore(true)} >
                                <option value="paid">Pagadas</option>
                                <option value="any">Cualquier status de pago</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="column is-narrow">
                    <div class="control">
                        <div class="select is-small">
                            <select bind:value={query.shipmentStatus} on:change={() => getOrdersStore(true)} >
                                <option value="unfulfilled">No preparadas</option>
                                <option value="any">Cualquier status de envío</option>
                            </select>
                        </div>
                    </div>
                </div>
            {/if}
            <div class="column is-narrow">
                <div class="control">
                    <div class="select is-small">
                        <select bind:value={query.created} on:change={() => getOrdersStore(true)} >
                            <option value="-1">Las ordenes nuevas primero</option>
                            <option value="1">Las ordenes antiguas primero</option>
                        </select>
                    </div>
                </div>
            </div>
            <Pagination bind:metadata bind:page={ query.page } on:change={ getOrdersStore } />
        </div>
    </div>

    <Message show={!$ordersStore.length} />

    <Message color="warning" message="Se muestran todas las ordenes de shopify incluso las que no se han pagado" show={$ordersStore.length && $storeStore.type === 'shopify' && query.paymentStatus === 'any'} />
    <Message color="warning" message="Se muestran todas las ordenes de shopify incluso las que ya han sido envíadas" show={$ordersStore.length && $storeStore.type === 'shopify' && query.shipmentStatus === 'any'} />

    {#if $ordersStore.length}
        <div class="table-container">
            <table class="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>
                            <div class="field">
                                <input on:click={onCheckedGlobal} bind:checked={checkedGlobal} class="is-checkradio is-primary" id="mainCheck" type="checkbox">
                                <label for="mainCheck"></label>
                            </div>
                        </th>
                        <th>
                            <div class="icon-text">
                                <span class="icon">
                                <i class="fas fa-hashtag"></i>
                                </span>
                            </div>
                        </th>
                        <th>
                            <div class="icon-text">
                                <span class="icon">
                                <i class="fas fa-user"></i>
                                </span>
                                <span>Cliente</span>
                            </div>
                        </th>
                        <th>
                            <div class="icon-text">
                                <span class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                                </span>
                                <span>Destino</span>
                            </div>
                        </th>
                        <th>
                            <div class="icon-text">
                                <span class="icon">
                                <i class="fas fa-calendar"></i>
                                </span>
                                <span>Fecha</span>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {#each $ordersStore as order}
                    <tr class:isShipment={order.shipment}>
                        <td>
                            <div class="field">
                                <input on:click={verifyCheckeds} bind:checked={order.checked} class="is-checkradio is-primary" id="{order._id || order.remoteId}" type="checkbox">
                                <label for="{order._id || order.remoteId}"></label>
                            </div>
                        </td>
                        <td>{ order.number }</td>
                        <td>
                            <div>{ order.delivery.contact }</div>
                        </td>
                        <td>
                            <div>{ order.delivery.zipcode }, { order.delivery.state } { order.delivery.city }</div>
                        </td>
                        <td>{ DateMethod.dateTimeShort(order.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('order', 'modal', order)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                    {/each}
                </tbody>
            </table>
        </div>
    {/if}
{/if}
<br>
<br>