<script>
	  
	export let loading = false
	  
</script>

<style>
    img {
        max-width: 180px;
        /* border-radius: 1000px;
        overflow: hidden; */
    }
</style>

{#if loading}
    <div class="items-center">
        <img src="/images/marzo_loading.gif" alt="Cargando">
        <!-- <div class="animate__animated animate__bounce animate__infinite">
        </div> -->
    </div>
{/if}