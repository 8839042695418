<script>

    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'

    import { N99MinutossStore, modalStore } from '../stores'
    import N99MinutosService from '../$services/99minutos.service'

    import Spinner from '../$components/spinner.svelte'

    const dispatch = createEventDispatcher()

    let data = {
        provider: '99Minutos'
    }

    let loading = false

    async function createCredential() {

        loading = true
        const resp = await N99MinutosService.createCredential(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        N99MinutossStore.append(resp.data)
        toast({message: 'Credencial creada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createCredential}>
        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/99Minutos.svg" alt="99Minutos" style="width: 60%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.alias}  type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Api key</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.apikey}  type="text"  class="input" placeholder="Api key" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => dispatch('close')} type="button" class="button">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary">
                        <span class="icon"><i class="fas fa-save"></i></span>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}