<script>

    import { toast } from 'bulma-toast'
    import { paquetexpresssStore, paquetexpressStore, modalStore } from '../stores'

    import Spinner from '../$components/spinner.svelte'
    import PaquetexpressService from '../$services/paquetexpress.service'

    const data = new Object({
        _id: $paquetexpressStore._id,
        alias: $paquetexpressStore.alias,
        client: $paquetexpressStore.client,
        account: $paquetexpressStore.account,
        token: $paquetexpressStore.token,
        tokenPassword: $paquetexpressStore.tokenPassword,
        password: $paquetexpressStore.password
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await PaquetexpressService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        paquetexpresssStore.replace(resp.data)
        paquetexpressStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/Paquetexpress.svg" alt="Paquetexpress" style="width: 60%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.alias} type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="columns">  
            <div class="column">
                <div class="field">
                    <label for="" class="label">Usuario</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.client}  type="text"  class="input" placeholder="Usuario" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Número de cuenta</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-id-card"></i></span>
                        <input bind:value={data.account}  type="text"  class="input" placeholder="Número de cuenta" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Token</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.token} type="text"  class="input" placeholder="Token" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Token password</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.tokenPassword} type="password"  class="input" placeholder="Token password" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Contraseña</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.password} type="password"  class="input" placeholder="Contraseña" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.set('paquetexpress_account_modal')} type="button" class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary is-fullwidth">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}