<script>

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'
    import PostComponent from './component.post.svelte'

    import PostUpdateModal from './modal.update.svelte'
    import CommentUpdateModal from '../comments/modal.update.svelte'

    export let params = {}

</script>

<Menu />

<Container>
    <div class="columns">
        <div class="column is-8 is-offset-2">
            <PostComponent postId={params.postId} />
        </div>
    </div>
</Container>

<PostUpdateModal />
<CommentUpdateModal />