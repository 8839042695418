<script>

    import TipsService from '../$services/tips.service'

    let loading = false
    let tip = null

    getTipRandom()

    async function getTipRandom() {
    
        loading = true
        const resp = await TipsService.getTipRandom()
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        tip = resp.data
    }

</script>

{#if tip}
    <div class="title">{ tip.type }</div>
    <div class="subtitle">{ tip.message }</div>
{/if}