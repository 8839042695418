import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createFolio
}

function createFolio(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/folios/user`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}