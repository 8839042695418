<script>

    import { toast } from 'bulma-toast'
    import { facturamaInvoicesStore, facturamaInvoiceStore, modalStore, notifyStore } from '../stores'
    
    import FacturamaService from '../$services/facturama.service'
    import DateMethod from '../$methods/date.methods'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    $: $notifyStore === 'facturama_invoices_updated' && getInvoices() && notifyStore.set(null)

    let loading = false
    let element = null

    getInvoices()

    async function getInvoices() {

        loading = true
        const resp = await FacturamaService.getInvoices()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        facturamaInvoicesStore.set(resp.data)
    }

    async function getInvoiceDocument(invoice, type) {

        loading = true
        const blob = await FacturamaService.getInvoiceDocument(invoice.invoiceId, type)
        loading = false

        if(!blob)
            return toast({message: 'Error al descargar el reporte', type: 'is-danger'})

        element.download = `$${invoice.amount}.${invoice.rfc}.${DateMethod.dateShort(invoice.created)}.${type}`
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

</script>

<a href="/" bind:this={element} style="display: none;">*</a>

<div class="title">Facturas</div>

<Spinner {loading} />
<Message show={!loading && !$facturamaInvoicesStore.length} />

{#if !loading && $facturamaInvoicesStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-address-card"></i>
                            </span>
                            <span>RFC</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span>Monto</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-file-alt"></i>
                            </span>
                            <span>Documentos</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {#each $facturamaInvoicesStore as invoice}
                    <tr>
                        <td>{ invoice.rfc }</td>
                        <td>${ invoice.amount }</td>
                        <td>{ DateMethod.dateTimeLarge(invoice.created) }</td>
                        <td>
                            <button on:click={() => getInvoiceDocument(invoice, 'xml')} class="button">
                                <span class="icon"><i class="fas fa-file-code"></i></span>
                                <span>XML</span>
                            </button>
                            <button on:click={() => getInvoiceDocument(invoice, 'pdf')} class="button">
                                <span class="icon"><i class="fas fa-file-pdf"></i></span>
                                <span>PDF</span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}