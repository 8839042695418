<script>

    import { toast } from 'bulma-toast'
    import { fedexsStore, modalStore } from '../stores'

    import FedexService from '../$services/fedex.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await FedexService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        fedexsStore.set(resp.data)
    }

    async function updateCredentialActive(fedex) {
    
        loading = true
        const resp = await FedexService.updateCredentialActive(fedex._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<style>
    img {
        width: 50%;
    }
</style>

<Spinner {loading} />

{#if !loading && $fedexsStore.length}
    <div class="subtitle is-5"><b>Cuentas con FedEx</b></div>
    <div class="credential-items">
        {#each $fedexsStore as fedex}
        <div class="credential-item">
            <img class="image" src="images/FedEx.svg" alt="FedEx">
            <div class="title is-5">{ fedex.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(fedex.created) }</div>
            <div class="items-space-around">
                <div class="field" style="margin: 0;">
                    <input on:click={() => updateCredentialActive(fedex)} bind:checked={fedex.active} class="is-checkradio is-primary" id="{fedex._id}" type="checkbox">
                    <label for="{fedex._id}"></label>
                </div>
                <button on:click={() => modalStore.open('fedex', 'account_modal', fedex)} class="button">
                    <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                </button>
            </div>
        </div>
        {/each}
    </div>
{/if}