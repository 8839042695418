<script>

    import { configStore } from '../stores'

    import InputText from '../$components/input.text.svelte'

</script>

<div class="box">
    <div class="items-center">
        <img src="{ $configStore.link.brand || '/images/brand_red.png' }" alt="Brand" style="max-height: 250px;">
    </div>
    <br>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Contacto" icon="user" placeholder="Nombre del contacto" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Empresa" icon="building" placeholder="Nombre de la empresa"/>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Teléfono" icon="phone" placeholder="Número de teléfono" />
        </div>
        <div class="column">
            <InputText disabled label="Correo electrónico" icon="envelope" placeholder="Correo electrónico" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Código postal" icon="map-marker-alt" placeholder="Código postal" />
        </div>
        <div class="column">
            <InputText disabled label="Estado" icon="map-marker-alt" placeholder="Estado" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Ciudad" icon="city" placeholder="Ciudad" />
        </div>
        <div class="column">
            <InputText disabled label="Municipio" icon="map-marker-alt" placeholder="Municipio"/>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Colonia" icon="map-marker-alt"  placeholder="Colonia" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Calle" icon="map-marker-alt" placeholder="Calle" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Número exterior" icon="hashtag" placeholder="Número exterior" />
        </div>
        <div class="column">
            <InputText disabled label="Número interior" icon="building" placeholder="Número interior" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText disabled label="Referencias" icon="asterisk" placeholder="Referencias de la casa/apartamento" />
        </div>
    </div>
    <br>
    <a href="{ $configStore.link.bannerURL }" target="_blank">
        <div class="items-center" style="border-radius: 8px; overflow: hidden;">
            <img src="{ $configStore.link.banner || '/images/brand_white.png' }" alt="Brand">
        </div>
    </a>
</div>