<script>

    import DateMethod from '../$methods/date.methods.js'
    import Value from '../$components/value.svelte'

    export let N99Minutos = null

</script>

<div class="columns">
    <div class="column">
        <Value label="Alias" value={ N99Minutos.alias } />
    </div>
    <div class="column">
        <Value label="Proveedor" value={ N99Minutos.provider } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Cuenta activa" value={ N99Minutos.active ? 'Si' : 'No' } />
    </div>
    <div class="column">
        <Value label="Fecha" value={ DateMethod.dateTimeLarge(N99Minutos.created) } />
    </div>
</div>