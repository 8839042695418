<script>

    import { toast } from 'bulma-toast'
    import { configStore, modalStore, userStore } from '../stores'

    import ConfigsService from '../$services/configs.service'
    import BucketService from '../$services/bucket.service'

    import InputText from '../$components/input.text.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Button from '../$components/button.svelte'
    import Modal from '../$components/modal.id.svelte'
    import File from '../$components/file.svelte'

    import LinkExample from './component.config.link.example.svelte'

    let data = {
        message: $configStore.link.message,
        facebook: $configStore.link.facebook,
        instagram: $configStore.link.instagram,
        pinterest: $configStore.link.pinterest,
        webpage: $configStore.link.webpage,
        bannerURL: $configStore.link.bannerURL,
    }

    let banner = null
    let brand = null
    let loading = false

    async function updateConfig() {

        if(banner) {
            const success = await uploadFile(banner, 'banner', 'link_banner')
            if(!success) return
        }

        if(brand) {
            const success = await uploadFile(brand, 'brand', 'link_brand')
            if(!success) return
        }

        loading = true
        const resp = await ConfigsService.updateConfig({link: data})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)
        toast({message: 'Configuración actualizada', type: 'is-success'})
    }

    async function uploadFile(file, key, fileName) {

        const query = {
            fileType: file.type,
            fileDir: $userStore._id,
            fileName
        }

        loading = true
        const resp = await BucketService.getSigned(query)

        if(!resp.success) {
            toast({message: resp.error.message, type: 'is-danger'})
            return false
        }

        const {signed, url} = resp.data

        await BucketService.uploadFile(file, signed)

        toast({message: `Archivo ${file.name} cargado`, type: 'is-info'})

        data[key] = url
        return true
    }

</script>

<Spinner {loading} />

{#if !loading}
    <div class="columns">
        <div class="column">
            <div class="title">Link personalizado</div>
            <form on:submit|preventDefault={updateConfig}>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ data.facebook } label="URL Facebook" iconBase="fab" icon="facebook" placeholder="URL de la página" required={false}/>
                        <InputText bind:value={ data.instagram } label="URL Instagram" iconBase="fab" icon="instagram" placeholder="URL de la página" required={false} />
                        <InputText bind:value={ data.pinterest } label="URL Pinterest" iconBase="fab" icon="pinterest" placeholder="URL de la página" required={false}/>
                        <InputText bind:value={ data.webpage } label="URL Webpage" icon="globe" placeholder="URL de la página" required={false} />
                        <InputText bind:value={ data.message } label="Mensaje" icon="comment" placeholder="Mensaje al cliente" required={false} />
                    </div>
                    <div class="column">
                        <div class="field">
                            <label for="" class="label">Header</label>
                            <div class="control">
                                <File bind:file={brand} required={false} />
                            </div>
                            {#if $configStore.link.brand}
                                <div class="items-end">
                                    <Button on:click={() => modalStore.set('show_brand_modal')} text="Ver imagen" icon="eye" size="small" />
                                </div>
                            {/if}
                        </div>
                        <div class="field">
                            <label for="" class="label">Banner</label>
                            <div class="control">
                                <File bind:file={banner} required={false} />
                            </div>
                            {#if $configStore.link.brand}
                                <div class="items-end">
                                    <Button on:click={() => modalStore.set('show_banner_modal')} text="Ver imagen" icon="eye" size="small" />
                                </div>
                            {/if}
                        </div>
                        <InputText bind:value={ data.bannerURL } label="URL Banner" icon="globe" placeholder="URL del banner" required={false} />
                    </div>
                </div>
                <div class="items-end">
                    <Button on:click={() => modalStore.set('show_link_example')} type="button" text="Ver link" icon="eye" />
                    <Button type="submit" text="Guardar" icon="save" color="primary" />
                </div>
            </form>
        </div>
    </div>
{/if}

<Modal id="show_link_example">
    <LinkExample />
</Modal>

<Modal id="show_brand_modal">
    <img src="{ $configStore.link.brand }" alt="Brand">
</Modal>

<Modal id="show_banner_modal">
    <img src="{ $configStore.link.banner }" alt="Banner">
</Modal>