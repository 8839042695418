<script>
    
    import { toast } from 'bulma-toast'
    import { userStore, notifyStore } from '../stores'

    import InputText from '../$components/input.text.svelte'
    import InputEmail from '../$components/input.email.svelte'
    import InputNumber from '../$components/input.number.svelte'

    import StorageService from '../$services/storage.service'
    import FacturamaService from '../$services/facturama.service'

    import ZipcodeColonyComponent from '../locations/component.zipcode.colony.svelte'

    import Select from '../$components/select.svelte'
    import Spinner from '../$components/spinner.svelte'

    const data = {location: {}}
    let loading = false

    async function createClient() {
        
        loading = true
        const resp = await FacturamaService.createClient(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        StorageService.setItem('user', resp.data)
        userStore.set(resp.data)
        notifyStore.set('facturama_updated')
    }

</script>

<div class="title is-5">Datos de facturación</div>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createClient} >
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.name } icon="address-card" label="Nombre" placeholder="Nombre" />
            </div>
            <div class="column">
                <InputText bind:value={ data.rfc } icon="fingerprint" label="RFC" placeholder="RFC" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Select bind:value={ data.fiscalRegime } 
                    label="Régimen fiscal"
                    icon="list-alt"
                    options={[
                        {value: '601', text: 'General de Ley Personas Morales'},
                        {value: '603', text: 'Personas Morales con Fines no Lucrativos'},
                        {value: '605', text: 'Sueldos y Salarios e Ingresos Asimilados a Salarios'},
                        {value: '606', text: 'Arrendamiento'},
                        {value: '607', text: 'Régimen de Enajenación o Adquisición de Bienes'},
                        {value: '608', text: 'Demás ingresos'},
                        {value: '609', text: 'Consolidación'},
                        {value: '610', text: 'Residentes en el Extranjero sin Establecimiento Permanente en México'},
                        {value: '611', text: 'Ingresos por Dividendos (socios y accionistas)'},
                        {value: '612', text: 'Personas Físicas con Actividades Empresariales y Profesionales'},
                        {value: '614', text: 'Ingresos por intereses'},
                        {value: '615', text: 'Régimen de los ingresos por obtención de premios'},
                        {value: '616', text: 'Sin obligaciones fiscales'},
                        {value: '620', text: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'},
                        {value: '621', text: 'Incorporación Fiscal'},
                        {value: '622', text: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
                        {value: '623', text: 'Opcional para Grupos de Sociedades'},
                        {value: '624', text: 'Coordinados'},
                        {value: '625', text: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
                        {value: '626', text: 'Régimen Simplificado de Confianza'},
                        {value: '628', text: 'Hidrocarburos'},
                        {value: '629', text: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'},
                        {value: '630', text: 'Enajenación de acciones en bolsa de valores'},
                    ]}
                />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputEmail bind:value={ data.email } icon="envelope" label="Correo electrónico" placeholder="Correo electrónico" />
            </div>
        </div>
        <ZipcodeColonyComponent 
            bind:zipcode={data.location.zipcode}
            bind:state={data.location.state}
            bind:city={data.location.city}
            bind:municipality={data.location.municipality}
            bind:colony={data.location.colony}
        />
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.location.street } icon="map-marker-alt" label="Calle" placeholder="Calle" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputNumber bind:value={ data.location.number } icon="hashtag" label="Número exterior" placeholder="Número exterior de casa/apartamento" />
            </div>
            <div class="column">
                <InputNumber bind:value={ data.location.apartment } icon="building" label="Número interior" placeholder="Número interior del apartamento" required={ false } />
            </div>
        </div>
        <div class="items-end">
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}