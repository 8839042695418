import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getLocations,
    getLocationDefault,
    createLocation,
    updateLocation,
    updateLocationDefault,
    deleteLocation
}

function getLocations(query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/locations`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getLocationDefault() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/locations/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createLocation(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/locations`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateLocation(locationId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/locations/${locationId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateLocationDefault(locationId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/locations/${locationId}/default`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteLocation(locationId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/locations/${locationId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}