<script>

    import { toast } from 'bulma-toast'
    import { incidentsStore, modalStore } from '../stores'
     
    import IncidentsService from '../$services/incidents.service'
    import DateMethods from '../$methods/date.methods'

    import Table from '../$components/table.svelte'
    import Button from '../$components/button.svelte'

    let loading = false

    getIncidents()
    
    async function getIncidents() {
    
        loading = true
        const resp = await IncidentsService.getIncidents()
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        incidentsStore.set(resp.data.incidents)
    }

</script>

<Table items={$incidentsStore.length} { loading } >
    <thead>
        <tr>
            <th>#</th>
            <th>Asunto</th>
            <th>Fecha</th>
            <td></td>
        </tr>
    </thead>
    <tbody>
        {#each $incidentsStore as incident, index}
            <tr>
                <td>{ index+1 }</td>
                <td>{ incident.subject }</td>
                <td>{ DateMethods.dateTimeLarge(incident.created) }</td>
                <td>
                    <Button on:click={() => modalStore.open('incident', 'modal', incident)} icon="ellipsis-v" />
                </td>
            </tr>
        {/each}
    </tbody>
</Table>