import Superagent from 'superagent'
import Storage from './storage.service'

const { HOST_API } = _CONSTANTS_

export default {
    getCredentials,
    createCredential,
    updateCredential,
    updateCredentialActive
}

function getCredentials() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/ups`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function createCredential(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/ups`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function updateCredential(upsId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/ups/${upsId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function updateCredentialActive(upsId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/ups/${upsId}/active`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                resolve(res.body)
            })
    })
}