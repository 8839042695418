<script>

    import { toast } from 'bulma-toast'
    import { commentsStore, commentStore, modalStore } from '../stores'

    import CommentsService from '../$services/comments.service'

    import Quill from '../$components/quill.svelte'
    import Button from '../$components/button.svelte'
    import Delete from '../$components/delete.svelte'

    let loading = false
    let data = {
        message: $commentStore.message
    }

    async function updateComment() {

        loading = true
        const resp = await CommentsService.updateComment($commentStore._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        const tree = new Tree($commentsStore).replace($commentStore._id, resp.data)
        data = {}

        commentsStore.set(tree.comments)
        modalStore.close()

        toast({message: 'Comentario actualizado', type: 'is-success'})
    }

    async function deleteComment() {

        loading = true
        const resp = await CommentsService.deleteComment($commentStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        const tree = new Tree($commentsStore).remove($commentStore.commentId, $commentStore._id)
        data = {}

        commentsStore.set(tree.comments)
        modalStore.close()

        toast({message: 'Comentario borrado', type: 'is-success'})
    }

    function Tree(comments = []) {

        const obj = this

        obj.comments = comments
        obj.parent = null

        obj.getParent = (comments, commentId) => {
            return comments.forEach(comment => {

                if(obj.parent)
                    return

                if(comment._id === commentId)
                    obj.parent = comment

                if(comment.comments && comment.comments.length)
                    obj.getParent(comment.comments, commentId)

            })
        }

        obj.replace = (commentId, comment) => {

            obj.parent = null
            obj.getParent(obj.comments, commentId)

            if(obj.parent)
                obj.parent.message = comment.message

            return obj
        }

        obj.remove = (parentId, commentId) => {

            if(!parentId) {
                obj.comments = obj.comments.filter(item => item._id != commentId)
                return obj
            }

            obj.parent = null
            obj.getParent(obj.comments, parentId)

            if(obj.parent)
                obj.parent.comments = obj.parent.comments.filter(item => item._id != commentId)

            return obj
        }

        return obj
    }
</script>

<Quill bind:value={data.message} placeholder="Comentario" />

<div class="items-space" style="margin-top: 4px;">
    <Delete on:confirm={deleteComment} size="small" fullwidth={false} />
    <Button on:click={updateComment} text="Guardar" icon="save" color="primary" size="small" />
</div>