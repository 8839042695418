<script>

    import { estafetaStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import EstafetaAccountComponent from './component.estafeta.account.svelte'

    $: show = $modalStore === 'estafeta_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <EstafetaAccountComponent estafeta={$estafetaStore} />
</Modal>