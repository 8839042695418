<script>

    import Value from '../$components/value.svelte'
    import NumeralMethods from '../$methods/numeral.methods'

    export let ipackage = null

</script>

{#if ipackage}
    <div class="columns">
        <div class="column">
            <Value label="Ancho" value={ `${ ipackage.width } cm` } />
        </div>
        <div class="column">
            <Value label="Alto" value={ `${ ipackage.height } cm` } />
        </div>
        <div class="column">
            <Value label="Largo" value={ `${ ipackage.length } cm` } />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Value label="Peso fisíco" value={ `${ ipackage.weight } kg` } />
        </div>
        <div class="column">
            <Value label="Peso volumétrico" value={ `${ ipackage.volumetric || ipackage.weight  } kg` } />
        </div>
        <div class="column">
            <Value label="Valor declarado" value={ NumeralMethods.cash(ipackage.value) } />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Value label="Contenido" value={ ipackage.content || 'Sin información' } />
        </div>
    </div>
{/if}