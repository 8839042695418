<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import TicketCreateComponent from './component.create.svelte'

    $: show = $modalStore === 'ticket_create_modal'

</script>

<Modal title="Agregar ticket" {show} >
    <TicketCreateComponent />
</Modal>