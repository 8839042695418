<script>

    import { prepaidStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import PrepaidComponent from './component.prepaid.svelte'

    $: show = $modalStore === 'prepaid_modal'

</script>

<Modal title="Guías prepagadas" {show}>
    <PrepaidComponent prepaid={$prepaidStore} />
</Modal>