<script>

    import DateMethod from '../$methods/date.methods.js'
    import Value from '../$components/value.svelte'

    export let ups = null

</script>

<div class="columns">
    <div class="column">
        <Value label="Alias" value={ ups.alias } />
    </div>
    <div class="column">
        <Value label="Proveedor" value={ ups.provider } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Cuenta activa" value={ ups.active ? 'Si' : 'No' } />
    </div>
    <div class="column">
        <Value label="Fecha" value={ DateMethod.dateTimeLarge(ups.created) } />
    </div>
</div>