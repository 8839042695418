<script>

    import { modalStore } from '../stores'

    import UserUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'user_update_modal'

</script>

<Modal title="Actualizar información" {show}>

    <UserUpdateComponent />
    
</Modal>