<script>

    import { configStore, modalStore } from '../stores'

    import ConfigShopifyComponent from './component.config.shopify.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'shopify_config_modal'

</script>

<Modal title="Configuración shopify" {show}>
    {#if $configStore}
        <ConfigShopifyComponent shopifyConfig={$configStore.shopify} />
    {/if}
</Modal>