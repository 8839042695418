<script>

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import StripeCheckoutComponent from '../stripe/component.checkout.svelte'

</script>

<Menu />

<Container>
    <div class="columns">
        <div class="column is-6">
            <div class="title is-4"><b>¡Vamos a agregar saldo!</b></div>
            <div class="subtitle is-5">Agregue saldo a su cartera</div>
        </div>
    </div>
    <div class="columns">
        <div class="column is-3">
            <StripeCheckoutComponent />
        </div>
    </div>
</Container>