import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getNotifications,
    getImportantNotifications,
    createNotification,
    updateViewNotification,
    deleteNotification
}

function getNotifications() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/notifications`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getImportantNotifications() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/notifications/importants`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createNotification(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/notifications`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateViewNotification(notificationId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/notification/${notificationId}/view`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteNotification(notificationId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/notifications/${notificationId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}