<script>

    import { modalStore } from '../stores'

    import LocationUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'location_update_modal'

</script>

<Modal title="Actualizar dirección" {show}>

    <LocationUpdateComponent />
    
</Modal>