<script>

    import { toast } from 'bulma-toast'
    import { commentsStore } from '../stores'

    import CommentsService from '../$services/comments.service'

    import Quill from '../$components/quill.svelte'
    import Button from '../$components/button.svelte'

    export let postId = null
    export let commentId = null

    let loading = false
    let data = {}

    async function createComment() {

        data.postId = postId
        data.commentId = commentId

        loading = true
        const resp = await CommentsService.createComment(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        const tree = new Tree($commentsStore).add(resp.data, commentId)

        commentsStore.set(tree.comments)
        data = {}

        toast({message: 'Comentario publicado', type: 'is-success'})
    }

    function Tree(comments = []) {

        const obj = this

        obj.comments = comments
        obj.parent = null

        obj.getParent = (comments, commentId) => {
            return comments.forEach(comment => {

                if(obj.parent)
                    return

                if(comment._id === commentId)
                    obj.parent = comment

                if(comment.comments && comment.comments.length)
                    obj.getParent(comment.comments, commentId)

            })
        }

        obj.add = (comment, commentId) => {

            obj.parent = null
            comment.comments = []

            obj.getParent(obj.comments, commentId)

            if(obj.parent)
                obj.parent.comments.push(comment)

            else
                obj.comments.push(comment)

            return obj
        }

        return obj
    }

</script>

<Quill bind:value={data.message} placeholder="Comentar"  />

<div class="items-end" style="margin-top: 4px;">
    <Button on:click={createComment} text="Guardar" icon="save" color="primary" size="small" />
</div>