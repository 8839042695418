<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'volumetric_popup'
    
</script>


<Popup url="https://www.notion.so/myshipper/Peso-volum-trico-7300b50d827f42bab2eb620dd41d09c8" {show}>
    <div class="title">¿Peso volumétrico?</div>
    <p>El peso volumétrico es una medida para calcular un peso «equivalente al volumen» de la mercancía que se transportará.</p>
</Popup>