<script>

    import { replace } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'
    import { postsStore, postStore, modalStore } from '../stores'

    import PostsService from '../$services/posts.service'

    import Quill from '../$components/quill.svelte'
    import Select from '../$components/select.svelte'
    import Button from '../$components/button.svelte'
    import InputText from '../$components/input.text.svelte'
    import Form from '../$components/form.svelte'
    import Delete from '../$components/delete.svelte'

    let loading = false
    let data = {
        tag: $postStore.tag,
        title: $postStore.title,
        content: $postStore.content,
    }

    async function updatePost() {

        loading = true
        const resp = await PostsService.updatePost($postStore._id, data)
        loading = false

        if(!resp.success)
            return toast({ message: resp.error.message, type: 'is-danger' })

        loading = true
        const post = await PostsService.getPost(resp.data._id)
        loading = false

        if(!post.success)
            return toast({message: post.error.message, type: 'is-danger'})

        postStore.set(resp.data)
        postsStore.replace(post.data)

        modalStore.close()
        toast({ message: 'Post actualizado', type: 'is-success' })
    }

    async function deletePost() {

        loading = true
        const resp = await PostsService.deletePost($postStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        modalStore.close()

        postsStore.remove($postStore._id)
        postStore.set(null)

        replace('#/posts')
    }

</script>

<Form on:submit={updatePost} {loading} >

    <Select bind:value={data.tag} text="Categoria" icon="star" options={[
        { value: "General", text: "General" },
        { value: "Marketing", text: "Marketing" },
        { value: "Tecnología", text: "Tecnología" },
        { value: "Resolución de problemas", text: "Resolución de problemas" },
        { value: "Ventas", text: "Ventas" },
        { value: "eCommerce", text: "eCommerce" },
    ]} />

    <InputText bind:value={data.title} label="Titulo" icon="heading" placeholder="Ejem: como integro Shopify" />

    <Quill bind:value={data.content} label="Contenido" placeholder="Contenido del post" />

    <div class="items-space space-top">
        <Delete on:confirm={ deletePost } fullwidth={false} />
        <Button type="submit" text="Postear" icon="save" color="primary" />
    </div>

</Form>