<script>

    import { modalStore, userStore } from '../stores'

    import Button from '../$components/button.svelte'

</script>

<div class="columns">
    <div class="column is-narrow">
        <figure class="image is-128x128">
            {#if $userStore.avatar}
                <img src="{ $userStore.avatar }?random={ Math.random() }" alt="Avatar">
            {:else}
                <img src="https://bulma.io/images/placeholders/128x128.png" alt="default">
            {/if}
        </figure>
    </div>
    <div class="column">
        <div class="space-left" style="width: 100%;">
            <div class="title is-5">{ $userStore.alias || $userStore.name }</div>
            <div class="content">{@html $userStore.description || 'Sin descripción' }</div>
        </div>
        <div class="items-end space-top">
            <Button on:click={() => modalStore.open('profile_update_modal')} text="Editar" icon="edit" />
        </div>
    </div>
</div>