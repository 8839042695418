<script>

    import { createEventDispatcher } from 'svelte'
    import { toast } from "bulma-toast"

    import { postStore, userStore } from '../stores'

    import PostsService from "../$services/posts.service"

    const dispatch = createEventDispatcher()

    export let post = null

    let loading = false
    let isLike = false
    let isDislike = false

    showLikes()

    async function setLike(set) {

        loading = true
        const resp = await PostsService.setLike(post._id, {set})
        loading = false

        if(!resp.success)
            return toast({ message: resp.error.message, type: "is-danger" })

        postStore.set(resp.data)
        post = resp.data

        dispatch('updated', resp.data)
        showLikes()
    }

    function showLikes() {
        isLike = post.likes.findIndex(item => item === $userStore._id) >= 0
        isDislike = post.dislikes.findIndex(item => item === $userStore._id) >= 0
    }

</script>

<div class="field has-addons">
    <p class="control">
        <button on:click={() => setLike(isDislike? '' : 'dislikes')} class="button is-small"
            class:is-loading={loading} class:is-primary={isDislike}>
                <span class="icon"><i class="fas fa-heart-broken"/></span>
                <span>{ post.dislikes.length }</span>
        </button>
    </p>
    <p class="control">
        <button on:click={() => setLike(isLike? '' : 'likes')} class="button is-small"
            class:is-loading={loading} class:is-primary={isLike}>
                <span class="icon"><i class="fas fa-heart"/></span>
                <span>{ post.likes.length }</span>
        </button>
    </p>
</div>