<script>

    import { modalStore } from '../stores'
    import CreateComponent from './component.create.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'post_create_modal'

</script>

<Modal title="Nuevo post" {show}>
    <CreateComponent/>
</Modal>