<script>

    import { modalStore } from '../stores'

    import OrderUpdatePackageComponent from './component.update.package.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'order_update_package_modal'

</script>

<Modal title="Actualizar paquete" {show}>

    <OrderUpdatePackageComponent />
    
</Modal>