<script>

    import { toast } from 'bulma-toast'

    import LinksService from '../$services/orders.links.service'
    import UsersService from '../$services/users.service'
    import OrdersService from '../$services/orders.service'
    import StripeService from '../$services/stripe.service'

    import InputNumber from '../$components/input.number.svelte'
    import Button from '../$components/button.svelte'
    import Spinner from '../$components/spinner.svelte'

    import LocationInputs from '../locations/component.inputs.svelte'
    import LocationDetails from '../locations/component.location.details.svelte'
    import Quotation from '../quotations/component.quotations.svelte'

    const { STRIPE_KEY } = _CONSTANTS_

    export let params = {}

    let quotationLoading = false
    let loading = false
    let config = null
    let step = 'delivery'
    let link = null
    let order = null
    let data = {
        delivery: {}
    }

    getLink()

    async function getLink() {

        loading = true
        const resp = await LinksService.getLink(params.linkId)

        if(!resp.success) {
            loading = false
            return toast({message: resp.error.message, type: 'is-danger'})
        }

        link = resp.data
        order = link.order

        await getUserConfig(link.userId)
    }

    async function getUserConfig(userId) {

        const resp = await UsersService.getUserConfig(userId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        config = resp.data
    }

    async function createOrder() {

        data.userId = link.userId

        loading = true
        const resp = await OrdersService.createOrder(data, {requestAccess: link.userId})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(link.type === 'payment')
            await createCheckoutLink(resp.data)

        order = resp.data
    }

    async function setQuotation(event) {

        data.pickup = link.pickup
        data.package = link.package
        data.quotation = event.detail

        if(link.type === 'payment')
            data.quotation.payment = 'pending'

        await createOrder()
    }

    async function createCheckoutLink(order) {

        data.orderId = order._id
        data.linkId = link._id

        loading = true
        const resp = await StripeService.createCheckoutLink(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        window.Stripe(STRIPE_KEY).redirectToCheckout({ sessionId: resp.data })
    }

    function nextStep() {

        if(link.type === 'basic')
            return createOrder()
            
        step = 'quotation'
    }

</script>

<Spinner { loading } />

{#if !loading}
    <div class="container">
        <div class="columns">
            <div class="column is-6 is-offset-3">
                <div class="box">

                    <div class="items-center">
                        <img src="{ config.link.brand || '/images/brand_red.png' }" alt="Brand" style="max-height: 250px;">
                    </div>
                    <br>

                    {#if !order}
                        {#if step === 'delivery'}
                            <LocationInputs on:submit={ nextStep } bind:location={ data.delivery }>
                                <div slot="buttons">

                                    {#if link.package && link.package.selectValue}
                                        <InputNumber bind:value={ link.package.value } label="Valor del seguro" placeholder="Ingrese el valor del seguro" />
                                        <span>El valor del seguro incrementa el costo del envío</span>
                                        <br>
                                        <br>
                                    {/if}
                                    
                                    {#if link.type === 'basic'}
                                        <Button type="submit" icon="save" text="Guardar información" color="primary" fullwidth />
                                    {:else}
                                        <Button type="submit" icon="arrow-circle-right" text="Siguiente paso" color="primary" fullwidth />
                                    {/if}

                                </div>
                            </LocationInputs>
                        {/if}
                        {#if step === 'quotation'}
                            <Quotation 
                                bind:loading={ quotationLoading } 
                                on:selected={ setQuotation } 
                                pickup={ link.pickup } 
                                delivery={ data.delivery } 
                                ipackage={ link.package } 
                                query={{requestAccess: link.userId, providers: link.providers || []}}
                                random={false} 
                            />
                        {/if}
                    {/if}

                    {#if order}
                        <div class="title">Información Registrada</div>
                        <div class="subtitle">Orden #{ order.number }</div>

                        <div class="notification is-light">{ config.link.message }</div>
                        
                        {#if order.quotation && order.quotation.reference}
                            <div class="notification is-light">Referencia de pago { order.quotation.reference }</div>
                        {/if}

                        <LocationDetails location={ order.delivery } />
                    {/if}

                    {#if !quotationLoading}
                        <br>
                        <a href="{ config.link.bannerURL }" target="_blank">
                            <div class="items-center" style="border-radius: 8px; overflow: hidden;">
                                <img src="{ config.link.banner || '/images/brand_white.png' }" alt="Brand">
                            </div>
                        </a>
                    {/if}
                </div>
            </div>
        </div>
    </div>
{/if}