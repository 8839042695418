<script>

    import { toast } from 'bulma-toast'
    import { ampmsStore, modalStore } from '../stores'

    import AmpmService from '../$services/ampm.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await AmpmService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ampmsStore.set(resp.data)
    }

    async function updateCredentialActive(ampm) {
    
        loading = true
        const resp = await AmpmService.updateCredentialActive(ampm._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<style>
    img {
        width: 50%;
    }
</style>

<Spinner {loading} />

{#if !loading && $ampmsStore.length}
    <div class="subtitle is-5"><b>Cuentas con AMPM</b></div>
    <div class="credential-items">
        {#each $ampmsStore as ampm}
        <div class="credential-item">
            <img class="image" src="images/AMPM.svg" alt="AMPM">
            <div class="title is-5">{ ampm.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(ampm.created) }</div>
            <div class="items-space-around">
                <div class="field" style="margin: 0;">
                    <input on:click={() => updateCredentialActive(ampm)} bind:checked={ampm.active} class="is-checkradio is-primary" id="{ampm._id}" type="checkbox">
                    <label for="{ampm._id}"></label>
                </div>
                <button on:click={() => modalStore.open('ampm', 'account_modal', ampm)} class="button">
                    <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                </button>
            </div>
        </div>
        {/each}
    </div>
{/if}