<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import LocationCreateComponent from './component.create.svelte'

    $: show = $modalStore === 'location_create_modal'

</script>

<Modal title="Agregar dirección" {show} >
    <LocationCreateComponent />
</Modal>