<script>

    import { toast } from 'bulma-toast'
    import { locationsStore, modalStore } from '../stores'

    import ZipcodeColonyComponent from './component.zipcode.colony.svelte'
    import LocationsService from '../$services/locations.service'
    import Spinner from '../$components/spinner.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'

    let loading = false
    let data = {}

    async function createLocation() {

        loading = true
        const resp = await LocationsService.createLocation(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        data = {}
        locationsStore.append(resp.data)

        toast({message: 'Dirección creada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createLocation}>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.alias } label="Alias" icon="address-book" placeholder="Identificador de la dirección"/>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.contact } label="Contacto" icon="user" placeholder="Nombre del contacto"/>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.corporate } label="Empresa" icon="building" placeholder="Nombre de la empresa" required ={false} />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.phone } label="Teléfono" icon="phone" placeholder="Número de teléfono" />
            </div>
            <div class="column">
                <InputText bind:value={ data.email } label="Correo electrónico" icon="envelope" placeholder="Correo electrónico" />
            </div>
        </div>
        <ZipcodeColonyComponent
            bind:zipcode={data.zipcode}
            bind:state={data.state}
            bind:city={data.city}
            bind:municipality={data.municipality}
            bind:colony={data.colony}
        />
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.street } label="Calle" icon="map-marker-alt" placeholder="Calle" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.number } label="Número exterior" icon="hashtag" placeholder="Número exterior de la casa/apartamento" />
            </div>
            <div class="column">
                <InputText bind:value={ data.apartment } label="Número interior" icon="building" placeholder="Número interior del apartamento" required ={false}/>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.references } label="Referencias" icon="asterisk" placeholder="Referencias de la casa/apartamento" required ={false} />
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <Button on:click={ () => modalStore.close() } text="Cancelar" icon="ban" fullwidth={ true }/>
                </div>
                <div class="column">
                    <Button type="submit" text="Guardar" icon="save" color="primary" fullwidth={ true }/> 
                </div>
            </div>
        </div>
    </form>
{/if}