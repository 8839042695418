<script>

    import { toast } from 'bulma-toast'
    import { treggosStore, modalStore } from '../stores'

    import treggoService from '../$services/treggo.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await treggoService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        treggosStore.set(resp.data)
    }

    async function updateCredentialActive(treggo) {
    
        loading = true
        const resp = await treggoService.updateCredentialActive(treggo._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<style>
    img {
        width: 30%;
    }
</style>

<Spinner {loading} />

{#if !loading && $treggosStore.length}
    <div class="subtitle is-5"><b>Cuentas con treggo</b></div>
    <div class="credential-items">
        {#each $treggosStore as treggo}
        <div class="credential-item">
            <img class="image" src="images/Treggo.svg" alt="Treggo">
            <div class="title is-5">{ treggo.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(treggo.created) }</div>
            <div class="items-space-around">
                <div class="field" style="margin: 0;">
                    <input on:click={() => updateCredentialActive(treggo)} bind:checked={treggo.active} class="is-checkradio is-primary" id="{treggo._id}" type="checkbox">
                    <label for="{treggo._id}"></label>
                </div>
                <button on:click={() => modalStore.open('treggo', 'account_modal', treggo)} class="button">
                    <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                </button>
            </div>
        </div>
        {/each}
    </div>
{/if}