<script>

    import { toast } from 'bulma-toast'
    import { facturamaInvoiceStore, notifyStore, modalStore } from '../stores'

    import FacturamaService from '../$services/facturama.service'
    import Modal from '../$components/modal.svelte'
    import Spinner from '../$components/spinner.svelte'

    $: show = $modalStore === 'facturama_invoice_cancel'

    let loading = false

    async function cancelInvoice() {

        const invoiceId = $facturamaInvoiceStore.invoiceId

        loading = true
        const resp = await FacturamaService.cancelInvoice(invoiceId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        notifyStore.set('facturama_invoices_updated')
        modalStore.set(null)
    }

</script>

<Modal title="Cancelar factura" {show} >

    <Spinner {loading} />

    {#if !loading}
        <form on:submit|preventDefault={cancelInvoice}>
            <div>¿Cancelar factura?</div>
            <div class="items-end">
                <button on:click={() => modalStore.set(null)} type="button" class="button space-right">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                    <span>Cancelar</span>
                </button>
                <button type="submit" class="button is-primary">
                    <span class="icon"><i class="fas fa-check-circle"></i></span>
                    <span>Aceptar</span>
                </button>
            </div>
        </form>
    {/if}
</Modal>