<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import OrderUploadComponent from './component.upload.svelte'

    $: show = $modalStore === 'order_upload_modal'

</script>

<Modal title="Cargar órdenes" {show} >
    <OrderUploadComponent />
</Modal>