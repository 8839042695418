<script>

    import { modalStore } from '../stores'
    import UpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'post_update_modal'

</script>

<Modal title="Editar post" {show} >
    <UpdateComponent/>
</Modal>