<script>

    import { toast } from "bulma-toast"

    export let value = null
    export let icon = 'tag'
    export let message = 'Link copiado'

    let input = null

    function copy() {
        input.select()
        input.setSelectionRange(0, 99999)
        document.execCommand("copy")
        toast({message, type: 'is-success'})
    }

</script>

<div class="columns">
    <div class="column">
        <div class="field">
            <div class="control has-icons-left">
                <span class="icon"><i class="fas fa-{ icon }"></i></span>
                <input bind:this={ input } bind:value={ value } type="text" class="input" readonly>
            </div>
        </div>
    </div>
    <div class="column is-narrow">
        <button on:click={ copy } type="button" class="button">
            <span class="icon"><i class="fas fa-copy"></i></span>
        </button>
    </div>
</div>