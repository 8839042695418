<script>

    import { toast } from 'bulma-toast'
    import { N99MinutossStore, N99MinutosStore, modalStore } from '../stores'

    import Spinner from '../$components/spinner.svelte'
    import N99MinutosService from '../$services/99minutos.service'

    const data = new Object({
        _id: $N99MinutosStore._id,
        alias: $N99MinutosStore.alias,
        apikey: $N99MinutosStore.apikey
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await N99MinutosService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        N99MinutossStore.replace(resp.data)
        N99MinutosStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/99Minutos.svg" alt="99Minutos" style="width: 60%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.alias}  type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Api key</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.apikey}  type="text"  class="input" placeholder="Api key" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.set('N99Minutos_account_modal')} type="button" class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary is-fullwidth">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}