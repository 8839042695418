<script>

    import { createEventDispatcher } from 'svelte'
    import { toast } from "bulma-toast"

    import { userStore } from '../stores'

    import CommentsService from "../$services/comments.service"

    const dispatch = createEventDispatcher()

    export let comment = null

    let loading = false
    let isLike = false
    let isDislike = false

    showLikes()

    async function setLike(set) {

        loading = true
        const resp = await CommentsService.setLike(comment._id, {set})
        loading = false

        if(!resp.success)
            return toast({ message: resp.error.message, type: "is-danger" })

        comment = resp.data

        dispatch('updated', resp.data)
        showLikes()
    }

    function showLikes() {
        isLike = comment.likes.findIndex(item => item === $userStore._id) >= 0
        isDislike = comment.dislikes.findIndex(item => item === $userStore._id) >= 0
    }

</script>

<style>
    .field, .control {
        margin: 0 !important;
    }
</style>

<div class="field has-addons">
    <p class="control">
        <button on:click={() => setLike(isDislike? '' : 'dislikes')} class="button is-small"
            class:is-loading={loading} class:is-primary={isDislike}>
                <span class="icon"><i class="fas fa-heart-broken"/></span>
                <span>{ comment.dislikes.length }</span>
        </button>
    </p>
    <p class="control">
        <button on:click={() => setLike(isLike? '' : 'likes')} class="button is-small"
            class:is-loading={loading} class:is-primary={isLike}>
                <span class="icon"><i class="fas fa-heart"/></span>
                <span>{ comment.likes.length }</span>
        </button>
    </p>
</div>