<script>

    import { fade } from 'svelte/transition'
    import { toast } from 'bulma-toast'
    import { modalStore } from '../stores'

    import NoticesService from '../$services/notices.services'

    $: show = $modalStore === 'notice_modal' && notices.length

    let loading = false
    let show = false
    let item = 0
    let focus = false
    let notices = []

    getNotices()
   
    async function getNotices() {
    
        loading = true
        const resp = await NoticesService.getNotices()
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        notices = resp.data
    }

    function next() {
        item += 1

        if(item >= notices.length)
            item = 0
    }

    function prev() {
        item -= 1
        if(item < 0)
            item = notices.length-1
    }

    function onClose() {
        modalStore.set(null)
        show = false
    }

</script>

<style>
    .modal-card-body {
        padding: 16px;
        background: none;
    }
    .modal-card {
        max-width: 60vw !important;
    }
    @media screen and (max-width: 800px) {
        .modal-card {
            max-width: 100vw !important;
        }
    }
</style>

<div class="modal" class:is-active={show}>
    <div on:click={onClose} class="modal-background"></div>
    <div class="modal-card">
        <div class="modal-card-body">
            {#each notices as notice, index} 
                {#if notice.type === 'image' && index === item}
                    <div on:focus on:blur on:mouseover={() => focus = true} on:mouseout={() => focus = false}>
                        {#if notice.link}
                            <a href="{ notice.link }" target="blank">
                                <img in:fade src="{ notice.url }" alt="{ notice._id }">
                            </a>
                        {:else}
                            <img in:fade src="{ notice.url }" alt="{ notice._id }">
                        {/if}
                    </div>
                {/if}
                {#if notice.type === 'video' && index === item}
                    <div on:focus on:blur on:mouseover={() => focus = true} on:mouseout={() => focus = false}>
                        {#if notice.link}
                            <a href="{ notice.link }" target="blank">
                                <video in:fade controls autoplay>
                                    <track kind="captions">
                                    <source src="{ notice.url }" type="video/mp4" />
                                </video>
                            </a>
                        {:else}
                            <video in:fade controls autoplay>
                                <track kind="captions">
                                <source src="{ notice.url }" type="video/mp4" />
                            </video>
                        {/if}
                    </div>
                {/if}
            {/each}
            <div class="items-end">
                <button on:click={onClose} class="button is-success is-fullwidth">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                    <span>Cerrar</span>
                </button>

                {#if notices.length > 1}
                    <button on:click={prev} class="button space-left">
                        <span class="icon"><i class="fas fa-chevron-left"></i></span>
                        <span>Anterior</span>
                    </button>
                    <button on:click={next} class="button space-left">
                        <span class="icon"><i class="fas fa-chevron-right"></i></span>
                        <span>Siguiente</span>
                    </button>
                    <button class="button space-left" readonly>
                        <span>{ item+1 }/{ notices.length }</span>
                    </button>
                {/if}
            </div>
        </div>
    </div>
</div>