<script>

    import { modalStore } from '../stores'

    import FedexAccountUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'fedex_update_modal'

</script>

<Modal title="Actualizar cuenta" {show}>
    <FedexAccountUpdateComponent />
</Modal>