<script>

    export let changeCountry = true
    export let showMexico = true
    export let country = showMexico? 'México' : 'EUA'
    export let state = ''

</script>

<div class="columns">
    <div class="column">
        <div class="field">
            <label for="" class="label">País</label>
            <div class="control">
                <div class="select">
                    <select bind:value={country} disabled={!changeCountry} required>
                        {#if showMexico}
                            <option value="México">México</option>
                        {/if}
                        <option value="EUA">Estados Unidos</option>
                        <option value="Canadá">Canadá</option>
    
                        <option value="Afganistán">Afganistán</option>
                        <option value="Albania">Albania</option>
                        <option value="Alemania">Alemania</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguila">Anguila</option>
                        <option value="Antártida">Antártida</option>
                        <option value="Antigua y Barbuda">Antigua y Barbuda</option>
                        <option value="Antillas Neerlandesas">Antillas Neerlandesas</option>
                        <option value="Arabia Saudita">Arabia Saudita</option>
                        <option value="Argel">Argel</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaiyán">Azerbaiyán</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahréin">Bahréin</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarús">Belarús</option>
                        <option value="Bélgica">Bélgica</option>
                        <option value="Belice">Belice</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermudas">Bermudas</option>
                        <option value="Bhután">Bhután</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia y Herzegovina">Bosnia y Herzegovina</option>
                        <option value="Botsuana">Botsuana</option>
                        <option value="Brasil">Brasil</option>
                        <option value="Brunéi">Brunéi</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cabo Verde">Cabo Verde</option>
                        <option value="Camboya">Camboya</option>
                        <option value="Camerún">Camerún</option>
                        <option value="Canadá">Canadá</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Chipre">Chipre</option>
                        <option value="Ciudad del Vaticano">Ciudad del Vaticano</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Corea del Norte">Corea del Norte</option>
                        <option value="Corea del Sur">Corea del Sur</option>
                        <option value="Costa de Marfil">Costa de Marfil</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Croacia">Croacia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Dinamarca">Dinamarca</option>
                        <option value="Domínica">Domínica</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egipto">Egipto</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Emiratos Árabes Unidos">Emiratos Árabes Unidos</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Eslovaquia">Eslovaquia</option>
                        <option value="Eslovenia">Eslovenia</option>
                        <option value="España">España</option>
                        <option value="Estados Unidos de América">Estados Unidos de América</option>
                        <option value="Estados Unidos">Estados Unidos</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Etiopía">Etiopía</option>
                        <option value="EUA">EUA</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Filipinas">Filipinas</option>
                        <option value="Finlandia">Finlandia</option>
                        <option value="Francia">Francia</option>
                        <option value="Gabón">Gabón</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia del Sur e Islas Sandwich del Sur">Georgia del Sur e Islas Sandwich del Sur</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Granada">Granada</option>
                        <option value="Grecia">Grecia</option>
                        <option value="Groenlandia">Groenlandia</option>
                        <option value="Guadalupe">Guadalupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guayana Francesa">Guayana Francesa</option>
                        <option value="Guayana">Guayana</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea Ecuatorial">Guinea Ecuatorial</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Haití">Haití</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungría">Hungría</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Irak">Irak</option>
                        <option value="Irán">Irán</option>
                        <option value="Irlanda">Irlanda</option>
                        <option value="Isla Bouvet">Isla Bouvet</option>
                        <option value="Isla de Man">Isla de Man</option>
                        <option value="Islandia">Islandia</option>
                        <option value="Islas Áland">Islas Áland</option>
                        <option value="Islas Caimán">Islas Caimán</option>
                        <option value="Islas Christmas">Islas Christmas</option>
                        <option value="Islas Cocos">Islas Cocos</option>
                        <option value="Islas Cook">Islas Cook</option>
                        <option value="Islas Faroe">Islas Faroe</option>
                        <option value="Islas Heard y McDonald">Islas Heard y McDonald</option>
                        <option value="Islas Malvinas">Islas Malvinas</option>
                        <option value="Islas Marshall">Islas Marshall</option>
                        <option value="Islas Norkfolk">Islas Norkfolk</option>
                        <option value="Islas Palaos">Islas Palaos</option>
                        <option value="Islas Pitcairn">Islas Pitcairn</option>
                        <option value="Islas Solomón">Islas Solomón</option>
                        <option value="Islas Svalbard y Jan Mayen">Islas Svalbard y Jan Mayen</option>
                        <option value="Islas Turcas y Caicos">Islas Turcas y Caicos</option>
                        <option value="Islas Vírgenes Británicas">Islas Vírgenes Británicas</option>
                        <option value="Islas Vírgenes de los Estados Unidos de América">Islas Vírgenes de los Estados Unidos de América</option>
                        <option value="Israel">Israel</option>
                        <option value="Italia">Italia</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japón">Japón</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordania">Jordania</option>
                        <option value="Kazajstán">Kazajstán</option>
                        <option value="Kenia">Kenia</option>
                        <option value="Kirguistán">Kirguistán</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Laos">Laos</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Letonia">Letonia</option>
                        <option value="Líbano">Líbano</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libia">Libia</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lituania">Lituania</option>
                        <option value="Luxemburgo">Luxemburgo</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malasia">Malasia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldivas">Maldivas</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marruecos">Marruecos</option>
                        <option value="Martinica">Martinica</option>
                        <option value="Mauricio">Mauricio</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="México">México</option>
                        <option value="Micronesia">Micronesia</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Mónaco">Mónaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Níger">Níger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Noruega">Noruega</option>
                        <option value="Nueva Caledonia">Nueva Caledonia</option>
                        <option value="Nueva Zelanda">Nueva Zelanda</option>
                        <option value="Omán">Omán</option>
                        <option value="Países Bajos">Países Bajos</option>
                        <option value="Pakistán">Pakistán</option>
                        <option value="Palestina">Palestina</option>
                        <option value="Panamá">Panamá</option>
                        <option value="Papúa Nueva Guinea">Papúa Nueva Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Perú">Perú</option>
                        <option value="Polinesia Francesa">Polinesia Francesa</option>
                        <option value="Polonia">Polonia</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reino Unido">Reino Unido</option>
                        <option value="República Centro-Africana">República Centro-Africana</option>
                        <option value="República Checa">República Checa</option>
                        <option value="República Dominicana">República Dominicana</option>
                        <option value="Reunión">Reunión</option>
                        <option value="Ruanda">Ruanda</option>
                        <option value="Rumanía">Rumanía</option>
                        <option value="Rusia">Rusia</option>
                        <option value="Sahara Occidental">Sahara Occidental</option>
                        <option value="Samoa Americana">Samoa Americana</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Bartolomé">San Bartolomé</option>
                        <option value="San Cristóbal y Nieves">San Cristóbal y Nieves</option>
                        <option value="San Marino">San Marino</option>
                        <option value="San Pedro y Miquelón">San Pedro y Miquelón</option>
                        <option value="San Vicente y las Granadinas">San Vicente y las Granadinas</option>
                        <option value="Santa Elena">Santa Elena</option>
                        <option value="Santa Lucía">Santa Lucía</option>
                        <option value="Santo Tomé y Príncipe">Santo Tomé y Príncipe</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia y Montenegro">Serbia y Montenegro</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leona">Sierra Leona</option>
                        <option value="Singapur">Singapur</option>
                        <option value="Siria">Siria</option>
                        <option value="Somalia">Somalia</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Suazilandia">Suazilandia</option>
                        <option value="Sudáfrica">Sudáfrica</option>
                        <option value="Sudán">Sudán</option>
                        <option value="Suecia">Suecia</option>
                        <option value="Suiza">Suiza</option>
                        <option value="Surinam">Surinam</option>
                        <option value="Tailandia">Tailandia</option>
                        <option value="Taiwán">Taiwán</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Tayikistán">Tayikistán</option>
                        <option value="Territorio Británico del Océano Índico">Territorio Británico del Océano Índico</option>
                        <option value="Territorios Australes Franceses">Territorios Australes Franceses</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad y Tobago">Trinidad y Tobago</option>
                        <option value="Túnez">Túnez</option>
                        <option value="Turkmenistán">Turkmenistán</option>
                        <option value="Turquía">Turquía</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Ucrania">Ucrania</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistán">Uzbekistán</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Wallis y Futuna">Wallis y Futuna</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Yibuti">Yibuti</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="columns">
    <div class="column">
        {#if country === 'México'}
            <div class="field">
                <label for="" class="label">Estado</label>
                <div class="control">
                    <div class="select">
                        <select bind:value={state} required>
                            <option value="">Estado</option>
                            <option value="Aguascalientes">Aguascalientes</option>
                            <option value="Baja California Sur">Baja California Sur</option>
                            <option value="Baja California">Baja California</option>
                            <option value="Campeche">Campeche</option>
                            <option value="Chiapas">Chiapas</option>
                            <option value="Chihuahua">Chihuahua</option>
                            <option value="Ciudad de México">Ciudad de México</option>
                            <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                            <option value="Colima">Colima</option>
                            <option value="Durango">Durango</option>
                            <option value="Guanajuato">Guanajuato</option>
                            <option value="Guerrero">Guerrero</option>
                            <option value="Hidalgo">Hidalgo</option>
                            <option value="Jalisco">Jalisco</option>
                            <option value="México">México</option>
                            <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                            <option value="Morelos">Morelos</option>
                            <option value="Nayarit">Nayarit</option>
                            <option value="Nuevo León">Nuevo León</option>
                            <option value="Oaxaca">Oaxaca</option>
                            <option value="Puebla">Puebla</option>
                            <option value="Querétaro">Querétaro</option>
                            <option value="Quintana Roo">Quintana Roo</option>
                            <option value="San Luis Potosí">San Luis Potosí</option>
                            <option value="Sinaloa">Sinaloa</option>
                            <option value="Sonora">Sonora</option>
                            <option value="Tabasco">Tabasco</option>
                            <option value="Tamaulipas">Tamaulipas</option>
                            <option value="Tlaxcala">Tlaxcala</option>
                            <option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</option>
                            <option value="Yucatán">Yucatán</option>
                            <option value="Zacatecas">Zacatecas</option>
                        </select>
                    </div>
                </div>
            </div>
        {:else if country === 'EUA'}
            <div class="field">
                <label for="" class="label">Estado</label>
                <div class="control">
                    <div class="select">
                        <select bind:value={state}>
                            <option value="">Estado</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Carolina del Norte">Carolina del Norte</option>
                            <option value="Carolina del Sur">Carolina del Sur</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Dakota">Dakota</option>
                            <option value="Dakota">Dakota</option>
                            <option value="Delaware">Delaware</option>
                            <option value="Distrito">Distrito</option>
                            <option value="Florida">Florida</option>
                            <option value="Fuerzas Armadas de América">Fuerzas Armadas de América</option>
                            <option value="Fuerzas Armadas de Europa">Fuerzas Armadas de Europa</option>
                            <option value="Fuerzas Armadas del Pacífico">Fuerzas Armadas del Pacífico</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawai">Hawai</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Luisiana">Luisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="Nueva Hampshire">Nueva Hampshire</option>
                            <option value="Nueva Jersey">Nueva Jersey</option>
                            <option value="Nueva York">Nueva York</option>
                            <option value="Nuevo México">Nuevo México</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregón">Oregón</option>
                            <option value="Pensilvania">Pensilvania</option>
                            <option value="Rhode">Rhode</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                        </select>
                    </div>
                </div>
            </div>
        {:else if country === 'Canadá'} 
            <div class="field">
                <label for="" class="label">Estado</label>
                <div class="control">
                    <div class="select">
                        <select bind:value={state}>
                            <option value="">Estado</option>
                            <option value="Alberta">Alberta</option>
                            <option value="Columbia Británica">Columbia Británica</option>
                            <option value="Isla Príncipe Eduardo">Isla Príncipe Eduardo</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="Nueva Escocia">Nueva Escocia</option>
                            <option value="Nuevo Brunswick">Nuevo Brunswick</option>
                            <option value="Nunavut, CA">Nunavut, CA</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Terranova y Labrador">Terranova y Labrador</option>
                            <option value="Territorios del Noroeste">Territorios del Noroeste</option>
                            <option value="Yukón">Yukón</option>
                        </select>
                    </div>
                </div>
            </div>
        {:else}
            <div class="field">
                <label for="" class="label">Estado</label>
                <div class="control">
                    <input bind:value={state} type="text" class="input" placeholder="Estado">
                </div>
            </div>
        {/if}
    </div>
</div>