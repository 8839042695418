<script>

    import { treggoStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import TreggoAccountComponent from './component.treggo.account.svelte'

    $: show = $modalStore === 'treggo_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <TreggoAccountComponent treggo={$treggoStore} />
</Modal>