<script>

    import { modalStore } from '../stores'

    import UpdateAutomaticComponent from './component.update.automatic.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'user_update_automatic_modal'

</script>

<Modal title="Actualizar información" {show}>
    <UpdateAutomaticComponent />
</Modal>