<script>

    import { modalStore } from '../stores'

    import ConfigLinkComponent from './component.config.link.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'link_modal'

</script>

<Modal title="" {show}>
    <ConfigLinkComponent />
</Modal>