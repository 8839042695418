<script>

    import { coursesStore, courseStore, modalStore } from '../stores'
    import { toast } from 'bulma-toast'
    import { afterUpdate } from 'svelte'

    import CoursesService from '../$services/courses.service'
    import Spinner from '../$components/spinner.svelte'

    $: $courseStore && getCourses()

    let loading = false
    let units = null

    afterUpdate(() => {

        const videos = document.getElementsByTagName('video')

        if(!videos || !videos.length)
            return

        for(let i=0; i<videos.length; i++)
            videos[i].currentTime = 3
    })

    async function getCourses() {

        loading = true
        const resp = await CoursesService.getCourses({course: $courseStore.course})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        coursesStore.set(resp.data.courses)

        units = groupBy(resp.data.courses, 'unit')
        units = units.slice(1, units.length)
    }

    function groupBy(collection, property) {
        var i = 0, val, index, values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }

</script>

<style>
    .store-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
</style>

<Spinner {loading} />

<br>
{#if !loading}
    {#each units as unit}
        <div class="title">
            Unidad {unit[0].unit} : {unit[0].nameUnit}
        </div>
        <hr>
        <div class="store-items">
            {#each unit as course}
                <div on:click={() => modalStore.open('course', 'modal', course)} class="pointer">
                    <video>
                        <source src={course.urlVideo} type="video/mp4">
                        <track kind="captions"/>
                        No soportado por el navegador
                    </video>
                    <div class="subtitle is-5">Lección { course.lesson } : { course.title } </div>
                </div>
            {/each}
        </div>
    {/each}
{/if}