<script>

    import { fade } from 'svelte/transition'
    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'

    import LocationsService from '../$services/locations.service'

    import ZipcodeColonyComponent from './component.zipcode.colony.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'
    import InputEmail from '../$components/input.email.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'

    const dispatch = createEventDispatcher()

    export let quotation = null
    export let pickup = null
    export let delivery = null
    export let ipackage = null

    export let step = null
    export let location = {}

    export let showSwitchs = true
    export let showSelected = true
    export let disabled = false
    export let shipmentType = 'national'

    let locations = []
    let selected = null
    let loading = false

    if(shipmentType === 'national')
        getLocations()

    async function getLocations() {

        loading = true
        const resp = await LocationsService.getLocations({})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        locations = resp.data.locations

        if(!location.zipcode && !location.contact) {
            location = locations.find(item => item.default) || {}
            selected = location? location._id : null
        }
    }

    function setLocation() {
        location = locations.find(location => location._id === selected) || {}
    }

    function onSelected() {
        dispatch('selected', location)
    }

</script>

<style>

    .title-location {
        font-size: 2rem;
        margin-bottom: 20px;
    }


    @media (max-width:862px) {

        .items-space {
            display: grid;
        }

        .title-location {
            font-size: 1.5rem;;
        }
    }

</style>

<Spinner {loading} />

{#if !loading}
    <div class="container">
        {#if delivery && ipackage && quotation}
            <div class="columns center">
                <div class="column">
                    <div class="title is-6">Origen</div>
                    <div class="subtitle is-6">{pickup.zipcode}</div>
                </div>
                <div class="column">
                    <div class="title is-6">Destino</div>
                    <div class="subtitle is-6">{delivery.zipcode}</div>
                </div>
                <div class="column">
                    <div class="title is-6">Paquete</div>
                    <div class="subtitle is-6">{ ipackage.width }x{ ipackage.height }x{ ipackage.length}, { ipackage.weight }Kg</div>
                </div>
                <div class="column">
                    <div class="title is-6">Seguro</div>
                    <div class="subtitle is-6">${quotation.package.value}</div>
                </div>
                <div class="column">
                    <div class="title is-6">Proveedor</div>
                    <div class="subtitle is-6">{quotation.provider}</div>
                </div>
                <div class="column">
                    <div class="title is-6">Servicio</div>
                    <div class="subtitle is-6">{quotation.service}</div>
                </div>
                <div class="column">
                    <div class="title is-6">Entrega</div>
                    <div class="subtitle is-6">{quotation.days}</div>
                </div>
                <div class="column">
                    <div class="title is-6">Precio</div>
                    <div class="subtitle is-6">${quotation.finalPrice}</div>
                </div>
            </div>
        {/if}
        {#if step === 'pickup'}
            <div class="title-location center">Datos del remitente (origen)</div>
        {:else}
            <div class="title-location center">Datos del destinatario (destino)</div>
        {/if}
        <form on:submit|preventDefault={onSelected} in:fade>
            {#if locations.length && showSelected}
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label for="" class="label">Selecciona una dirección</label>
                            <div class="control">
                                <div class="select">
                                    <select bind:value={selected} on:change={setLocation}>
                                        <option value="">Selecciona una dirección</option>
                                        {#each locations as item}
                                            <option value="{item._id}">{ item.alias }</option>
                                        {/each}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/if}
            {#if location}
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ location.contact } label="Contacto" icon="user" placeholder="Nombre del contacto" />
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ location.corporate } label="Empresa" icon="building" placeholder="Nombre de la empresa" required={false}/>

                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ location.phone } label="Teléfono" icon="phone" placeholder="Número de teléfono" />
                    </div>
                    <div class="column">
                        <InputEmail bind:value={ location.email } label="Correo electrónico" icon="envelope" placeholder="Correo electrónico" />
                    </div>
                </div>
                <ZipcodeColonyComponent
                    bind:zipcode={location.zipcode}
                    bind:state={location.state}
                    bind:city={location.city}
                    bind:municipality={location.municipality}
                    bind:colony={location.colony}
                    {shipmentType}
                    {disabled}
                    {step}
                />
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ location.street } label="Calle" icon="map-marker-alt" placeholder="Calle" />
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ location.number } label="Número exterior" icon="hashtag" placeholder="Número exterior" />
                    </div>
                    <div class="column">
                        <InputText bind:value={ location.apartment } label="Número interior" icon="building" placeholder="Número interior" required={false}/>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ location.references } label="Referencias" icon="asterisk" minLength="10" placeholder="Ej: Fachada blanca, a lado de farmacia" />
                        <article class="message">
                            <div class="message-body">
                                ❗ Si la guía <b style="color: red;">no cuenta</b> con las referencias, aumenta la probabilidad de
                                <b style="color: red;">{ step === 'pickup'? 'recolección fallida.' : 'entrega fallida.' }</b>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        {#if showSwitchs}
                            {#if step === 'pickup'}
                                <div class="columns">
                                    <div class="column">
                                        <div class="field">
                                            <Switch bind:checked={location.sendEmail} label="Envíar correo a remitente" />
                                        </div>
                                    </div>
                                </div>
                            {:else}
                                <div class="columns">
                                    <div class="column">
                                        <div class="field">
                                            <Switch bind:checked={location.sendEmail} label="Envíar correo a destinatario" />
                                        </div>
                                    </div>
                                </div>
                            {/if}
                        {/if}
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <Button type="submit" text="Continuar" icon="arrow-circle-right" color="primary" fullwidth />
                    </div>
                </div>
            {/if}
        </form>
    </div>
{/if}