<script>

    import { toast } from 'bulma-toast'
    import { notifyStore } from '../stores'

    import FacturamaService from '../$services/facturama.service'
    import NumeralMethdos from '../$methods/numeral.methods'

    import Spinner from '../$components/spinner.svelte'

    $: $notifyStore === 'facturama_invoices_updated' && getInvoiceAmount() && notifyStore.set(null)

    let amount = 0
    let loading = false

    getInvoiceAmount()

    async function getInvoiceAmount() {

        loading = true
        const resp = await FacturamaService.getInvoiceAmount()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        amount = resp.data
    }

</script>

<Spinner  {loading} />

{#if !loading}
    <div class="box">
        <div class="title">Monto máximo a facturar</div>
        <div class="subtitle">{ NumeralMethdos.cash(amount) }</div>
    </div>
{/if}