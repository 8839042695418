<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'value_popup'
    
</script>


<Popup url="https://www.notion.so/myshipper/Seguro-adicional-de-pago-26e9375880354518b6f5d4c189e8a8fd" {show}>
    <div class="title">¿Asegurar el envío?</div>
    <p>Cuando <b>el valor del envío no está declarado</b> y el paquete sufre pérdida total o daños totales o parciales, la empresa de mensajería cubrirá <b>como máximo</b> la cantidad equivalente a <b>30 salarios mínimos</b>.</p>
    <p>Para proteger la mercancía adicionalmente se puede adquirir un seguro adicional, declarando el valor del envío.</p>   
    <p>El precio en los siguientes pasos ya aparecerá incluyendo el seguro adicional.</p>
    <p>❗ Antes de declarar el valor y adquirir el seguro, recomendamos conocer la información sobre los artículos excluidos de la cobertura de los seguros y la importancia de un empaque adecuado.</p>
</Popup>