<script>

    import { modalStore } from '../stores'

    import PackageUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'package_update_modal'

</script>

<Modal title="Actualizar paquete" {show}>
    <PackageUpdateComponent />
</Modal>