<script>

    import { toast } from 'bulma-toast'
    import { userStore } from '../stores'
    
    import UsersService from '../$services/users.service'

    import InputEmail from '../$components/input.email.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Value from '../$components/value.svelte'

    let data = {
        email: $userStore.email
    }
    let loading = false
    let showUpdate = false

    async function updateUser() {

        loading = true
        const resp = await UsersService.updateUser(data)
        loading = false
        
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
        
        userStore.set(resp.data)
        
        toast({message: 'Información actualizada', type: 'is-success'})
        showUpdate = false
    }

</script>

<Spinner {loading} />

{#if !showUpdate}
    <div class="title">Contacto</div>
    <div class="columns">
        <div class="column">
            <Value label="Correo electrónico" value={ $userStore.email } />
        </div>
    </div>
    <div class="items-end">
        <button on:click={() => showUpdate = true } class="button">
            <span class="icon"><i class="fas fa-edit"></i></span>
            <span>Editar</span>
        </button>
    </div>
{/if}

{#if showUpdate && !loading}
    <div class="title">Contacto</div>
    <form on:submit|preventDefault={updateUser}>
        <div class="columns">
            <div class="column">
                <InputEmail bind:value={ data.email } icon="envelope" label="Correo electrónico" placeholder="Correo electrónico que ocupa actualmente" />
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => showUpdate = false } type="button" class="button space-right">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary">
                        <span class="icon"><i class="fas fa-save"></i></span>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}