<script>

    import UsersService from '../$services/users.service'

    $: userId? getUser() : false

    export let userId = null

    let loading = false
    let user = null
    
    async function getUser() {
    
        loading = true
        const resp = await UsersService.getUser(userId)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        user = resp.data
    }

</script>

<div class="container">
    
</div>
{#if user}
    <div class="items-start">
        <figure class="image is-128x128">
            {#if user.avatar}
                <img src="{ user.avatar }" alt="Avatar">
            {:else}
                <img src="https://bulma.io/images/placeholders/128x128.png" alt="default">
            {/if}
        </figure>
        <div class="space-left">
            <div class="title is-5">{ user.alias || user.name }</div>
            <div class="subtitle is-6">{@html user.description || 'Sin descripción' }</div>
        </div>
    </div>
{/if}