<script>

    import { modalStore } from '../stores'

    import OrderUpdatePickupComponent from './component.update.pickup.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'order_update_pickup_modal'

</script>

<Modal title="Actualizar dirección de origen" {show}>
    <OrderUpdatePickupComponent />
</Modal>