<script>

    import { toast } from 'bulma-toast'
    import { packagesStore, modalStore } from '../stores'

    import PackagesService from '../$services/packages.service'
    import Delete from '../$components/delete.svelte'

    import PackageDetails from '../packages/component.package.details.svelte'

    export let ipackage = null

    let loading = false

    async function deletePackage() {

        loading = true
        const resp = await PackagesService.deletePackage(ipackage._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        packagesStore.remove(ipackage._id)
        toast({message: 'Paquete borrado', type: 'is-success'})
        modalStore.close()
    }

</script>

<PackageDetails ipackage={ ipackage } />

<div class="columns">
    <div class="column">
        <Delete on:confirm={deletePackage} {loading} />
    </div>
    <div class="column">
        <button on:click={() => modalStore.set('package_update_modal') } class="button is-fullwidth is-primary">
            <span class="icon"><i class="fas fa-edit"></i></span>
            <span>Editar</span>
        </button>
    </div>
</div>