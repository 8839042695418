

<div class="hero is-primary">
    <div class="hero-body">
        <div class="container">
            <div class="title">Error al integrar shopify</div>
            <div class="subtitle">Por favor intente de nuevo</div>
        </div>
    </div>
</div>

<div class="items-center">
    <img src="images/shopify-logo.png" alt="Shopify">
    <div class="subtitle">401</div>
</div>