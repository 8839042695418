<script>

    import { toast } from 'bulma-toast'
    import { userStore, modalStore } from '../stores'

    import InputText from '../$components/input.text.svelte'

    import StorageService from '../$services/storage.service'
    import UsersService from '../$services/users.service'
    
    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'

    let data = {
        firstName: $userStore.firstName,
        lastName: $userStore.lastName,
        corporate: $userStore.corporate,
        phone: $userStore.phone,
        isChat: $userStore.isChat,
        autoInvoice: $userStore.autoInvoice,
    }
    
    let loading = false

    async function updateUser() {

        loading = true
        const resp = await UsersService.updateUser(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        StorageService.setItem('user', resp.data)
        userStore.set(resp.data)

        toast({message: 'Información actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateUser}>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.firstName } icon="user" label="Nombre(s)" placeholder="Nombre(s)" />
            </div>
            <div class="column">
                <InputText bind:value={ data.lastName } icon="user" label="Apellido(s)" placeholder="Apellido(s)" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.corporate } icon="building" label="Empresa" placeholder="Empresa" />
            </div>
            <div class="column">
                <InputText bind:value={ data.phone } icon="phone" label="Teléfono" placeholder="Teléfono" maxlength=10 />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Switch bind:checked={ data.autoInvoice } label="Facturación automática" />
            </div>
            <div class="column">
                <Switch bind:checked={ data.isChat } label="Visible en chat" />
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.close() } type="button" class="button space-right">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary">
                        <span class="icon"><i class="fas fa-save"></i></span>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}