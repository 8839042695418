<script>

    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'
    import { odoosStore, modalStore } from '../stores'

    import OdooService from '../$services/odoo.service'
    import Spinner from '../$components/spinner.svelte'

    const dispatch = createEventDispatcher()

    let data = {}
    let loading = false

    async function createOdoo() {

        loading = true
        const resp = await OdooService.createOdoo(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        odoosStore.append(resp.data)
        
        toast({message: 'Tienda odoo integrada', type: 'is-success'})
        modalStore.close()
    }

</script>

<style>
    .items-center {
        max-height: 180px;
        margin: 8px;
    }

    .items-center img {
        height: 120px !important;
        width: 100%;
    }
</style>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createOdoo}>
        <div class="items-center">
            <img src="images/odoo.svg" alt="odoo">
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Dominio</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.domain} type="text" class="input" placeholder="Dominio" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Base de datos</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-store"></i></span>
                        <input bind:value={data.database} type="text" class="input" placeholder="Nombre de la base de datos" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Usuario</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.username} type="text" class="input" placeholder="Usuario" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Contraseña</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-store"></i></span>
                        <input bind:value={data.password} type="text" class="input" placeholder="Contraseña" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="items-end">
            <button on:click={() => dispatch('close')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Integrar</span>
            </button>
        </div>
    </form>
{/if}