<script>

    import { createEventDispatcher } from 'svelte'

    const dispatch = createEventDispatcher()

    export let metadata = {}
    export let page = null

    function navigate(to) {

        if(to === 'previous' && metadata.previous != null && metadata.previous != undefined) {
            page = metadata.previous
            dispatch('change')
        }

        if(to === 'next' && metadata.next != null && metadata.next != undefined) {
            page = metadata.next
            dispatch('change')
        }
    }

</script>

<div class="field has-addons">
    <p class="control" style="margin-right: 7px;">
        <button on:click={() => navigate('previous')} class="button is-small pagination" disabled={ metadata.previous === null || metadata.previous === undefined }>
            <span class="icon"><i class="fas fa-angle-left"></i></span>
        </button>
    </p>
    {#if !!(page && !isNaN(page))}
        <p class="control" style="margin-right: 7px;">
            <button class="button is-small pagination" disabled>
                <span>{ page }</span>
            </button>
        </p>
    {/if}
    <p class="control">
        <button on:click={() => navigate('next')} class="button is-small pagination" disabled={ metadata.next === null || metadata.next === undefined }>
            <span class="icon"><i class="fas fa-angle-right"></i></span>
        </button>
    </p>
</div>