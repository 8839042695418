<script>

    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'
    import { modalStore } from '../stores'

    import OrdersService from '../$services/orders.service'
    import Spinner from '../$components/spinner.svelte'

    export let shipments = []

    let loading = false
    let globalIndex = 0
    let errors = []

    createOrders()

    async function createOrders() {

        loading = true
        await createOrder()
        loading = false

    }

    async function createOrder(index = 0) {

        globalIndex = index

        if(index >= shipments.length)
            return

        const shipment = shipments[index]

        try {

            const data = {
                pickup: shipment.order.pickup,
                delivery: shipment.order.delivery,
                package: shipment.order.package
            }
    
            const resp = await OrdersService.createOrder(data)
    
            if(!resp.success)
                throw resp.error

        } catch(error) {
            errors = [...errors, {
                number: shipment.order.number,
                message: error.message || 'Error al duplicar la orden'
            }]
        }

        return await createOrder(index+1)
    }

    function orders() {
        modalStore.set(null)
        replace('#/orders')
    }

</script>

<div class="items-center col">
    <Spinner {loading} />
    <br>
    {#if globalIndex === shipments.length}
        <div class="title">Proceso finalizado</div>
    {:else}
        <div class="title is-4">Duplicando ordenes</div>
    {/if}
    
    <table class="table">
        <tbody>
            {#each errors as error} 
                <tr>
                    <td>{ error.number }</td>
                    <th>{ error.message }</th>
                </tr>
            {/each}
        </tbody>
    </table>
    <br>
    <div class="subtitle is-5">{ parseFloat((globalIndex*100)/shipments.length).toFixed(2) } % </div>
    <br>
   {#if globalIndex === shipments.length}
        <button on:click={orders} type="button" class="button is-primary">
            <span class="icon"><i class="fas fa-clipboard-list"></i></span>
            <span>Ver mis ordenes</span>
        </button>
   {/if}
</div>