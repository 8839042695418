<script>

    import { facturamaClientStore, notifyStore, userStore } from '../stores'

    import FacturamaService from '../$services/facturama.service'

    import UpdateClientModal from './modal.update.client.svelte'
    import CreateClientComponent from './component.create.client.svelte'
    import ClientComponent from './component.client.svelte'
    import InvoiceAmountComponent from './component.invoice.amount.svelte'
    import CreateInvoiceComponent from './component.create.invoice.svelte'
    import InvoicesComponent from './component.invoices.svelte'
    import InvoiceCancelModal from './component.cancel.svelte'

    import Spinner from '../$components/spinner.svelte'

    $: $userStore.facturamaId && getClient()
    $: $notifyStore === 'facturama_client_updated' && getClient() && notifyStore.set(null)

    let loading = false    

    async function getClient() {

        loading = true
        const resp = await FacturamaService.getClient()
        loading = false

        if(!resp.success)
            return facturamaClientStore.set(null)

        facturamaClientStore.set(resp.data)
    } 

</script>

<UpdateClientModal />
<InvoiceCancelModal />

<Spinner {loading} />

{#if !loading}
    {#if !$userStore.facturamaId}
        <CreateClientComponent />
    {:else}
        <InvoiceAmountComponent />
        <div class="columns">
            <div class="column">
                <ClientComponent client={$facturamaClientStore} />
            </div>
            <div class="column">
                <CreateInvoiceComponent />
            </div>
        </div>
        <InvoicesComponent />
    {/if}
{/if}