<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import Download from './component.download.svelte'

    $: show = $modalStore === 'charges_download_modal'
    
</script>

<Modal title="Descargar cargos" {show}>
    <Download />
</Modal>