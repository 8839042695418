<script>

    import { N99MinutosStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import N99MinutosAccountComponent from './component.99minutos.account.svelte'

    $: show = $modalStore === 'N99Minutos_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <N99MinutosAccountComponent N99Minutos={$N99MinutosStore} />
</Modal>