import { wrap } from 'svelte-spa-router/wrap'
import { needLogin, isLogin } from './auth'

import LoginView from './users/view.login.svelte'
import NotFound from './notfound.svelte'

import AccessView from './users/view.access.svelte'
import AccountsParcelsView from './accounts.parcels/view.accounts.svelte'
import AccountsView from './accounts/view.accounts.svelte'
import AutomaticView from './orders/view.automatic.svelte'
import ChargesView from './charges/view.charges.svelte'
import ChatsView from './chats/view.chats.svelte'
import CoursesView from './courses/view.courses.svelte'
import CourseView from './courses/view.course.svelte'
import FacturamaView from './facturama/view.facturama.svelte'
import HomeView from './home/view.home.svelte'
import IntegrationsView from './integrations/view.integrations.svelte'
import InvitationView from './users/view.invitation.svelte'
import OrdersView from './orders/view.orders.svelte'
import OrderView from './orders/view.order.svelte'
import PaymentsView from './payments/view.payments.svelte'
import PickupsView from './pickups/view.pickups.svelte'
import PostsUserView from './posts/view.posts.user.svelte'
import PostsView from './posts/view.posts.svelte'
import PostView from './posts/view.post.svelte'
import QuotationsView from './quotations/view.quotations.svelte'
import RecoveryView from './users/view.recovery.svelte'
import SettingsView from './settings/view.settings.svelte'
import ShipmentReferenceView from './shipments/view.shipments.references.svelte'
import ShipmentsView from './shipments/view.shipments.svelte'
import ShopifyErrorView from './integrations/view.shopify.error.svelte'
import TicketsView from './tickets/view.tickets.svelte'
import TracingsView from './tracings/view.tracings.svelte'
import TrackingsView from './trackings/view.trackings.svelte'
import TutorialsView from './tutorials/view.tutorials.svelte'
import TutorialView from './tutorials/view.tutorial.svelte'
import FulfillmentView from './fulfillment/view.fulfillment.svelte'
import OrderLinkView from './orders.links/view.order.link.svelte'
import TiendanubeInstallView from './tiendanube/view.tiendanube.install.svelte'

const routes = {

    '/': wrap({
        component: LoginView,
        conditions: [needLogin]
    }),

    '/invitation': wrap({
        component: InvitationView,
        conditions: [needLogin]
    }),

    '/access': wrap({
        component: AccessView,
        conditions: [needLogin]
    }),

    '/home': wrap({
        component: HomeView,
        conditions: [isLogin]
    }),

    '/fulfillment': wrap({
        component: FulfillmentView,
        conditions: [isLogin]
    }),

    '/orders/links/:linkId': wrap({
        component: OrderLinkView
    }),

    '/orders': wrap({
        component: OrdersView,
        conditions: [isLogin]
    }),

    '/order/:default?': wrap({
        component: OrderView,
        conditions: [isLogin]
    }),

    '/automatic': wrap({
        component: AutomaticView,
        conditions: [isLogin]
    }),

    '/payments': wrap({
        component: PaymentsView,
        conditions: [isLogin]
    }),

    '/shipments': wrap({
        component: ShipmentsView,
        conditions: [isLogin]
    }),

    '/pickups': wrap({
        component: PickupsView,
        conditions: [isLogin]
    }),

    '/quotations': wrap({
        component: QuotationsView,
        conditions: [isLogin]
    }),

    '/invoices': wrap({
        component: FacturamaView,
        conditions: [isLogin]
    }),

    '/settings/:tab?': wrap({
        component: SettingsView,
        conditions: [isLogin]
    }),

    '/tickets': wrap({
        component: TicketsView,
        conditions: [isLogin]
    }),

    '/tutorials': wrap({
        component: TutorialsView,
        conditions: [isLogin]
    }),

    '/tutorial': wrap({
        component: TutorialView,
        conditions: [isLogin]
    }),

    '/courses': wrap({
        component: CoursesView,
        conditions: [isLogin]
    }),

    '/course': wrap({
        component: CourseView,
        conditions: [isLogin]
    }),
    
    '/accountsparcels': wrap({
        component: AccountsParcelsView,
        conditions: [isLogin]
    }),

    '/accounts': wrap({
        component: AccountsView,
        conditions: [isLogin]
    }),

    '/integrations': wrap({
        component: IntegrationsView,
        conditions: [isLogin]
    }),

    '/charges': wrap({
        component: ChargesView,
        conditions: [isLogin]
    }),

    '/chats': wrap({
        component: ChatsView,
        conditions: [isLogin]
    }),

    '/posts': wrap({
        component: PostsView,
        conditions: [isLogin]
    }),

    '/tracings': wrap({
        component: TracingsView,
        conditions: [isLogin]
    }),

    '/posts/user/:userId': wrap({
        component: PostsUserView,
        conditions: [isLogin]
    }),

    '/post/:postId': wrap({
        component: PostView,
        conditions: [isLogin]
    }),

    '/trackings/:shipmentCode': wrap({
        component: TrackingsView
    }),

    '/tracking/:shipmentCode': wrap({
        component: TrackingsView
    }),

    '/shipments/:shipmentCode/references': wrap({
        component: ShipmentReferenceView
    }),

    '/shopify/error': wrap({
        component: ShopifyErrorView
    }),

    '/recovery/:hash': wrap({
        component: RecoveryView
    }),

    '/tiendanube/install': wrap({
        component: TiendanubeInstallView
    }),

    '*': wrap({
        component: NotFound
    })
}

export {routes}