import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getTickets,
    createTicket,
    createTicketTracing,
    updateTicket,
    updateTicketTracing,
    deleteTicket,
    deleteTicketTracing
}

function getTickets(query) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/tickets`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
               resolve(res.body)
           })
    })
}

function createTicket(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/tickets`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createTicketTracing(data, ticketId) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/tickets/${ticketId}/tracing`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateTicket(data, ticketId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/tickets/${ticketId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateTicketTracing(data, ticketId, tracingId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/tickets/${ticketId}/tracing/${tracingId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteTicket(ticketId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/tickets/${ticketId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteTicketTracing(ticketId, tracingId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/tickets/${ticketId}/tracing/${tracingId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}