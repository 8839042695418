import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getSigned,
    uploadFile
}

function getSigned(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/bucket/signed`)
            .set('access', Storage.getItem('access'))
            .query(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function uploadFile(file, signed) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(signed)
            .send(file)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}