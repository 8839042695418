<script>

    import { toast } from 'bulma-toast'
    import { chargesStore, modalStore } from '../stores'

    import ChargesService from '../$services/charges.service'

    import DateMethod from '../$methods/date.methods.js'

    import Pagination from '../$components/pagination.svelte'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'
    import Icon from '../$components/icon.svelte'
    import Button from '../$components/button.svelte'

    export let userId = null

    let query = {}
    let metadata = {}
    let loading = false

    getCharges()

    async function getCharges() {

        query.userId = userId

        loading = true
        const resp = await ChargesService.getCharges(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        chargesStore.set(resp.data.charges)
        metadata = resp.data.metadata
    }

</script>

{#if !loading}
    <div class="items-space space-bottom">
        <small>
            { $chargesStore.length } 
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <div class="items-end">
            <Button on:click={() => modalStore.open('charges_download_modal')} icon="file" text="Descargar" />
            <Pagination bind:metadata bind:page={ query.page } on:change={ getCharges } />
        </div>
    </div>
{/if}

<Spinner {loading} />
<Message show={!loading && !$chargesStore.length} />

{#if !loading && $chargesStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <Icon icon="fa-clipboard-check" text='Estatus'/>
                    </th>
                    <th>
                        <Icon icon="fa-signature" text='Concepto'/>
                    </th>
                    <th>
                        <Icon icon="fa-clipboard-list" text='Tipo'/>
                    </th>
                    <th>
                        <Icon icon="fa-dollar-sign" text='Monto'/>
                    </th>
                    <th>
                        <Icon icon="fa-calendar" text='Fecha'/>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $chargesStore as charge, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ charge.status === 'paid' ? 'Pagado' : charge.status === 'account'? 'Cuenta consumo' : 'Pendiente' }</td>
                        <td>{ charge.concept }</td>
                        <td>{ charge.type === 'charge' ? 'Cargo' : 'Reembolso' }</td>
                        <td>${ charge.amount }</td>
                        <td>{ DateMethod.dateTimeShort(charge.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('charge', 'modal', charge)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}