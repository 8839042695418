<script>

    import { modalStore } from '../stores'
    import TutorialComponent from './component.tutorial.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'tutorial_modal'

</script>

<Modal title=Tutorial {show}>
    <TutorialComponent />
</Modal>