<script>

    import { toast } from 'bulma-toast'

    import { packagesStore, modalStore } from '../stores'
    import PackagesService from '../$services/packages.service'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    let loading = false

    getPackages()

    async function getPackages() {

        loading = true
        const resp = await PackagesService.getPackages(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        packagesStore.set(resp.data)
    }

    async function updatePackageDefault(ipackage) {
    
        loading = true
        const resp = await PackagesService.updatePackageDefault(ipackage._id)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        getPackages()
    }

</script>

<div class="items-end">
    <button on:click={() => modalStore.set('package_create_modal')} class="button">
        <span class="icon"><i class="fas fa-plus"></i></span>
        <span>Agregar</span>
    </button>
    <br>
</div>

<Spinner {loading} />
<Message show={!loading && !$packagesStore.length} />

{#if !loading && $packagesStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-tag"></i>
                            </span>
                            <span>Principal</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-list"></i>
                            </span>
                            <span>Alias</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-ruler-combined"></i>
                            </span>
                            <span>Medidas</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span>Valor declarado</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-box-open"></i>
                            </span>
                            <span>Contenido</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $packagesStore as ipackage}
                    <tr>
                        <td>
                            <div class="field">
                                <input on:click={() => updatePackageDefault(ipackage)} bind:checked={ipackage.default} class="is-checkradio is-primary" id="{ipackage._id}" type="checkbox">
                                <label for="{ipackage._id}"></label>
                            </div>
                        </td>
                        <td>{ ipackage.alias }</td>
                        <td>{ ipackage.width }cm x { ipackage.height }cm x { ipackage.length }cm | { ipackage.weight }kg</td>
                        <td>${ ipackage.value }</td>
                        <td>{ ipackage.content }</td>
                        <td>
                            <button on:click={() => modalStore.open('package', 'modal', ipackage)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}