<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import CreateComponent from './component.create.svelte'

    $: show = $modalStore === 'order_link_create_modal'

</script>

<Modal title="Crear link" {show}>
    <CreateComponent />
</Modal>