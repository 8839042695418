
<style>
    .main {
        height: 100vh;
    }
</style>

<div class="main items-center">
    <div>
        <div class="title">Pagina no encontrada</div>
        <div class="subtitle">404</div>
        <a href="#/">Ir a inicio</a>
    </div>
</div>
