<script>

    import { toast } from 'bulma-toast'
    import { userStore } from '../stores'

    import UsersService from '../$services/users.service'
    import DateMethods from '../$methods/date.methods'

    let loading = false
    let users = []

    getReferidos()

    async function getReferidos() {

        loading = true
        const resp = await UsersService.getReferidos($userStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        users = resp.data
    }

</script>

<div class="title">Mi Código</div>
<div class="subtitle">{ $userStore.share }</div>

{#if users.length}
    <br>
    <br>
    <div class="title">Mis Referidos</div>
    <div class="table-container">
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Correo</th>
                    <th>Envíos</th>
                    <th>Registro</th>
                    <th>Aplicado</th>
                </tr>
            </thead>
            <tbody>
                {#each users as user}
                    <tr>
                        <td>{ user.name }</td>
                        <td>{ user.email }</td>
                        <td>{ user.shipments }</td>
                        <td>{ DateMethods.dateLarge(user.created) }</td>
                        <td>{ user.referidoApply? 'Si' : 'No' }</td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}