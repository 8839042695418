<script>

    import { toast } from 'bulma-toast'
    import { merchandisesStore, userStore, modalStore } from '../stores'

    import MerchandisesService from '../$services/merchandises.service'

    import SatProducts from '../catalogs/component.sat.products.svelte'
    import SatUnits from '../catalogs/component.sat.units.svelte'

    import PackagesSelect from '../packages/component.package.select.svelte'

    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'
    import Form from '../$components/form.svelte'

    let loading = false
    let data = {
        userId: $userStore._id
    }
    
    async function createMerchandise() {

        if(!data.product.code)
            return

        if(!data.unit.code)
            return
    
        loading = true
        const resp = await MerchandisesService.createMerchandise(data)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        merchandisesStore.append(resp.data)
        modalStore.close()
    }

</script>

<Form on:submit={createMerchandise} {loading} >
    <div class="columns">
        <div class="column">
            <PackagesSelect bind:packageId={data.packageId} />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={data.alias} label="Alias" icon="signature" placeholder="Alias" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <SatProducts bind:selected={data.product} />
        </div>
        <div class="column">
            <SatUnits bind:selected={data.unit} />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputNumber bind:value={data.items} label="Cantidad" icon="list-alt" placeholder="Cantidad de productos" />
        </div>
        <div class="column">
            <InputNumber bind:value={data.weight} label="Peso" icon="weight" placeholder="KG" step="0.01" />
        </div>
        <div class="column is-6">
            <InputNumber bind:value={data.value} label="Valor" icon="dollar-sign" placeholder="MXN" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={data.name} label="Nombre" icon="file-signature" placeholder="Nombre" />
        </div>
        <div class="column">
            <InputText bind:value={data.brand} label="Marca" icon="file-signature" placeholder="Marca" required={ false } />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={data.description} label="Descripción" icon="file-signature" placeholder="Descripción" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <div class="items-end">
                <div class="buttons">
                    <Button on:click={modalStore.close} type="button" text="Cancelar" icon="ban" />
                    <Button type="submit" text="Guardar" icon="save" color="primary" />
                </div>
            </div>
        </div>
    </div>
</Form>