import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getCourses,
    getCoursesList,
    findCourse,
    setViewed
}

function getCourses(query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/courses`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getCoursesList() {
    return new Promise((resolve, reject) => {
        Superagent
        .get(`${HOST_API}/courses/list`)
        .set('access', Storage.getItem('access'))
        .end((err, res) => {
            $logout(res.body)
            resolve(res.body)
        })
    })
}

function findCourse(query) {
    return new Promise((resolve, reject) => {
        Superagent
        .get(`${HOST_API}/course/find`)
        .set('access', Storage.getItem('access'))
        .query(query)
        .end((err, res) => {
            $logout(res.body)
            resolve(res.body)
        })
    })
}

function setViewed(tutorialId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/courses/${tutorialId}/viewed`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}