<script>

    import { toast } from 'bulma-toast'
    import { tracingsStore, incidentStore } from '../stores'

    import TracingsService from '../$services/tracings.service'
    import DateMethod from '../$methods/date.methods'

    import Table from '../$components/table.svelte'

    let query = {
        incidentId: $incidentStore._id
    }
    let loading = false

    getTracings()

    async function getTracings() {
    
        loading = true
        const resp = await TracingsService.getTracings(query)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        tracingsStore.set(resp.data)
    }

</script>

<Table items={$tracingsStore.length} {loading} >
    <tbody>
        {#each $tracingsStore as tracing}
            <tr>
                <td>
                    <div class="items-end"><small>{ DateMethod.dateLarge(tracing.created) }</small></div>
                    <div>{ tracing.comment }</div>
                </td>
            </tr>
        {/each}
    </tbody>
</Table>