<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'frequency_popup'
    
</script>


<Popup url="https://www.notion.so/myshipper/Zonas-y-frecuencia-de-entrega-b87d349094834561af51b2e5ae7fb86d" {show}>
    <div class="title">¿Frecuencia de entrega?</div>
    <p>Las coberturas de las paqueterías se basan en los códigos postales y para hacer más eficientes las entregas, algunos códigos postales, como por ejemplo los que se ubican en <b>un centro urbano</b>, son visitados <b>con mayor frecuencia</b>. </p>
    <p>Cuando son <b>zonas alejadas o de difícil acceso</b>, las paqueterías las visitan con <b>menor frecuencia</b> para realizar las recolecciones y/o entregas. Esas zonas se conocen como extendidas o remotas, y entran en <b>la cobertura extendida</b> de las paqueterías.</p>   
    <p>Para enviar a/de una zona extendida, <b>se cobra un tarifa adicional</b>.</p>
</Popup>