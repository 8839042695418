<script>

    import { afterUpdate } from 'svelte'
    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'

    import CoursesService from '../$services/courses.service'
    import Spinner from '../$components/spinner.svelte'

    afterUpdate(() => {

        const videos = document.getElementsByTagName('video')

        if(!videos || !videos.length)
            return

        for(let i=0; i<videos.length; i++)
            videos[i].currentTime = 7
    })

    let loading = false
    let courses = []

    getCoursesList()

    async function getCoursesList() {

        loading = true
        const resp = await CoursesService.getCoursesList()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        courses = resp.data
    }

    function showView(course) {
        replace(`#/course?course=${course.course}`)
    }

</script>

<style>
    .store-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
</style>

<div class="title center space-bottom">
    Bienvenido a nuestra sección de cursos
</div>
<br/>
<Spinner {loading} />

{#if !loading}
    <div class="store-items">
        {#each courses as course }
            <div on:click={() => showView(course)} class="pointer">
                <video>
                    <source src={course.urlVideo} type="video/mp4">
                    <track kind="captions"/>
                    No soportado por el navegador
                </video>
                <div class="title is-5">{ course.course } </div>
            </div>
        {/each}
    </div>
{/if}