<script>

    export let icon = null
    export let text = ''
    export let tooltip = null
    export let type = 'button'
    export let color = ''
    export let size = ''
    export let iconType = 'fa'
    export let fullwidth = false
    export let loading = false
    export let outlined = false

</script>

<button on:click type={type} class="button is-{color} is-{size} space-right" data-tooltip={tooltip} class:is-fullwidth={fullwidth} class:is-outlined={outlined} class:is-loading={loading}>
    {#if icon}
        <span class="icon"><i class="fas { iconType }-{ icon }"></i></span>
    {/if}
    {#if text}
        <span>{ text }</span>
    {/if}
</button>