<script>

    import { toast } from 'bulma-toast'
    import { accountStore } from '../stores'

    import AccountsService from '../$services/accounts.service'
    import DateMethod from '../$methods/date.methods'
    import Spinner from '../$components/spinner.svelte'

    import Value from '../$components/value.svelte'

    let loading = false
    let data = {}

    getAccountDetails()

    async function getAccountDetails() {

        data = {}

        loading = true
        const resp = await AccountsService.getAccountDetails($accountStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        data = resp.data
    }
    
</script>

<Spinner {loading} />

{#if !loading && $accountStore}
    <div class="columns">
        <div class="column">
            <Value label="Estatus del pago" value={ $accountStore.status === 'paid' || $accountStore.status === 'free'? 'Nota Credito' : 'Por Aplicar' } />
        </div>
        <div class="column">
            {#if $accountStore.status === 'paid' || $accountStore.status === 'free'}
                <Value label="Fecha de pago" value={ DateMethod.dateTimeLarge($accountStore.payment) } />
            {/if}
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Value label="Envíos realizados" value={ $accountStore.shipments.length } />
        </div>
        <div class="column">
            <Value label="Envíos" value={ `$ ${ data.shipments }` } />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Value label="Cargos" value={ `$ ${ data.charges }` } />
        </div>
        <div class="column">
            <Value label="Reembolsos" value={ `$ ${ data.refunds }` } />
        </div>
    </div>
    {#if $accountStore.prepayments.length}
        <div class="columns">
            <div class="column">
                <Value label="Pagos anticipados" value={ `$ ${ data.prepayments }` } />
            </div>
            <div class="column">
                {#each $accountStore.prepayments as prepayment}
                    <small><b>${ prepayment.amount }</b> { DateMethod.dateTimeLarge(prepayment.payment) }</small><br>
                {/each}
            </div>
        </div>
    {/if}
    {#if $accountStore.status === 'pending'}
        <div class="columns">
            <div class="column">
                <Value label="Total pendiente" value={ `$ ${ data.pendingAmount }` } />
            </div>
        </div>
    {/if}
    <div class="columns">
        <div class="column">
            <Value label="Total al corte" value={ `$ ${ data.shipments + data.charges - data.refunds }` } />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <Value label="Inicio" value={ DateMethod.dateTimeLarge($accountStore.activated) } />
        </div>
        <div class="column">
            <Value label="Corte" value={ DateMethod.dateTimeLarge($accountStore.expired) } />
        </div>
    </div>
{/if}