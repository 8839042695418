import Superagent from 'superagent'
import Storage from './storage.service'

const { HOST_API } = _CONSTANTS_

export default {
    getNotices,
}

function getNotices() {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/notices`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
               resolve(res.body)
           })
    })
}