<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import RedpackAccountCreateComponent from '../redpack/component.create.svelte'
    import EstafetaAccountCreateComponent from '../estafeta/component.create.svelte'
    import PaquetexpressAccountCreateComponent from '../paquetexpress/component.create.svelte'
    import AmpmAccountCreateComponent from '../ampm/component.create.svelte'
    import DhlAccountCreateComponent from '../dhl/component.create.svelte'
    import FedexAccountCreateComponent from '../fedex/component.create.svelte'
    import N99MinutosAccountCreateComponent from '../99minutos/component.create.svelte'
    import TreggoAccountCreateComponent from '../treggo/component.create.svelte'
    import UpsAccountCreateComponent from '../ups/component.create.svelte'

    $: show = $modalStore === 'account_create_modal'

    let provider = ''

</script>

<style>

    .items-center {
        max-height: 180px;
        margin: 8px;
    }

    .items-center img {
        height: 120px !important;
        width: 100%;
        cursor: pointer;
    }

</style>

<!-- svelte-ignore a11y-missing-attribute -->

<Modal title="Crear cuenta con paquetería" {show} >
    {#if !provider}
        <div class="columns">
            <div class="column">
                <img on:click={() => provider='Redpack'} src="images/Redpack.svg">
            </div>
            <div class="column">
                <img on:click={() => provider='Estafeta'} src="images/Estafeta.svg"> 
            </div>
            <div class="column">
                <img on:click={() => provider='Paquetexpress'} src="images/Paquetexpress.svg"> 
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <img on:click={() => provider='AMPM'} src="images/AMPM.svg"> 
            </div>
            <div class="column">
                <img on:click={() => provider='DHL'} src="images/DHL.svg"> 
            </div>
            <div class="column">
                <img on:click={() => provider='FedEx'} src="images/FedEx.svg"> 
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <img on:click={() => provider='99Minutos'} src="images/99Minutos.svg"> 
            </div>
            <div class="column">
                <img on:click={() => provider='Treggo'} src="images/Treggo.svg"> 
            </div>
            <div class="column">
                <img on:click={() => provider='UPS'} src="images/UPS.svg"> 
            </div>
        </div>
    {:else if provider === 'Redpack'}
        <RedpackAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'Estafeta'}
        <EstafetaAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'Paquetexpress'}
        <PaquetexpressAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'AMPM'}
        <AmpmAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'DHL'}
        <DhlAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'FedEx'}
        <FedexAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === '99Minutos'}
        <N99MinutosAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'Treggo'}
        <TreggoAccountCreateComponent on:close={() => provider=''} />
    {:else if provider === 'UPS'}
        <UpsAccountCreateComponent on:close={() => provider=''} />
    {/if}
</Modal>