<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'download_popup'
    
</script>


<Popup {show}>
    <div class="title">Descargar guías</div>
    <p>Selecciona los checks de las guías que deseas descargar y da click en el botón que aparece al lado izquierdo de la barra de búsqueda.</p>
</Popup>