<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import Tabs from '../$components/tabs.svelte'

    import Merchandise from './component.merchandise.svelte'
    import Stocks from '../stocks/component.stocks.svelte'
    import Graphic from '../graphics/component.stocks.total.svelte'

    $: show = $modalStore === 'merchandise_modal'

    let selected = 'merchandise'

</script>

<Modal title="Información" {show} >

    <Tabs bind:selected={ selected } options={[
        {key: 'merchandise', name: 'Información', icon: 'info'},
    ]} />

    {#if selected === 'merchandise'}
        <Merchandise />
    {:else if selected === 'stocks'}
        <Stocks />
    {:else if selected === 'graphic'}
        <Graphic />
    {/if}

</Modal>