<script>

    import { courseStore } from '../stores'

</script>

<style>
    video{
        width: 100%;
    }

    .center {
        width: 100%;
        text-align: center;
    }
</style>

<div class="center">
    <video controls>
        <source src={$courseStore.urlVideo} type="video/mp4">
        <track kind="captions"/>
        No soportado por el navegador
    </video>
</div>