<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'cancel_guides_popup'
    
</script>


<Popup {show}>
    <div class="title">Cancelar ordenes</div>
    <p>Selecciona los checks de las ordenes que deseas cancelar y da click en el botón que aparece al lado izquierdo de la barra de búsqueda.</p>
</Popup>