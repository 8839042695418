<script>

    import { toast } from 'bulma-toast'
    import { ampmsStore, ampmStore, modalStore } from '../stores'

    import Icon from '../$components/icon.svelte'
    import Spinner from '../$components/spinner.svelte'
    import InputText from '../$components/input.text.svelte'
    import InputPassword from '../$components/input.password.svelte'

    import AmpmService from '../$services/ampm.service'

    const data = new Object({
        _id: $ampmStore._id,
        alias: $ampmStore.alias,
        client: $ampmStore.client,
        password: $ampmStore.password
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await AmpmService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ampmsStore.replace(resp.data)
        ampmStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/Estafeta.svg" alt="Estafeta" style="width: 60%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <InputText bind:value={ data.alias } icon="user" label="Alias" placeholder="Alias" />
            </div>
            <div class="column">
                 <InputText bind:value={ data.client } icon="id-card" label="Usuario" placeholder="Usuario" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputPassword bind:value={ data.password } icon="key" label="Contraseña" placeholder="Contraseña" />
            </div>
        </div>

        <div class="items-end">
            <button on:click={() => modalStore.set('ampm_account_modal')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>

            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-edit"></i></span>
                <span>Editar</span>
            </button>
        </div>
    </form>
{/if}