<script>

    import { toast } from 'bulma-toast' 
    import { pickupsStore, modalStore } from '../stores'
    
    import PickupsService from '../$services/pickups.service'
    import DateMethods from '../$methods/date.methods'
    import Delete from '../$components/delete.svelte'

    import LocationDetails from '../locations/component.location.details.svelte'
    import PackageDetails from '../packages/component.package.details.svelte'

    export let pickup = null

    let loading = false

    async function deletePickup() {

        loading = true
        const resp = await PickupsService.deletePickup(pickup._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        pickupsStore.remove(pickup._id)
        toast({message: 'Recolección borrada', type: 'is-success'})
        modalStore.close()
    }


</script>

    <div class="columns">
        <div class="column">
            <div class="subtitle is-5">Dirección</div>
            <div>
                <LocationDetails location = { pickup.location } />
            </div>
        </div>
        <div class="column">
            <div class="subtitle is-5">Paquete</div>
            <div>
                <PackageDetails ipackage = { pickup.package } />
            </div>
            <div class="is-divider"></div>
            <div class="title is-5">Folio de recolección</div>
            <div class="subtitle is-5">{ pickup.folio }</div>
            <label for="" class="label">Fecha de recolección</label>
            <div>{ DateMethods.dateLarge(pickup.date) }</div>
        </div>
    </div>

    <div class="columns">
        <div class="column is-6 is-offset-6">
            <Delete on:confirm={deletePickup} {loading} />
        </div>
    </div>