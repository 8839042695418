<script>

    import { modalStore } from '../stores'

    import RecoveryComponent from './component.recovery.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'recovery_modal'

</script>

<Modal title="Recuperar contraseña" {show} >
    <RecoveryComponent />
</Modal>