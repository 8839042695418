<script>

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'

    import Tabs from '../$components/tabs.svelte'

    import Invoices from '../facturama/view.facturama.svelte'
    import Pickups from '../pickups/view.pickups.svelte'
    import Tickets from '../tickets/view.tickets.svelte'
    import Charges from '../charges/view.charges.svelte'
    import Accounts from '../accounts/view.accounts.svelte'
    import Prepaids from '../prepaids/view.prepaids.svelte'
    import Incidents from '../incidents/view.incidents.svelte'

    let selected = 'incidents'

</script>

<Menu />
<Container>

    <Tabs bind:selected={selected} options={[
        {key: 'incidents', name: 'Incidencias', icon: 'exclamation-triangle'},
        {key: 'pickups', name: 'Recolecciones', icon: 'truck'},
        {key: 'tickets', name: 'Tickets', icon: 'ticket-alt'},
        {key: 'charges', name: 'Cargos', icon: 'search-dollar'},
        {key: 'prepaids', name: 'Guías prepago', icon: 'shopping-cart'},
        {key: 'accounts', name: 'Cuentas consumo', icon: 'dollar-sign'},
        {key: 'invoices', name: 'Facturas', icon: 'file-invoice-dollar'},
    ]} />

    {#if selected === 'invoices'}
        <Invoices />
    {:else if selected === 'incidents'}
        <Incidents />
    {:else if selected === 'pickups'}
        <Pickups />
    {:else if selected === 'tickets'}
        <Tickets />
    {:else if selected === 'charges'}
        <Charges />
    {:else if selected === 'accounts'}
        <Accounts />
    {:else if selected === 'prepaids'}
        <Prepaids />
    {/if}

</Container>