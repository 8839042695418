<script>

    import { toast } from 'bulma-toast'
    import { modalStore } from '../stores'
       
    import ChargesService from '../$services/charges.service'
    
    import Select from '../$components/select.svelte'
    import Button from '../$components/button.svelte'
    import Form from '../$components/form.svelte'

    let query = {}
    let loading = false
    let element = null

    async function downloadCharges() {
    
        loading = true
        const blob = await ChargesService.downloadCharges(query)
        loading = false
    
        if(!blob)
            return toast({message: 'Error al descargar el archivo', type: 'is-danger'})
        
        element.download = `cargos.xlsx`
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

</script>

<a bind:this={element} href="/" style="display: none;">.</a>

<Form on:submit={downloadCharges} { loading } >
    
    <div class="columns">
        <div class="column">
            <Select bind:value={ query.type } label="Tipo" icon="tag" required={false} options={[
                {value: 'charge', text: 'Cargo'},
                {value: 'refund', text: 'Reembolso'},
            ]} />
        </div>
    </div>

    <div class="items-end">
        <div class="buttons">
            <Button type="button" on:click={modalStore.close} text="Cancelar" icon="ban" />
            <Button type="submit" text="Descargar" icon="download" />
        </div>
    </div>
</Form>