<script>

    import './$styles/dark.scss'
    import './$styles/global.scss'
    import './$styles/light.scss'
    
    import Router from 'svelte-spa-router'

    import { afterUpdate } from 'svelte'
    import { replace } from 'svelte-spa-router'

    import { userStore, modalStore } from './stores'
    import { routes } from './routes'

    import LocalStorage from './$services/storage.service'
    import ModalNotice from './$modal.importants/modal.notice.svelte'

    let dark = LocalStorage.getItem('dark')

    async function onLoaded(event) {

        const route = event.detail.route

        if(route === '/')
            return
    }

    async function onFailed(event) {

        const route = event.detail.route

        if(route === '/')
            return replace('#/shipments')

        if(route != '/') {
            LocalStorage.deleteItem('user')
            LocalStorage.deleteItem('access')
            replace('#/')
        }
    }

    afterUpdate(() => {

        const audios = document.getElementsByTagName('audio')

        if(!audios || !audios.length)
            return

        for(let i=0; i<audios.length; i++)
            audios[i].volume = 0.1
    })

    modalStore.set('notice_modal')

</script>

<style>
    .app-size {
        width: 100vw;
        height: 100vh;
        overflow-x: hidden;
    }
</style>

{#if $userStore}
    <!-- <Socket /> -->
    <ModalNotice />
{/if}

<div id="app" class="app-size" class:is-dark={dark}>
    <Router {routes}
        on:routeLoaded={onLoaded}
        on:conditionsFailed={onFailed}
    />
</div>