

export default {
    get
}

function get(value) {
    switch(value) {
        case '601': return 'General de Ley Personas Morales'
        case '603': return 'Personas Morales con Fines no Lucrativos'
        case '605': return 'Sueldos y Salarios e Ingresos Asimilados a Salarios'
        case '606': return 'Arrendamiento'
        case '607': return 'Régimen de Enajenación o Adquisición de Bienes'
        case '608': return 'Demás ingresos'
        case '609': return 'Consolidación'
        case '610': return 'Residentes en el Extranjero sin Establecimiento Permanente en México'
        case '611': return 'Ingresos por Dividendos (socios y accionistas)'
        case '612': return 'Personas Físicas con Actividades Empresariales y Profesionales'
        case '614': return 'Ingresos por intereses'
        case '615': return 'Régimen de los ingresos por obtención de premios'
        case '616': return 'Sin obligaciones fiscales'
        case '620': return 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'
        case '621': return 'Incorporación Fiscal'
        case '622': return 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'
        case '623': return 'Opcional para Grupos de Sociedades'
        case '624': return 'Coordinados'
        case '625': return 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'
        case '626': return 'Régimen Simplificado de Confianza'
        case '628': return 'Hidrocarburos'
        case '629': return 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'
        case '630': return 'Enajenación de acciones en bolsa de valores'
        default: return value
    }
}