import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createShopifyApp,
    getShopifyApps,
    deleteShopifyApp
}

function createShopifyApp(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/shopify/app`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getShopifyApps() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/shopify/app`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteShopifyApp(shopifyId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/shopify/app/${shopifyId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}
