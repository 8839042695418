<script>

    import Moment from 'moment'
    import { toast } from 'bulma-toast'

    import ShipmentsService from '../$services/shipments.service'
    import DateMethod from '../$methods/date.methods'

    import Spinner from '../$components/spinner.svelte'
    import Date from '../$components/date.svelte'

    export let storeId = null
    export let storeName = null

    let data = {}
    let loading = false
    let element = null

    async function getShipmentsReport() {

        data.storeId = storeId

        loading = true
        const blob = await ShipmentsService.getShipmentsReport(data)
        loading = false

        if(!blob)
            return toast({message: 'Error al descargar el reporte', type: 'is-danger'})

        let fileName = storeName? storeName : 'myshipper'

        fileName += `.${ Moment(data.dateStart).format('DD-MM-YYYY') }.${ Moment(data.dateEnd).format('DD-MM-YYYY') }.`

        element.download = `${fileName}.xlsx`
        element.href = window.URL.createObjectURL(blob)
        element.click()
        
        return await downloadGuide(index+1)
    }

</script>

<style>
    form {
        padding: 200px 32px;
    }
</style>

<a bind:this={element} href="/" style="display: none;">.</a>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={getShipmentsReport}>

        <div class="columns">
            <div class="column">
                <Date label="Fecha de inicio" bind:value={data.dateStart} showTime={false} />
            </div>
            <div class="column">
                <Date label="Fecha final" bind:value={data.dateEnd} showTime={false} />
            </div>
        </div>

        <div class="items-end">
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Descargar</span>
            </button>
        </div>
    </form>
{/if}