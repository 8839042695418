import Numeral from 'numeral'

export default {
    cash,
    percent
}

function cash(value) {
    return Numeral(value).format('$0,0.00')
}

function percent(value) {
    return Numeral(value).format('0.000%')
}