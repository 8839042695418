<script>

    import { replace } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'

    import UsersService from '../$services/users.service'

    import Form from '../$components/form.svelte'
    import Button from '../$components/button.svelte'

    export let params;

    const {hash} = params

    let loading = false
    let data = {}
    
    async function recoveryHash() {
    
        loading = true
        const resp = await UsersService.recoveryHash(hash, data)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        toast({message: 'Contraseña actualizada correctamente', type: 'is-success'})
        localStorage.clear()
        replace('#/')
    }

</script>

<div class="hero is-primary">
    <div class="hero-body">
        <div class="container">
            <div class="title">MyShipper</div>
            <div class="subtitle">Recuperar contraseña</div>
        </div>
    </div>
</div>

<div class="container">
    <div class="columns">
        <div class="column is-4 is-offset-4">
            <Form on:submit={recoveryHash} {loading} >
            
                <div class="field">
                    <label for="" class="label">Nueva contraseña</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-lock"></i></span>
                        <input bind:value={data.password} type="text" class="input" placeholder="Nueva contraseña">
                    </div>
                </div>
            
                <div class="items-end">
                    <Button type="submit" text="Guardar" icon="save" color="primary" />
                </div>
            </Form>
        </div>
    </div>
</div>