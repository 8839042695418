import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    uploadOrders,
    createOrder,
    getOrdersStore,
    updateOrder,
    updateOrderStoreShipment,
    updateOrderReferences,
    deleteOrder
}

function uploadOrders(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/orders/upload`)
            .set('access', Storage.getItem('access'))
            .attach('ordersFile', data.file)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createOrder(data, query) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/orders`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getOrdersStore(storeId, query) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/orders/store/${storeId}`)
            .set('access', Storage.getItem('access'))
            .query(query)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateOrder(orderId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/orders/${orderId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateOrderStoreShipment(orderId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/orders/${orderId}/store/shipment`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateOrderReferences(orderId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/orders/${orderId}/references`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function deleteOrder(orderId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/orders/${orderId}`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}