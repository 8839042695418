<script>

    import DateMethod from '../$methods/date.methods.js'

    export let prepaid = null

</script>

<div class="table-container">
    <table class="table is-fullwidth is-hoverable">
        <tbody>
            <tr>
                <th>Proveedor</th>
                <td>{ prepaid.provider }</td>
            </tr>

            <tr>
                <th>Servicio</th>
                <td>{ prepaid.service }</td>
            </tr>

            <tr>
                <th>Peso minímo</th>
                <td>{ prepaid.volumetricMin }Kg</td>
            </tr>

            <tr>
                <th>Peso máximo</th>
                <td>{ prepaid.volumetricMax }Kg</td>
            </tr>

            <tr>
                <th>Guías adquiridas</th>
                <td>{ prepaid.shipmentsTotal }</td>
            </tr>

            <tr>
                <th>Guías usadas</th>
                <td>{ prepaid.shipments.length }</td>
            </tr>

            <tr>
                <th>Guías disponibles</th>
                <td>{ prepaid.shipmentsTotal - prepaid.shipments.length }</td>
            </tr>

            <tr>
                <th>Fecha de registro</th>
                <td>{ DateMethod.dateTimeLarge(prepaid.created) }</td>
            </tr>

            <tr>
                <th>Fecha de expiración</th>
                <td>{ DateMethod.dateTimeLarge(prepaid.expired) }</td>
            </tr>

        </tbody>
    </table>
</div>