<script>

    import { toast } from 'bulma-toast'
    import { ticketStore, ticketsStore, modalStore } from '../stores'

    import TicketsService from '../$services/tickets.service'
    import DateMethod from '../$methods/date.methods.js'
    
    import Delete from '../$components/delete.svelte'
    import Value from '../$components/value.svelte'

    import LocationDetails from '../locations/component.location.details.svelte'

    let loading = false 

    async function deleteTicket() {

        loading = true
        const resp = await TicketsService.deleteTicket($ticketStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        ticketsStore.remove($ticketStore._id)
        toast({message: 'Ticket borrado', type: 'is-success'})
        modalStore.close()
    }

</script>

<div class="title is-3">Ticket</div>
<div class="columns">
    <div class="column">
        <Value label="Folio" value={ $ticketStore.code } />
        <br>
        <Value label="Estatus" value={ $ticketStore.status } />
        <br>
        <Value label="Fecha" value={ DateMethod.dateTimeLarge($ticketStore.created) } />
        <div class="items-end space-top">
            <Delete on:confirm={deleteTicket} fullwidth={false} {loading} />
        </div>
    </div>
    <div class="column">
        <Value label="Información" value={ $ticketStore.message || 'Sin datos extras' } />
    </div>
</div>
<div class="is-divider"></div>
<div class="columns">
    <div class="column">
        <div>
            <Value label="Proveedor" value={ $ticketStore.shipment.provider } />
            <br>
            <Value label="Servicio" value={ $ticketStore.shipment.service } />
            <br>
            <Value label="Guia" value={ $ticketStore.shipment.guide } />
            <br>
            <Value label="Código" value={ $ticketStore.shipment.code } />
            <br>
            <Value label="Fecha" value={ DateMethod.dateTimeLarge($ticketStore.shipment.created) } />
        </div>
    </div>
    <div class="column">
        <LocationDetails location = { $ticketStore.shipment.order.delivery } />
    </div>
</div>

{#if $ticketStore.response}
    <div class="is-divider"></div>
    <div class="columns">
        <div class="column">
            <Value label="Último comentario" value={ $ticketStore.response } />
        </div>
    </div>
{/if}

{#if $ticketStore.history && $ticketStore.history.length}
    <div class="is-divider"></div>
    <div class="subtitle is-5">Historial</div>
    {#each $ticketStore.history as item}
        <div class="columns">
            <div class="column">
                <Value label="Respuesta" value={ item.response } />
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <Value value={ DateMethod.dateLarge(item.created) } />
                </div>
            </div>
        </div>
    {/each}
{/if}