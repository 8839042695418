<script>

    import { toast } from 'bulma-toast'
    import { createEventDispatcher } from 'svelte'
    import { woocommercesStore, modalStore } from '../stores'

    import WoocommercesService from '../$services/woocommerce.service'
    import Spinner from '../$components/spinner.svelte'

    const dispatch = createEventDispatcher()

    let data = {}
    let loading = false

    async function createWoocommerce() {

        loading = true
        const resp = await WoocommercesService.createWoocommerce(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        woocommercesStore.append(resp.data)
        
        toast({message: 'Tienda woocommerce integrada', type: 'is-success'})
        modalStore.close()
        data = {}
    }

</script>

<style>
    .items-center {
        max-height: 180px;
        margin: 8px;
    }

    .items-center img {
        height: 120px !important;
        width: 100%;
    }
</style>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createWoocommerce}>
        <div class="items-center">
            <img src="images/woocommerce.svg" alt="woocommerce">
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Consumer key</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.consumerKey} type="text" class="input" placeholder="Consumer key" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Consumer secret</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.consumerSecret} type="text" class="input" placeholder="Consumer secret" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Store name</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-store"></i></span>
                        <input bind:value={data.storeName} type="text" class="input" placeholder="Store name" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Store domain</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-store"></i></span>
                        <input bind:value={data.storeDomain} type="text" class="input" placeholder="Store domain" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="items-end">
            <button on:click={() => dispatch('close')} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Integrar</span>
            </button>
        </div>
    </form>
{/if}