<script>

    import { toast } from 'bulma-toast'
    import { userStore } from '../stores'

    import UsersService from '../$services/users.service'

    import InputPassword from '../$components/input.password.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Value from '../$components/value.svelte'

    let data = {
        password: $userStore.password
    }
    let loading = false
    let showUpdate = false

    async function updateUser() {

        loading = true
        const resp = await UsersService.updateUser(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        userStore.set(resp.data)

        toast({message: 'Información actualizada', type: 'is-success'})
        showUpdate = false
    }

</script>

<Spinner {loading} />

{#if !showUpdate}
    <div class="title">Contraseña</div>
    <div class="columns">
        <div class="column">
            <Value label="Contraseña" value="••••••••••••••••" />
        </div>
    </div>
    <div class="items-end">
        <button on:click={() => showUpdate = true } class="button">
            <span class="icon"><i class="fas fa-edit"></i></span>
            <span>Editar</span>
        </button>
    </div>
{/if}

{#if showUpdate && !loading}
    <div class="title">Contraseña</div>
    <form on:submit|preventDefault={updateUser}>
        <div class="columns">
            <div class="column">
                <InputPassword bind:value={ data.password } icon="key" label="Contraseña" placeholder="Nueva contraseña" />
            </div>
        </div>
        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => showUpdate = false } type="button" class="button space-right">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary">
                        <span class="icon"><i class="fas fa-save"></i></span>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}