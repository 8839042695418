<script>

    import { toast } from 'bulma-toast'
    import { locationsStore, modalStore } from '../stores'

    import LocationsService from '../$services/locations.service'
    import Delete from '../$components/delete.svelte'
    import Button from '../$components/button.svelte'

    import LocationDetails from '../locations/component.location.details.svelte'

    export let location = null

    let loading = false

    async function deleteLocation() {

        loading = true
        const resp = await LocationsService.deleteLocation(location._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        locationsStore.remove(location._id)
        modalStore.close()
        toast({message: 'Dirección borrada', type: 'is-success'})
    }

</script>

<LocationDetails location = { location } />
<div class="columns">
    <div class="column">
        <Delete on:confirm={deleteLocation} {loading} />
    </div>
    <div class="column">
        <Button on:click={ () => modalStore.set('location_update_modal') } text="Editar" icon="edit" color="primary" fullwidth={ true }/>
    </div>
</div>