<script>

    import { toast } from 'bulma-toast'
    import { foliosStore, modalStore } from '../stores'

    import FoliosService from '../$services/folios.service'

    import Spinner from '../$components/spinner.svelte'

    export let redpack = null

    let data = {
        provider: 'Redpack',
        credentialId: redpack._id,
        foliosFormat: 'range'
    }
    
    let loading = false

    async function createFolio() {

        loading = true
        const resp = await FoliosService.createFolio(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        foliosStore.append(resp.data)
        toast({message: resp.data.total+' folios creados', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createFolio}>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Selecciona un servicio</label>
                    <div class="control">
                        <div class="select">
                            <select bind:value={data.service} required>
                                <option value="">Selecciona un servicio</option>
                                <option value="EXPRESS">Express</option>
                                <option value="ECOEXPRESS">Ecoexpress</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Inicio de folios</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-stream"></i></span>
                        <input bind:value={data.foliosStart} type="number"  class="input" placeholder="Inicio de folios" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Fin de folios</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-stream"></i></span>
                        <input bind:value={data.foliosFinish}  type="number"  class="input" placeholder="Fin de folios" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.close()} type="button" class="button">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary">
                        <span class="icon"><i class="fas fa-save"></i></span>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}