import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getTracings,
}

function getTracings(query) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/tracings`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
           })
    })
}