<script>

    import { modalStore } from '../stores'

    import UsersReferidos from './component.referidos.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'referidos_modal'

</script>

<Modal title="Referidos" {show}>
    <UsersReferidos />
</Modal>