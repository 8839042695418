import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getAccounts,
    getAccountDetails,
    getShipmentsReport,
    getChargesReport
}

function getAccounts(query) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/accounts`)
           .set('access', Storage.getItem('access'))
           .query(query)
           .end((err, res) => {
               resolve(res.body)
           })
    })
}

function getAccountDetails(accountId) {
    return new Promise((resolve, reject) => {
        Superagent
           .get(`${HOST_API}/accounts/${accountId}/details`)
           .set('access', Storage.getItem('access'))
           .end((err, res) => {
               resolve(res.body)
           })
    })
}

function getShipmentsReport(accountId) {
    return new Promise((resolve, reject) => {

        Superagent
            .get(`${HOST_API}/accounts/${accountId}/shipments/report`)
            .set('access', Storage.getItem('access'))
            .responseType('blob')
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}

function getChargesReport(accountId) {
    return new Promise((resolve, reject) => {

        Superagent
            .get(`${HOST_API}/accounts/${accountId}/charges/report`)
            .set('access', Storage.getItem('access'))
            .responseType('blob')
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}