<script>

    import { createEventDispatcher } from 'svelte'

    import DateRange from '../$components/date.range.svelte'
    import Select from '../$components/select.svelte'
    import SelectProvider from '../$components/select.providers.svelte'
    import Form from '../$components/form.svelte'
    import Button from '../$components/button.svelte'

    const dispatch = createEventDispatcher()

    export let query = {
        provider: ""
    }

    function onSubmit() {
        dispatch('submit', query)
    }

</script>

<div class="title">Filtrar datos</div>

<Form on:submit={onSubmit}>

    <SelectProvider on:select={onSubmit} bind:provider={query.provider} />

    <DateRange bind:dateStart={query.dateStart} bind:dateEnd={query.dateEnd} showTime={false} />

    <Select bind:value={query.rangeType} label="Agrupar" icon="calendar-check" options={[
        {value: 'days', text: 'Días'},
        {value: 'months', text: 'Meses'},
    ]} />

    <div class="items-end">
        <Button type="submit" text="Buscar" icon="search" color="primary" fullwidth />
    </div>

</Form>