<script>

    import { redpackStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import RedpackFoliosComponent from '../folios/component.redpack.folios.svelte'

    $: show = $modalStore === 'redpack_folios_modal'
    
</script>


<Modal title="Folios" {show}>
    <RedpackFoliosComponent redpack={$redpackStore} />
</Modal>