<script>

    import { toast } from 'bulma-toast'
    import { upssStore, modalStore } from '../stores'

    import UpsService from '../$services/ups.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await UpsService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        upssStore.set(resp.data)
    }

    async function updateCredentialActive(ups) {
    
        loading = true
        const resp = await UpsService.updateCredentialActive(ups._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<style>
    img {
        width: 20%;
    }
</style>

<Spinner {loading} />

{#if !loading && $upssStore.length}
    <div class="subtitle is-5"><b>Cuentas con UPS</b></div>
    <div class="credential-items">
        {#each $upssStore as ups}
        <div class="credential-item">
            <img class="image" src="images/UPS.svg" alt="UPS">
            <div class="title is-5">{ ups.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(ups.created) }</div>
            <div class="items-space-around">
                <div class="field" style="margin: 0;">
                    <input on:click={() => updateCredentialActive(ups)} bind:checked={ups.active} class="is-checkradio is-primary" id="{ups._id}" type="checkbox">
                    <label for="{ups._id}"></label>
                </div>
                <button on:click={() => modalStore.open('ups', 'account_modal', ups)} class="button">
                    <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                </button>
            </div>
        </div>
        {/each}
    </div>
{/if}