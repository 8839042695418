<script>

    import { toast } from 'bulma-toast'
    import { treggosStore, treggoStore, modalStore } from '../stores'

    import Spinner from '../$components/spinner.svelte'
    import treggoService from '../$services/treggo.service'

    const data = new Object({
        _id: $treggoStore._id,
        alias: $treggoStore.alias,
        email: $treggoStore.email,
        secret: $treggoStore.secret
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await treggoService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        treggosStore.replace(resp.data)
        treggoStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/Treggo.svg" alt="Treggo" style="width: 20%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.alias}  type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Email</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-email"></i></span>
                        <input bind:value={data.email}  type="text"  class="input" placeholder="Email" required>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Secret</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.secret}  type="text"  class="input" placeholder="Secret" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.set('treggo_account_modal')} type="button" class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary is-fullwidth">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}