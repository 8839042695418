<script>

    import { upsStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import UpsAccountComponent from './component.ups.account.svelte'

    $: show = $modalStore === 'ups_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <UpsAccountComponent ups={$upsStore} />
</Modal>