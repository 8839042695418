<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import PickupCreateComponent from './component.create.svelte'

    $: show = $modalStore === 'pickup_create_modal'

</script>

<Modal title="Agregar recolección" {show} >
    <PickupCreateComponent />
</Modal>