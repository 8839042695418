<script>

    import { facturamaClientStore, modalStore } from '../stores'
    
    import EnumMethods from '../$methods/enum.methods'

</script>

<div class="title">Datos de facturación</div>

<div class="table-container">
    <table class="table is-fullwidth">
        <tbody>
            <tr>
                <th>Nombre</th>
                <td>{ $facturamaClientStore.name }</td>
            </tr>
            <tr>
                <th>RFC</th>
                <td>{ $facturamaClientStore.rfc }</td>
            </tr>
            <tr>
                <th>Régimen</th>
                <td>{ EnumMethods.get($facturamaClientStore.fiscalRegime) }</td>
            </tr>
            <tr>
                <th>Código postal</th>
                <td>{ $facturamaClientStore.zipcode }</td>
            </tr>
        </tbody>
    </table>
</div>
<button on:click={() => modalStore.set('facturama_update_client_modal')} class="button">
    <span class="icon"><i class="fas fa-sync-alt"></i></span>
    <span>Actualizar datos</span>
</button>