<script>

    import { configStore, modalStore } from '../stores'

    import ConfigWoocommerceComponent from './component.config.woocommerce.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'woocommerce_config_modal'

</script>

<Modal title="Configuración woocommerce" {show}>
    {#if $configStore}
        <ConfigWoocommerceComponent woocommerceConfig={$configStore.woocommerce} />
    {/if}
</Modal>