<script>

    import { shipmentStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import ShipmentComponent from './component.shipment.svelte'

    $: show = $modalStore === 'shipment_modal'

</script>

<Modal title="Información" {show}>
    <ShipmentComponent shipment={$shipmentStore} />
</Modal>