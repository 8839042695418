<script>

    import SelectCountryState from '../$components/select.country.state.svelte'
    import Zipcodes from './component.zipcodes.svelte'
    import InputText from '../$components/input.text.svelte'

    export let label = ''
    export let location = {}
    export let showMexico = true
    export let changeCountry = true

</script>

<div class="title is-5">{ label }</div>

<SelectCountryState bind:country={location.country} bind:state={location.state} bind:changeCountry {showMexico} />

<div class="columns">
    <div class="column">
        <InputText bind:value={ location.city } label="Ciudad"  placeholder="Ciudad" required={false} />
    </div>
    <div class="column">
        <div class="field">
            <label for="" class="label">Código postal</label>
            <div class="control">
                <Zipcodes bind:selected={location} details={false} clear={false} autocomplete={!changeCountry} />
            </div>
        </div>
    </div>
</div>