<script>

    import { toast } from 'bulma-toast'
    import { prepaidsStore, modalStore } from '../stores'
    
    import PrepaidsService from '../$services/prepaids.service'
    import DateMethod from '../$methods/date.methods.js'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    export let userId = null

    let loading = false

    getPrepaids()

    async function getPrepaids() {

        loading = true
        const resp = await PrepaidsService.getPrepaids(userId, 'user')
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        prepaidsStore.set(resp.data)
    }

</script>

<Spinner {loading} />
<Message show={!loading && !$prepaidsStore.length} />

{#if !loading && $prepaidsStore.length}
    <div class="table-container">
        <table class="table is-fullwidth">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-truck"></i>
                            </span>
                            <span>Proveedor</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-truck"></i>
                            </span>
                            <span>Servicio</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-weight"></i>
                            </span>
                            <span>Peso</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-check"></i>
                            </span>
                            <span>Guías compradas</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-clipboard-check"></i>
                            </span>
                            <span>Guías disponibles</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha de registro</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $prepaidsStore as prepaid, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ prepaid.provider }</td>
                        <td>{ prepaid.service }</td>
                        <td>{ prepaid.volumetricMin }Kg a { prepaid.volumetricMax }Kg</td>
                        <td>{ prepaid.shipmentsTotal }</td>
                        <td>{ prepaid.shipmentsTotal - prepaid.shipments.length }</td>
                        <td>{ DateMethod.dateTimeLarge(prepaid.created) }</td>
                        <td>
                            <button on:click={() => modalStore.open('prepaid', 'modal', prepaid)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}