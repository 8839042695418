<script>

    import { toast } from 'bulma-toast'
    import { redpacksStore, modalStore } from '../stores'

    import RepackService from '../$services/redpack.service'

    import Spinner from '../$components/spinner.svelte'
    import DateMethod from '../$methods/date.methods.js'

    let loading = false

    getCredentials()

    async function getCredentials() {

        loading = true
        const resp = await RepackService.getCredentials(null)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        redpacksStore.set(resp.data)
    }

    async function updateCredentialActive(redpack) {
    
        loading = true
        const resp = await RepackService.updateCredentialActive(redpack._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        getCredentials()
    }

</script>

<Spinner {loading} />

{#if !loading && $redpacksStore.length}
    <div class="subtitle is-5"><b>Cuentas con Redpack</b></div>
    <div class="credential-items">
        {#each $redpacksStore as redpack}
        <div class="credential-item">
            <img class="image" src="images/Redpack.svg" alt="Redpack">
            <div class="title is-5">{ redpack.alias }</div>
            <div class="subtitle is-6">{ DateMethod.dateTimeLarge(redpack.created) }</div>
            <div class="columns">
                    <div class="column center">
                        <div class="field" style="margin: 0;">
                            <input on:click={() => updateCredentialActive(redpack)} bind:checked={redpack.active} class="is-checkradio is-primary" id="{redpack._id}" type="checkbox">
                            <label for="{redpack._id}"></label>
                        </div>
                    </div>
                    <div class="column">
                        <button on:click={() => modalStore.open('redpack', 'account_modal', redpack)} class="button is-fullwidth">
                            <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                        </button>
                    </div>
                    <div class="column">
                        <button on:click={() => modalStore.open('redpack', 'folios_modal', redpack)} class="button is-fullwidth">
                            <span class="icon"><i class="fas fa-plus"></i></span>
                            <span>Agregar folios</span>
                        </button>
                    </div>
            </div>
        </div>
        {/each}
    </div>
{/if}