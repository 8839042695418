<script>

    import { toast } from 'bulma-toast'

    import ShipmentsService from '../$services/shipments.service'
    import ConfigsService from '../$services/configs.service'
    import Container from '../$layouts/container.layout.svelte'

    import TrackingComponent from './component.tracking.svelte'

    export let params = {}

    let loading = false
    let shipment = null
    let configTracking = {}

    if(params.shipmentCode)
        getShipmentCode()

    async function getShipmentCode() {

        loading = true
        const resp = await ShipmentsService.getShipmentCode(params.shipmentCode)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipment = resp.data

        getConfigTracking()
    }

    async function getConfigTracking() {
    
        loading = true
        const resp = await ConfigsService.getConfigTracking(shipment.userId)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        configTracking = resp.data || {}
    }

</script>

{#if configTracking.brand}
    <div class="center">
        <img src="{configTracking.brand}?random={Math.random()}" alt="Brand">
    </div>
{:else}
    <div class="hero is-primary">
        <div class="hero-body">
            <div class="container">
                <div class="title">MyShipper</div>
                <div class="subtitle">Rastreo</div>
            </div>
        </div>
    </div>
{/if}

<Container>
    {#if !loading}
        <div class="columns">
            <div class="column">
                <div class="columns">
                    <div class="column">
                        <div class="items-end">
                            {#if configTracking.facebook}
                                <a href="{configTracking.facebook}" target="_blank" style="color: #333;">
                                    <span class="icon is-large"><i class="fab fa-2x fa-facebook-square"></i></span>
                                </a>
                            {/if}
                            {#if configTracking.instagram}
                                <a href="{configTracking.instagram}" target="_blank" style="color: #333;">
                                    <span class="icon is-large"><i class="fab fa-2x fa-instagram"></i></span>
                                </a>
                            {/if}
                            {#if configTracking.pinterest}
                                <a href="{configTracking.pinterest}" target="_blank" style="color: #333;">
                                    <span class="icon is-large"><i class="fab fa-2x fa-pinterest"></i></span>
                                </a>
                            {/if}
                            {#if configTracking.webpage}
                                <a href="{configTracking.webpage}" target="_blank" style="color: #333;">
                                    <span class="icon is-large"><i class="fas fa-2x fa-globe"></i></span>
                                </a>
                            {/if}
                        </div>
                    </div>
                </div>

                <div class="title is-4">Origen</div>
                <div class="subtitle is-5">
                    {shipment.order.pickup.zipcode}, {shipment.order.pickup.state}, {shipment.order.pickup.city}
                </div>
                <hr>
                <div class="title is-4">Destino</div>
                <div class="subtitle is-5">
                    {shipment.order.delivery.zipcode}, {shipment.order.delivery.state}, {shipment.order.delivery.city}
                </div>
                <hr>
                <div class="title is-4">Guía</div>
                <div class="subtitle is-5">{shipment.guide}</div>
                <hr>
                <div class="title is-4">{shipment.provider}</div>
                <div class="subtitle is-5">{shipment.service}</div>
                <hr>
                <TrackingComponent shipmentId={shipment._id}/>
                <hr>
                <div>{configTracking.message || ''}</div>

                {#if configTracking.footer}
                    <br>
                    <div class="center">
                        <img src="{configTracking.footer}?random={Math.random()}" alt="Footer">
                    </div>
                {/if}

            </div>
            <div class="column">
                {#if configTracking.banner}
                    <div class="center">
                        <a href="{configTracking.bannerURL}" target="_blank">
                            <img src="{configTracking.banner}?random={Math.random()}" alt="Banner">
                        </a>
                    </div>
                {/if}
            </div>
        </div>
    {/if}
</Container>