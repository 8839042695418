<script>

    import { toast } from 'bulma-toast'
    import { merchandiseStore, merchandisesStore, modalStore } from '../stores'

    import MerchandisesService from '../$services/merchandises.service'
    
    import Button from '../$components/button.svelte'
    import Delete from '../$components/delete.svelte'
    import Value from '../$components/value.svelte'

    let loading = false
   
    async function deleteMerchandise() {
    
        loading = true
        const resp = await MerchandisesService.deleteMerchandise($merchandiseStore._id)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        merchandisesStore.remove($merchandiseStore._id)
        modalStore.close()
    }

</script>

{#if $merchandiseStore.package}
    <div class="columns">
        <div class="column">
            <Value label="Paquete vinculado" value={$merchandiseStore.package.alias} />
        </div>
    </div>
{/if}

<div class="columns">
    <div class="column">
        <Value label="Alias" value={$merchandiseStore.alias} />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Nombre" value={$merchandiseStore.name || 'Sin información'} />
    </div>
    <div class="column">
        <Value label="Marca" value={$merchandiseStore.brand || 'Sin información'} />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Descripción" value={$merchandiseStore.description} />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Producto" value={$merchandiseStore.product.name} />
    </div>
    <div class="column">
        <Value label="Código" value={$merchandiseStore.product.code} />
    </div>
    <div class="column">
        <Value label="Unidad" value={$merchandiseStore.unit.name} />
    </div>
    <div class="column">
        <Value label="Código" value={$merchandiseStore.unit.code} />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Items" value={$merchandiseStore.items} />
    </div>
    <div class="column">
        <Value label="Valor" value={'$'+ $merchandiseStore.value} />
    </div>
    <div class="column">
        <Value label="Peso" value={$merchandiseStore.weight +'KG'} />
    </div>
    <div class="column"></div>
</div>
<div class="columns">
    <div class="column">
        <Delete on:confirm={deleteMerchandise} fullwidth={true} />
    </div>
    <div class="column">
        <Button on:click={() => modalStore.set('merchandise_update_modal')} text="Editar" icon="edit" color="primary" fullwidth={true} />
    </div>
</div>