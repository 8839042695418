<script>

    import { modalStore } from '../stores'

    import ConfigEmailComponent from './component.config.email.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'email_modal'

</script>

<Modal title="" {show}>
    <ConfigEmailComponent />
</Modal>