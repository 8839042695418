<script>

    import Incidents from '../incidents/component.incidents.svelte'
    import Incident from '../incidents/component.incident.svelte'
    import Tracings from '../tracings/component.tracings.svelte'
    
    import Modal from '../$components/modal.id.svelte'

</script>

<Modal id="incident_modal" title="Información de la incidencia">
    <Incident />
    <hr>
    <Tracings />
</Modal>

<Incidents />