<script>

    import { userStore } from '../stores'
    const appId = '5211'

</script>

<div class="center">
    <a href="https://www.tiendanube.com/apps/{ appId }/authorize?state={ $userStore._id }">
        <img src="images/tiendanube.svg" alt="Nube">
        <div class="title">Integrar Tienda Nube</div>
    </a>
</div>