<script>

    import { redpackStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import RedpackAccountComponent from './component.redpack.account.svelte'

    $: show = $modalStore === 'redpack_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <RedpackAccountComponent redpack={$redpackStore} />
</Modal>