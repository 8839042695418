<script>

    import { toast } from 'bulma-toast'
    import { packagesStore } from '../stores'

    import PackagesService from '../$services/packages.service'

    import Select from '../$components/select.svelte'

    export let packageId = ''
    export let ipackage = null

    let loading = false

    getPackages()

    async function getPackages() {

        loading = true
        const resp = await PackagesService.getPackages()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        packagesStore.set(resp.data)
    }

    function setPackage() {
        ipackage = $packagesStore.find(pack => pack._id === packageId)
    }

</script>

{#if $packagesStore.length}
    <Select 
        bind:value={ packageId }
        on:select={ setPackage}
        text="Vincular paquete"
        label="Lista de paquetes"
        icon="box-open"
        required={ false }
        options={$packagesStore.map(pack => {
            return {value: pack._id, text: pack.alias}
        })}
    />
{/if}