<script>

    import { toast } from 'bulma-toast'
    import { locationsStore, modalStore } from '../stores'
    
    import LocationsService from '../$services/locations.service'

    import Pagination from '../$components/pagination.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'
    import Button from '../$components/button.svelte'
    import Input from '../$components/input.text.svelte'

    let loading = false
    let query = {}
    let metadata = {}

    getLocations()

    async function getLocations() {

        loading = true
        const resp = await LocationsService.getLocations(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        locationsStore.set(resp.data.locations)
        metadata = resp.data.metadata
    }

    async function updateLocationDefault(location) {
    
        loading = true
        const resp = await LocationsService.updateLocationDefault(location._id)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        getLocations()
    }

</script>

<div class="columns">
    <div class="column">
        <Input on:enter={ getLocations } bind:value={ query.find } placeholder="Buscar" icon="search" />
    </div>
    <div class="column is-narrow">
        <Button on:click={ () => modalStore.set('location_create_modal') } text="Agregar" icon="plus"/>
    </div>
</div>

<Spinner {loading} />
<Message show={!loading && !$locationsStore.length} />

{#if !loading && $locationsStore.length}
    <div class="items-end">
        <Pagination bind:metadata bind:page={query.page} on:change={ getLocations } />
    </div>
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-tag"></i>
                            </span>
                            <span>Principal</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-address-book"></i>
                            </span>
                            <span>Alias</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-user"></i>
                            </span>
                            <span>Contacto</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-phone"></i>
                            </span>
                            <span>Teléfono</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-map-marker-alt"></i>
                            </span>
                            <span>CP</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-city"></i>
                            </span>
                            <span>Ciudad</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-map-marker-alt"></i>
                            </span>
                            <span>Estado</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $locationsStore as location, index}
                    <tr>
                        <td>
                            <div class="field">
                                <input on:click={() => updateLocationDefault(location)} bind:checked={location.default} class="is-checkradio is-primary" id="{location._id}" type="checkbox">
                                <label for="{location._id}"></label>
                            </div>
                        </td>
                        <td>
                            { (index+1) + (100 * (query.page || 0)) }. { location.alias }
                        </td>
                        <td>{ location.contact }</td>
                        <td>{ location.phone }</td>
                        <td>{ location.zipcode }</td>
                        <td>{ location.city }</td>
                        <td>{ location.state }</td>
                        <td>
                            <Button on:click={ () => modalStore.open('location', 'modal', location) }  icon="ellipsis-v"/>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
    <div class="items-end">
        <Pagination bind:metadata bind:page={query.page} on:change={ getLocations } />
    </div>
{/if}