<script>

    import { toast } from 'bulma-toast'
    import { afterUpdate, onDestroy } from 'svelte'
    import { userStore, userChatStore, socketStore, socketEventsStore } from '../stores'

    import ChatsService from '../$services/chats.service'
    import TextsService from '../$services/texts.service'

    import InputText from '../$components/input.text.svelte'
    import Spinner from '../$components/spinner.svelte'
    import DateMethods from '../$methods/date.methods'

    $: $userChatStore && findChat()

    const socket = $socketStore

    let loading = false
    let data = {}
    let query = {
        page: 0
    }

    let eventName = null
    let chat = null
    let texts = []

    let chatContent = null

	afterUpdate(() => {

        if(chatContent)
		    chatContent.scrollTo(0, chatContent.scrollHeight)
	})

    findChat()

    async function findChat() {

        if(!$userChatStore)
            return

        chat = null
        texts = []
        query.page = 0

        loading = true
        const resp = await ChatsService.findChat($userChatStore._id)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(eventName) {
            socket.removeAllListeners(eventName)
            socketEventsStore.update(events => {
                return events.filter(event => event != eventName)
            })
        }

        chat = resp.data

        getTexts()
        socketConfig()
    }

    async function getTexts() {

        loading = true
        const resp = await TextsService.getTexts(chat._id, query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        texts = resp.data.texts
    }

    async function setMessage() {

        data.chatId = chat._id
        data.userId = $userStore._id
        data.page = query.page

        socket.emit('ChatMessage', data)

        data.message = null
    }

    function socketConfig() {

        eventName = `ChatMessage_${ chat._id }`

        const eventSet = $socketEventsStore.find(item => item === eventName)

        if(eventSet)
            return

        socketEventsStore.append(eventName)

        socket.on(eventName, arg => {
            texts = texts.concat(arg)

            if(arg.userId != $userStore._id)
                socket.emit('ChatConfirm', arg)
        })
    }

    onDestroy(() => {
        if(eventName) {
            socket.removeAllListeners(eventName)
            socketEventsStore.update(events => {
                return events.filter(event => event != eventName)
            })
        }
    })

</script>

<style>
    .chat-content {
        height: 62vh;
        overflow-y: auto;
        background: url('/images/tile.png');
        background-repeat: repeat;
        padding: 5% 10%;
    }

    .message {
        background: cornflowerblue;
        border-radius: 8px;
        padding: 8px;
        margin-bottom: 2px;
        color: white;
    }

    .message.light {
        background: #555;
    }

    .message.info {
        background: cornflowerblue;
    }

    .date {
        color: white;
        font-size: 10px;
    }
</style>

{#if chat}

    <div class="items-center">
        <div class="title">{ $userChatStore.alias || $userChatStore.name }</div>
    </div>

    <Spinner {loading} />

    {#if !loading}
        <div bind:this={chatContent} class="chat-content">
            {#each texts as text}
                <div class:items-end={ text.userId === $userStore._id } class:items-start={ text.userId !== $userStore._id } >
                    <div class="message" class:light={ text.userId !== $userStore._id } class:info={ text.userId === $userStore._id } >
                        <div style="padding-right: 36px;">{ text.message }</div>
                        <div class="items-end date">{ DateMethods.timeAgo(text.created) }</div>
                    </div>
                </div>
            {/each}
        </div>
        <div class="space-top">
            <InputText bind:value={data.message} on:enter={setMessage} icon="comment" placeholder="Mensaje" />
        </div>
    {/if}
{:else}
    <div class="items-center">
        <img src="images/talking.png" alt="Talking">
    </div>
{/if}