<script>

    import Menu from '../$layouts/menu.layout.svelte'
    import Container from '../$layouts/container.layout.svelte'
    import PostsComponent from './component.posts.svelte'
    import PostCreateModal from './modal.create.svelte'
    import UserProfileComponent from '../users/component.profile.svelte'
    import UserProfielUpdateModal from '../users/modal.profile.update.svelte'
    import PostsBestsComponent from './component.posts.bests.svelte'

</script>

<Menu />

<Container>
    <div class="columns">
        <div class="column is-8">
            <UserProfileComponent />
            <PostsComponent/>
        </div>
        <div class="column">
            <PostsBestsComponent />
        </div>
    </div>
</Container>

<PostCreateModal/>
<UserProfielUpdateModal />