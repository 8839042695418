<script>

    import { fade } from 'svelte/transition'

    import Switch from '../$components/switch.svelte'

    export let label;
    export let icon;
    export let field;

    let showFieldProp = isOption()

    function isOption() {
        const options = ['name','company','email','phone','zip','province','city','address1','address2']
        return options.indexOf(field.prop) < 0
    }

</script>

<div class="subtitle is-5">{ label }</div>
<div class="columns">
    <div class="column">
        {#if !showFieldProp}
            <div class="field">
                <label for="" class="label">Campo por defecto</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-{icon}"></i></span>
                    <div class="select">
                        <select bind:value={field.prop} required>
                            <option value="">Campo por defecto</option>
                            <option value="name">Nombre</option>
                            <option value="company">Compañia</option>
                            <option value="email">Email</option>
                            <option value="phone">Teléfono</option>
                            <option value="zip">Código postal</option>
                            <option value="province">Provincia</option>
                            <option value="city">Ciudad</option>
                            <option value="address1">Dirección 1</option>
                            <option value="address2">Dirección 2</option>
                        </select>
                    </div>
                </div>
            </div>
        {/if}
        {#if showFieldProp}
            <div class="field" in:fade>
                <label for="" class="label">Nombre del campo o valor fijo</label>
                <div class="control has-icons-left">
                    <span class="icon"><i class="fas fa-asterisk"></i></span>
                    <input bind:value={field.prop} type="text" class="input" placeholder="Nombre del campo o valor fijo">
                </div>
            </div>
        {/if}
        <div class="field">
            <Switch bind:checked={showFieldProp} label="Campo personalizado" />
        </div>
    </div>
    <div class="column">
        <div class="field">
            <label for="" class="label">Nombre del campo o valor fijo</label>
            <div class="control has-icons-left">
                <span class="icon"><i class="fas fa-asterisk"></i></span>
                <input bind:value={field.value} type="text" class="input" placeholder="Nombre del campo o valor fijo">
            </div>
        </div>
    </div>
</div>