import Superagent from 'superagent'
import Storage from './storage.service'

const { HOST_API } = _CONSTANTS_

export default {
    getCredentials,
    createCredential,
    updateCredential,
    updateCredentialActive
}

function getCredentials() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/paquetexpress`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function createCredential(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/paquetexpress`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function updateCredential(paquetexpressId, data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/paquetexpress/${paquetexpressId}`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                resolve(res.body)
            })
    })
}

function updateCredentialActive(paquetexpressId) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/paquetexpress/${paquetexpressId}/active`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                resolve(res.body)
            })
    })
}