<script>

    import { toast } from 'bulma-toast'

    import { ordersStore, modalStore, notifyStore } from '../stores'
    import OrdersService from '../$services/orders.service'

    import Spinner from '../$components/spinner.svelte'
    import File from '../$components/file.svelte'

    let loading = false
    let data = {}

    async function uploadOrders() {

        loading = true
        const resp = await OrdersService.uploadOrders(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
        
        toast({message: 'Órdenes cargadas', type: 'is-success'})
        notifyStore.set('orders_updated')
        modalStore.close()
    } 

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit={uploadOrders}>
        <div class="field">
            <div class="control">
                <File bind:file={data.file} />
            </div>
        </div>
        <div class="items-space">
            <a href="/files/orders_template.xlsx" download="orders_template.xlsx">Descargar plantilla</a>
            <button class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}