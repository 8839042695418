<script>

    import { shipmentsSelectedStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import CreateOrdersComponent from './component.create.orders.svelte'

    $: show = $modalStore === 'shipments_create_orders_modal'

</script>

<Modal {show}>
    <CreateOrdersComponent shipments={$shipmentsSelectedStore} />
</Modal>