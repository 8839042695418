<script>

    import { chargeStore } from '../stores'

    import DateMethod from '../$methods/date.methods.js'

    import Value from '../$components/value.svelte'

    function mapDetails(charge) {

        if(!charge.shipment)
            return charge.details

        return `Orden: ${charge.shipment.order.number}, ${charge.shipment.provider} ${charge.shipment.service} ${charge.shipment.guide}, ${charge.details}`
    }


</script>
<div class="columns">
    <div class="column">
        <Value label="Fecha" value={ DateMethod.dateTimeLarge($chargeStore.created) } />
    </div>
    <div class="column">
        <Value label="Estatus" value={ $chargeStore.status === 'paid' ? 'Pagado' : 'Pendiente' } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Tipo" value={ $chargeStore.type === 'charge' ? 'Cargo' : 'Reembolso' } />
    </div>
    <div class="column">
        <Value label="Método" value={ $chargeStore.method } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Concepto" value={ $chargeStore.concept } />
    </div>
    <div class="column">
        <Value label="Monto" value={ `$ ${ $chargeStore.amount }` } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Detalles" value={ mapDetails($chargeStore) } />
    </div>
</div>