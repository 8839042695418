<script>

    import { toast } from 'bulma-toast'
    import { shipmentStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'
    import OrdersService from '../$services/orders.service'
    import ConfigsService from '../$services/configs.service'

    import Container from '../$layouts/container.layout.svelte'
    import Button from '../$components/button.svelte'
    import Form from '../$components/form.svelte'
    import Map from '../$components/map.svelte'

    export let params = {}

    let loading = false
    let configTracking = {}
    let references = null
    let success = false
    let position = {
        lat: 19.435109,
        lng: -99.133806
    }

    if(params.shipmentCode)
        getShipmentCode()

    async function getShipmentCode() {

        loading = true
        const resp = await ShipmentsService.getShipmentCode(params.shipmentCode)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipmentStore.set(resp.data)
        getConfigTracking()
    }

    async function getConfigTracking() {

        loading = true
        const resp = await ConfigsService.getConfigTracking($shipmentStore.userId)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configTracking = resp.data || {}
    }

    async function updateShipmentReference() {

        loading = true
        const resp = await OrdersService.updateOrderReferences($shipmentStore.order._id, {references, position})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        success = true
        
        shipmentStore.update(shipment => {
            shipment.order = resp.data
            return shipment
        })
    }

</script>

{#if configTracking.brand}
    <div class="center">
        <img src="{configTracking.brand}?random={Math.random()}" alt="Brand">
    </div>
{:else}
    <div class="hero is-primary">
        <div class="hero-body">
            <div class="container">
                <div class="title">MyShipper</div>
                <div class="subtitle">Información del destino</div>
            </div>
        </div>
    </div>
{/if}

<Container>
    <div class="columns">
        <div class="column">
            {#if success}
                <div class="items-center">
                    <div class="title">Tu Información ha sido guardada 😉</div>
                </div>  
            {/if}

            {#if $shipmentStore && !success}
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Nombre</div>
                        <div class="title">{ $shipmentStore.order.delivery.contact }</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Empresa</div>
                        <div class="title">{ $shipmentStore.order.delivery.corporate }</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Código postal</div>
                        <div class="title">{ $shipmentStore.order.delivery.zipcode }</div>
                    </div>
                    <div class="column">
                        <div class="subtitle">Estado</div>
                        <div class="title">{ $shipmentStore.order.delivery.state }</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Ciudad</div>
                        <div class="title">{ $shipmentStore.order.delivery.city }</div>
                    </div>
                    <div class="column">
                        <div class="subtitle">Municipio</div>
                        <div class="title">{ $shipmentStore.order.delivery.muncipality || 'Sin información' }</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Colonia</div>
                        <div class="title">{ $shipmentStore.order.delivery.colony }</div>
                    </div>
                    <div class="column">
                        <div class="subtitle">Calle</div>
                        <div class="title">{ $shipmentStore.order.delivery.street }</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Número exterior</div>
                        <div class="title">{ $shipmentStore.order.delivery.number }</div>
                    </div>
                    <div class="column">
                        <div class="subtitle">Número interior</div>
                        <div class="title">{ $shipmentStore.order.delivery.apartment }</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="subtitle">Referencias</div>
                        <div class="title">{ $shipmentStore.order.delivery.references || 'Sin referencias' }</div>
                    </div>
                </div>
        
                {#if $shipmentStore.order.delivery.referencesClient}
                    <div class="columns">
                        <div class="column">
                            <div class="subtitle">Referencias extras</div>
                            <div class="title">{ $shipmentStore.order.delivery.referencesClient || 'Sin referencias' }</div>
                        </div>
                    </div>
                {/if}

                <hr>
                <div class="columns">
                    <div class="column">
                        
                        <div class="title">Referencias</div>
                        
                        <div class="notification">
                            <div class="subtitle">Datos extra nos ayudaran en caso de que la paquetería no logre encontrar tu domicilio, puedes ingresar toda la información que creas necesaria para la entrega de tu paquete.</div>
                        </div>

                        <Map bind:position={position} />
                        <br>

                        <Form on:submit={updateShipmentReference} {loading} >
                            <textarea bind:value={references} class="textarea" placeholder="Ingresa referencias adcionales"></textarea>
                            <div class="items-end space-top">
                                <Button type="submit" text="Guardar" icon="save" color="primary" />
                            </div>
                        </Form>
                    </div>
                </div>
            {/if}
        </div>
    </div>
</Container>
<br>
<br>
<br>
<br>
<br>
<br>