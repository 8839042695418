<script>

    import { pickupStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import PickupComponent from './component.pickup.svelte'

    $: show = $modalStore === 'pickup_modal'

</script>

<Modal title="Información" {show}>
    <PickupComponent pickup={$pickupStore} />
</Modal>