<script>

    import { link } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'

    import { postsStore } from '../stores'

    import PostService from '../$services/posts.service'
    import DateMethods from '../$methods/date.methods'

    import Pagination from '../$components/pagination.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    $: userId? getPostsUser() : false;

    export let userId = null

    let loading = false
    let query = {}
    let metadata = {}

    async function getPostsUser() {

        loading = true
        const resp = await PostService.getPostsUser(userId, query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        postsStore.set(resp.data.posts)
        metadata = resp.data.metadata
    }

</script>

<style>
    .content {
        height: 120px;
        overflow: hidden;
    }
</style>

<div class="items-end space-bottom">
    <Pagination bind:metadata bind:page={query.query} on:change={ getPostsUser } />
</div>

<Spinner {loading} />

{#if !loading}

    <Message show={!$postsStore || !$postsStore.length} />

    {#each $postsStore as post}
        <a href="/post/{post._id}" use:link class="pointer">
            <div class="space-bottom">
                <small>
                    <span class="icon"><i class="fas fa-user"></i></span>
                    <span>{ post.user.alias || post.user.name } · { DateMethods.timeAgo(post.created) } </span>
                </small>
            </div>
            <div class="title is-5">{ post.title }</div>
            <div class="content">{@html post.content}</div>
        </a>
    {/each}
{/if}