<script>

    import { createEventDispatcher } from 'svelte'

    import InputEmail from '../$components/input.email.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'

    import ZipcodeColonyComponent from './component.zipcode.colony.svelte'
    import Switch from '../$components/switch.svelte'

    const dispatch = createEventDispatcher()

    export let location = {}
    export let disabled = false
    export let showReturn = false

    function onSubmit() {
        dispatch('selected', location)
    }

</script>

<form on:submit={onSubmit} on:submit >
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.contact } label="Contacto" icon="user" placeholder="Nombre del contacto" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.corporate } label="Empresa" icon="building" placeholder="Nombre de la empresa" required={false}/>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.phone } label="Teléfono" icon="phone" placeholder="Número de teléfono" />
        </div>
        <div class="column">
            <InputEmail bind:value={ location.email } label="Correo electrónico" icon="envelope" placeholder="Correo electrónico" />
        </div>
    </div>
    <ZipcodeColonyComponent 
        bind:zipcode={location.zipcode}
        bind:state={location.state}
        bind:city={location.city}
        bind:municipality={location.municipality}
        bind:colony={location.colony}
        {disabled}
    />
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.street } label="Calle" icon="map-marker-alt" placeholder="Calle" />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.number } label="Número exterior" icon="hashtag" placeholder="Número exterior" />
        </div>
        <div class="column">
            <InputText bind:value={ location.apartment } label="Número interior" icon="building" placeholder="Número interior" required={false} />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ location.references } label="Referencias" icon="asterisk" placeholder="Referencias de la casa/apartamento" />
        </div>
    </div>
    <slot name="buttons">
        <div class="columns">
            <div class="column">
                <div class="field">
                    <Switch bind:checked={location.sendEmail} label="Envíar correo" />
                </div>
            </div>
        </div>
        <div class="items-end">
            <Button on:click={ () => dispatch('cancel') } text="Cancelar" icon="ban" />
            {#if showReturn}
                <Button on:click={ () => dispatch('return') } text="Regresar" icon="arrow-alt-circle-left" />
            {/if}
            <Button type="submit" text="Siguiente" icon="arrow-alt-circle-righ" color="primary" />
        </div>
    </slot>
</form>