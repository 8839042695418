<script>

    import { courseStore } from '../stores'
    import { afterUpdate } from 'svelte'

    import CourseComponent from './component.course.svelte'

    let showCourse = false

    afterUpdate(() => {

        const videos = document.getElementsByTagName('video')

        if(!videos || !videos.length)
            return

        for(let i=0; i<videos.length; i++)
            videos[i].currentTime = 3
    })

</script>

<style>
    p {
        text-align: justify;
    }
</style>

<div class="title center space-bottom">
    Marketing viral: campañas que se comparten solas
</div>
<div class="subtitle center space-bottom">
    Por Renato Farfán Basauri, Director creativo. Curso de Domestika.<br/>
    Categoría: Marketing y negocios
</div>

{#if showCourse}
    <CourseComponent />
{:else}
    <div class="center">
        <video controls width="80%">
            <source src={$courseStore.urlVideo} type="video/mp4">
            <track kind="captions"/>
            No soportado por el navegador
        </video>
    </div>
    <br/>
    <br/>
    <div class="columns">
        <div class="column">
            <div class="title is-4 center space-bottom">
                Aprende a educar tu olfato para crear los virales más contagiosos
            </div>
            <div>
                <p>Internet y las redes sociales han cambiado la manera en la que consumimos información y, hoy en día, no es descabellado que un vídeo casero como el adiós a Chimuelo pueda equipararse en impacto a una campaña multimillonaria de una marca como Nike o Dove. Bienvenido a la era del marketing viral.</p>
                <p>En este curso aprenderás las claves para crear los virales más contagiosos de la mano de Renato Farfán, Director General Creativo de YoupanquiBBDO, y ganador de premios publicitarios prestigiosos como el Cannes Lions, Clio y Ojo de Iberoamérica, en múltiples ocasiones.</p>
            </div>
            <br/>
            <div class="center">
                <img src="https://assets0.domestika.org/course-images/000/004/780/4780-big.jpg" alt="" width="80%" />
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                Contenidos
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 1: ¡Hola Marketing Viral!
                </div>
                <div>
                    2 clases
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 2: Introducción al Marketing Viral
                </div>
                <div>
                    4 clases
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 3: Planificando el viral
                </div>
                <div>
                    5 clases
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 4: ¡Acción!
                </div>
                <div>
                    6 clases
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 5: Evaluación
                </div>
                <div>
                    1 clase
                </div>
            </div>
            <div class=" contenido items-space">
                <div>
                    <span class="icon"><i class="fas fa-check"></i></span>
                    Unidad 6: Proyecto final
                </div>
                <div>
                    1 clase
                </div>
            </div>
            <br/>
            <div class="title is-3  space-bottom">
                Información del curso
            </div>
            <div>
                <p>El curso comenzará con una introducción sobre el marketing viral y lo que puedes lograr ejecutando una campaña viral. Conocerás el poder de las redes sociales y la influencia que ejercen en las masas.</p>
                <br/>
                <p>Luego de tener las nociones básicas sobre el marketing viral, pasarás a conocer cuáles son los pasos para hacer una campaña. Iniciarás conociendo a la marca y a su público. Luego, aprenderás sobre campañas virales o de publicidad similares a la marca de tu elección.</p>
                <br/>
                <p>Después de obtener esta información, aprenderás sobre cómo se organiza una campaña con tiempo o sin tiempo de planificación, y escogerás los hashtags a usar y el producto que quieres hacer viral. Para terminar la etapa de organización y planificación, definirás qué ecosistema de redes es el mejor para lanzar tu viral.</p>
                <br/>
                <p>Asimismo, verás qué decisiones y acciones tomar en la etapa de ejecución de tu campaña, dependiendo del éxito obtenido. Obtendrás recomendaciones sobre qué hacer si tu viral fue contagioso y qué hacer si no llegó a cobrar vida. Para culminar esta etapa conocerás la importancia de un buen copywriting.</p>
                <br/>
                <p>Por último, aprenderás a analizar una campaña, las métricas de los aciertos, de los errores y de lo que se pudo hacer.</p>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                ¿Cuál es el proyecto del curso?
            </div>
            <div>
                <p>Planificarás una campaña viral para una marca de tu elección.</p>
                <br/>
                <div class="center">
                    <img src="https://assets1.domestika.org/course-images/000/004/781/4781-big.jpg" alt="" width="80%" />
                </div>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                ¿A quién está dirigido?
            </div>
            <div>
                <p>A todos aquellos que quieran convertir su marca, o la marca que manejan, en el tema de conversación más importante en redes a través de estrategias relevantes para la audiencia.</p>
            </div>
            <br/>
            <div class="title is-3 space-bottom">
                Requisitos
            </div>
            <div>
                <p>Necesitarás un smartphone y muchas ganas. Una computadora también te vendrá bien.</p>
            </div>
            <br/>
            <button on:click={() => (showCourse = true)} class="button is-danger is-large is-fullwidth">Ir al curso</button>
        </div>
    </div>
    <br>
    <br>
{/if}