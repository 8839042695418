<script>

    import { link } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'

    import {postsStore, modalStore} from '../stores'

    import PostService from '../$services/posts.service'
    import DateMethods from '../$methods/date.methods'

    import Pagination from '../$components/pagination.svelte'
    import InputText from '../$components/input.text.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Select from '../$components/select.svelte'
    import Button from '../$components/button.svelte'

    let loading = false
    let query = {}
    let metadata = {}

    getPosts()

    async function getPosts() {

        loading = true
        const resp = await PostService.getPosts(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        postsStore.set(resp.data.posts)
        metadata = resp.data.metadata
    }

</script>

<style>
    .content {
        height: 112px;
        overflow: hidden;
    }
</style>

<div class="columns">
    <div class="column">
        <InputText on:enter={getPosts} bind:value={query.find} icon="search" placeholder="Buscar" />
    </div>
    <div class="column is-narrow">
        <Select bind:value={query.tag} text="Categoria" icon="star" options={[
            { value: "General", text: "General" },
            { value: "Marketing", text: "Marketing" },
            { value: "Tecnología", text: "Tecnología" },
            { value: "Resolución de problemas", text: "Resolución de problemas" },
            { value: "Ventas", text: "Ventas" },
            { value: "eCommerce", text: "eCommerce" },
        ]} />
    </div>
    <div class="column is-narrow">
        <Button on:click={() => modalStore.set('post_create_modal')} text="Agregar post" icon="signature" color="primary" />
    </div>
</div>

<div class="items-end space-bottom">
    <Pagination bind:metadata bind:page={query.query} on:change={ getPosts } />
</div>

<Spinner {loading} />

{#if !loading}
    {#each $postsStore as post}
        <a href="/post/{post._id}" use:link class="pointer">
            <div class="space-bottom">
                <small>
                    <span class="icon"><i class="fas fa-user"></i></span>
                    <span>{ post.user.alias || post.user.name } · { DateMethods.timeAgo(post.created) } </span>
                </small>
            </div>
            <div class="title is-5">{ post.title } <div class="tag is-primary">{ post.tag }</div> </div>
            <div class="content">{@html post.content}</div>
            <div class="items-space">
                <div class="items-end">{ post.comments } { post.comments === 1? 'Comentario' : 'Comentarios' }</div>
                <div class="items-start">
                    <span class="icon"><i class="fas fa-heart"></i></span>
                    <span>{ post.likes.length }</span>
                </div>
            </div>
        </a>
    {/each}
{/if}