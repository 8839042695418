<script>

    import { createEventDispatcher } from 'svelte'

    export let provider = 'Estafeta'
    export let service = 'Terrestre'

    const dispatch = createEventDispatcher()

    let services = []

    const providers = [
        'Estafeta',
        'Redpack',
        'DFExpress',
        'AMPM',
        'Paquetexpress',
        'FedEx',
        'Treggo',
        '99Minutos',
        'UPS',
        'JTExpress',
        'BigSmart',
    ]

    const servicesList = {
        Estafeta: [
            'Terrestre',
            'Express'
        ],
        Redpack: [
            'Ecoexpress',
            'Express'
        ],
        DFExpress: [
            'Express'
        ],
        AMPM: [
            'Terrestre'
        ],
        Paquetexpress: [
            'Terrestre',
            'Express',
            'Superexpress'
        ],
        FedEx: [
            'Express Saver',
            'Standard Overnight'
        ],
        Treggo: [
            'Auto'
        ],
        '99Minutos': [
            'NextDay',
            'CO2',
            'SameDay',
            '99minutos',
        ],
        UPS: [
            'UPS Express Saver',
            'UPS Standard'
        ],
        JTExpress: [
            'Terrestre'
        ],
        BigSmart: [
            'Next Day'
        ],
    }

    setServices()

    function setServices() {

        services = provider? servicesList[provider] : servicesList.Estafeta

        if(services.indexOf(service) == -1)
            service = services[0]

        dispatch('change')
    }

</script>

<style>
    .select {
        min-width: 100%;
    }

    .select select {
        min-width: 100%;
    }
</style>


<div class="columns">
    <div class="column">
        <label for="" class="label">Proveedor</label>
        <div class="field">
            <div class="control">
                <div class="select">
                    <select bind:value={provider} on:change={setServices}>
                        {#each providers as providerItem}
                            <option value="{ providerItem }">{ providerItem }</option>
                        {/each}
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="column">
        <label for="" class="label">Servicio</label>
        <div class="field">
            <div class="control">
                <div class="select">
                    <select bind:value={service} on:change={() => dispatch('change')}>
                        {#each services as serviceItem}
                            <option value="{ serviceItem }">{ serviceItem }</option>
                        {/each}
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>