<script>

    import UserProfileComponent from '../users/component.user.profile.svelte'
    import PostsUserComponent from '../posts/component.posts.user.svelte'

    import Menu from '../$layouts/menu.layout.svelte'

    export let params = {}

</script>

<Menu />

<div class="container">
    <div class="columns is-8 is-offset-2">
        <div class="column">
            <UserProfileComponent userId={params.userId} />
            <PostsUserComponent userId={params.userId} />
        </div>
    </div>
</div>