<script>
    
    import Quickview from '../$components/quickview.svelte'
    import NotificationsComponent from './component.notifications.svelte'

    let show = false

</script>

<span on:click={() => show = true} class="icon is-medium pointer">
    <i class="fas fa-bell"></i>
</span>

<Quickview title="Notificaciones" bind:show>
    <NotificationsComponent />
</Quickview>