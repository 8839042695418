<script>

    import { modalStore } from '../stores'
    import { replace, location } from 'svelte-spa-router'

    import StorageService from '../$services/storage.service'

    import Wallet from '../wallets/component.wallet.svelte'
    import ThemeMode from '../$components/theme.svelte'
    import NotifcationsQuickview from '../notifications/quickview.notifications.svelte'
    import MenuWebQuickview from './menu.burger.web.svelte'
    import MenuMobileQuickview from './menu.burger.mobile.svelte'
    import ImportantNotifcations from '../notifications/component.important.notifications.svelte'
    import UserReferidosModal from '../users/modal.referidos.svelte'

    function showView(view) {
        replace(`#/${view}`);
    }

    function onLogout() {
        StorageService.deleteItem('user')
        StorageService.deleteItem('access')
        replace('#/')
    }

</script>

<style>

    .menu {
        /* background: linear-gradient(90deg, #62227E, #C33B8D, #C33B8D); */
        /* background-image: url('/images/marzo_menu.webp');
        background-size: contain;
        background-repeat: no-repeat; */
        background-color: #FCEEF5;
    }

    .title {
        padding: 0 32px;
        padding-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #64227E !important;
    }

    .brand {
        max-height: 100px;
    }

    .web {
        display: block;
    }

    .mobile {
        display: none;
    }

    .menu-col {
        display: flex;
        align-items: center;
    }

    .column.menu-col {
        padding: 0;
    }

    .menu-item {
        padding: 16px;
        cursor: pointer;
        color: #64227E !important;
        font-weight: bold;
    }

    .menu-item small {
        color:  #64227E !important;
    }

    .icon .fas {
        color:  #64227E !important;
    }

    .menu-item:hover * {
        color: #C33B8D;
    }

    .menu-item.active * {
        color: #C33B8D;
    }

    @media (max-width:862px) {

        .mobile {
            display: block;
        }

        .web {
            display: none;
        }
    }

</style>

<div class="web">
    <div class="menu space-bottom">
        <div class="columns">
            <div class="column is-narrow" style="display: flex; align-items: center;">
                <div class="title">
                    <img class="brand" src="/images/marzo_menu.webp" alt="">
                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        MyShipper
                        <br>
                        <div style="font-size: 0.8rem;">Día Internacional de la Mujer</div>
                    </div>
                </div>
            </div>
            <div class="column items-center menu-col">
                <div on:click={() => showView('home')} class="menu-item items-space" class:active={$location === '/home'}>
                    <span class="icon is-medium">
                        <i class="fas fa-home"></i>
                    </span>
                    <small>Inicio</small>
                </div>
                <div on:click={() => showView('quotations')} class="menu-item items-space" class:active={$location === '/quotations'}>
                    <span class="icon is-medium">
                        <i class="fas fa-calculator"></i>
                    </span>
                    <small>Cotizador</small>
                </div>
                <div on:click={() => showView('shipments')} class="menu-item items-space" class:active={$location === '/shipments'}>
                    <span class="icon is-medium">
                        <i class="fas fa-shipping-fast"></i>
                    </span>
                    <small class="center">Envíos</small>
                </div>
                <div on:click={() => showView('orders')} class="menu-item items-space" class:active={$location === '/orders'}>
                    <span class="icon is-medium">
                        <i class="fas fa-clipboard-list"></i>
                    </span>
                    <small>Órdenes</small>
                </div>
                <MenuWebQuickview />
            </div>
            <div class="column items-center menu-col">
                <div class="menu-item" on:click={() => modalStore.set('referidos_modal')}>
                    <div class="items-center">
                        <span class="icon is-medium pointer"><i class="fas fa-users"></i></span>
                        <span>Referidos</span>
                    </div>
                </div>
                <div class="menu-item" on:click={() => showView('payments')}>
                    <div class="items-center">
                        <span class="icon is-medium pointer">
                            <i class="fas fa-wallet"></i>
                        </span>
                        <div><Wallet/></div>
                    </div>
                </div>
                <div class="menu-item tools">
                    <NotifcationsQuickview />
                </div>
                <div class="menu-item tools">
                    <ThemeMode />
                </div>
                <div class="menu-item tools" on:click={onLogout}>
                    <span class="icon is-medium pointer">
                        <i class="fas fa-sign-in-alt"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ImportantNotifcations />
</div>

<div class="container">
    <div class="mobile">
        <nav class="menu">
            <div class="items-space">
                <MenuMobileQuickview />
                <ThemeMode />
                <div class="menu-item" on:click={onLogout}>
                    <span class="icon is-medium pointer">
                        <i class="fas fa-sign-in-alt" style="color: white !important;"></i>
                    </span>
                </div>
                <div class="menu-item tools">
                    <NotifcationsQuickview />
                </div>
                <div class="menu-item">
                    <div class="items-center">
                        <span class="icon is-medium pointer">
                            <i class="fas fa-wallet"></i>
                        </span>
                        <div><Wallet/></div>
                    </div>
                </div>
            </div>
        </nav>
        <ImportantNotifcations />
    </div>
</div>

<UserReferidosModal />