<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import MerchandiseCreate from './component.create.svelte'

    $: show = $modalStore === 'merchandise_create_modal'

</script>

<Modal title="Agregar mercancia" {show} >
    <MerchandiseCreate />
</Modal>