<script>

    import Papaparse from 'papaparse'
    import Moment from 'moment'

    import { toast } from 'bulma-toast'

    import GraphicsService from '../$services/graphics.service'
    import NumeralMethods from '../$methods/numeral.methods'
    import Graphic from '../$components/graphic.svelte'
    import Title from '../$components/title.svelte'
    import Button from '../$components/button.svelte'
    
    export let query = null
    export let count = 1

    $: count && getShipmentsInvoice()

    let loading = false
    let labels = []
    let datasets = []
    let results = []
    let invoice = 0
    let element = null

    async function getShipmentsInvoice() {

        if(!query) return

        const send = {...query}
              send.dateStart = query.dateStart.toISOString()
              send.dateEnd = query.dateEnd.toISOString()
    
        loading = true
        const resp = await GraphicsService.getShipmentsInvoice(send)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        labels = resp.data.labels
        datasets = resp.data.datasets
        results = resp.data.results
        invoice = datasets.reduce((total, value) => total+value, 0)
    }

    function createFile() {

        const info = results.map(result => {
            return {
                'Fecha': `${result._id.date? `${result._id.date}/` : '' }${result._id.month}/${result._id.year}`,
                'Total': parseFloat(result.total).toFixed(2),
            }
        })

        const blob = new Blob([Papaparse.unparse(info, { delimiter: ',' })], {type : 'text/csv'})

        let providerName = `${query.provider? query.provider+'.' : ''}`
        let dateStart = Moment(query.dateStart).format('DD-MM-YYYY')
        let dateEnd = Moment(query.dateEnd).format('DD-MM-YYYY')

        element.download = `${providerName}.facturacion.${dateStart}.a.${dateEnd}.csv`
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

</script>

<style>
    @media (max-width: 500px) {
        .items-space {
            display: grid;
        }
    }
</style>

<a bind:this={element} href="/" style="display: none;">.</a>

<div>
    <div class="items-space">
        <Title text="Facturación" />
        <Title text="{NumeralMethods.cash(invoice)}" />
    </div>

    <div class="columns">
        <div class="column">
            <Graphic bind:labels={labels} bind:datasets={datasets} chartType="bar" colorRandom={true} {loading} />
        </div>
    </div>
</div>

<div class="items-end">
    <Button on:click={createFile} text="Descargar" icon="file-csv" size="small" />
</div>