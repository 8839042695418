<script>

    import Switch from '../$components/switch.svelte'

    import LocalStorage from '../$services/storage.service'

    let dark = LocalStorage.getItem('dark')

    function onTheme() {

        let app = document.getElementById('app')

        localStorage.setItem('dark', !dark)

        if(!dark)
            app.classList.add('is-dark')
        else 
            app.classList.remove('is-dark')
    }

</script>

<div class="items-space">
    <span class="icon space-left" style="color: #64227E;"><i class="fas fa-sun"></i></span>
    <Switch bind:checked={dark} on:click={onTheme} />
    <span class="icon space-right" style="color: #64227E;"><i class="fas fa-moon"></i></span>
</div>