<script>

    import { toast } from 'bulma-toast'
    import { upssStore, upsStore, modalStore } from '../stores'

    import Spinner from '../$components/spinner.svelte'
    import UpsService from '../$services/ups.service'

    const data = new Object({
        _id: $upsStore._id,
        alias: $upsStore.alias,
        client: $upsStore.client,
        shipper: $upsStore.shipper,
        licence: $upsStore.licence,
        password: $upsStore.password
    })
    
    let loading = false

    async function updateCredential() {

        loading = true
        const resp = await UpsService.updateCredential(data._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        upssStore.replace(resp.data)
        upsStore.set(resp.data)

        toast({message: 'Cuenta actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading && data}
    <form on:submit|preventDefault={updateCredential}>

        <div class="columns">
            <div class="column">
                <div class="center">
                    <img src="images/UPS.svg" alt="UPS" style="width: 20%;">
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Alias</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.alias}  type="text"  class="input" placeholder="Alias" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Client</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.client}  type="text"  class="input" placeholder="Client" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Shipper</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        <input bind:value={data.shipper}  type="text"  class="input" placeholder="Shipper" required>
                    </div>
                </div>
            </div>
        </div>
          
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.password}  type="text"  class="input" placeholder="Password" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Licence</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-key"></i></span>
                        <input bind:value={data.licence}  type="text"  class="input" placeholder="Licence" required>
                    </div>
                </div>
            </div>
        </div>

        <div class="items-end">
            <div class="columns">
                <div class="column">
                    <button on:click={() => modalStore.set('ups_account_modal')} type="button" class="button is-fullwidth">
                        <span class="icon"><i class="fas fa-ban"></i></span>
                        <span>Cancelar</span>
                    </button>
                </div>
                <div class="column">
                    <button type="submit" class="button is-primary is-fullwidth">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
{/if}