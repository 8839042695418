<script>

    import { locationStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import LocationComponent from './component.location.svelte'

    $: show = $modalStore === 'location_modal'
    
</script>


<Modal title="Dirección" {show}>

    <LocationComponent location={$locationStore} />
    
</Modal>