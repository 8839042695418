<script>

    import { toast } from 'bulma-toast'

    import { modalStore } from '../stores'
    import UsersService from '../$services/users.service'

    import Form from '../$components/form.svelte'
    import Button from '../$components/button.svelte'

    let loading = false
    let data = {}
    
    async function recoveryUser() {
    
        loading = true
        const resp = await UsersService.recoveryUser(data)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})
    
        modalStore.close()
        toast({message: 'Revisa tu buzón de correo, envíamos una liga para cambiar tu contraseña', type: 'is-success'})
    }
</script>

<Form on:submit={recoveryUser} {loading} >

    <div class="field">
        <label for="" class="label">Correo</label>
        <div class="control has-icons-left">
            <span class="icon"><i class="fas fa-envelope"></i></span>
            <input bind:value={data.email} type="text" class="input" placeholder="Correo electronico">
        </div>
    </div>

    <div class="items-end">
        <Button type="submit" text="Envíar" icon="save" color="primary" />
    </div>
</Form>