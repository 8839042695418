<script>

    import { dhlStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import DhlAccountComponent from './component.dhl.account.svelte'

    $: show = $modalStore === 'dhl_account_modal'
    
</script>


<Modal title="Cuenta" {show}>
    <DhlAccountComponent dhl={$dhlStore} />
</Modal>