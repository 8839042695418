import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    getQuotations,
    getQuotationsInternational,
}

function getQuotations(data, query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .post(`${HOST_API}/quotations`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone})
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getQuotationsInternational(data, query) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .post(`${HOST_API}/quotations/international`)
            .set('access', Storage.getItem('access'))
            .query({...query, timezone})
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}