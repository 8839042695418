<script>

    import { toast } from 'bulma-toast'
    import { modalStore, notifyStore } from '../stores'

    import FacturamaService from '../$services/facturama.service'
    import ZipcodeColonyComponent from '../locations/component.zipcode.colony.svelte'
    
    import Select from '../$components/select.svelte'
    import Spinner from '../$components/spinner.svelte'

    let data = {}
    let loading = false

    getClient()

    async function getClient() {

        loading = true
        const resp = await FacturamaService.getClient()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        data = resp.data
    }

    async function updateClient() {

        loading = true
        const resp = await FacturamaService.updateClient(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        toast({message:'Información actualizada', type: 'is-success'})

        notifyStore.set('facturama_client_updated')
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateClient} >
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Nombre</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-address-card"></i></span>
                        <input bind:value={data.name} type="text" class="input" placeholder="Nombre" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">RFC</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-fingerprint"></i></span>
                        <input bind:value={data.rfc} type="text" class="input" placeholder="RFC" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <Select bind:value={ data.fiscalRegime } 
                    label="Régimen fiscal"
                    icon="list-alt"
                    options={[
                        {value: '601', text: 'General de Ley Personas Morales'},
                        {value: '603', text: 'Personas Morales con Fines no Lucrativos'},
                        {value: '605', text: 'Sueldos y Salarios e Ingresos Asimilados a Salarios'},
                        {value: '606', text: 'Arrendamiento'},
                        {value: '607', text: 'Régimen de Enajenación o Adquisición de Bienes'},
                        {value: '608', text: 'Demás ingresos'},
                        {value: '609', text: 'Consolidación'},
                        {value: '610', text: 'Residentes en el Extranjero sin Establecimiento Permanente en México'},
                        {value: '611', text: 'Ingresos por Dividendos (socios y accionistas)'},
                        {value: '612', text: 'Personas Físicas con Actividades Empresariales y Profesionales'},
                        {value: '614', text: 'Ingresos por intereses'},
                        {value: '615', text: 'Régimen de los ingresos por obtención de premios'},
                        {value: '616', text: 'Sin obligaciones fiscales'},
                        {value: '620', text: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'},
                        {value: '621', text: 'Incorporación Fiscal'},
                        {value: '622', text: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
                        {value: '623', text: 'Opcional para Grupos de Sociedades'},
                        {value: '624', text: 'Coordinados'},
                        {value: '625', text: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
                        {value: '626', text: 'Régimen Simplificado de Confianza'},
                        {value: '628', text: 'Hidrocarburos'},
                        {value: '629', text: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'},
                        {value: '630', text: 'Enajenación de acciones en bolsa de valores'},
                    ]}
                />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Correo electrónico</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-envelope"></i></span>
                        <input bind:value={data.email} type="email" class="input" placeholder="Correo electrónico" required>
                    </div>
                </div>
            </div>
        </div>
        <ZipcodeColonyComponent
            bind:zipcode={data.location.zipcode}
            bind:state={data.location.state}
            bind:city={data.location.city}
            bind:municipality={data.location.municipality}
            bind:colony={data.location.colony}
        />
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Calle</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                        <input bind:value={data.location.street} type="text" class="input" placeholder="Calle" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label">Número exterior</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-hashtag"></i></span>
                        <input bind:value={data.location.number} type="text" class="input" placeholder="Número exterior de la casa/apartamento" required>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <label for="" class="label">Número interior</label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-building"></i></span>
                        <input bind:value={data.location.apartment} type="text" class="input" placeholder="Número interior del apartamento">
                    </div>
                </div>
            </div>
        </div>
        <div class="items-end">
            <button on:click={() => modalStore.set(null)} type="button" class="button space-right">
                <span class="icon"><i class="fas fa-ban"></i></span>
                <span>Cancelar</span>
            </button>
            <button type="submit" class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}