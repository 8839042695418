<script>

    import { toast } from 'bulma-toast'
    import { configStore, modalStore } from '../stores'
    
    import ConfigsService from '../$services/configs.service'
    import Field from './component.config.shopify.field.svelte'

    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'

    import Value from '../$components/value.svelte'

    export let shopifyConfig;

    let loading = false

    const data = {
        changeStatus: shopifyConfig.changeStatus,
        contact: shopifyConfig.contact,
        corporate: shopifyConfig.corporate,
        phone: shopifyConfig.phone,
        email: shopifyConfig.email,
        zipcode: shopifyConfig.zipcode,
        state: shopifyConfig.state,
        city: shopifyConfig.city,
        municipality: shopifyConfig.municipality,
        colony: shopifyConfig.colony,
        street: shopifyConfig.street,
        number: shopifyConfig.number,
        apartment: shopifyConfig.apartment,
        references: shopifyConfig.references,
        services: shopifyConfig.services || []
    }

    let service = {
        code: null,
        apply: null
    }

    async function updateConfigShopify() {
        
        loading = true
        const resp = await ConfigsService.updateConfig({shopify: data})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)

        toast({message: 'Configuración de shopify actualizada', type: 'is-success'})
        modalStore.close()
    }

    async function updateConfigShopifyDefault() {

        loading = true
        const resp = await ConfigsService.updateConfigShopifyDefault()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)

        toast({message: 'Configuración de shopify actualizada', type: 'is-success'})
        modalStore.close()
    }

    async function addService() {
        
        const services = [...data.services]
              services.push(service)

        data.services = [...services]
        service = {}
    }

    async function removeService(index) {
        data.services = data.services.filter((item, i) => !(i === index))
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateConfigShopify}>
        
        <div class="columns">
            <div class="column">
                <div class="subtitle is-5">Mapeo de datos (dirección de destino)</div>
                <Field label="Contacto" icon="user" bind:field={data.contact} />
                <Field label="Empresa" icon="building" bind:field={data.corporate} />
                <Field label="Teléfono" icon="phone" bind:field={data.phone} />
                <Field label="Correo" icon="envelope" bind:field={data.email} />
                <Field label="Código postal" icon="map-marker-alt" bind:field={data.zipcode} />
                <Field label="Estado" icon="map-marker-alt" bind:field={data.state} />
                <Field label="Ciudad" icon="map-marker-alt" bind:field={data.city} />
                <Field label="Municipio" icon="map-marker-alt" bind:field={data.municipality} />
                <Field label="Colonia" icon="map-marker-alt" bind:field={data.colony} />
                <Field label="Calle" icon="map-marker-alt" bind:field={data.street} />
                <Field label="Número exterior" icon="map-marker-alt" bind:field={data.number} />
                <Field label="Apartamento" icon="map-marker-alt" bind:field={data.apartment} />
                <Field label="Referencias" icon="asterisk" bind:field={data.references} />
            </div>
        </div>

        <hr>
        <div class="columns">
            <div class="column">
                <div class="subtitle is-5">Estatus de orden</div>
                <div class="field">
                    <div class="control">
                        <Switch bind:checked={data.changeStatus} label="Cambiar el estatus de la orden en la tienda" />
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <div class="columns">
            <div class="column">

                <div class="subtitle is-5">Mapeo de servicios (servicio seleccionado por el cliente)</div>

                {#if data.services && data.services.length}
                    {#each data.services as service, index}
                        <div class="columns">
                            <div class="column">
                                <Value label="Nombre del servicio" value={ service.code } /> <br>
                                <Value label="Aplica servicio" value={ service.apply } /> <br>
                                <div class="items-end">
                                    <button on:click={() => removeService(index)} type="button" class="button">
                                        <span class="icon"><i class="fas fa-ban"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    {/each}
                {/if}

                <form on:submit|preventDefault={addService}>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Nombre del servicio</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-user"></i></span>
                                    <input bind:value={service.code} type="text" class="input" placeholder="Nombre del servicio" required>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Aplica servicio</label>
                                <div class="control">
                                    <div class="select">
                                        <select bind:value={service.apply} required>
                                            <option value="">Aplica servicio</option>
                                            <option value="Estafeta.Express">Estafeta Express</option>
                                            <option value="Estafeta.Terrestre">Estafeta Terrestre</option>
                                            <option value="Redpack.Express">Redpack Express</option>
                                            <option value="Redpack.Ecoexpress">Redpack Ecoexpress</option>
                                            <option value="DFExpress.Express">DFExpress Express</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-narrow">
                            <button type="submit" class="button is-fullwidth" style="margin-top: 32px;">
                                <span class="icon"><i class="fas fa-plus"></i></span>
                                <span>Agregar</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <button on:click={updateConfigShopifyDefault} type="button" class="button is-primary is-fullwidth">
                    <span class="icon"><i class="fas fa-history"></i></span>
                    <span>Restaurar</span>
                </button>
            </div>
            <div class="column">
                <button on:click={modalStore.close} type="button" class="button is-fullwidth">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                    <span>Cancelar</span>
                </button>
            </div>
            <div class="column">    
                <button type="submit" class="button is-primary is-fullwidth">
                    <span class="icon"><i class="fas fa-save"></i></span>
                    <span>Guardar</span>
                </button>
            </div>
        </div>
    </form>
{/if}
