<script>

    import { toast  } from 'bulma-toast'
    import { storesStore, storeStore, ordersStore } from '../stores'

    import StoresService from '../$services/stores.service'

    $: storeId = $storeStore? $storeStore._id : null

    export let status = 'enabled'

    let loading = false
    let query = {
        status
    }

    getStores()
    
    async function getStores() {

        ordersStore.set([])

        loading = true
        const resp = await StoresService.getStores(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        storesStore.set(resp.data)
        storeStore.set(resp.data[0])
    }

</script>

{#if !loading}
    <div class="tabs">
        <ul>
            {#each $storesStore as store}
                <li class:is-active={store._id === storeId}>
                    <a on:click={() => storeStore.set(store) }>
                        <span class="icon"><i class="fas fa-shopping-bag"></i></span>
                        <span>{ store.name }</span>
                    </a>
                </li>
            {/each}
        </ul>
    </div>
{/if}