<script>

    import { modalStore, storeStore } from '../stores'

    import DownloadMassiveComponent from './component.download.massive.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'shipments_download_massive_modal'

</script>

<Modal title="Descarga de guías masiva" {show} >
    <DownloadMassiveComponent storeId={$storeStore._id} />
</Modal>