<script>

    import { toast } from 'bulma-toast'
    import { storeStore, storesStore, modalStore } from '../stores'

    import StoresService from '../$services/stores.service'
    import Spinner from '../$components/spinner.svelte'

    let data = {
        name: $storeStore.name
    }

    let loading = false

    async function updateStore() {

        loading = true
        const resp = await StoresService.updateStore($storeStore._id, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        storesStore.replace(resp.data)
        storeStore.set(resp.data)

        toast({message: 'Tienda actualizada', type: 'is-success'})
        modalStore.close()
    }

</script>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={updateStore}>

        <div class="field">
            <label for="" class="label">Nombre de la tienda</label>
            <div class="control">
                <input bind:value={data.name} type="text" class="input" placeholder="Nombre de la tienda" required>
            </div>
        </div>

        <div class="items-end">
            <button class="button is-primary">
                <span class="icon"><i class="fas fa-save"></i></span>
                <span>Guardar</span>
            </button>
        </div>
    </form>
{/if}