<script>

    import Value from '../$components/value.svelte'

    export let location = {}

</script>

<div class="columns">
    <div class="column">
        <Value label="Contacto" value={ location.contact } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Empresa" value={ location.corporate || location.contact } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Teléfono" value={ location.phone } />
    </div>
</div>
{#if location.email}
    <div class="columns">
        <div class="column">
            <Value label="Correo" value={ location.email } />
        </div>
    </div>
{/if}
<div class="columns">
    <div class="column">
        <Value label="Dirección" value={ `${ location.street } ${ location.number || '' } ${ location.apartment || '' }, ${ location.colony } `} />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Estado" value={ `${ location.city }, ${ location.state }, ${ location.zipcode }` } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Referencias" value={ location.references || 'Sin referencias' } />
    </div>
</div>