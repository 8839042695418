<script>

    import { shipmentsSelectedStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import CancelShipmentsComponent from './component.cancel.shipments.svelte'

    $: show = $modalStore === 'shipments_cancel_shipments_modal'

</script>

<Modal {show}>
    <CancelShipmentsComponent shipments={$shipmentsSelectedStore} />
</Modal>