<script>

    import { toast } from 'bulma-toast'
    import { shipmentStore, shipmentsStore, modalStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'

    import LocationsInputsComponent from '../locations/component.inputs.svelte'
    import Spinner from '../$components/spinner.svelte'

    export let shipmentId
    export let orderId
    export let pickup
    export let delivery
    export let packageContent

    let data = {
        orderId,
        pickup,
        delivery,
        documentFormat: 'pdf',
        packageContent
    }

    let loading = false
    let step = 'pickup'

    function onPickupSelected() {
        step = 'delivery'
    }

    function onDeliverySelected() {
        step = 'guide'
    }

    function onCancel() {
        modalStore.set('shipment_modal')
    }

    async function updateShipmentGuide() {

        loading = true
        const resp = await ShipmentsService.updateShipmentGuide(shipmentId, data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        shipmentStore.set(resp.data)
        shipmentsStore.replace(resp.data)

        toast({message: 'Guía actualizada', type: 'is-success'})
        modalStore.set('shipment_modal')
    }

</script>

<ul class="steps has-content-centered">
    <li class="steps-segment" class:is-active={step === 'pickup'}>
        <span class="steps-marker">1</span>
        <div class="steps-content">
            <div class="subtitle">Origen</div>
        </div>
    </li>
    <li class="steps-segment" class:is-active={step === 'delivery'}>
        <span class="steps-marker">2</span>
        <div class="steps-content">
            <div class="subtitle">Destino</div>
        </div>
    </li>
    <li class="steps-segment" class:is-active={step === 'guide'}>
        <span class="steps-marker">3</span>
        <div class="steps-content">
            <div class="subtitle">Guía</div>
        </div>
    </li>
</ul>

{#if step === 'pickup'}
    <LocationsInputsComponent bind:location={data.pickup} on:selected={onPickupSelected} on:cancel={onCancel} />
{/if}

{#if step === 'delivery'}
    <LocationsInputsComponent bind:location={data.delivery} on:selected={onDeliverySelected} on:cancel={onCancel} />
{/if}

{#if step === 'guide'}

    <Spinner {loading} />

    {#if !loading}
        <form on:submit|preventDefault={updateShipmentGuide}>
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label for="" class="label">Alias</label>
                        <div class="control has-icons-left">
                            <span class="icon"><i class="fas fa-tag"></i></span>
                            <input bind:value={data.tag} type="text" class="input" placeholder="Identificador personal">
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label for="" class="label">Contenido</label>
                        <div class="control has-icons-left">
                            <span class="icon"><i class="fas fa-asterisk"></i></span>
                            <input bind:value={data.packageContent} type="text" class="input" placeholder="Contenido o descripción del paquete" required>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="field">
                        <label for="" class="label">Formato de guía</label>
                        <div class="control">
                            <div class="select">
                                <select bind:value={data.documentFormat}>
                                    <option value="pdf">PDF</option>
                                    <option value="6x4">6x4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="items-end">
                <button on:click={modalStore.close} type="button" class="button space-right">
                    <span class="icon"><i class="fas fa-ban"></i></span>
                    <span>Cancelar</span>
                </button>
                <button type="submit" class="button is-primary">
                    <span class="icon"><i class="fas fa-heart"></i></span>
                    <span>Generar guía</span>
                </button>
            </div>
        </form>
    {/if}
{/if}