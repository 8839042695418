<script>

    import { toast } from 'bulma-toast'
    import { configStore, modalStore, userStore } from '../stores'

    import ConfigsService from '../$services/configs.service'
    import BucketService from '../$services/bucket.service'

    import InputText from '../$components/input.text.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Button from '../$components/button.svelte'
    import Modal from '../$components/modal.id.svelte'
    import File from '../$components/file.svelte'

    import TrackingExample from './component.config.tracking.example.svelte'

    let data = {
        message: $configStore.tracking.message,
        facebook: $configStore.tracking.facebook,
        pinterest: $configStore.tracking.pinterest,
        instagram: $configStore.tracking.instagram,
        pinterest: $configStore.tracking.pinterest,
        webpage: $configStore.tracking.webpage,
    }

    let banner = null
    let brand = null
    let footer = null
    let loading = false

    async function updateConfig() {

        loading = true

        if(banner) {
            const success = await uploadFile(banner, 'banner', 'tracking_banner')
            if(!success) return
        }

        if(brand) {
            const success = await uploadFile(brand, 'brand', 'tracking_brand')
            if(!success) return
        }

        if(footer) {
            const success = await uploadFile(footer, 'footer', 'tracking_footer')
            if(!success) return
        }

        const resp = await ConfigsService.updateConfig({tracking: data})
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        configStore.set(resp.data)
        toast({message: 'Configuración actualizada', type: 'is-success'})
    }

    async function uploadFile(file, key, fileName) {

        const query = {
            fileType: file.type,
            fileDir: $userStore._id,
            fileName
        }

        loading = true
        const resp = await BucketService.getSigned(query)

        if(!resp.success) {
            toast({message: resp.error.message, type: 'is-danger'})
            return false
        }

        const {signed, url} = resp.data

        await BucketService.uploadFile(file, signed)

        toast({message: `Archivo ${file.name} cargado`, type: 'is-info'})

        data[key] = url
        return true
    }

</script>

<Spinner {loading} />

{#if !loading}
    <div class="columns">
        <div class="column">
            <div class="title">Rastreo personalizado</div>
            <form on:submit|preventDefault={updateConfig}>
                <div class="columns">
                    <div class="column">
                        <InputText bind:value={ data.facebook } label="URL Facebook" iconBase="fab"  icon="facebook" placeholder="URL de la página" required={false}/>
                        <InputText bind:value={ data.instagram } label="URL Instagram" iconBase="fab" icon="instagram" placeholder="URL de la página" required={false} />
                        <InputText bind:value={ data.pinterest } label="URL Pinterest" iconBase="fab" icon="pinterest" placeholder="URL de la página" required={false}/>
                        <InputText bind:value={ data.webpage } label="URL Webpage" icon="globe" placeholder="URL de la página" required={false}/>
                        <InputText bind:value={ data.message } label="Mensaje" icon="comment" placeholder="Mensaje al cliente" required={false} />
                    </div>
                    <div class="column">
                        <div class="field">
                            <label for="" class="label">Header</label>
                            <div class="control">
                                <File bind:file={brand} required={false} />
                                <small>Recomendamos una imagen mayor o igual a 1280px de largo</small>
                            </div>
                        </div>
                        <div class="field">
                            <label for="" class="label">Banner</label>
                            <div class="control">
                                <File bind:file={banner} required={false} />
                                <small>Recomendamos una imagen de 600px de ancho</small>
                            </div>
                        </div>
                        <div class="field">
                            <label for="" class="label">Footer</label>
                            <div class="control">
                                <File bind:file={ footer } required={false} />
                                <small>Recomendamos una imagen mayor o igual a 1280px de largo</small>
                            </div>
                        </div>
                        <InputText bind:value={ data.bannerURL } label="URL Banner" icon="globe" placeholder="URL del banner" required={false} />
                    </div>
                </div>
                <div class="items-end">
                    <Button on:click={() => modalStore.set('tracking_view_example')} type="button" text="Ver tracking" icon="eye" />
                    <Button type="submit" text="Guadar" icon="save" color="primary" />
                </div>
            </form>
        </div>
    </div>
{/if}

<Modal id="tracking_view_example">
    <TrackingExample />
</Modal>