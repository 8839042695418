<script>

    import { toast } from 'bulma-toast'

    import StripeService from '../$services/stripe.service'
    import Spinner from '../$components/spinner.svelte'

    const { STRIPE_KEY } = _CONSTANTS_

    let data = {}
    let loading = false

    async function createCheckout() {


        loading = true
        const resp = await StripeService.createCheckout(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        window.Stripe(STRIPE_KEY).redirectToCheckout({ sessionId: resp.data })
    }

</script>

<style>
    img {
        width: 200px;
    }
</style>

<Spinner {loading} />

{#if !loading}
    <form on:submit|preventDefault={createCheckout}>
        <div class="field">
            <label for="" class="label">Monto</label>
            <div class="control">
                <input bind:value={data.amount} min="100" type="number" class="input" placeholder="Ingrese el monto del saldo a comprar" required >
            </div>
        </div>
        <div class="items-end">
            <button class="button is-primary is-fullwidth">
                <span class="icon"><i class="fas fa-credit-card"></i></span>
                <span>Continuar con el pago</span>
            </button>
        </div>
    </form>
{/if}
