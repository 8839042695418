<script>
    
    import Moment from 'moment'
    import { toast } from 'bulma-toast'
    import { userStore, accountsStore, modalStore } from '../stores'

    import AccountsService from '../$services/accounts.service'
    import StripeService from '../$services/stripe.service'
    import DateMethod from '../$methods/date.methods'
    
    import Pagination from '../$components/pagination.svelte'

    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    import Icon from '../$components/icon.svelte'

    const { STRIPE_KEY } = _CONSTANTS_

    let loading = false
    let query = {}
    let metadata = {}
    let element = null

    getAccounts()

    async function getAccounts() {
    
        query.userId = $userStore._id

        loading = true
        const resp = await AccountsService.getAccounts(query)
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        accountsStore.set(resp.data.accounts)
        metadata = resp.data.metadata
    }

    async function getShipmentsReport(account) {
    
        loading = true
        const blob = await AccountsService.getShipmentsReport(account._id)
        loading = false

        if(!blob)
            return toast({message: 'Error al descargar el reporte', type: 'is-danger'})

        let fileName = `consumo.envios.${ Moment(account.expired).format('DD-MM-YYYY') }`

        element.download = `${fileName}.xlsx`
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

    async function getChargesReport(account) {
    
        loading = true
        const blob = await AccountsService.getChargesReport(account._id)
        loading = false

        if(!blob)
            return toast({message: 'Error al descargar el reporte', type: 'is-danger'})

        let fileName = `consumo.cargos.reembolsos.${ Moment(account.expired).format('DD-MM-YYYY') }`

        element.download = `${fileName}.xlsx`
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

    async function createCheckoutAccount(account) {
    
        loading = true
        const resp = await StripeService.createCheckoutAccount({accountId: account._id})
        loading = false
    
        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(resp.data.status)
            return getAccounts()
    
        window.Stripe(STRIPE_KEY).redirectToCheckout({ sessionId: resp.data })
    }

</script>

<a bind:this={element} href="/" style="display: none;">.</a>

{#if !loading}
    <div class="items-space space-bottom">
        <small>
            { $accountsStore.length } 
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <Pagination bind:metadata bind:page={ query.page } on:change={ getAccounts } />
    </div>
{/if}

<Spinner {loading} />
<Message show={!loading && !$accountsStore.length} />

{#if !loading && $accountsStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>
                        <Icon icon='fa-hashtag'/>
                    </th>
                    <th>
                        <Icon icon='fa-clipboard-check' text='Estatus'/>
                    </th>
                    <th>
                        <Icon icon='fa-shipping-fast' text='Envíos'/>
                    </th>
                    <th>
                        <Icon icon='fa-calendar-day' text='Inicio'/>
                    </th>
                    <th>
                        <Icon icon='fa-calendar-day' text='Corte'/>
                    </th>
                    <th>
                        <Icon icon='fa-file-pdf' text='Reporte envíos'/>
                    </th>
                    <th>
                        <Icon icon='fa-file-pdf' text='Reporte cargos'/>
                    </th>
                    <th>
                        <Icon icon='fa-info-circle' text='Detalles'/>
                    </th>
                    <th>
                        <Icon icon='fa-credit-card' text='Pago'/>
                    </th>
                </tr>
            </thead>
            <tbody>
                {#each $accountsStore as account, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ DateMethod.isBefore(account.expired)? 'Cerrada' : 'Activa' }</td>
                        <td>{ account.shipments.length }</td>
                        <td>{ DateMethod.dateShort(account.activated) }</td>
                        <td>{ DateMethod.dateShort(account.expired) }</td>
                        <td>
                            <button on:click={() => getShipmentsReport(account)} class="button">
                                <span class="icon"><i class="fas fa-file-pdf"></i></span>
                                <span>Envíos</span>
                            </button>
                        </td>
                        <td>
                            <button on:click={() => getChargesReport(account)} class="button">
                                <span class="icon"><i class="fas fa-file-pdf"></i></span>
                                <span>Cargos</span>
                            </button>
                        </td>
                        <td>
                            <button on:click={() => modalStore.open('account', 'modal', account)} class="button">
                                <span class="icon"><i class="fas fa-info-circle"></i></span>
                                <span>Detalles</span>
                            </button>
                        </td>
                        <td>
                            {#if account.status === 'paid' || account.status === 'free'}
                                <div class="tag">Pagada</div>
                            {:else}
                                <button on:click={() => createCheckoutAccount(account)} class="button is-primary is-small">
                                    <span class="icon"><i class="fas fa-credit-card"></i></span>
                                    <span>
                                        {#if DateMethod.isAfter(account.expired) }
                                            Pago anticipado
                                        {:else}
                                            Pagar cuenta
                                        {/if}
                                    </span>
                                </button>
                            {/if}
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}