<script>

    import { courseStore, modalStore } from '../stores'

    import ComponentPlay from './component.play.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'course_modal'

</script>

{#if $courseStore}
    <Modal title={$courseStore.title} {show}>
        <ComponentPlay />
    </Modal>
{/if}