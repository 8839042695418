<script>

    import { popupStore } from '../stores'

    import Popup from '../$components/popup.svelte'

    $: show = $popupStore === 'create_orders_popup'
    
</script>


<Popup {show}>
    <div class="title">Duplicar ordenes</div>
    <p>Selecciona los checks de las ordenes que deseas duplicar y da click en el botón que aparece al lado izquierdo de la barra de búsqueda.</p>
</Popup>