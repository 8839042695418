<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import ChargeComponent from './component.charge.svelte'

    $: show = $modalStore === 'charge_modal'
    
</script>

<Modal title="Cargo" {show}>
    <ChargeComponent />
</Modal>