<script>
    export let message = 'Sin resultados'
    export let color = 'ligth'
    export let show = false
</script>

{#if message && show}
    <div class="notification is-{color}">
        { message }
    </div>
{/if}