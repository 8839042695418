<script>

    import { toast } from 'bulma-toast'

    import ShipmentsService from '../$services/shipments.service'
    import Spinner from '../$components/spinner.svelte'

    export let shipments = []

    let loading = false
    let globalIndex = 0
    let element = null

    downloadGuides()

    async function downloadGuides() {

        loading = true
        await downloadGuide()
        loading = false

    }

    async function downloadGuide(index = 0) {

        globalIndex = index

        if(index >= shipments.length)
            return

        const shipment = shipments[index]

        const blob = await ShipmentsService.getShipmentDocument(shipment._id)

        if(!blob)
            return toast({message: 'Error al descargar el reporte', type: 'is-danger'})

        const order = shipment.order
        const alias = order.tag? `.${order.tag}` : ''

        element.download = `${order.number}.${order.storeType}.${shipment.provider}.${shipment.guide}.${order.delivery.contact}${alias? alias: ''}.pdf`
        element.href = window.URL.createObjectURL(blob)
        element.click()
        
        return await downloadGuide(index+1)
    }

</script>

<a bind:this={element} href="/" style="display: none;">.</a>

<div class="items-center col">
    <Spinner {loading} />
    <br>
    {#if globalIndex === shipments.length}
        <div class="title">Proceso finalizado</div>
    {:else}
        <div class="title is-4">Descargando guías</div>
    {/if}
    <div class="subtitle is-5">{globalIndex} de {shipments.length}</div>
</div>