import { replace } from 'svelte-spa-router'
import StorageService from './storage.service'

export default function(resp) {

    if(!resp.success && resp.error && resp.error.key === 'tokenExpired') {
        StorageService.deleteItem('user')
        StorageService.deleteItem('access')
        replace('#/')
    }
}