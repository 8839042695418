<script>

    import { modalStore } from '../stores'

    import OrderUpdateDeliveryComponent from './component.update.delivery.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'order_update_delivery_modal'

</script>

<Modal title="Actualizar dirección de destino" {show}>
    <OrderUpdateDeliveryComponent />
</Modal>