<script>

    import { link } from 'svelte-spa-router'
    import { fade } from 'svelte/transition'
    import { modalStore, postStore, userStore } from '../stores'

    import CreateComment from './component.create.svelte'
    import DateMethods from "../$methods/date.methods"

    import LikesComment from './component.likes.svelte'
    import Button from '../$components/button.svelte'

    export let comments = []

    function editComment(comment) {
        modalStore.open('comment', 'update_modal', comment)
    }

</script>

{#if comments.length}
    {#each comments as comment}
        <article class="media" in:fade>
            <figure class="media-left">
                <p class="image is-64x64">
                    {#if comment.user.avatar}
                        <img src="{ comment.user.avatar }" alt="Avatar">
                    {:else}
                        <img src="https://bulma.io/images/placeholders/128x128.png" alt="default">
                    {/if}
                </p>
              </figure>
            <div class="media-content">
                <div class="content">
                    <p>
                        <a href="/posts/user/{ comment.user._id }" use:link>{ comment.user.alias || comment.user.name }</a> • <small>{ DateMethods.timeAgo(comment.created) }</small>
                        <br/>
                            {@html comment.message}
                        <br/>
                        <small class="items-end">
                            {#if $userStore._id === comment.userId}
                                <div class="space-right">
                                    <Button on:click={() => editComment(comment)} text="Editar" icon="edit" size="small" />
                                </div>
                            {/if}
                            <div class="space-right">
                                <Button on:click={() => comment.reply = !comment.reply} text="Responder" icon="comment" size="small" />
                            </div>
                            <LikesComment comment={comment} />
                        </small>
                    </p>
                </div>

                {#if comment.reply}
                    <CreateComment on:created={() => comment.reply = false} postId={$postStore._id} commentId={comment._id} />
                {/if}

                {#if comment.comments.length}
                    <svelte:self comments={comment.comments} />
                {/if}
            </div>
        </article>
    {/each}
{/if}