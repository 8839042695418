<script>

    import { shipmentStore, shipmentsStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'
    import Spinner from '../$components/spinner.svelte'

    export let shipments = []

    let data = {}
    let loading = false
    let globalIndex = 0

    let errors = []

    cancelShipments()

    async function cancelShipments() {

        loading = true
        await cancelShipment()
        loading = false

    }

    async function cancelShipment(index = 0) {

        globalIndex = index

        if(index >= shipments.length)
            return

        const shipment = shipments[index]

        try {
                
            const resp = await ShipmentsService.cancelShipment(shipment._id, data)

            if(!resp.success)
                throw resp.error

            shipmentStore.set(resp.data)
            shipmentsStore.replace(resp.data)

        } catch(error) {
            errors = [...errors, {
                number: shipment.order.number,
                message: error.message || 'Error al cancelar el envío'
            }]
        }

        return await cancelShipment(index+1)
    }

</script>

<div class="items-center col">
    <Spinner {loading} />
    <br>
    {#if globalIndex === shipments.length}
        <div class="title">Proceso finalizado</div>
    {:else}
        <div class="title is-4">Cancelando envíos</div>
    {/if}

    <table class="table">
        <tbody>
            {#each errors as error} 
                <tr>
                    <td>{ error.number }</td>
                    <th>{ error.message }</th>
                </tr>
            {/each}
        </tbody>
    </table>
    <br>
    <div class="subtitle is-5">{ parseFloat((globalIndex*100)/shipments.length).toFixed(2) } % </div>
</div>