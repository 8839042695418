<script>

    import { toast } from 'bulma-toast'

    import ZipcodesService from '../$services/zipcodes.service'
    import Spinner from '../$components/spinner.svelte'

    import InputEmail from '../$components/input.email.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'

    $: zipcode && zipcode.length === 5 && findZipcode(false)

    export let zipcode = null
    export let state = null
    export let city = null
    export let municipality = null
    export let colony = null
    export let shipmentType = 'national'
    
    export let disabled = false

    let colonies = []
    let loading = false
    let showInput = false
    let data = {state, city, municipality}

    if(zipcode && !colony)
        findZipcode()

    if(zipcode && colony)
        findZipcode(false)

    if(disabled && !zipcode)
        disabled = false

    async function findZipcode(deleteColony = true) {

        if(shipmentType != 'national')
            return

        state = ''
        city = ''
        municipality = ''
        colony = deleteColony ? '' : colony
        colonies = []
        showInput = false
        
        if(!zipcode || zipcode.length != 5)
            return

        loading = true
        const resp = await ZipcodesService.getZipcodeColony(zipcode)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        if(!resp.data)
            return toast({message: 'No se encontró información del código postal', type: 'is-danger'})

        state = resp.data.state || data.state
        city = resp.data.city || data.city
        municipality = resp.data.municipality || data.municipality
        colonies = resp.data.colony || []

        if(colony) {
            const inColonies = colonies.find(item => item === colony)
            showInput = !inColonies
        }
    }

</script>

<style>
    @media (max-width: 600px) {
        .items-space {
            display: grid;
        }
    }
</style>

<Spinner {loading} />

{#if !loading}
    <div class="columns">
        <div class="column">
            <InputText bind:value={ zipcode } on:keyup={findZipcode}  label="Código postal" icon="map-marker-alt" placeholder="Código postal" {disabled} />
        </div>
        <div class="column">
            <InputText bind:value={ state } label="Estado" icon="map-marker-alt" placeholder="Estado" disabled={shipmentType === 'international'} />
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <InputText bind:value={ city } label="Ciudad" icon="city" placeholder="Ciudad" />
        </div>
        {#if shipmentType === 'national'}
            <div class="column">
                <InputText bind:value={ municipality } label="Municipio" icon="map-marker-alt" placeholder="Municipio" required={false}/>
            </div>
        {/if}
    </div>
    {#if shipmentType === 'national'}
        <div class="columns">
            <div class="column">
                <div class="field">
                    <label for="" class="label checkbox items-end">
                        <div class="items-space">
                            Colonia
                            {#if colonies.length}
                                <!-- svelte-ignore a11y-label-has-associated-control -->
                                <small>
                                    <input bind:checked={ showInput } type="checkbox" />
                                    <label>Mi colonia no está en la lista</label>
                                </small>
                            {/if}
                        </div>
                    </label>
                    <div class="control has-icons-left">
                        <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                        {#if colonies.length && !showInput}
                            <div class="select">
                                <select bind:value={colony} required>
                                    <option value="">Selecciona una colonia</option>
                                    {#each colonies as colony}
                                        <option value="{colony}">{ colony }</option>
                                    {/each}
                                </select>
                            </div>
                        {/if}
                        {#if !colonies.length || showInput}
                            <InputText bind:value={ colony }  placeholder="Colonia" />
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    {/if}
{/if}