<script>

    import { toast } from 'bulma-toast'
    import { pickupsStore, modalStore, userStore } from '../stores'
    
    import PickupsService from '../$services/pickups.service'
    import DateMethod from '../$methods/date.methods.js'

    import Pagination from '../$components/pagination.svelte'
    
    import Spinner from '../$components/spinner.svelte'
    import Message from '../$components/message.svelte'

    let query = {
        page: 0,
        find: null
    }
    let metadata = {}
    let loading = false

    getPickups()

    async function getPickups() {

        query.userId = $userStore._id

        loading = true
        const resp = await PickupsService.getPickups(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        pickupsStore.set(resp.data.pickups)
        metadata = resp.data.metadata
    }

</script>

{#if !loading }
    <div class="items-space scpace-bottom">
        <small>
            { $pickupsStore.length } 
            {#if metadata.total}
                / {metadata.total}
            {/if}
            Resultados
        </small>
        <Pagination bind:metadata bind:page={ query.page } on:change={ getPickups } />
    </div>
{/if}

<Spinner {loading} />
<Message show={!loading && !$pickupsStore.length} />

{#if !loading && $pickupsStore.length}
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-truck"></i>
                            </span>
                            <span>Proveedor</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-hashtag"></i>
                            </span>
                            <span>Guía</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-map-marker-alt"></i>
                            </span>
                            <span>Destino</span>
                        </div>
                    </th>
                    <th>
                        <div class="icon-text">
                            <span class="icon">
                              <i class="fas fa-calendar"></i>
                            </span>
                            <span>Fecha de recolección</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each $pickupsStore as pickup, index}
                    <tr>
                        <td>{ index+1 }</td>
                        <td>{ pickup.provider }</td>
                        <td>{ pickup.guide }</td>
                        <td>
                            <div>{ pickup.location.contact }</div>
                            <div>{ pickup.location.zipcode } { pickup.location.city }, { pickup.location.state }</div>
                        </td>
                        <td>{ DateMethod.dateShort(pickup.date) }</td>
                        <td>
                            <button on:click={() => modalStore.open('pickup', 'modal', pickup)} class="button">
                                <span class="icon"><i class="fas fa-ellipsis-v"></i></span>
                            </button>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{/if}