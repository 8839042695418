<script>

    import { orderStore, modalStore } from '../stores'

    import OrderComponent from './component.order.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'order_modal'

</script>

{#if $orderStore}
    <Modal title="Información" {show}>
        <OrderComponent order={$orderStore} />
    </Modal>
{/if}