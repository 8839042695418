<script>

    export let text = ''
    export let icon = ''

</script>

<span class="icon-text">
    <span class="icon">
      <i class="fas { icon }"></i>
    </span>
    <span>{ text }</span>
</span>