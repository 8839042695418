<script>

    import Spinner from './spinner.svelte'

    export let loading = false
    export let nopadding = false

</script>

<style>
    .nopadding {
        padding: 0;
    }
</style>

<Spinner { loading } />

{#if !loading}
    <form on:submit|preventDefault class:nopadding={nopadding}>
        <slot></slot>
    </form>
{/if}