<script>

    import { incidentStore } from '../stores'
    
    import DateMethods from '../$methods/date.methods'

    import Value from '../$components/value.svelte'

    function mapStatus(status) {
        switch (status) {
            case 'open': return 'Abierta'
            case 'closed': return 'Cerrada'
            case 'success': return 'Solucionada'
        }
    }

</script>

<div class="columns">
    <div class="column">
        <Value label="Asunto" value={ mapStatus($incidentStore.status) } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Asunto" value={ $incidentStore.subject } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Detalles" value={ $incidentStore.details } />
    </div>
</div>
<div class="columns">
    <div class="column">
        <Value label="Fecha de registro" value={ DateMethods.dateTimeLarge($incidentStore.created) } />
    </div>
</div>