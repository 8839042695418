<script>

    import { onMount, onDestroy } from 'svelte'

    $: (!value && quill) && quill.setText('')

    export let value = null
    export let label = null
    export let placeholder = null

    let container = null
    let quill = null

    onMount(() => {
        try {

            window.Quill.register('modules/imageCompress', window.imageCompressor)

            if(container && !quill) {

                quill = new window.Quill(container, {
                    placeholder,
                    theme: 'snow',
                    modules: {
                        toolbar: [
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline', 'strike'],
                            ['blockquote', 'code', 'link', 'image'],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'align': [] }],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ],
                        imageCompress: {
                            quality: 0.9,
                            maxWidth: 1000,
                            maxHeight: 1000,
                            imageType: 'image/jpeg'
                        }
                    }
                })

                if(value) {
                    const delta = quill.clipboard.convert(value)
                    quill.setContents(delta, 'silent')
                }

                quill.on('text-change', (delta, oldDelta, source) => {
                    value = quill.container.firstChild.innerHTML
                })
            }
        } catch(e) {}
    })

    onDestroy(() => {
        container = null
        quill = null
    })

</script>

<div class="field">
    {#if label}
        <label for="" class="label">{ label }</label>
    {/if}
    <div class="control">
        <div bind:this={container}></div>
    </div>
</div>