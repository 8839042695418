import Superagent from 'superagent'
import Storage from './storage.service'
import $logout from './$logout'

const { HOST_API } = _CONSTANTS_

export default {
    createClient,
    createInvoice,
    getClient,
    getInvoices,
    getInvoiceDocument,
    getInvoiceAmount,
    updateClient,
    cancelInvoice
}

function createClient(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .post(`${HOST_API}/facturama/clients`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function createInvoice(data) {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .post(`${HOST_API}/facturama/invoices`)
            .set('access', Storage.getItem('access'))
            .query({timezone})
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getClient() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/facturama/clients`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getInvoices() {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/facturama/invoices`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function getInvoiceDocument(invoiceId, type) {
    return new Promise((resolve, reject) => {
        Superagent
            .get(`${HOST_API}/facturama/invoices/${invoiceId}/${type}`)
            .set('access', Storage.getItem('access'))
            .responseType('blob')
            .end((err, res) => {
                $logout(res.body)
                if(err)
                    resolve(null)
                else
                    resolve(res.body)
            })
    })
}

function getInvoiceAmount() {
    return new Promise((resolve, reject) => {

        const timezone = new Date().getTimezoneOffset()/60

        Superagent
            .get(`${HOST_API}/facturama/invoices/amount`)
            .set('access', Storage.getItem('access'))
            .query({timezone})
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function updateClient(data) {
    return new Promise((resolve, reject) => {
        Superagent
            .put(`${HOST_API}/facturama/clients`)
            .set('access', Storage.getItem('access'))
            .send(data)
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}

function cancelInvoice(invoiceId) {
    return new Promise((resolve, reject) => {
        Superagent
            .delete(`${HOST_API}/facturama/invoices/${invoiceId}/cancel`)
            .set('access', Storage.getItem('access'))
            .end((err, res) => {
                $logout(res.body)
                resolve(res.body)
            })
    })
}