<script>

    import { modalStore } from '../stores'
    
    import AccountComponent from './component.account.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'account_modal'

</script>

<Modal title="Cuenta consumo" {show} >
    <AccountComponent />
</Modal>