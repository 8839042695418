<script>

    import { toast } from 'bulma-toast'
    import { modalStore } from '../stores'

    import MerchandisesService from '../$services/merchandises.service'
    import NumeralMethods from '../$methods/numeral.methods'

    import SatProducts from '../catalogs/component.sat.products.svelte'
    import SatUnits from '../catalogs/component.sat.units.svelte'

    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Button from '../$components/button.svelte'
    import Select from '../$components/select.svelte'
    import Table from '../$components/table.svelte'
    import Form from '../$components/form.svelte'

    import ModalId from '../$components/modal.id.svelte'

    export let merchandises = []
    export let packageId = null
    export let maxWeight = 0

    let loading = false
    let data = {}
    let items = []
    let itemSelected = ""

    getMerchandises()

    async function getMerchandises() {

        loading = true
        const resp = await MerchandisesService.getMerchandises()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        items = resp.data

        if(!data.product)
            data = items.find(item => item.default) || {}

        itemSelected = data? data._id : null

        if(packageId) {
            const merchandise = items.find(item => item.packageId === packageId)
            if(merchandise)
                merchandises = [merchandise]
        } else {
            const merchandiseDefault = items.find(item => item.default)
            if(merchandiseDefault)
                merchandises = [merchandiseDefault]
        }
    }

    function appendMerchandise() {

        if(!data.product.code)
            return toast({message: 'No se eligio un producto de la lista', type: 'is-warning'})

        if(!data.unit.code)
            return toast({message: 'No se eligio una unidad de medida de la lista', type: 'is-warning'})

        merchandises = [...merchandises, data]
        modalStore.close()
    }

    function removeMerchandise(index) {
        merchandises = merchandises.filter((item, i) => i != index)
    }

    function setSelected() {
        const item = items.find(item => item._id === itemSelected)
        data = {...item}
    }

</script>

<ModalId id="merchandise_append_modal" title="Agregar producto a la lista">

    {#if items.length}
        <Select on:select={ setSelected } bind:value={itemSelected} label="Mis Mercancias" icon="list" blank={true}
            options={items.map(item => {
                return {value: item._id, text: item.alias}
            })}
        />
        <hr>
    {/if}

    <Form on:submit={appendMerchandise} >
        <div class="columns">
            <div class="column">
                <SatProducts bind:selected={data.product} />
            </div>
            <div class="column">
                <SatUnits bind:selected={data.unit} />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputNumber bind:value={data.items} label="Cantidad" icon="list-alt" placeholder="Cantidad de productos" />
            </div>
            <div class="column">
                <InputNumber bind:value={data.weight} label="Peso" icon="weight" placeholder="KG" step="0.01" max={ maxWeight } />
            </div>
            <div class="column is-6">
                <InputNumber bind:value={data.value} label="Valor" icon="dollar-sign" placeholder="MXN" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <InputText bind:value={data.description} label="Descripción" icon="file-signature" placeholder="Descripción exacta de los productos" />
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="items-end">
                    <Button type="submit" icon="plus" text="Agregar" />
                </div>
            </div>
        </div>
    </Form>
</ModalId>

<div class="columns">
    <div class="column">

        <div class="items-end space-bottom">
            <Button on:click={() => modalStore.set('merchandise_append_modal')} type="button" text="Agregar" icon="plus" />
        </div>

        <Table items={merchandises.length}>
            <thead>
                <tr>
                    <th>Cantidad</th>
                    <th>Producto</th>
                    <th>Valor</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {#each merchandises as merchandise, index}
                    <tr>
                        <td>{ merchandise.items }</td>
                        <td>{ merchandise.product.name }</td>
                        <td>{ NumeralMethods.cash(merchandise.value) }</td>
                        <td>
                            <Button on:click={() => removeMerchandise(index)} icon="trash" />
                        </td>
                    </tr>
                {/each}
            </tbody>
        </Table>
    </div>
</div>