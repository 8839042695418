<script>

    import { packageStore, modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import PackageComponent from './component.package.svelte'

    $: show = $modalStore === 'package_modal'
    
</script>


<Modal title="Paquete" {show}>
    <PackageComponent ipackage={$packageStore} />
</Modal>