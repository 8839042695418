<script>

    import { modalStore } from '../stores'

    import UpsAccountUpdateComponent from './component.update.svelte'
    import Modal from '../$components/modal.svelte'

    $: show = $modalStore === 'ups_update_modal'

</script>

<Modal title="Actualizar cuenta" {show}>
    <UpsAccountUpdateComponent />
</Modal>