<script>

    import { modalStore } from '../stores'

    import Modal from '../$components/modal.svelte'
    import TicketComponent from './component.ticket.svelte'

    $: show = $modalStore === 'ticket_modal'

</script>

<Modal title="Ticket" {show}>
    <TicketComponent />
</Modal>