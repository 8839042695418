<script>

    import { afterUpdate } from 'svelte'
    import { replace } from 'svelte-spa-router'
    import { toast } from 'bulma-toast'

    import { tutorialsStore } from '../stores'

    import TutorialsService from '../$services/tutorials.service'

    import Spinner from '../$components/spinner.svelte'
    import InputText from '../$components/input.text.svelte'
    import Pagination from '../$components/pagination.svelte'

    afterUpdate(() => {
        
        const videos = document.getElementsByTagName('video')

        if(!videos || !videos.length)
            return

        for(let i=0; i<videos.length; i++)
            videos[i].currentTime = 2
    })

    let loading = false
    let query = {}
    let metadata = {}

    getTutorials()

    async function getTutorials() {

        loading = true
        const resp = await TutorialsService.getTutorials(query)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        tutorialsStore.set(resp.data.tutorials)
        metadata = resp.data.metadata
    }

    function showView(tutorial) {
        replace(`#/tutorial?title=${tutorial.title}`)
    }

</script>

<style>
    .store-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
</style>

<div class="center space-bottom">
    <div class="title">Tutoriales</div>
</div>

<div class="columns">
    <div class="column">
        <InputText on:enter={ getTutorials } bind:value={query.find} icon="search" placeholder="Buscar" />
    </div>
</div>

<div class="items-end space-bottom">
    <Pagination bind:metadata bind:page={query.query} on:change={ getTutorials } />
</div>
<br/>
<Spinner {loading} />

{#if !loading}
    <div class="store-items">
        {#each $tutorialsStore as tutorial }
            <div on:click={() => showView(tutorial)} class="pointer">
                <video>
                    <source src={tutorial.urlVideo} type="video/mp4" >
                    <track kind="captions"/>
                    No soportado por el navegador
                </video>
                <div class="title is-5">{ tutorial.title } </div>
            </div>
        {/each}
    </div>
{/if}