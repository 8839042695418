<script>
    
    import { toast } from 'bulma-toast'
    import { replace } from 'svelte-spa-router'
    import { ordersAutomaticStore, storeStore, configStore, userStore, notifyStore } from '../stores'

    import ShipmentsService from '../$services/shipments.service'

    import LocationSelectComponent from '../locations/component.select.svelte'
    import PackageSelectComponent from '../packages/component.select.svelte'
    import Merchandises from '../merchandises/component.merchandises.list.svelte'
    import Providers from '../$components/providers.svelte'
    import Switch from '../$components/switch.svelte'

    import Menu from '../$layouts/menu.layout.svelte'
    
    $: data.pickup.sendEmail = $configStore && $configStore.email? $configStore.email.sendPickup : false
    
    let data = {
        documentFormat: 'pdf',
        maxPrice: 1000,
        pickup: {},
        delivery: {},
        merchandises: []
    }
    
    let step = 'pickup'

    let orders = $ordersAutomaticStore
    
    let success = false
    let error = false
    let total = 0

    if(!$ordersAutomaticStore.length)
        replace('#/orders')

    async function createAutomatic() {

        await orderAutomatic()

        toast({message: 'Proceso terminado', type: 'is-success'})
        notifyStore.set('orders_updated')
    }

    async function orderAutomatic(index = 0) {

        if(index >= orders.length) {
            success = true
            error = orders.find(order => order.error)
            total = orders.filter(order => order.success).length
            return
        }

        orders[index].error = null
        orders[index].loading = true

        data.delivery = orders[index].delivery

        const orderdata = {
            orderId: orders[index]._id,
            remoteId: orders[index].remoteId,
            userId: $userStore._id,
            storeId: $storeStore._id,
            ...data
        }

        const resp = await ShipmentsService.createShipmentAutomatic(orderdata)

        orders[index].loading = false

        if(!resp.success) {
            orders[index].error = resp.error.message
        }
        else {
            orders[index].success = true
            orders[index].shipment = resp.data.shipment
        }

        return await orderAutomatic(index+1)
    }

    function onPickupSelected() {
        step = 'package'
    }

    function onPackageSelected() {

        if(!data.merchandises.length)
            return toast({message: 'Se requerie registrar la mercancia que se envía', type: 'is-danger'})

        data.deliverySendEmail = $configStore && $configStore.email? $configStore.email.sendDelivery : false
        step = 'provider'
    }

    function onProviderSelected() {
        step = 'shipments'
        createAutomatic()
    }

    function onCancel() {
        step = 'pickup'
        replace('#/orders')
    }

    function setStep(event) {
        step = event.detail || event
    }

</script>

<style>
    .column.is-11 .steps{
        margin-left: 7%;
        margin-right: 16%;
        margin-bottom: 0%
    }
</style>

<Menu />

<div class="container">
    <br><br>
    <div class="columns">
        <div class="column is-1">
            <div class="items-center">
                {#if step === 'pickup'}
                    <span on:click={onCancel} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'package'}
                    <span on:click={() => setStep('pickup')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'provider'}
                    <span on:click={() => setStep('package')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'shipments'}
                    <span on:click={() => setStep('provider')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
            </div>
        </div>
        <div class="column is-11">
            <ul class="steps has-content-centered">
                <li class="steps-segment" class:is-active={step === 'pickup'}>
                    <span class="steps-marker">1</span>
                    <div class="steps-content">
                        <div class="subtitle">Origen</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'package'}>
                    <span class="steps-marker">2</span>
                    <div class="steps-content">
                        <div class="subtitle">Paquete</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'provider'}>
                    <span class="steps-marker">3</span>
                    <div class="steps-content">
                        <div class="subtitle">Servicio</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'shipments'}>
                    <span class="steps-marker">4</span>
                    <div class="steps-content">
                        <div class="subtitle">Guías</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    {#if $ordersAutomaticStore}
        {#if step === 'pickup'}
            <LocationSelectComponent {step} bind:location={data.pickup} on:selected={onPickupSelected} />
        {/if}
        {#if step === 'package'}
            <div class="columns">
                <div class="column">
                    <div class="title is-5">Mercancia</div>
                    <Merchandises bind:merchandises={data.merchandises} />
                </div>
            </div>
            <hr>
            <PackageSelectComponent bind:ipackage={data.package} showSwitchOrder={true} on:selected={onPackageSelected} />
        {/if}
        {#if step === 'provider'}
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <form on:submit|preventDefault={onProviderSelected}>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label for="" class="label">¿De donde se tomará el servicio?</label>
                                        <div class="control">
                                            <div class="select">
                                                <select bind:value={data.quotationType} required>
                                                    <option value="">¿De donde se tomará el servicio?</option>
                                                    <option value="order">Orden</option>
                                                    <option value="setting">Configuración</option>
                                                    <option value="any">Primera opción con orden, si no como segunda opción configuración</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {#if data.quotationType === 'setting' || data.quotationType === 'any'}
                                <label for="" class="label">Servicio principal</label>
                                <Providers bind:provider={data.provider} bind:service={data.service} />

                                <label for="" class="label">Servicio secundario</label>
                                <Providers bind:provider={data.providerAux} bind:service={data.serviceAux} />
                            {/if}

                            <div class="field">
                                <label for="" class="label">Precio máximo</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-dollar-sign"></i></span>
                                    <input bind:value={data.maxPrice} type="text" class="input" placeholder="Precio máximo">
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label for="" class="label">Identificador personal</label>
                                        <div class="control has-icons-left">
                                            <span class="icon"><i class="fas fa-tag"></i></span>
                                            <input bind:value={data.tag} type="text" class="input" placeholder="Identificador personal">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label for="" class="label">Formato de guía</label>
                                        <div class="control">
                                            <div class="select">
                                                <select bind:value={data.documentFormat}>
                                                    <option value="pdf">pdf</option>
                                                    <option value="6x4">6x4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <Switch bind:checked={data.deliverySendEmail} label="Envíar correo al destinatario" />
                            </div>
                            <div class="items-end">
                                <button type="submit" class="button is-primary">
                                    <span class="icon"><i class="fas fa-play"></i></span>
                                    <span>Iniciar</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        {/if}
        {#if step === 'shipments'}
            <div class="container">
                <div class="columns">
                    <div class="column">
                        {#if success}
                            <div class="items-center">
                                {#if error}
                                    {#if total > 0}
                                        <div class="title is-4">Se logro generar algunas guías 🙂</div>
                                    {:else}
                                        <div class="title is-4">Lo sentimos, no logramos generar las guías 😧</div>
                                    {/if}
                                {:else}
                                    <div class="title is-4">Se han generado todas las guías 🤩</div>
                                {/if}
                            </div>
                            <div class="is-divider"></div>
                        {/if}
                        <div class="table-container">
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="icon-text">
                                                <span class="icon">
                                                <i class="fas fa-hashtag"></i>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="icon-text">
                                                <span class="icon">
                                                <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                                <span>Destino</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="icon-text">
                                                <span class="icon">
                                                <i class="fas fa-clipboard-check"></i>
                                                </span>
                                                <span>Estatus</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="icon-text">
                                                <span class="icon">
                                                <i class="fas fa-truck"></i>
                                                </span>
                                                <span>Servicio</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each orders as order}
                                        <tr>
                                            <th>{ order.number }</th>
                                            <td>
                                                <div>{ order.delivery.contact }</div>
                                                <div>{ order.delivery.zipcode }, { order.delivery.city }, { order.delivery.state }</div>
                                            </td>
                                            <td>
                                                {#if order.loading}
                                                    <progress class="progress is-primary" max="100">0%</progress>
                                                {:else if order.error}
                                                    <div>{ order.error }</div>
                                                {:else if order.success}
                                                    <div>
                                                        <a href="{ order.shipment.document }" class="button is-primary" target="_blank">
                                                            <span class="icon has-text-primary"><i class="fas fa-file-pdf"></i></span>
                                                            <span>Descargar</span>
                                                        </a>
                                                    </div>
                                                {/if}
                                            </td>
                                            <td>
                                                {#if order.success}
                                                    <div>{ order.shipment.provider } { order.shipment.service }</div>
                                                    {#if order.service}
                                                        <div>{ order.service.code }</div>
                                                    {/if}
                                                {/if}
                                            </td>
                                        </tr>
                                    {/each}
                                </tbody>
                            </table>
                        </div>
                        {#if success}
                            <div class="items-end">
                                <button on:click={() => replace('#/orders')} class="button space-right">
                                    <span class="icon"><i class="fas fa-clipboard-list"></i></span>
                                    <span>Ver mis órdenes</span>
                                </button>
                                <button on:click={() => replace('#/shipments')} class="button is-primary">
                                    <span class="icon"><i class="fas fa-truck"></i></span>
                                    <span>Ver mis envíos</span>
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
        {/if}
    {/if}
</div>