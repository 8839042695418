<script>

    import { toast } from "bulma-toast"
    import { postsStore, modalStore } from "../stores"

    import PostService from "../$services/posts.service"

    import Quill from "../$components/quill.svelte"
    import Select from "../$components/select.svelte"
    import Button from "../$components/button.svelte"
    import InputText from "../$components/input.text.svelte"
    import Form from "../$components/form.svelte"

    let loading = false
    let data = {}

    async function createPost() {

        loading = true
        const resp = await PostService.createPost(data)
        loading = false

        if(!resp.success)
            return toast({ message: resp.error.message, type: "is-danger" })

        loading = true
        const post = await PostService.getPost(resp.data._id)
        loading = false

        if(!post.success)
            return toast({message: post.error.message, type: 'is-danger'})


        postsStore.append(post.data)
        modalStore.close()

        toast({ message: 'Post creado', type: "is-success" })
    }

</script>

<Form on:submit={createPost} {loading} >

    <Select bind:value={data.tag} text="Categoria" icon="star" options={[
        { value: "General", text: "General" },
        { value: "Marketing", text: "Marketing" },
        { value: "Tecnología", text: "Tecnología" },
        { value: "Resolución de problemas", text: "Resolución de problemas" },
        { value: "Ventas", text: "Ventas" },
        { value: "eCommerce", text: "eCommerce" },
    ]} />

    <InputText bind:value={data.title} label="Titulo" icon="heading" placeholder="Ejem: como integro Shopify" />

    <Quill bind:value={data.content} />

    <div class="items-end space-top">
        <Button type="submit" text="Postear" color="primary" />
    </div>

</Form>